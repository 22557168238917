import create from 'zustand';
import { OkrTypeEnum } from '@/api/okr';
import { IProcessDetail, ProcessSourceTypeEnum } from '@/api/process';
import useOkrStore from './okr';
import useMessageStore from './message';

export interface IStore {
  isEmptyKrProcessHide: boolean; // 查看他人进展时，是否隐藏空的进展的 OKR
  toggleEmptyKrProcessHide: () => void;
  currentProcessType: OkrTypeEnum;
  setStoreValue: SetStoreValue;
  setStoreValues: (values: Partial<IStore>) => void;
  currentProcessUserId?: number;
  currentProcessDeptId?: string;
  currentProcessDateId: number;
  currentProcessYear: number;
  currentProcessOkrDateId: number;
  currentProcessSourceType?: ProcessSourceTypeEnum;
  processDetail: IProcessDetail;
}

type SetStoreValue = <K extends keyof IStore>(key: K, value: IStore[K]) => void;

export const useStore = create<IStore>((set) => ({
  isEmptyKrProcessHide: true,
  toggleEmptyKrProcessHide: () =>
    set((state) => ({ isEmptyKrProcessHide: !state.isEmptyKrProcessHide })),
  setStoreValue: (key, value) => set(() => ({ [key]: value })),
  setStoreValues: (values) => set(() => ({ ...values })),
  currentProcessType: OkrTypeEnum.personal,
  currentProcessUserId: 0,
  currentProcessDeptId: undefined,
  currentProcessDateId: 0,
  currentProcessYear: new Date().getFullYear(),
  currentProcessOkrDateId: 0,
  currentProcessSourceType: undefined,
  processDetail: {} as IProcessDetail,
}));

export { useOkrStore, useMessageStore };
