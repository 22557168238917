import { IUserRoleItem } from '@/api/auth';

// return用户角色权限表，页面路由和操作功能，用法：useAccess()或Access组件包裹
export default function (initialState: any) {
  const { userInfo } = initialState || {};

  const userRoles = userInfo?.userRoleList?.map((role: IUserRoleItem) => role.name);

  return {
    userRoles: {
      groupOkrAdmin: userRoles?.includes('DEPT_OKR_ADMIN'),
      deptAdmin: userRoles?.includes('DEPT_OKR_ADMIN'),
      virDeptAdmin: userRoles?.includes('VIR_OKR_ADMIN'),
      seeReport: userInfo?.seeReport,
      superAdmin: userInfo?.superAdmin,
    },
  };
}
