import React, { memo } from 'react';
import { Tooltip } from 'antd';
import { IDiscoverItem } from '@/api/discover';
import { IconFont } from '@/components';
import { RelType } from '@/constants';
import { getParams } from '@/utils/tools';
import { dataTrack } from '@/utils/track';
import { OAndKrTitle } from '..';
import {
  UGLY_determinePeriodType,
  UGLY_determineShareType,
  UGLY_determineSetGroupType,
  UGLY_determineAlignType,
} from './utils';
import styles from './o-card.less';

const EVENT_ID_MAP = {
  team: {
    o: 'click_discover_team_o',
    align: 'click_discover_team_align',
  },
  follow: {
    o: 'click_discover_follow_o',
    align: 'click_discover_follow_align',
  },
};

interface IProps {
  data: IDiscoverItem;
  onOpenDrawer: (data: IDiscoverItem, tabActivityKey?: string) => void;
}

const OCard = (props: IProps) => {
  const { data, onOpenDrawer } = props;

  const urlParams: any = getParams();

  const { lastRecordContent, relResultList } = data || {};

  const [, ...actionTextArr] = (lastRecordContent || '').split(' ');
  const actionStr = actionTextArr.join('  ');

  const isPeriodDiscover = UGLY_determinePeriodType(actionStr);
  const isShareDiscover = UGLY_determineShareType(actionStr);
  const isGroupChangeDiscover = UGLY_determineSetGroupType(actionStr);
  const isAlignDiscover = UGLY_determineAlignType(actionStr);

  const onClickAlign = () => {
    onOpenDrawer(data, 'align');

    // @ts-ignore
    const eventId = EVENT_ID_MAP[urlParams?.tab]?.align || '';
    if (!eventId) return;
    dataTrack(eventId);
  };

  const relText = relResultList
    ?.map(
      (item) =>
        // @ts-ignore
        RelType[item.relType] +
        '：' +
        item.userList?.map((u: any) => u.belongName + `(${u.relResult?.length || 0})`).join('，'),
    )
    .join('  ');

  const onClickTitle = (data: IDiscoverItem) => {
    onOpenDrawer(data);

    // @ts-ignore
    const eventId = EVENT_ID_MAP[urlParams?.tab]?.o || '';
    if (!eventId) return;
    dataTrack(eventId);
  };

  return (
    <div className={styles.container}>
      {isAlignDiscover && (
        <div className={styles.alignContainer}>
          <IconFont className={styles.icon} type="icon-duiqi" />
          <Tooltip title="查看详情">
            <span className={styles.alignText} onClick={onClickAlign}>
              {relText}
            </span>
          </Tooltip>
        </div>
      )}
      <OAndKrTitle data={data} onOpenDrawer={onClickTitle} />
      {(isPeriodDiscover || isShareDiscover || isGroupChangeDiscover) && (
        <div className={styles.oContent}>{actionStr}</div>
      )}
    </div>
  );
};

export default memo(OCard);
