import React, { useState, useEffect } from 'react';
import { Tree } from 'antd';
import type { TreeProps } from 'antd';
import { useTreeData } from '@/hooks';
import settingApi from '@/api/setting';
import './index.less';

interface IProps {
  deptTreeChange?: (deptId: string) => void;
  isVirtual?: boolean;
}

export default function DeptTree(props: IProps) {
  const { deptTreeChange, isVirtual } = props;
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]); //  选中

  const { treeData, onLoadData } = useTreeData(
    isVirtual ? settingApi.getVirDeptTree : settingApi.getDeptTree,
    {
      getPayload: (params: any) => params?.id,
      // @ts-ignore
      listToNodes: (deptList) => {
        return deptList.map((item) => {
          const { id, deptName, hasChildren } = item;
          return {
            ...item,
            key: id,
            value: id,
            title: deptName,
            isLeaf: !hasChildren,
          };
        });
      },
    },
  );

  const onSelect: TreeProps['onSelect'] = (selectedKeys, option) => {
    setSelectedKeys(selectedKeys);
    deptTreeChange?.(option.node.key as string);
  };

  useEffect(() => {
    if (treeData && !selectedKeys?.length) {
      deptTreeChange?.(treeData[0]?.key as string);
      setSelectedKeys([treeData[0]?.key]);
    }
  }, [treeData, selectedKeys]);

  return (
    <div className="m-dept-tree">
      <Tree
        onSelect={onSelect}
        selectedKeys={selectedKeys}
        treeData={treeData}
        loadData={onLoadData}
      />
    </div>
  );
}
