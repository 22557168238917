import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Select, Col, Row, TreeSelect, Form } from 'antd';
import { useRequest } from 'ahooks';
import { ExportButton } from '@/components';
import operationApi from '@/api/operation';
import { IDeptItem } from '@/api/auth';
import processApi, { DateTimeRange, ProcessSourceTypeEnum } from '@/api/process';
import styles from './index.less';

const { TreeNode } = TreeSelect;

function formatTime(date: string) {
  return dayjs(date).isValid() ? dayjs(date).format('MM/DD') : '-';
}

const DataExport = () => {
  const [form] = Form.useForm();

  const [deptTree, setDeptTree] = useState<IDeptItem[]>([]);

  useRequest(operationApi.getDeptTree, {
    onSuccess: (data) => {
      setDeptTree(data);
    },
  });
  const { data: periodList } = useRequest(() =>
    //@ts-ignore okrDateId不传
    processApi.getPeriodList({
      type: 1,
      processSourceType: ProcessSourceTypeEnum.PROCESS_WEEK_REPORT,
      year: new Date().getFullYear(),
      dateTimeRange: DateTimeRange.HalfYear,
    }),
  );

  const onExportData = async () => {
    const values = await form.validateFields();
    const { processKey } = await operationApi.exportWeekReport(values);
    return processKey;
  };

  const loadData = async (treeNode: any) => {
    if (treeNode.props.childList) return;
    const data = await operationApi.getDeptTree(treeNode.props.dataRef.id);
    treeNode.props.dataRef.childList = data;
    setDeptTree([...deptTree]);
  };

  const renderTreeNodes = (data: IDeptItem[]) => {
    return data?.map((item) => {
      if (item.childList) {
        return (
          <TreeNode
            key={item.deptId}
            title={item.deptName}
            fullPath={item.deptPath}
            isLeaf={!item.childList}
            dataRef={item}
            value={item.deptId}
          >
            {renderTreeNodes(item.childList)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.deptId}
          title={item.deptName}
          fullPath={item.deptPath}
          isLeaf={!item.hasChildren}
          dataRef={item}
          value={item.deptId}
        />
      );
    });
  };

  return (
    <div className={styles.container}>
      <div>周报数量统数据导出</div>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} className={styles.form}>
        <Row>
          <Col span={8}>
            <Form.Item
              label="部门"
              name="deptIdList"
              required
              rules={[{ message: '请选择', required: true }]}
            >
              <TreeSelect
                loadData={loadData}
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                allowClear
                placeholder="请选择"
                treeCheckable
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
              >
                {renderTreeNodes(deptTree)}
              </TreeSelect>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="时间"
              name="dateIdList"
              required
              rules={[{ message: '请选择', required: true }]}
            >
              <Select
                placeholder="请选择"
                allowClear
                mode="multiple"
                options={periodList?.map((item) => ({
                  label: `${item.name} (${formatTime(item.startDate)} ~ ${formatTime(
                    item.endDate,
                  )})`,
                  value: item.dateId,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <ExportButton getTaskId={onExportData} />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DataExport;
