import React, { memo } from 'react';
import { Cascader } from 'antd';
import './index.less';

const START_YEAR = 2017;
const CURRENT_YEAR = new Date().getFullYear();
const END_YEAR = CURRENT_YEAR + 1;

const types = [
  { value: '全部', label: '全部' },
  {
    value: '年度',
    label: '年度',
    date: ['1-1', '12-31'],
  },
  {
    value: '上半年',
    label: '上半年度',
    date: ['1-1', '6-30'],
  },
  {
    value: '下半年',
    label: '下半年度',
    date: ['7-1', '12-31'],
  },
  {
    value: 'Q1',
    label: 'Q1',
    date: ['1-1', '3-31'],
  },
  {
    value: 'Q2',
    label: 'Q2',
    date: ['4-1', '6-30'],
  },
  {
    value: 'Q3',
    label: 'Q3',
    date: ['7-1', '9-30'],
  },
  {
    value: 'Q4',
    label: 'Q4',
    date: ['10-1', '12-31'],
  },
  { value: '自定义周期', label: '自定义周期' },
];

interface IProps {
  value?: any;
  onChange?: (value: any) => void;
}

const ChooseCycle = (props: IProps) => {
  const { value, onChange } = props;

  const options = Array.from({ length: END_YEAR - START_YEAR + 1 }).map((n, index) => ({
    value: START_YEAR + index,
    label: `${START_YEAR + index}年`,
    children: types,
  }));

  const onCycleChange = (value: any) => {
    const [year] = value;
    if (year === '自定义周期') {
      onChange?.(['自定义周期', '自定义周期']);
      return;
    }
    onChange?.(value);
  };

  return (
    <Cascader
      className="u-chose-cycle"
      size="middle"
      options={options}
      expandTrigger="click"
      allowClear={false}
      placeholder="全部"
      changeOnSelect
      value={value}
      onChange={onCycleChange}
    />
  );
};

export default memo(ChooseCycle);
