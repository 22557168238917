import React from 'react';
import {
  EditorState,
  Transaction,
  EditorView,
  UICommand,
  createPopup,
  isSelectionWithinNodeType,
} from '@ehr-ui/bedrock-editor';
import { SingleUserSelect } from '@/components';
import { IUserInfo } from '@/api/auth';

class MentionCommand extends UICommand {
  name = 'mention';
  _popUp: any = null;
  editorId: string;
  constructor(icon: any, options: { tooltip?: any; desc?: any; editorId: string }) {
    super();
    this.icon = icon;
    this.tooltip = options.tooltip ?? {};
    this.desc = options.desc ?? {};
    this.editorId = options.editorId ?? '';
  }
  isEnabled = (state: EditorState) => {
    const { selection, schema } = state;
    const excludedNodeTypes = ['appendix', 'code_block'].map((n) => schema.nodes[n]);
    return !isSelectionWithinNodeType(state.doc, selection, excludedNodeTypes);
  };

  waitForUserInput = (
    state: EditorState,
    dispatch?: (tr: Transaction) => void,
    view?: EditorView,
  ): Promise<any> => {
    if (this._popUp) {
      return Promise.resolve(undefined);
    }
    return new Promise((resolve) => {
      this._popUp = createPopup(SingleUserSelect, {
        open: true,
        getContainer: document.getElementById(this.editorId),
        handleSelect: (values: IUserInfo) => {
          resolve(values);
          const { id, name } = values;
          if (dispatch) {
            let { schema, tr } = state;
            const mentionNode = schema.nodes['mention'];
            const node = mentionNode.create({ id, name }, null, undefined);
            tr = tr.replaceSelectionWith(node);
            dispatch(tr);
          }
          view && view.focus();
          this._popUp?.destroy();
          this._popUp = null;
        },
        onCancel: () => {
          resolve(undefined);
          this._popUp?.destroy();
          this._popUp = null;
        },
      });
    });
  };

  // executeWithUserInput = (
  //   state: EditorState,
  //   dispatch?: (tr: Transaction) => void,
  //   view?: EditorView,
  //   values?: { content: string; href: string },
  // ): boolean => {
  //   console.log('executeWithUserInput values', values);
  //   if (!values) return true;
  //   const { content } = values;
  //   if (dispatch) {
  //     let { schema } = state;
  //     let { tr } = state;
  //     const mentionNode = schema.nodes['mention'];
  //     const node = mentionNode.create(
  //       { mention: content },
  //       null,
  //       undefined,
  //     );
  //     tr = tr.replaceSelectionWith(node);
  //     dispatch(tr);
  //   }
  //   view && view.focus();
  //   return true;
  // };
}

export default MentionCommand;
