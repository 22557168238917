import React from 'react';
import { Tooltip } from 'antd';
import './index.less';

interface IProps {
  value?: number;
  onClickItem?: () => void;
}

const OkrProcess = ({ value, onClickItem }: IProps) => {
  return (
    <Tooltip title="查看进展">
      <span className="m-okr-process" onClick={() => onClickItem?.()}>
        {value || 0}
      </span>
    </Tooltip>
  );
};

export default OkrProcess;
