import { memo, useState } from 'react';
import { DownOutlined, ClockCircleOutlined, UpOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { IDynamicRecordItem } from '@/api/discover';
import './index.less';

interface IProps {
  dataList: IDynamicRecordItem[];
}

const RecordList = (props: IProps) => {
  const { dataList } = props;
  const [isExpand, setIsExpand] = useState(false);
  return (
    <div className="m-discover-log">
      <span>
        共{dataList.length || 1}条操作记录，
        <span className="m-show-all-btn" onClick={() => setIsExpand(!isExpand)}>
          {isExpand ? '收起' : '查看全部'}
          {isExpand ? (
            <UpOutlined className="super-mini-icons" />
          ) : (
            <DownOutlined className="super-mini-icons" />
          )}
        </span>
      </span>
      {isExpand && (
        <ul className="m-t-12">
          {dataList.map((item, index) => {
            const isLast = index === dataList?.length - 1;
            const { id, createTime, dsc, userName } = item || {};
            return (
              <li key={id}>
                <ClockCircleOutlined
                  className={`${isLast ? 'last-update' : 'not-last-update'} position-icon`}
                />
                <span
                  className={classnames({
                    'not-last-update': !isLast,
                    'right-empty': true,
                  })}
                >
                  {createTime}
                </span>
                <span
                  className={classnames({
                    'not-last-update': !isLast,
                    color: true,
                  })}
                >
                  {userName} {dsc}
                </span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default memo(RecordList);
