import React from 'react';
import emojis from './emoji-data';
import { PopoProps } from './interface';
import './emoji-sprite.less';
import './index.less';

class Popo extends React.Component<PopoProps> {
  selectItem = (code: string) => this.props.onChange(`[${code}]`);

  render() {
    return (
      <div className="u-popo">
        <ul className="emoji-container popo-container">
          <li>
            {emojis.map((emoji) => (
              <a key={emoji} onClick={() => this.selectItem(emoji)}>
                <span title={emoji} className={`emoji-item popo-emoji-${emoji}`} />
              </a>
            ))}
          </li>
        </ul>
      </div>
    );
  }
}

export default Popo;
