import React from 'react';
import { useParams, Redirect } from 'umi';

const DiscoverDetail = () => {
  const { id, type, userId }: any = useParams();

  return <Redirect to={`/discover/${id}?type=${type}&userId=${userId}`} />;
};

export default DiscoverDetail;
