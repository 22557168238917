// okr 当前筛选值 localStorage
export const COMMON_FILTERS_KEY = 'okr_filters';
export const REAL_COMMON_FILTERS_KEY = 'real_okr_filters';
export const VIR_COMMON_FILTERS_KEY = 'vir_okr_filters';
// okr 存储当前视图状态 session
export const OKR_VIEW_MODE = 'okr_view_mode';
// 兼容 v2.16 进展跳转
export const PROCESS_SOURCE = 'process-source-type';
export const PROCESS_WEEK_ID = 'process-week-date-id';
export const PROCESS_PERIOD_YEAR = 'process-okr-period-year';
