import { NodeSpec } from '@ehr-ui/bedrock-editor';

export const mentionSpec: NodeSpec = {
  attrs: {
    id: { default: '' },
    name: { default: '' },
  },
  inline: true,
  group: 'inline',
  draggable: true,
  toDOM: (node) => {
    const { id, name } = node.attrs;
    return [
      'span',
      {
        class: 'okr-mention',
        'data-id': id,
        'data-name': name,
      },
      `@${name}`,
    ];
  },
  parseDOM: [
    {
      tag: 'span[data-id][data-name]',
      getAttrs: (dom) => {
        const id = (dom as HTMLElement).getAttribute('data-id');
        const name = (dom as HTMLElement).getAttribute('data-name');
        return { id, name };
      },
    },
  ],
};
