import React, { useEffect, useState, memo } from 'react';
import { Button, App } from 'antd';
import type { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { RequestCodeMap } from '@/api';
import { OkrTypeEnum } from '@/api/okr';
import { Avatar } from '@/components';
import { useStore } from '@/store';
import authApi from '@/api/auth';
import processApi, {
  IProcessDetail,
  ProcessSourceOperateTypeEnum,
  ProcessSourceTypeEnum,
} from '@/api/process';
import {
  getStatusText,
  getTitle,
  getProcessTitle,
} from '@/pages/process/list/components/process-card/utils';
import { getEmailTitle } from './utils';
import { ModalMailTo, ShareBetweenDept } from '..';
import './index.less';
import { ProcessOtherKey } from '@/constants';

interface IProps {
  loading: boolean;
  isMine?: boolean;
  cacheEditingProcess: any;
  releaseAll$: EventEmitter<any>;
  processStatusMap?: { [key in string]: boolean };
  readonly?: boolean;
  processInfo: IProcessDetail;
  onRelease?: () => void;
  modifyProcessVO?: (partialProcessVO: IProcessDetail) => void;
}

// 进展看板头部
function DashboardHeader(props: IProps) {
  const {
    // 是否是登录用户的进展
    isMine = false,
    loading: parentLoading,
    // 缓存正在编辑的进展
    cacheEditingProcess,
    releaseAll$,
    readonly,
    processInfo,
    onRelease: onRefresh,
    modifyProcessVO,
    // 进展中是否有草稿状态，是否有发布状态
    processStatusMap,
  } = props;

  const {
    currentProcessDateId,
    currentProcessDeptId,
    currentProcessUserId,
    currentProcessSourceType,
    currentProcessType,
  } = useStore();

  const { message, modal } = App.useApp();

  const [headerInfo, setHeaderInfo] = useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentProcessType === OkrTypeEnum.organization && currentProcessDeptId) {
      processApi.getOrgProcessInfo(currentProcessDeptId).then((data) => {
        setHeaderInfo(data);
      });
      return;
    }
    if (!currentProcessUserId) return;
    processApi.getProcessInfo(currentProcessUserId).then((data) => {
      setHeaderInfo(data);
    });
  }, [currentProcessType, currentProcessDeptId, currentProcessUserId]);

  // 一键发布，当有正在编辑的进展时，发布不成功，有提示
  const releaseAll = async () => {
    let id = processInfo?.id;
    if (!id) {
      id = await beforeSubmit();
    }
    const payload = {
      userId: currentProcessUserId,
      deptId: currentProcessDeptId,
      dateId: currentProcessDateId,
      id,
      sourceType: currentProcessSourceType!,
      processList: Object.keys(cacheEditingProcess.current)
        ?.filter((item) => item !== ProcessOtherKey && cacheEditingProcess.current[item])
        ?.map((item) => cacheEditingProcess.current[item]),
    };
    console.log('cacheEditingProcess.current', cacheEditingProcess.current);
    if (cacheEditingProcess.current?.[ProcessOtherKey]) {
      Object.assign(payload, {
        processOther: cacheEditingProcess.current?.[ProcessOtherKey],
      });
    }

    console.log('payload', payload);

    processApi
      .releaseAllProcess(payload)
      .then(() => {
        releaseAll$?.emit('onSuccess');
        onRefresh?.();
        message.success('发布成功');
      })
      .catch((e) => {
        if (+e?.code === RequestCodeMap.locked) {
          modal.error({
            title: '发布失败！',
            content: '有OKR进展正处于他人编辑状态，暂不可一键发布，可以单个发布',
            okText: '我知道了',
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRelease = () => {
    if (!currentProcessDateId) return;
    setLoading(true);
    releaseAll$?.emit('onGet');
    setTimeout(() => {
      releaseAll?.();
    }, 100);
  };

  const beforeSubmit = async () => {
    const processId = await processApi.createProcessWhenEmpty({
      processSourceType: currentProcessSourceType!,
      //@ts-ignore
      operateTarget: currentProcessSourceType,
      dateId: currentProcessDateId,
      targetId: currentProcessUserId!,
    });
    return processId;
  };

  const onConfirmModify = async (authShareInfo: any) => {
    const { deptIds, virDeptIds, userIds } = authShareInfo;
    const processId = await beforeSubmit();
    return new Promise((resolve, reject) => {
      authApi
        .authShareUpdate({
          authRelId: processId,
          deptIdList: deptIds,
          userIdList: userIds,
          virDeptIdList: virDeptIds,
          // @ts-ignore
          sourceType: currentProcessSourceType,
        })
        .then((data: any) => {
          resolve(data);
          modifyProcessVO?.(authShareInfo);
        })
        .catch(() => {
          reject();
        });
    });
  };

  if (parentLoading) {
    return null;
  }

  // 小结日期不展示
  const statusMap = getStatusText(processInfo?.entity?.status);

  if (!currentProcessSourceType) return null;
  return (
    <header className="pd-header">
      <Avatar type="middle" imgPath={headerInfo?.photoUrl} userName={headerInfo?.name} />
      <div className="pd-header__info">
        <div className="pd-header__info-top">
          <span className="pd-header__info-title">
            {getTitle(currentProcessSourceType, headerInfo?.name)}
          </span>
          <span className="pd-header__info-period">{getProcessTitle(processInfo)}</span>
          {Number.isInteger(processInfo?.entity?.status) && (
            <div className={`pd-header-_info-status ${statusMap.className}`}>{statusMap.text}</div>
          )}
        </div>
        <div className="pd-header__info-bottom">
          {OkrTypeEnum.organization !== currentProcessType && (
            <div className="pd-header__card-footer">
              <ShareBetweenDept
                processId={processInfo?.id}
                authType={currentProcessSourceType}
                hasEditAuth={!readonly}
                authShareList={processInfo?.authShareList}
                onConfirmModify={onConfirmModify}
              />
            </div>
          )}
        </div>
        {!readonly && isMine && (
          <span className="pd-header__btns">
            {processStatusMap?.hasPublished &&
              currentProcessSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT && (
                <ModalMailTo
                  title={getEmailTitle(headerInfo?.name, processInfo)}
                  id={processInfo?.id}
                />
              )}
            {processStatusMap?.hasDraft && (
              <Button type="primary" onClick={onRelease} loading={loading}>
                一键发布
              </Button>
            )}
          </span>
        )}
      </div>
    </header>
  );
}

export default memo(DashboardHeader);
