//@ts-nocheck
import dayjs from 'dayjs';

function checkTimeRange(beginDateStr, endDateStr) {
  let curDate = dayjs(),
    beginDate = dayjs(beginDateStr).endOf('day'),
    endDate = dayjs(endDateStr).endOf('day');

  if (curDate >= beginDate && curDate <= endDate) {
    return true;
  }
  return false;
}

// 获取当前时间范围周期
export const getCurrentPeriod = (periodList, maxCount = 1) => {
  // 排除年度周期 type = 4
  const index = periodList?.findIndex(
    (item) => item.type !== 4 && checkTimeRange(item.startDate, item.endDate),
  );

  // 容错处理：如果获取不到对应的当前时间周期默认选择第一页第一个周期
  const id = periodList?.[index]?.id || periodList?.[0]?.id;
  const page = Math.ceil((index + 1) / maxCount) || 1;

  return [id, page];
};
