import { Spin, Empty, List, Pagination } from 'antd';
import { useAntdTable } from '@/hooks';
import api from '@/api/message';
import { MessageItem } from './components';
import styles from './index.less';

const Message = () => {
  const { tableProps, run } = useAntdTable(
    (params) =>
      api.getAllMessage({
        currentPage: params.current,
        ...(params || {}),
      }),
    {
      defaultPageSize: 20,
      useNewPagination: true,
    },
  );

  const { dataSource, pagination, loading, onChange } = tableProps;

  const onChangePage = (page: number, pageSize: number) => {
    onChange({ current: page, pageSize });
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.pageTitle}>消息中心</div>
        {!!dataSource?.length && (
          <div>
            <Spin spinning={loading}>
              <List
                className={styles.list}
                dataSource={dataSource}
                renderItem={(item) => <MessageItem key={item.id} data={item} />}
              />
            </Spin>
            <Pagination {...pagination} onChange={onChangePage} />
          </div>
        )}
        {!dataSource?.length && !loading && <Empty />}
      </div>
    </div>
  );
};

export default Message;
