import React from 'react';
import { Empty } from 'antd';
import './index.less';

const ListEmpty = ({ footer }: { footer?: React.ReactNode }) => {
  return (
    <div className="m-okr-list-empty">
      <Empty image={require('@/assets/img/no-data-okr.png')} description="当前周期内暂无OKR">
        {footer}
      </Empty>
    </div>
  );
};

export default ListEmpty;
