import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { Split } from '@/components';
import api from '@/api/setting';
import { PeriodTypeEnum } from '@/api/okr';
import { OkrPeriodOptions } from '@/constants';
import { OkrPeriodEnum } from '@/api/okr';
import { DeptTree } from '../components';
import { PeriodSelect } from './components';
import './index.less';

// OKR周期设置
function OkrPeriodSetting() {
  const [selectedDeptId, setDeptId] = useState<string>();
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const getPeriods = () => {
    if (!selectedDeptId) return;
    setLoading(true);
    api
      .getDeptPeriodList(selectedDeptId)
      .then((data) => {
        const nextList = (data || [])
          .reduce((acc, item) => {
            const { childList, ...rest } = item;
            //@ts-ignore
            return acc.concat([rest]).concat(childList || []);
          }, [])
          .map((item: any) => {
            const { periodMap } = item;
            return {
              ...item,
              ...(periodMap || {}),
            };
          });
        setList(nextList);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPeriods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeptId]);

  // OKR设定周期选项
  // 周期的选项变更为：年度、半年度、季度、双月、月度
  // 年度可选/可不选，半年度、季度、双月和月度四个选项必选其一且互斥
  // 默认年度+季度
  // OKR总结周期
  // 可选年度/半年度/季度/月度，多选不互斥
  // 默认年度+季度
  // OKR小结周期
  // 删除小结周期的设置入口

  const columns: TableColumnsType<any> = [
    {
      title: '部门名称',
      dataIndex: 'deptName',
      width: '20%',
      render: (name, record) => {
        const { isFirstDept } = record;
        const style = isFirstDept ? { fontWeight: 'bold' } : {};
        return <div style={style}>{name}</div>;
      },
    },
    {
      title: 'OKR设定周期',
      dataIndex: PeriodTypeEnum.okr,
      width: '40%',
      render: (periodValue, record) => {
        const { deptId } = record;
        return (
          <PeriodSelect
            rules={[
              OkrPeriodEnum.halfYear,
              OkrPeriodEnum.quarter,
              OkrPeriodEnum.month,
              OkrPeriodEnum.dMonth,
            ]}
            deptId={deptId}
            options={OkrPeriodOptions}
            defaultValue={periodValue}
            periodType={PeriodTypeEnum.okr}
            onModifySuccess={getPeriods}
          />
        );
      },
    },
    {
      title: 'OKR总结周期',
      dataIndex: PeriodTypeEnum.summary,
      width: '40%',
      render: (periodValue, record) => {
        const { deptId } = record;
        return (
          <PeriodSelect
            deptId={deptId}
            options={OkrPeriodOptions.filter((item: any) => item.value !== OkrPeriodEnum.dMonth)}
            defaultValue={periodValue}
            periodType={PeriodTypeEnum.summary}
            onModifySuccess={getPeriods}
          />
        );
      },
    },
  ];

  const onSelectTree = (deptId: string) => {
    if (!deptId) return;
    setDeptId(deptId);
  };

  return (
    <div className="okr-period-setting">
      <header className="okr-period-setting__header">
        <div style={{ fontSize: 16, fontWeight: 500 }}>OKR周期设置</div>
      </header>
      <Split className="okr-period-content" minSize={[188, 1000]}>
        <div className="okr-period-setting__sider">
          <DeptTree deptTreeChange={onSelectTree} />
        </div>
        <div className="okr-period-setting__content" id="okr-period-setting__content">
          <Table
            rowKey="deptId"
            columns={columns}
            loading={loading}
            dataSource={list}
            pagination={false}
          />
        </div>
      </Split>
    </div>
  );
}

export default OkrPeriodSetting;
