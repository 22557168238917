import React from 'react';
import { Empty } from 'antd';
import { OKR_EMPTY_PLACEHOLDER } from '@/constants';
import './index.less';

interface IProps {
  simple: boolean;
  hasEditAuth?: boolean;
  onSelect?: () => void;
  onAdd?: () => void;
}

function OkrEmptyPlaceholder(props: IProps) {
  const { simple = false, hasEditAuth = false, onSelect, onAdd } = props;
  return (
    <div className="pl-okr-empty-placeholder">
      {simple ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <div className="pl-okr-empty-placeholder_content">
          <img src={OKR_EMPTY_PLACEHOLDER} alt="No okrs, please check okr's period" />
          <div>当前周期内暂无OKR，您可以：</div>
          {hasEditAuth ? (
            <>
              <div>
                方式1：重新选择“OKR周期” <span onClick={onSelect}>去选择</span>
              </div>
              <div>
                方式2：添加本周期的OKR <span onClick={onAdd}>去添加</span>
              </div>
            </>
          ) : (
            <div>
              重新选择“OKR周期” <span onClick={onSelect}>去选择</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default OkrEmptyPlaceholder;
