import React, { useMemo, useState } from 'react';
import { Popover } from 'antd';
import { AddFeedback, DisplayFeedback } from '@netease-ehr/ui';
import { useRequest } from 'ahooks';
import api from '@/api/auth';
import './index.less';

const isDevEnv = !['okr.netease.com', 'okr-pre.netease.com'].includes(window.location.host);
export const EP_APP_KEY = isDevEnv
  ? 'ec5f509feb31475d9d3675100d0cae07'
  : '59b4d3915c724c84bf548b789ae52c9e';
export const EP_EMAIL = 'liyajie03@corp.netease.com'; // 负责人
export const SUB_PRODUCT_ID = isDevEnv ? '296' : 185;
export const EP_ENV = {
  Online: 'online-project',
  Dev: 'dev',
  Test: 'test',
};
export const FEEDBACK_TYPE = {
  Submit: 'submit',
  Display: 'display',
};

function SystemFeedback(props: { email: string }) {
  const { email } = props;

  const [visible, setVisible] = useState(false);

  const config = useMemo(
    () => ({
      email, // 反馈人邮箱
      appkey: EP_APP_KEY,
      responseEmail: EP_EMAIL, // 需求、bug对接人邮箱
      subProductId: SUB_PRODUCT_ID,
      channel: 'OKR',
      env: EP_ENV.Online,
    }),
    [email],
  );

  const { data } = useRequest(api.getHunterPermission);

  if (data?.bountyHunterPerm) {
    const url = isDevEnv
      ? 'https://bounty-hunter-test.netease.com'
      : 'https://hrshangjin.netease.com';
    return (
      <a className="hunter-feedback" target="_blank" rel="noreferrer" href={url}>
        <span className="hunter-pop">提系统需求/Bug及制度流程建议</span>
        <img
          width={60}
          height={60}
          src="https://ehr-web.netease.com/ehr-web/ehr-base/bounty-icon.png"
          alt=""
        />
      </a>
    );
  }

  return (
    <Popover
      overlayClassName="kf-popover"
      placement="leftBottom"
      trigger="hover"
      open={visible}
      onOpenChange={(visible) => setVisible(visible)}
      content={
        <div className="kf-content" onClick={() => setVisible(false)}>
          <AddFeedback config={config} prefixCls="ant">
            <div className="item">提需求 / 报BUG</div>
          </AddFeedback>

          <DisplayFeedback config={config}>
            <div className="item">查看用户声音</div>
          </DisplayFeedback>
        </div>
      }
    >
      <div className="system-feedback">
        <img src="https://nos.netease.com/rms/9629fe2c2761e43ad2464c7de52122ec" alt="" />
      </div>
    </Popover>
  );
}

export default SystemFeedback;
