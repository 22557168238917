import React, { useEffect, useState } from 'react';
import { Checkbox, Collapse, ConfigProvider, Select } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CaretRightOutlined } from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { IQueryAlignOkrItem, RelTypeEnum } from '@/api/okr';
import { RelType } from '@/constants';
import { OLabel } from '@/components';
import KRList from './kr-list';
import './index.less';

const OPTIONS = [
  {
    label: RelType[RelTypeEnum.U],
    value: RelTypeEnum.U,
  },
  {
    label: RelType[RelTypeEnum.M],
    value: RelTypeEnum.M,
  },
];

const { Panel } = Collapse;

interface IProps {
  item: IQueryAlignOkrItem;
  index: number;
}

const OKRList = (props: IProps) => {
  const { item, index } = props;
  const { krList = [] } = item || {};
  const [state, setState]: any = useControllableValue(props, {
    defaultValue: {},
  });

  const [checked, setChecked] = useState(state?.id === item.id);
  const [select, setSelect] = useState(state?.relType);
  const [prompt, setPrompt] = useState(false);

  const onCheckChange = (e: CheckboxChangeEvent) => {
    const {
      target: { checked },
    } = e;

    setChecked(checked);
    setPrompt(checked);
  };

  useEffect(() => {
    setSelect(state?.relType);
  }, [state?.relType]);

  const onSelect = (value: string) => {
    setSelect(value);
  };

  useEffect(() => {
    const { id, relType, ...rest } = state || {};
    let obj: any = {};
    if (checked) {
      obj.id = item.id;
      obj.type = item.type;
    }
    if (select) {
      obj.relType = select;
    }
    setState({ ...rest, ...obj });
  }, [select, checked]);

  const onKRChange = (value: any) => {
    const { krList, ...rest } = state || {};
    value?.length ? setState({ ...rest, krList: value }) : setState(rest);
    setPrompt(!!value?.length);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            fontSize: 12,
          },
        },
      }}
    >
      <div className="m-okr-alignment-list">
        <Checkbox checked={checked} onChange={onCheckChange} onClick={(e) => e.stopPropagation()} />
        <Collapse
          className="m-alignment-collapse"
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          defaultActiveKey={krList?.length ? [item.id] : undefined}
        >
          {krList?.length ? (
            <Panel
              key={item.id}
              className="m-alignment-panel"
              header={
                <span className="m-alignment-name">
                  <OLabel />
                  {item.name}
                </span>
              }
              extra={
                <span onClick={(e) => e.stopPropagation()}>
                  <Select
                    className={`m-alignment-select ${prompt ? 'm-alignment-prompt' : ''}`}
                    value={select}
                    placeholder="请选择"
                    onSelect={onSelect}
                    options={OPTIONS}
                    size="small"
                  />
                </span>
              }
            >
              <KRList list={krList} onChange={onKRChange} />
            </Panel>
          ) : (
            <div className="m-alignment-O">
              <span className="flex">
                <OLabel />
                {item.name}
              </span>
              <Select
                className={`m-alignment-select ${prompt ? 'm-alignment-prompt' : ''}`}
                value={select}
                placeholder="请选择"
                onSelect={onSelect}
                options={OPTIONS}
                size="small"
              />
            </div>
          )}
        </Collapse>
      </div>
    </ConfigProvider>
  );
};

export default OKRList;
