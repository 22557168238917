import React from 'react';
import { Table, Typography } from 'antd';
import type { TableProps, TableColumnsType } from 'antd';

export default function Table1(props: TableProps<any>) {
  const columns: TableColumnsType<any> = [
    {
      title: '姓名',
      dataIndex: 'userName',
      fixed: 'left',
      ellipsis: true,
      width: 100,
    },
    {
      title: '工号',
      fixed: 'left',
      width: 100,
      dataIndex: 'userNo',
      render: (text) => (
        <Typography.Text style={{ width: 100 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '部门',
      width: 200,
      dataIndex: 'userDeptName',
      render: (text) => (
        <Typography.Text style={{ width: 200 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: 'OKR数量',
      width: 100,
      dataIndex: 'objectNum',
      render: (text) => (
        <Typography.Text style={{ width: 100 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '周报数量',
      width: 100,
      dataIndex: 'weekReportNum',
      render: (text) => (
        <Typography.Text style={{ width: 100 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: (
        <span>
          过程小结
          <br />
          创建数量
        </span>
      ),
      dataIndex: 'briefSummaryNum',
      width: 100,
      render: (text) => (
        <Typography.Text style={{ width: 100 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: (
        <span>
          总结自评
          <br />
          创建数量
        </span>
      ),
      dataIndex: 'summaryNum',
      width: 100,
      render: (text) => (
        <Typography.Text style={{ width: 100 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: 'OKR最新状态',
      width: 240,

      children: [
        {
          title: '无状态',
          dataIndex: 'noSummaryObjectNum',
        },
        {
          title: '红灯',
          dataIndex: 'redObjectNum',
        },
        {
          title: '黄灯',
          dataIndex: 'yellowObjectNum',
        },
        {
          title: '绿灯',
          dataIndex: 'greenObjectNum',
        },
      ],
    },
    {
      title: 'OKR最新评分',
      width: 240,

      children: [
        {
          title: '无评分',
          dataIndex: 'noScoreObjectNum',
        },
        {
          title: '0-3分',
          dataIndex: 'threeScoreObjectNum',
        },
        {
          title: '3-6分',
          dataIndex: 'sixScoreObjectNum',
        },
        {
          title: '6-9分',
          dataIndex: 'nineScoreObjectNum',
        },
        {
          title: '9-10分',
          dataIndex: 'tenScoreObjectNum',
        },
      ],
    },
    {
      title: '员工类型',
      width: 140,
      dataIndex: 'empType',
    },
    {
      title: '在职状态',
      width: 100,

      dataIndex: 'state',
    },
    {
      title: '入职时间',
      width: 140,

      dataIndex: 'entryDate',
    },
  ];

  return <Table {...props} rowKey="userId" size="small" columns={columns} scroll={{ x: 2000 }} />;
}
