import React, { memo, useState } from 'react';
import { List } from 'antd';
import { IconFont, Avatar } from '@/components';
import { IOkrAlignMapItem } from '@/api/okr';
import GridList from '../grid-list'; // 第二列以后 list
import OKRStatusItem from '../okr-status-item';
import OKRPopover from '../popover';

interface IProps {
  dataSource: IOkrAlignMapItem[];
  name: string;
  headerType: string;
  onSelectUser: (user: any, option: any) => void;
}

const Basic = (props: IProps) => {
  const [currentClickId, setCurrentClickId] = useState<number>();

  const { dataSource, name, headerType, onSelectUser } = props;

  const isUser = headerType === '人员';
  const isVirDept = headerType === '虚拟';
  const isDept = headerType === '部门';

  const reRender = (id?: number) => {
    setCurrentClickId(id);
  };

  const header = (
    <div className="u-header">
      {isUser && (
        <Avatar imgPath={dataSource?.[0]?.photoUrl} userName={name} className="avatar-32" />
      )}
      {isDept && <IconFont type="icon-zuzhi1" className="dept-logo" />}
      {isVirDept && <IconFont type="icon-zuzhi2" className="dept-logo" />}
      <span style={{ marginLeft: '16px' }}>{name}的OKR</span>
    </div>
  );

  console.log('dataSource', dataSource);
  return (
    <List
      className="u-relative-main"
      header={header}
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={(item) => {
        const { id, objectivesName, summaryStatus } = item;
        return (
          <List.Item
            actions={[
              <GridList
                {...(item as any)}
                onSelectUser={onSelectUser}
                currentClickId={currentClickId!}
                reRender={reRender}
                parentIdList={[id]}
              />,
            ]}
          >
            <OKRPopover info={item}>
              <div>
                <OKRStatusItem
                  {...item}
                  objectivesName={objectivesName}
                  okrId={id}
                  summaryStatus={summaryStatus}
                />
              </div>
            </OKRPopover>
          </List.Item>
        );
      }}
    />
  );
};

export default memo(Basic);
