import React, { useEffect, useState } from 'react';
import { useControllableValue, useToggle } from 'ahooks';
import { Tooltip } from 'antd';
import { LockOutlined, EditOutlined } from '@ant-design/icons';
import useGetDefaultScope from '@/components/okr/useGetDefaultScope';
import { ChooseScope } from '@/components';
import './index.less';

interface IProps {
  authEdit?: boolean;
  value?: boolean;
  onChange?: (value: any) => void;
  oId: number;
}

const OkrShareGroup = (props: IProps) => {
  const { authEdit = false, oId } = props;
  const [value, setValue] = useControllableValue(props);
  const [state, { toggle }] = useToggle(false);
  const [choose, setChoose] = useState(props.value);

  const { defaultScope, defaultVirScope } = useGetDefaultScope(oId);

  useEffect(() => {
    setChoose(value);
  }, [value]);

  const onConfirm = () => {
    setValue(choose);
    toggle();
  };

  const text = Object.values(value || {})
    .flat()
    .map((i: any) => i?.deptName || i?.userName)
    .join('，');

  return (
    <div className="m-okr-detail-share">
      {state ? (
        <span className="m-share-edit">
          <div className="m-share-choose-scope">
            <ChooseScope
              defaultVirDeptScope={defaultVirScope}
              defaultScope={defaultScope}
              value={choose}
              onChange={(value) => setChoose(value)}
            />
          </div>
          <a onClick={toggle} style={{ color: '#666' }}>
            取消
          </a>
          <a onClick={onConfirm}>确认</a>
        </span>
      ) : (
        <span className="m-detail-share-text">
          <Tooltip title={`共享范围：${text}`} placement="bottom">
            <LockOutlined />
            <div className="m-share-list">{text}</div>
          </Tooltip>
          {authEdit && <EditOutlined className="m-share-edit-icon" onClick={toggle} />}
        </span>
      )}
    </div>
  );
};

export default OkrShareGroup;
