import React, { memo, useEffect, useState } from 'react';
import { App, Select } from 'antd';
import { useDebounceFn } from 'ahooks';
import { OkrPeriodEnum, PeriodTypeEnum } from '@/api/okr';
import api from '@/api/setting';

interface IProps {
  defaultValue: number[];
  options: { label: string; value: OkrPeriodEnum }[];
  periodType: PeriodTypeEnum;
  deptId: string;
  rules?: OkrPeriodEnum[];
  onModifySuccess?: () => void;
}

function intersection(arr1: any, arr2: any) {
  const result: any = [];
  arr1?.forEach((item: any) => {
    if (arr2?.includes(item)) {
      result.push(item);
    }
  });
  return result;
}

const PeriodSelect = memo((props: IProps) => {
  const { defaultValue, options, periodType, deptId, rules, onModifySuccess, ...rest } = props;

  const [value, setValue] = useState(defaultValue);
  const [focused, setFocused] = useState(false);
  const [newOptions, setNewOptions] =
    useState<{ label: string; value: OkrPeriodEnum; disabled?: boolean }[]>(options);

  const { message } = App.useApp();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const formatOption = (
    options: { label: string; value: OkrPeriodEnum }[],
    value: number[],
    rules: OkrPeriodEnum[],
  ) => {
    return options.map((item) => {
      let disabled = true;

      if (!rules?.includes(item.value)) {
        // 年度的可点击
        disabled = false;
      }

      if (rules?.includes(item.value)) {
        // 规则内元素
        // 存在交集，当前元素可选
        if (intersection(rules, value).length === 1 && value.includes(item.value)) {
          disabled = false;
        }

        // 不存在交集，所有元素可选
        if (intersection(rules, value).length === 0) {
          disabled = false;
        }
      }

      return {
        value: item.value,
        label: item.label,
        disabled,
      };
    });
  };

  useEffect(() => {
    if (periodType === PeriodTypeEnum.okr && rules) {
      setNewOptions(formatOption(options, value, rules));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const updatePeriod = () => {
    if (periodType === PeriodTypeEnum.okr && intersection(rules, value)?.length === 0) {
      message.warning('半年度、季度、双月和月度四个选项必选其一！');
      setValue(defaultValue);
      return;
    }

    if (!value?.length) {
      setValue(defaultValue);
      return;
    }

    api
      .updateDeptPeriod({ deptId, periodList: value, periodType })
      .then(() => {
        onModifySuccess?.();
      })
      .catch((e: any) => {
        message.warning(e.msg);
        setValue(defaultValue);
      });
  };

  const { run: deUpdatePeriod } = useDebounceFn(updatePeriod, { wait: 1200 });

  const onBlur = () => {
    setFocused(false);
    updatePeriod();
  };

  const onDeselect = () => {
    if (focused) return;
    deUpdatePeriod();
  };

  const formatData = (data: number[]) => {
    if (data.includes(OkrPeriodEnum.year)) {
      data = data.filter((item) => item !== OkrPeriodEnum.year);
      data.unshift(OkrPeriodEnum.year);
    }

    return data;
  };

  const onChange = (v: number[]) => {
    v = formatData(v);
    if (periodType === PeriodTypeEnum.summary) {
      setValue(v);
    } else {
      if (value.length > v.length) {
        // 做减法
        setValue(v);
      } else {
        // 做加法的时候做控制
        if (rules && intersection(rules, v)?.length <= 1) {
          setValue(v);
        }
      }
    }
  };

  const onFocus = () => {
    setFocused(true);
  };

  return (
    <Select
      {...rest}
      style={{ minWidth: 160 }}
      size="small"
      mode="multiple"
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onDeselect={onDeselect}
      // @ts-ignore
      getPopupContainer={() => document.getElementById('okr-period-setting__content')}
      options={newOptions}
    />
  );
});

export default PeriodSelect;
