import React from 'react';
import { useSafeState } from 'ahooks';
import { Col, Modal, Row } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { IconFont } from '@/components';
import { OkrTypeEnum } from '@/api/okr';
import { SummaryPeriodTypeEnum } from '@/constants';
import useSummaryPeriod from './use-summary-period';
import { SummaryPeriodSelect } from '..';
import './index.less';
import { dataTrack } from '@/utils/track';

interface IProps {
  year: number | string;
  okrDateId?: number;
  userId?: number;
  deptId?: string;
  onJumpDetail: (yearPeriod: any) => void;
}

/**
 * 总结编辑入口
 * @param {object} props
 * @param {number} [props.okrType] - OkrTypeEnum.user | OkrTypeEnum.org
 * @param {number} [props.userId] - 选中人员
 * @param {string} [props.deptId] - 选中部门
 */
function SummaryEditEntry(props: IProps) {
  const { year, okrDateId, userId, deptId, onJumpDetail } = props;

  const [periodItem, setPeriodItem] = useSafeState<any>();
  const [selectedDateId, setSelectDateId] = useSafeState();

  const { list, yearPeriod } = useSummaryPeriod({
    userId,
    year,
    okrDateId,
  });

  if (!list || list.length === 0) return null;

  return (
    <div className="pl-summary-entry">
      <Row gutter={20}>
        {list.map((item) => {
          const { label, value, count, className, eventParam } = item;
          return (
            <Col key={value} span={list?.length ? 24 / list?.length : 0}>
              <div
                className={`pl-summary-entry-item ${className}`}
                onClick={() => {
                  if (value === SummaryPeriodTypeEnum.year && yearPeriod) {
                    onJumpDetail?.(yearPeriod);
                  } else {
                    //@ts-ignore
                    setPeriodItem(item);
                  }

                  dataTrack('click_process_summary_entry', {
                    type: eventParam,
                  });
                }}
              >
                <div className="pl-summary-entry-item-icon">
                  <IconFont type="icon-a-rili2" style={{ color: 'white', fontSize: 28 }} />
                  <span className="pl-summary-entry-item-icon_count">{count}</span>
                </div>
                {label}
                <div className="pl-summary-entry-item-extra">
                  去填写 <RightOutlined style={{ marginLeft: 4 }} />
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Modal
        title={`${periodItem?.label} | 选择周期`}
        destroyOnClose
        bodyStyle={{ display: 'flex', justifyContent: 'center' }}
        open={!!periodItem}
        onCancel={() => {
          setPeriodItem(undefined);
          setSelectDateId(undefined);
        }}
        okButtonProps={{ disabled: !selectedDateId }}
        onOk={() => onJumpDetail?.({ dateId: selectedDateId })}
      >
        <SummaryPeriodSelect
          type={OkrTypeEnum.personal}
          userId={userId}
          deptId={deptId}
          year={year}
          okrDateId={okrDateId}
          periodType={periodItem?.value}
          selectedDateId={selectedDateId}
          onSelect={setSelectDateId}
        />
      </Modal>
    </div>
  );
}

export default SummaryEditEntry;
