import React, { useEffect } from 'react';
import { history, useLocation } from 'umi';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import { useSafeState } from 'ahooks';
import storage from '@/utils/storage';
import { ProcessSourceTypeEnum } from '@/api/process';
import './process-list-tab.less';

function ProcessTabGuide(props: { onOk: () => void }) {
  const { onOk } = props;
  return (
    <div className="pl-tab-guide">
      <div className="pl-tab-guide_arrow" />
      <div className="pl-tab-guide_content">
        你可以在这里选择OKR进展类型：
        <br />
        · 周进展（每周更新OKR进展）
        <br />· 总结自评（每月、每季度或OKR周期结束时对OKR进行总结打分）
      </div>
      <div className="pl-tab-guide_btn" onClick={onOk}>
        我知道了
      </div>
    </div>
  );
}

const TABS = [
  {
    label: '周进展',
    value: ProcessSourceTypeEnum.PROCESS_WEEK_REPORT,
  },
  {
    label: '总结自评',
    value: ProcessSourceTypeEnum.PROCESS_SUMMARY,
  },
];

const STORAGE_GUIDE_KEY = 'PROCESS_LIST_GUIDE';

interface IProps {
  isPersonalCenter?: boolean;
  activeKey: ProcessSourceTypeEnum;
  onChange: (activeKey: ProcessSourceTypeEnum) => void;
  extra?: React.ReactNode;
}

function ProcessListTab(props: IProps) {
  const { isPersonalCenter = false, activeKey, onChange, extra } = props;

  const [guideVisible, setGuideVisible] = useSafeState(false);

  const location = useLocation();

  useEffect(() => {
    // 个人中心不显示引导
    if (isPersonalCenter) return;
    if (!storage.get(STORAGE_GUIDE_KEY)) {
      setGuideVisible(true);
    }
  }, [isPersonalCenter]);

  const onChangeTab = (tab: ProcessSourceTypeEnum) => {
    onChange?.(tab);
    // @ts-ignore
    if (location.query.tab) {
      // @ts-ignore
      history.replace(`${location.pathname}?tab=${location.query.tab}&processSourceType=${tab}`);
    } else {
      // @ts-ignore
      history.replace(`${location.pathname}?processSourceType=${tab}`);
    }
  };

  return (
    <div className="pl-process-list-tabs">
      <Tooltip
        open={guideVisible}
        placement="bottom"
        //@ts-ignore
        getPopupContainer={(triggerNode) => triggerNode?.parentElement}
        overlayStyle={{
          backgroundColor: 'rgba(255, 255, 255, 0)',
          maxWidth: 380,
          visibility: 'hidden',
        }}
        title={
          <ProcessTabGuide
            onOk={() => {
              setGuideVisible(false);
              storage.set(STORAGE_GUIDE_KEY, true);
            }}
          />
        }
      >
        <div className="pl-process-list-tabs-bar">
          {TABS.map((item) => {
            const { label, value } = item;
            return (
              <div
                key={value}
                className={classnames('pl-process-list-tab', {
                  'pl-process-list-tab__active': activeKey === value,
                })}
                onClick={() => onChangeTab(value)}
              >
                {label}
              </div>
            );
          })}
        </div>
      </Tooltip>
      <div className="pl-process-list-tabs_extra">{extra}</div>
    </div>
  );
}

export default ProcessListTab;
