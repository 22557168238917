// todo 菜单后期改为运维平台配置
export default [
  {
    label: '动态',
    key: '/discover',
  },
  {
    label: 'OKR',
    key: '/okr',
  },
  {
    label: 'OKR进展',
    key: '/process/list',
  },
  {
    label: '设置',
    key: '/setting',
    needAuth: true,
    role: ['seeReport', 'deptAdmin'],
    children: [
      {
        label: 'OKR看板',
        key: '/setting/view-panel',
        needAuth: true,
        role: ['deptAdmin'],
      },
      {
        label: 'OKR报表',
        key: '/setting/report',
        needAuth: true,
        role: ['seeReport', 'deptAdmin'],
      },
      {
        label: '共享设置',
        key: '/setting/share',
        needAuth: true,
        role: ['deptAdmin'],
      },
      {
        label: '周期设置',
        key: '/setting/period',
        needAuth: true,
        role: ['deptAdmin'],
      },
      {
        label: '运维工具',
        key: '/setting/operation',
        needAuth: true,
        role: ['superAdmin'],
      },
    ],
  },
];
