import React from 'react';
import { Link } from 'umi';
import { CloseOutlined } from '@ant-design/icons';
import { CommentTypeEnum, ICommentItem } from '@/api/common';
import { IUserInfo } from '@/api/auth';
import { Avatar } from '@/components';
import CommentCard from '../comment-card';
import CommentInput from '../comment-input';
import './index.less';

interface ICommentMainProps {
  id: number;
  style?: React.CSSProperties;
  list: ICommentItem[];
  userInfo: IUserInfo;
  targetType: CommentTypeEnum;
  handleSuccess?: (data?: ICommentItem) => void;
  handleDelete?: (count: number, id: number) => void;
  autoFocus?: boolean;
  autoSize?: any;
  onCancel?: () => void;
  hideInput?: boolean;
  beforeSubmit?: () => Promise<number>;
}

export function CommentMain(props: ICommentMainProps) {
  const { id, list, style, userInfo, targetType, handleSuccess, handleDelete, hideInput, ...rest } =
    props;

  return (
    <div className="comment-main" style={style}>
      {!hideInput && (
        <div className="comment-input-container">
          <Link
            to={{ pathname: `/team/${userInfo?.id}`, search: '?tab=okr' }}
            onFocus={() => undefined}
          >
            <Avatar imgPath={userInfo?.photoUrl} className="avatar-32" userName={userInfo?.name} />
          </Link>
          <CommentInput id={id} handleSuccess={handleSuccess} targetType={targetType} {...rest} />
        </div>
      )}
      {!!list?.length && (
        <div className="comment-list">
          {list?.map((item) => (
            <CommentCard
              handleDelete={handleDelete}
              handleSuccess={handleSuccess}
              key={item.id}
              data={item}
              id={id}
              targetType={targetType}
            />
          ))}
        </div>
      )}
    </div>
  );
}

interface IProps extends ICommentMainProps {
  count: number;
  onClose: () => void;
}

export default function CommentModal(props: IProps) {
  const { count, onClose, ...rest } = props;

  return (
    <div className="comment-overlay">
      <div className="comment-header">
        <span>评论（{count || 0}）</span>
        <CloseOutlined className="comment-header-close" onClick={onClose} />
      </div>
      <CommentMain {...rest} />
    </div>
  );
}
