/**
 * 评分下拉框
 */

import React, { memo } from 'react';
import { Popover, Select } from 'antd';
import type { SelectProps } from 'antd';
import { SCORE_OPTION } from '@/constants/okr';

const { Option } = Select;

const ScoreSelect = (props: SelectProps) => {
  return (
    <Select placeholder="请选择" style={{ width: 90 }} {...props}>
      {SCORE_OPTION.map((i) => (
        <Option key={i.key} value={i.key}>
          <Popover content={i.popover} placement="left" arrow>
            <span
              style={{
                display: 'inline-block',
                width: '100%',
                color: i.color,
                textAlign: 'left',
              }}
            >
              {'● '} {i.label}
            </span>
          </Popover>
        </Option>
      ))}
    </Select>
  );
};

export default memo(ScoreSelect);
