import React, { useEffect, useMemo, useState } from 'react';
import { useBoolean } from 'ahooks';
import { Tag, Popover, Row, Col, Button } from 'antd';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import uniqBy from 'lodash/uniqBy';
import { DeptTreeModal, SearchUserModal } from '@/components';
import api, { IUserInfo, AuthShareType } from '@/api/auth';
import { ProcessSourceTypeEnum } from '@/api/process';
import { useStore } from '@/store';
import './index.less';

const AuthShareTypeEnum = {
  dept: 'dept',
  virDept: 'virDept',
  user: 'user',
};

/**
 * 给数组添加类型
 * @param {object[]}list
 * @param {('dept'|'virDept'|'user')}type
 * @returns {(*&{type: *})[]}
 */
function addTypeIntoArrayObject(list: any, type: string) {
  return list?.map((item: any) => ({
    ...item,
    type,
  }));
}

function uniteIdAndName(list: any, idKey = 'id', nameKey = 'name') {
  return (
    list?.map((item: any) => ({
      ...item,
      id: item[idKey] || item.deptId,
      name: item[nameKey] || item.deptName,
    })) || []
  );
}

function transformAuthShareList2Simple(authShareList: any) {
  const { authDeptList, authVirDeptList, authUserList } = authShareList || {};
  const deptList = addTypeIntoArrayObject(
    uniteIdAndName(authDeptList, 'deptId', 'deptName'),
    AuthShareTypeEnum.dept,
  );
  const virDeptList = addTypeIntoArrayObject(
    uniteIdAndName(authVirDeptList, 'deptId', 'deptName'),
    AuthShareTypeEnum.virDept,
  );

  const userList = addTypeIntoArrayObject(
    uniteIdAndName(authUserList, 'id', 'name'),
    AuthShareTypeEnum.user,
  );
  return [...deptList, ...virDeptList, ...userList];
}

function transformSimple2AuthShareList(list: any) {
  const authDeptList: any = [];
  const authVirDeptList: any = [];
  const authUserList: any = [];
  (list || []).forEach((item: any) => {
    const { type } = item;
    if (type === AuthShareTypeEnum.dept) {
      authDeptList.push(item);
    } else if (type === AuthShareTypeEnum.virDept) {
      authVirDeptList.push(item);
    } else if (type === AuthShareTypeEnum.user) {
      authUserList.push(item);
    }
  });
  return Object.assign(
    { authDeptList, authVirDeptList, authUserList },
    {
      deptIds: authDeptList.map((item: any) => item.id),
      virDeptIds: authVirDeptList.map((item: any) => item.id),
      userIds: authUserList.map((item: any) => item.id),
    },
  );
}

interface IProps {
  hasVO?: boolean;
  className?: string;
  style?: React.CSSProperties;
  processId: number;
  authType: AuthShareType | ProcessSourceTypeEnum;
  authShareList: any;
  hasEditAuth?: boolean;
  onConfirmModify: (data: any) => Promise<any>;
}

// 共享范围设置
function ShareBetweenDept(props: IProps) {
  const {
    hasVO = true,
    className,
    style,
    processId,
    authType,
    authShareList,
    hasEditAuth = true,
    onConfirmModify,
  } = props;

  const { currentProcessUserId } = useStore();

  // 实体组织可选列表
  const [deptList, setDeptList] = useState<any>([]);
  // 虚拟组织可选列表
  const [virDeptList, setVirDeptList] = useState<any>([]);
  // 实体组织弹窗选择
  const [deptVisible, { setTrue: setDeptVisible, setFalse: setDeptVisibleFalse }] =
    useBoolean(false);
  // 虚拟组织弹窗选择
  const [virDeptVisible, { setTrue: setVirDeptVisible, setFalse: setVirDeptVisibleFalse }] =
    useBoolean(false);
  // 用户弹窗选择
  const [userVisible, { setTrue: setUserVisible, setFalse: setUserVisibleFalse }] =
    useBoolean(false);
  // 编辑状态
  const [editing, setEditing] = useState(false);
  // 组件内部保存编辑的共享范围
  const [editingAuthList, setEditingAuthList] = useState<any>([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (deptVisible || virDeptVisible || userVisible) {
      setOpen(false);
    }
  }, [deptVisible, virDeptVisible, userVisible]);

  useEffect(() => {
    const p = {
      userId: currentProcessUserId,
      authType,
    };
    Promise.all([api.getDefaultScope(p), api.getDefaultVirDeptList(p)]).then(([res1, res2]) => {
      const tempDeptList = [...res1, ...res2?.filter((item: any) => item.isRealDept)];
      // 实体组织
      const tempListFromDept = uniteIdAndName(
        addTypeIntoArrayObject(tempDeptList, AuthShareTypeEnum.dept),
      );
      const tempListFromVirDept = uniteIdAndName(
        addTypeIntoArrayObject(
          res2?.filter((item: any) => item.isRealDept),
          AuthShareTypeEnum.dept,
        ),
      );

      // TODO: 待确认是否重复出现
      setDeptList([...tempListFromDept, ...tempListFromVirDept]);
      // 虚拟组织
      const tempVirList = addTypeIntoArrayObject(
        uniteIdAndName(res2).filter((item: any) => !item.isRealDept),
        AuthShareTypeEnum.virDept,
      );
      setVirDeptList(uniqBy(tempVirList, 'deptId'));
    });
  }, [currentProcessUserId, authType, hasEditAuth]);

  useEffect(() => {
    //@ts-ignore
    setEditingAuthList(transformAuthShareList2Simple(authShareList));
  }, [authShareList]);

  const scopeInfo = useMemo(() => {
    const availableList = uniqBy([...deptList, ...virDeptList], 'deptId')?.map((item) => ({
      ...item,
      id: item.deptId || item.id,
      name: item.deptName || item.name,
    }));

    const defaultScopeIds = availableList
      .filter((d: any) => d.defaultAuth)
      ?.map((d: any) => d.deptId);
    // 选中的不可修改的列表；具体逻辑：如果创建进展，用户的共享范围从顶部获取，否则取默认的共享范围
    const scopeList = (processId ? editingAuthList : availableList).filter((d: any) =>
      defaultScopeIds.includes(d.id),
    );
    // 可编辑的列表
    const settingShareList = editingAuthList.filter((d: any) => !defaultScopeIds.includes(d.id));
    const selectedShareList = uniqBy([...scopeList, ...settingShareList], 'id');

    const shareNameList = selectedShareList.map((item) => item.name).join('，');
    return {
      scopeList,
      shareNameList,
      settingShareList,
      selectedIds: selectedShareList.map((item) => item.id),
    };
  }, [processId, deptList, virDeptList, editingAuthList]);

  const { scopeList, shareNameList, settingShareList, selectedIds } = scopeInfo;

  const onDeleteAuthItem = (authItem: any) => {
    setEditingAuthList(editingAuthList.filter((item: any) => item.id !== authItem.id));
  };

  const onSelectSimpleDept = (dept: any) => {
    if (selectedIds.includes(dept.id)) return;
    setEditingAuthList(editingAuthList.concat([dept]));
  };

  const onSelectDept = (list: any) => {
    const addDeptList = list
      .map((item: any) => {
        const { deptId, deptName } = item;
        return { id: deptId, name: deptName, type: AuthShareTypeEnum.dept };
      })
      .filter((item: any) => !selectedIds.includes(item.id));
    setEditingAuthList(editingAuthList.concat(addDeptList));
  };

  const onSelectVirDept = (list: any) => {
    const addDeptList = list
      .map((item: any) => {
        const { deptId, deptName } = item;
        return { id: deptId, name: deptName, type: AuthShareTypeEnum.virDept };
      })
      .filter((item: any) => !selectedIds.includes(item.id));
    setEditingAuthList(editingAuthList.concat(addDeptList));
  };

  const onSelectUsers = (list: IUserInfo[]) => {
    const addUserList = list
      .map((item: any) => {
        const { value, userName } = item;
        return { id: value, name: userName, type: AuthShareTypeEnum.user };
      })
      .filter((item: any) => !selectedIds.includes(item.id));
    setEditingAuthList(editingAuthList.concat(addUserList));
    setUserVisibleFalse();
  };

  const onCancelEdit = () => {
    setEditingAuthList(transformAuthShareList2Simple(authShareList));
    setEditing(false);
  };

  const confirmEdit = () => {
    setConfirmLoading(true);
    onConfirmModify?.(transformSimple2AuthShareList([...scopeList, ...editingAuthList]))
      ?.then(() => setEditing(false))
      ?.finally(() => setConfirmLoading(false));
  };

  return (
    <div className={className} style={style}>
      <div className="pd-edit-share">
        共享范围：
        {!editing ? (
          <span>
            <span title={shareNameList} className="share-name">
              {shareNameList}
            </span>
            {hasEditAuth && <EditOutlined onClick={() => setEditing(true)} />}
          </span>
        ) : (
          <span>
            {/* 这里是用户的默认共享范围，不能被修改 */}
            {scopeList.map((item: any) => {
              const { id, name } = item;
              return <Tag key={id}>{name}</Tag>;
            })}
            {/* 这里是用户的新增共享范围 */}
            {settingShareList.map((item: any) => {
              const { id, name } = item;
              return (
                <Tag key={id} closable onClose={() => onDeleteAuthItem(item)}>
                  {name}
                </Tag>
              );
            })}
            <Popover
              placement="right"
              open={open}
              onOpenChange={(o: boolean) => setOpen(o)}
              trigger="hover"
              content={
                <Row gutter={[0, 4]} style={{ width: 200 }}>
                  {[...deptList, ...virDeptList].map((item) => {
                    const { deptId, deptName, isRealDept } = item;
                    const disabled = selectedIds.includes(deptId);
                    return (
                      <Col key={deptId} span={24}>
                        <Button
                          className="btn-share-type"
                          size="small"
                          disabled={disabled}
                          onClick={() =>
                            onSelectSimpleDept({ ...item, id: deptId, name: deptName })
                          }
                        >
                          {!isRealDept && <span className="tip-vir-dept">虚拟</span>}
                          {deptName}
                        </Button>
                      </Col>
                    );
                  })}
                  <Col span={24}>
                    <Button className="btn-share-type" size="small" onClick={setDeptVisible}>
                      选择部门
                    </Button>
                  </Col>
                  {hasVO && (
                    <Col span={24}>
                      <Button className="btn-share-type" size="small" onClick={setVirDeptVisible}>
                        选择虚拟组织
                      </Button>
                    </Col>
                  )}
                  <Col span={24}>
                    <Button className="btn-share-type" size="small" onClick={setUserVisible}>
                      指定人员
                    </Button>
                  </Col>
                </Row>
              }
            >
              <PlusCircleOutlined className="share-add-icon" />
            </Popover>
            <Button
              type="link"
              disabled={confirmLoading}
              className="btn-action"
              onClick={onCancelEdit}
            >
              取消
            </Button>
            <Button
              type="link"
              disabled={confirmLoading}
              loading={confirmLoading}
              className="btn-action"
              onClick={confirmEdit}
            >
              确定
            </Button>
          </span>
        )}
      </div>
      {/* 组织树 */}
      <DeptTreeModal
        open={deptVisible}
        deptTreeHidden={setDeptVisibleFalse}
        deptTreeChange={onSelectDept}
      />
      {/* 虚拟组织树 */}
      {hasVO && (
        <DeptTreeModal
          isVir
          open={virDeptVisible}
          deptTreeHidden={setVirDeptVisibleFalse}
          deptTreeChange={onSelectVirDept}
        />
      )}
      {/* 多选人弹层 */}
      <SearchUserModal
        modalProps={{
          open: userVisible,
          onCancel: setUserVisibleFalse,
        }}
        handleOk={onSelectUsers}
      />
    </div>
  );
}

ShareBetweenDept.defaultProps = {
  hasVO: true,
  hasEditAuth: true,
};

export default ShareBetweenDept;
