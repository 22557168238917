import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModel } from 'umi';
import { useEventEmitter } from 'ahooks';
import { Spin } from 'antd';
import processApi, {
  IProcessDetail,
  IProcessOkrItem,
  IProcessVO,
  ProcessSourceTypeEnum,
} from '@/api/process';
import { useStore } from '@/store';
import { addProcessInfo2OkrList, transformProcessList2Map } from '../components/dashboard/utils';
import {
  ProcessDashboardHeader,
  WeeklyOtherContent,
  RecordWrapper,
  DashboardOkr,
  Empty,
  PdLayoutHeader,
} from '../components';
import '../components/dashboard/index.less';

interface IProps {
  id: number; // 进展ID
}

// 进展详情展示
function ProcessDetail(props: IProps) {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { id } = props;

  const { isEmptyKrProcessHide, currentProcessSourceType, setStoreValues } = useStore();

  // 缓存正在编辑的进展项
  const cacheEditingProcess = useRef({});
  // 一键发布事件
  const releaseAll$ = useEventEmitter();

  const [loading, setLoading] = useState(true);
  // OKR 列表
  const [okrList, setOkrList] = useState<IProcessOkrItem[]>();
  // OKR 进展列表
  const [processVO, setProcessVO] = useState<any>();
  // OKR 进展列表Map
  const [processListMap, setProcessListMap] = useState<{ [key in string]: IProcessVO }>({});

  const { dateId, otherContentAuth, entity, year } = processVO || {};
  const { targetId: userId } = entity || {};

  const { okrDateId } = okrList?.[0] || {};

  useEffect(() => {
    setStoreValues({
      currentProcessDateId: dateId,
      currentProcessYear: year,
      currentProcessUserId: userId,
      currentProcessOkrDateId: okrDateId,
    });
  }, [dateId, year, userId, okrDateId]);

  useEffect(() => {
    if (!id || !currentProcessSourceType) return;
    setLoading(true);
    processApi
      .getProcessDetail({ id, processSourceType: currentProcessSourceType })
      .then((data) => {
        const { okrList: processOkrList, processEntity } = data;
        setOkrList(processOkrList);
        setProcessVO(processEntity);
        setProcessListMap(
          transformProcessList2Map(processEntity?.processVOList, processEntity?.processOther),
        );
      })
      .finally(() => setLoading(false));
  }, [id, currentProcessSourceType]);

  const getProcessVO = () => {
    const payload = { id, processSourceType: currentProcessSourceType! };
    return processApi.getProcessDetail(payload).then((data) => {
      const { okrList: processOkrList, processEntity } = data;
      setOkrList(processOkrList);
      setProcessVO(processEntity);
      setProcessListMap(
        transformProcessList2Map(processEntity?.processVOList, processEntity?.processOther),
      );
      return processEntity?.processVOList;
    });
  };

  const isSelf = userInfo?.id === +processVO?.entity?.targetId;

  const okrListWithProcess = useMemo(
    () =>
      addProcessInfo2OkrList(okrList || [], processListMap, isSelf ? false : isEmptyKrProcessHide),
    [okrList, processListMap, isEmptyKrProcessHide, isSelf],
  );

  const modifyProcessVO = useCallback(
    (partialProcessVO: IProcessDetail) => {
      setProcessVO({ ...processVO, ...partialProcessVO });
    },
    [processVO],
  );

  const modifyProcessListMap = (partialProcessListMap: { [key in string]: IProcessVO }) => {
    setProcessListMap({ ...processListMap, ...partialProcessListMap });
  };

  const noOkr = okrListWithProcess.length === 0 && !processVO?.processOther;

  if (!loading && noOkr) {
    return <Empty text="暂无OKR进展" />;
  }

  if (loading) {
    return (
      <Spin spinning>
        <div style={{ padding: 40, margin: '0 auto' }} />
      </Spin>
    );
  }

  console.log('processVO', processVO);

  return (
    <div className="process-dashboard">
      <div className="process-dashboard-main">
        <div className="process-dashboard-content">
          <ProcessDashboardHeader
            loading={loading}
            readonly
            processInfo={processVO!}
            releaseAll$={releaseAll$}
            cacheEditingProcess={cacheEditingProcess}
          />
          <div className="pd-layout">
            <PdLayoutHeader processSource={currentProcessSourceType!} />
            {okrListWithProcess?.map((item) => {
              const { objectivesId } = item;
              return (
                <DashboardOkr
                  releaseAll$={releaseAll$}
                  key={objectivesId}
                  readonly
                  info={item}
                  modifyProcessListMap={modifyProcessListMap}
                  getProcessVO={getProcessVO}
                  cacheEditingProcess={cacheEditingProcess}
                />
              );
            })}
          </div>
        </div>
      </div>
      {/* 组织OKR 不展示 */}
      {!noOkr && currentProcessSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT && (
        <WeeklyOtherContent
          readonly
          auth={otherContentAuth!}
          processOther={processListMap?.processOther}
          getProcessVO={getProcessVO}
          cacheEditingProcess={cacheEditingProcess}
          modifyProcessListMap={modifyProcessListMap}
        />
      )}
      {/* 无任何进展发布时，不可进行点赞和评论（置灰或者不显示） */}
      {!noOkr && <RecordWrapper id={id} likeInfo={processVO?.thumbUpVO} />}
    </div>
  );
}

export default ProcessDetail;
