import React, { useEffect } from 'react';
import { useHistory } from 'umi';
import { useSafeState } from 'ahooks';
import * as querystring from 'querystring';
import { Spin } from 'antd';
import { OkrTypeEnum } from '@/api/okr';
import processListApi, { IProcessPeriodItem, ProcessSourceTypeEnum } from '@/api/process';
import { ProcessTabEnum, STORAGE_KEY_MAP } from '@/constants';
import storage from '@/utils/storage';
import { generateUUID } from '@/utils/tools';
import { OkrEmptyPlaceholder, ProcessCard } from '..';
import { formatProcessList } from '../process-list/utils';

interface IProps {
  year: number;
  deptId?: string;
  processSourceType: ProcessSourceTypeEnum;
  deptInfo?: any;
  weeklyExtraFilters?: any;
  okrDateId: number;
}

function ProcessListWrap(props: IProps) {
  const { year, deptId, processSourceType, deptInfo, okrDateId, weeklyExtraFilters } = props;

  const [loading, setLoading] = useSafeState(false);
  const [processList, setProcessList] = useSafeState<IProcessPeriodItem[] | null>(null);

  const history = useHistory();

  useEffect(() => {
    if (!okrDateId) return;
    setLoading(true);
    const payload = {
      type: OkrTypeEnum.organization,
      year,
      processSourceType,
      okrDateId,
      notNull: processSourceType !== ProcessSourceTypeEnum.PROCESS_WEEK_REPORT,
      deptId,
    };

    // 周报有个快捷筛选：周进展范围
    if (processSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT) {
      Object.assign(payload, weeklyExtraFilters);
    }
    processListApi
      .getPeriodList(payload)
      .then((data) => {
        setProcessList(formatProcessList(data, processSourceType));
      })
      .finally(() => setLoading(false));
  }, [okrDateId, year, deptId, processSourceType, weeklyExtraFilters]);

  const userOrDeptInfo = { ...deptInfo, name: deptInfo?.name };

  const onJumpDetail = (process: any) => {
    const { dateId } = process;
    const query = {
      dateId,
      type: processSourceType,
      year,
      periodId: okrDateId,
      tab: ProcessTabEnum.org,
      deptId,
    };
    console.log('query', query);
    if (processSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT) {
      storage.session.set(STORAGE_KEY_MAP.weeklyCustomRange, weeklyExtraFilters);
    }
    history.push(`/process/dashboard?${querystring.stringify(query)}`);
  };

  const isEmpty = !loading && !processList?.length;

  return (
    <div className="pl-process-list-wrap">
      <Spin spinning={loading}>
        {processList &&
          (isEmpty ? (
            <OkrEmptyPlaceholder simple />
          ) : (
            <>
              {processList?.map((item) => {
                return (
                  <ProcessCard
                    readonly={false}
                    year={year}
                    okrDateId={okrDateId!}
                    dateId={item.dateId}
                    deptId={deptId}
                    key={generateUUID()}
                    userOrDeptInfo={userOrDeptInfo}
                    info={item}
                    type={OkrTypeEnum.organization}
                    processSourceType={processSourceType}
                    onJumpDetail={onJumpDetail}
                  />
                );
              })}
            </>
          ))}
      </Spin>
    </div>
  );
}

export default ProcessListWrap;
