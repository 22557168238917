import React, { useEffect } from 'react';
import { useRequest } from 'ahooks';
import api from '@/api/setting';
import style from './index.less';

export default function ViewPanel() {
  const { data: url } = useRequest(api.getViewPannelBI);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [url]);

  return (
    <div className={style.page}>
      <iframe width="100%" height="726px" src={url} frameBorder={0} title="有数OKR看板" />
    </div>
  );
}
