import React from 'react';
import { ConfigProvider, App } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { install, version } from '@ehr-ui/ehr-login-sdk';
import '@ehr-ui/ehr-login-sdk/dist/index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConfigProvider as EhrConfigProvider } from '@netease-ehr/ui';
import '@ehr-ui/ehr-login-sdk/dist/index.css';
import { LANGUAGE_LOCALE_FILE } from '@/constants/app';
import { Logger } from '@/utils/logger';
import api, { IUserInfo } from '@/api/auth';
import '@/assets/css/index.less';
import packageJson from '../package.json';

dayjs.locale('zh-cn');

console.log('version', version);
install({
  systemCode: 'okr',
});

window.Logger = new Logger();

const getEnvironment = () => {
  return window.location.host.includes('dev')
    ? 'dev'
    : location.host.includes('test')
    ? 'test'
    : location.host.includes('localhost')
    ? 'localhost'
    : 'online';
};

EhrConfigProvider.config({
  prefixCls: 'ant',
  theme: {
    primaryColor: '#1890ff',
  },
});

type LanguageType = 'zh' | 'en' | 'fr';

const initWatermark = (userInfo: IUserInfo) => {
  window.WaterMark.mark({
    text: `${userInfo?.name || ''} (${userInfo?.uNo || ''})`,
    xSpace: 100,
    ySpace: 100,
    opacity: 0.08,
    size: 10,
  });
};
const initSentry = (userInfo: IUserInfo) => {
  Sentry.init({
    dsn: 'https://16c504e3061f451ba3d6a4cd93b8cca9@ehr-sentry.netease.com/18',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: packageJson.version,
    environment: getEnvironment(),
  });
  Sentry.configureScope((scope) => {
    scope.setUser({
      username: `${userInfo?.name || ''} (${userInfo?.uNo || ''})`,
      email: userInfo.corpMail,
      time: new Date().toLocaleString(),
    });
  });
};

const initDataTrack = (userInfo: IUserInfo) => {
  window.DATracker.login(`${userInfo?.name || ''} (${userInfo?.uNo || ''})`);
  window.DATracker.people.set({
    userId: userInfo.uNo,
    name: userInfo.name,
    email: userInfo.corpMail,
    deptId: userInfo.deptId,
    deptName: userInfo.deptPath,
  });
};

export async function getInitialState() {
  const userInfo = await api.getUserInfo();

  // 正常上報
  window.Logger.init({
    systemCode: 'okr',
    userId: userInfo.uNo,
    userName: userInfo.name,
    email: userInfo.corpMail,
  });
  initWatermark(userInfo);
  // initSentry(userInfo);
  initDataTrack(userInfo);

  return {
    userInfo,
  };
}

function AppWrap({ children }: any) {
  const currentLanguage = localStorage.getItem('language') || 'zh';
  const fileName = LANGUAGE_LOCALE_FILE[currentLanguage as LanguageType] || 'zh_CN';
  const localeFile = require(`antd/es/locale/${fileName}`).default;
  return (
    <EhrConfigProvider theme={{ token: { colorPrimary: '#1890ff' } }}>
      <ConfigProvider
        locale={localeFile}
        theme={{
          token: {
            colorPrimary: '#1890ff',
            colorError: '#f53f3f',
            colorSuccess: '#1ea14c',
            colorWarning: '#ef6b0b',
            colorInfo: '#2491fc',
            colorTextBase: '#333333',
            colorText: '#333333',
            colorTextSecondary: '#666666',
            colorTextTertiary: '#999999',
            colorTextQuaternary: '#cccccc',
            colorBorder: '#E0E0E0',
            colorBorderSecondary: '#F0F0F0',
            fontSize: 14,
            wireframe: true,
            colorBgSpotlight: '#fff',
            boxShadow:
              '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
          },
          components: {
            Button: {},
            Dropdown: {
              fontSize: 14,
              controlHeight: 36,
              paddingXXS: 0,
            },
            Tooltip: {
              colorTextLightSolid: '#333',
              fontSize: 12,
            },
            Modal: {
              borderRadiusLG: 12,
              fontSize: 14,
              fontSizeLG: 16,
            },
            Notification: {
              borderRadiusLG: 12,
              fontSize: 14,
              fontSizeLG: 16,
              marginXS: 4,
              paddingMD: 16,
            },
            Segmented: {
              controlHeight: 32,
            },
          },
        }}
      >
        <App>{children}</App>
      </ConfigProvider>
    </EhrConfigProvider>
  );
}

export function rootContainer(container: React.ReactElement) {
  // console.log(window, version);
  return React.createElement(AppWrap, null, container);
}
