import React from 'react';
import { useModel } from 'umi';
import { Button } from 'antd';
import { useRequest } from 'ahooks';
import { WEEKLY_GUIDE_IMG } from '@/constants';
import processListApi from '@/api/process';
import { dataTrack } from '@/utils/track';
import { getProcessTitle } from '../process-card/utils';
import './index.less';

interface IProps {
  onJumpDetail: (weeklyInfo: any) => void;
}

// 周报填写引导
function WeeklyReportGuide(props: IProps) {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { onJumpDetail } = props;

  const { data: weeklyInfo } = useRequest(processListApi.getCurrentWeeklyInfo, {
    ready: !!userInfo?.id,
  });

  const onClickBtn = () => {
    dataTrack('click_process_write_week_report');
    onJumpDetail?.(weeklyInfo);
  };

  return (
    <div className="pl-weekly-guide">
      <div className="pl-weekly-guide_img">
        <img src={WEEKLY_GUIDE_IMG} alt="guide" />
      </div>
      <div className="pl-weekly-guide_info">
        <div className="pl-weekly-guide_info-note">
          hi~ {userInfo?.name}，今天是{getProcessTitle(weeklyInfo)}
        </div>
        <Button type="primary" className="pl-weekly-guide_info-entry" onClick={onClickBtn}>
          我要写周报
        </Button>
      </div>
    </div>
  );
}

export default WeeklyReportGuide;
