import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Button, ConfigProvider, Spin } from 'antd';
import { useSafeState } from 'ahooks';
import { ProcessSourceTypeEnum } from '@/api/process';
import { ProcessPublishStatusEnum, O_KR_ENUM } from '@/constants';
import { SingleUserSelect, UploadFile, BedrockEditor } from '@/components';
import type { EditorRef } from '@/components/bedrock-editor';
import { generateUUID, pickUserIdFromHTML } from '@/utils/tools';
import { IUserInfo } from '@/api/auth';
import { dataTrack } from '@/utils/track';
import { ReadonlyRichText } from '@/components';
import { addDataIndentAttrToStyles } from './utils';
import './index.less';

const EVENT_ID_MAP = {
  [ProcessSourceTypeEnum.PROCESS_WEEK_REPORT]: 'click_process_add_week_content',
};

interface IProps {
  content: string;
  readonly?: boolean;
  onChangeActive?: (active: boolean) => void;
  isActive: boolean;
  onHandleSaveDraft?: (payload: any, shouldCallback: boolean) => void;
  onHandleSave?: (payload: any) => void;
  status: ProcessPublishStatusEnum;
  updateTime: string;
  statusEmpty?: boolean;
  processType?: ProcessSourceTypeEnum;
  okrType?: string;
  files: any;
}

function RichTextEditor(props: IProps, ref: any) {
  const {
    content,
    readonly,
    onChangeActive,
    isActive,
    onHandleSaveDraft,
    onHandleSave,
    status,
    updateTime,
    statusEmpty,
    processType,
    okrType,
    files,
  } = props;

  const editorRef = useRef<EditorRef>();

  const editorId = useMemo(() => generateUUID(), []);

  const [visibleSelectPerson, setVisibleSelectPerson] = useState<boolean>(false);
  const [fileList, setFileList] = useState(files);
  const [loading, setLoading] = useSafeState(false);

  const checkDisabled = () => {
    const isEmpty = !editorRef?.current
      ?.getEditorHtml?.()
      ?.replace(/^<p>(?:&nbsp;|\s)*<\/p>$/g, '');
    // 编辑可更新为空
    if (status === ProcessPublishStatusEnum.published) return false;
    if (processType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT) {
      // 周报：进展内容新建不可为空
      return isEmpty;
    }
    if (processType === ProcessSourceTypeEnum.PROCESS_SUMMARY && okrType === O_KR_ENUM.O) {
      return isEmpty;
    }
    // 小结、总结：新建状态不可为空，内容可为空
    return !!statusEmpty;
  };

  const [disabledPost, setDisabledPost] = useState<boolean>(checkDisabled());

  useEffect(() => {
    setDisabledPost(checkDisabled());
  }, [statusEmpty]);

  const onChangeEditor = () => {
    setDisabledPost(checkDisabled());
  };

  const getPayload = () => {
    const payloadContent = editorRef?.current
      ? editorRef?.current?.getEditorHtml?.()
      : displayContent;
    const payloadUserIds = pickUserIdFromHTML(payloadContent as string);
    const appendixList = fileList?.map((item: any) => {
      const { id, url, name, nosKey } = item;
      return { id, url, name, nosKey };
    });
    const filteredContent = payloadContent?.replace(/^<p>(?:&nbsp;|\s)*<\/p>$/g, '');
    return {
      appendixList,
      content: filteredContent,
      userIds: payloadUserIds,
    };
  };

  useImperativeHandle(ref, () => ({
    getPayload,
  }));

  // 自动聚焦
  // useEffect(() => {
  //     if (!isActive) return;
  //     const draftInstance = editorRef?.current?.getDraftInstance();
  //     draftInstance?.focus?.();
  // }, [isActive]);

  useEffect(() => {
    setFileList(files);
  }, [files]);

  const handleSelect = (empInfo: IUserInfo) => {
    const { id, name } = empInfo || {};
    const editorState = editorRef?.current?.getEditorState?.();

    const editorView = editorRef?.current?.getEditorView?.();

    const mentionNode = editorState?.schema.nodes['mention'];
    const node = mentionNode?.create({ id, name, hasCharBefore: false }, null, undefined);
    editorView?.dispatch(
      // @ts-ignore
      editorState?.tr.replaceWith(
        editorState?.selection.from - 1,
        editorState?.selection.from,
        // @ts-ignore
        node,
      ),
    );
  };

  // 上传附件
  const onUpload = (list: any) => {
    setFileList([...list]);
    dataTrack('click_process_upload_file');
  };

  const onClickDraft = async () => {
    onHandleSaveDraft?.(getPayload(), true);
    onChangeActive?.(false);
  };

  const onClickCancel = () => {
    onChangeActive?.(false);
  };

  const onClickSave = () => {
    onHandleSave?.(getPayload());
    onChangeActive?.(false);
  };

  const onClickEdit = () => {
    if (readonly) return;
    onChangeActive?.(true);

    // @ts-ignore
    const eventId = EVENT_ID_MAP[processType];
    if (!eventId) return;
    dataTrack(eventId);
  };

  const onKeyDown = (e: any) => {
    const { nativeEvent } = e;
    if (nativeEvent.key === '@' || (nativeEvent.shiftKey && nativeEvent.code === 'Digit2')) {
      // 只有在编辑器输入框内触发@才会弹出人员选择框
      if (!e.target?.getAttribute('class')?.includes('ProseMirror')) return;
      setVisibleSelectPerson(true);
    }
  };

  const onCancel = () => setVisibleSelectPerson(false);

  // 兼容BraftEditor的缩进样式，将text-indent:Xem转换为data-indent:X / 2
  const displayContent = addDataIndentAttrToStyles(content);

  if (displayContent && !isActive) {
    return (
      <ReadonlyRichText
        className="editor-readonly"
        content={displayContent}
        updateTime={updateTime}
      />
    );
  }
  if (readonly) {
    return <div className="editor-readonly initial-editor-readonly">暂无进展</div>;
  }
  if (!isActive) {
    return (
      <div className="initial-editor" onClick={onClickEdit}>
        暂无进展，点击进行添加
      </div>
    );
  }
  return (
    <ConfigProvider autoInsertSpaceInButton={false}>
      <div>
        <Spin spinning={loading}>
          <div onKeyDown={onKeyDown}>
            <BedrockEditor
              ref={editorRef}
              initialHtml={displayContent}
              id={editorId}
              onChange={onChangeEditor}
            />
          </div>
        </Spin>
        <div className="editor-bottom-bar">
          <div className="bar-operation">
            {status !== ProcessPublishStatusEnum.published && (
              <Button
                size="small"
                type="primary"
                className="editor-save-btn"
                disabled={false}
                onClick={onClickDraft}
              >
                保存草稿
              </Button>
            )}
            <Button
              size="small"
              type="primary"
              className="editor-save-btn"
              disabled={disabledPost}
              onClick={onClickSave}
            >
              {status !== ProcessPublishStatusEnum.published ? '发布' : '更新'}
            </Button>
            <Button type="link" onClick={onClickCancel} style={{ fontSize: 12, padding: 0 }}>
              取消
            </Button>
          </div>
          {status !== ProcessPublishStatusEnum.published && <div>{updateTime} 自动保存</div>}
        </div>
        <div className="editor-upload">
          <UploadFile onChange={onUpload} fileList={fileList} />
        </div>
        <SingleUserSelect
          open={visibleSelectPerson}
          onCancel={onCancel}
          handleSelect={handleSelect}
          autoFocus={false}
          getContainer={() => document.getElementById(editorId)}
        />
      </div>
    </ConfigProvider>
  );
}

export default forwardRef(RichTextEditor);
