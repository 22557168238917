/**
 * 进展类型
 * @type {{PROCESS_SUMMARY = string, PROCESS_WEEK_REPORT = string, PROCESS_CONCLUSION = string}}
 */
import { IAuthShare, OkrTypeEnum } from '@/api/okr';
import { IUserInfo } from '@/api/auth';
import { IThumbUpVO } from '@/api/discover';
import { O_KR_ENUM } from '@/constants';

export enum ProcessSourceTypeEnum {
  PROCESS_WEEK_REPORT = 'PROCESS_WEEK_REPORT', // 周报
  PROCESS_CONCLUSION = 'PROCESS_CONCLUSION', // 小结
  PROCESS_SUMMARY = 'PROCESS_SUMMARY', // 总结
}

export enum ProcessSourceOperateTypeEnum {
  PROCESS = 'PROCESS', // 单一进展
  PROCESS_OTHER = 'PROCESS_OTHER', // 进展其他
  PROCESS_SUMMARY = 'PROCESS_SUMMARY', // 总结
  PROCESS_WEEK_REPORT = 'PROCESS_WEEK_REPORT', // 周报
}

export enum DateTimeRange {
  ThreeMonth = 'ThreeMonth',
  OneMonth = 'OneMonth',
  OneYear = 'OneYear',
  Customer = 'Customer',
  HalfYear = 'HalfYear',
}

// 我的进展-获取进展周期
export interface IProcessPeriodItem {
  dateId: number;
  name: string;
  status: number; //  0-草稿 1-发布 2 过期未发布， null 是空数据
  position: boolean;
  year: string | number;
  startDate: string;
  endDate: string;
  history: boolean; // 是否历史周期
  month: number; // 月份，周报专用
  auth: boolean; // 是否能编辑
  id: number;
  see: boolean;
  deptId: string;
  periodType: string;
  processSourceType: ProcessSourceTypeEnum;
  // 前端追加数据
  isShowMonth: boolean;
  authShareName: string;
  monthText: string;
}

// 获取该员工所在部门的配置
export interface IPeriodConfigItem {
  name: string;
  periodType: number;
}

export interface IProcessDetailQuery {
  userId?: number; // 用户ID，不传时，默认查询当前用户
  okrDateId: number; // okr周期
  year: number | string; // 年份
  dateId: number; // 主键
}

export interface IAppendix {
  appendixId: number;
  id: number;
  name: string;
  nosKey: string;
  url: string;
}

export interface IProcessVO {
  appendixList: IAppendix[];
  auth: boolean;
  commentCount: number;
  content: string;
  createTime: string;
  dateId: number;
  evaluationScore: number;
  evaluationStatus: number;
  id: number;
  sourceId: number;
  sourceType: ProcessSourceTypeEnum;
  status: number;
  thumbUpVO: IThumbUpVO;
  type: string;
  typeSourceId: number;
  updateTime: string;
  thumbUpCount: number;
  thumbUpEd: boolean;
}

// 周报、总结、小结实体
export interface IProcessEntity {
  id: number;
  dateId: number; // 周期id
  targetId: number; // 目标Id，人员id，部门人员id
  type: OkrTypeEnum; // 1-人 2-组织
  content: string; // 内容
  status: number;
  name: string;
}

// 进展详情
export interface IProcessDetail {
  entity: IProcessEntity;
  id: number;
  year: string;
  endDate: string;
  startDate: string;
  name: string;
  thumbUpVO: IThumbUpVO;
  otherContentAuth: boolean;
  processVOList: IProcessVO[];
  processOther: IProcessVO;
  authShareList: IAuthShare; // 共享范围对象
}

// 进展的okr列表
export interface IProcessOkrQuery {
  userId?: number; // 用户ID，不传时，默认查询当前用户
  okrDateId?: number; // 周期
  year?: number | string; // 年份
  deptId?: string; // 部门id
}

export interface IOkrBelongsItem {
  belongId: string;
  belongName: string;
  belongType: number; // 1 部门  2 人员 3 虚拟部门
  objectivesId: number;
}

export interface IProcessKrItem {
  auth: boolean;
  id: 89329;
  isValid: number;
  keyResultName: string;
  objectivesId: number;
  sorts: number;
  userList: IUserInfo[];
}

export interface IProcessOkrItem {
  auth: boolean;
  dateName: string;
  periodName: string; // 所属周期
  objectivesId: number;
  objectivesName: string;
  objectivesType: OkrTypeEnum;
  photoUrl: string;
  principal: string;
  principalId: number;
  sorts: number;
  objectivesBelongsVOList: IOkrBelongsItem[];
  krList: IProcessKrItem[];
  okrDateId: number;
}

// 我的进展-进展详情页查看，组合接口
export interface IProcessDetailCompose {
  okrList: IProcessOkrItem[];
  processDateCountVO: {
    commentCount: number;
    thumbUpCount: number;
    viewCount: number;
  };
  processEntity: IProcessDetail;
}

// 进展编辑记录
export interface IProcessHistoryItem {
  createTime: string;
  dsc: string;
  id: number;
  photoUrl: string;
  processId: number;
  type: number;
  updateTime: string;
  userId: number;
  userName: string;
}

// 进展-创建进展记录并返回主键
export interface ICreateProcessWhenEmptyPayload {
  processSourceType: ProcessSourceTypeEnum; // 进展的类型
  operateTarget: ProcessSourceOperateTypeEnum;
  sourceId?: number; // O 或KR 的主键
  okrType?: string; // O 或者 KR
  dateId: number; // 关联的周期
  targetId?: number | string; // 目标所属的人的id/组织进展的部门的 id
}

// 我的进展-暂存进展&其他
export interface IProcessSavePayload {
  id: number; // 进展主键
  processSourceTypeEnum: ProcessSourceTypeEnum; // 进展的类型
  processSourceOperateTypeEnum: ProcessSourceOperateTypeEnum; // 当前暂存的类型：PROCESS,PROCESS_OTHER
  content?: string; // 进展内容
  evaluationStatus?: number; // 评价状态，101-顺利进行 102-有点延迟  103-很大障碍,（小结）
  evaluationScore?: number; // 评分，0-10分（总结）
  appendixList?: IAppendix[]; // 附件列表
}

// 保存草稿的进展返回值
export interface IProcessResult {
  id: number;
  dateId: number;
  typeSourceId: number; // O或KR的id
  type: string; // 进展类型 进展类型, {@link ProcessTypeEnum#OKR}, {@link ProcessTypeEnum#KR}
  sourceType: ProcessSourceTypeEnum; // 进展来源类型
  sourceId: number; // 进展来源id
  status: 0 | 1; // 进展状态 0-草稿 1-发布
  evaluationStatus: number; // 评价状态，101-顺利进行 102-有点延迟  103-很大障碍,（小结）
  evaluationScore: number; // 评分，0-10分（总结）
  commentCount: number; // 评论数
  thumbUpVO: IThumbUpVO; // 点赞信息
  updateTime: string; // 更新时间
}

// 我的进展-获取进展周期(个人&组织)入参
export interface IQueryPeriodParam {
  type: OkrTypeEnum; // 1-个人 2-组织
  year: number | string; // 年份
  userId?: number; // 用户id
  deptId?: string;
  processSourceType: ProcessSourceTypeEnum; // 进展类型
  dateTimeRange?: DateTimeRange; // 时间范围枚举，自定义枚举时，from和to必填
  from?: string; // 自定义时间范围开始时间
  to?: string; // 自定义时间范围结束时间
  okrDateId: number; // okr周期Id
  periodType?: number; // 总结周期拦截字段,1,2,3,4,7
  notNull?: boolean; // 是否需要过滤空数据: true：过滤 false:不过滤
}

export interface IQueryHistoryParam {
  year?: number;
  okrDateId: number; // okr 周期
  dateId: number; // 进展周期的Id
  processSourceType: ProcessSourceTypeEnum;
  type: OkrTypeEnum; // 1-个人 2-组织
  userId?: number; // 用户id
  deptId?: string;
}

// 发送邮件 payload
export interface ISendEmailPayload {
  content: string;
  title: string;
  mailList: string[];
}

// 一键发布payload
export interface IReleaseAllPayload {
  id: number;
  sourceType: ProcessSourceTypeEnum;
  dateId: number;
  userId?: number;
  processList: IProcessSavePayload[];
  processOther?: IProcessSavePayload;
}

// 单挑进展的详情
export interface ISingleProcessParams {
  processSourceType: ProcessSourceTypeEnum;
  dateId: number;
  typeSourceId: number;
  okrType: O_KR_ENUM;
}
