import React from 'react';
import { Skeleton } from 'antd';
import { history } from 'umi';
import { IKrItem } from '@/api/okr';
import { IconFont, OkrScoreStatus } from '@/components';
import { SUMMARY_TYPE_CODE } from '@/constants';
import './index.less';

const Info = (props: any) => {
  const { info } = props;

  const jump = (info: any) => {
    history.push(
      `/team/${info.userId}?tab=okr&id=${info.id}&year=${info.year}&periodId=${info.dateId}`,
    );
  };

  const {
    loading,
    summaryType,
    summaryStatus,
    summaryScore,
    titleName,
    objectivesName,
    belongListStr,
    belongVirDeptListStr,
    krList = [],
  } = info;

  return (
    <div className="time-view-okr">
      <div className="time-view-item__title" onClick={() => jump(info)}>
        {objectivesName}
        {belongListStr &&
          belongListStr
            .replace(/,$/gi, '')
            .split(',')
            .map((i: any, idx: number) => ({ name: i, key: idx }))
            .map((i: any) => (
              <span key={i.key} className="time-view-item-scope">
                {i.name}
              </span>
            ))}
        {belongVirDeptListStr &&
          belongVirDeptListStr
            .replace(/,$/gi, '')
            .split(',')
            .map((i: any, idx: number) => ({ name: i, key: idx }))
            .map((i: any) => (
              <span key={i.key} className="time-view-item-scope-vir">
                {i.name}
              </span>
            ))}
      </div>
      <div className="time-view-item__detail">
        <div className="time-view-period-name">{titleName}</div>
        <div className="time-view-okr-status">
          <OkrScoreStatus
            noBackgroundColor
            type={summaryType || (summaryScore ? SUMMARY_TYPE_CODE.SUM : SUMMARY_TYPE_CODE.STAGE)}
            summaryScore={summaryScore}
            summaryStatus={summaryStatus}
          />
        </div>
      </div>
      {loading ? (
        <Skeleton />
      ) : (
        <div className="tv-kr-list">
          {krList &&
            krList.map((krItem: IKrItem) => {
              const {
                id: krId,
                keyResultName,
                summaryStatus: krSummaryStatus,
                summaryScore: krSummaryScore,
              } = krItem;
              return (
                <div className="tv-kr-item" key={krId}>
                  <div className="status-wrap">
                    <OkrScoreStatus
                      isMini
                      type={krSummaryScore ? SUMMARY_TYPE_CODE.SUM : SUMMARY_TYPE_CODE.STAGE}
                      summaryScore={krSummaryScore}
                      summaryStatus={+(krSummaryStatus || '')}
                    />
                  </div>
                  <div className="tv-kr-item_desc">{keyResultName}</div>
                </div>
              );
            })}
        </div>
      )}
      <div className="tv-show-more" onClick={() => jump(info)}>
        查看详情
        <IconFont type="icon-zhankai" style={{ transform: 'rotate(-90deg)', marginLeft: 8 }} />
      </div>
    </div>
  );
};

export default Info;
