import React, { memo, useEffect, useState } from 'react';
import { App, Button, Col, ConfigProvider, Form, Input, Popconfirm, Radio, Row } from 'antd';
import type { RadioChangeEvent } from 'antd';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import okrApi, { IKrItem, IOkrItem, OkrTypeCodeEnum, OkrTypeEnum } from '@/api/okr';
import authApi, { AuthShareType, IAuthDeptVO, IUserInfo } from '@/api/auth';
import { useDebounceFn } from 'ahooks';
import useGetDefaultScope from '@/components/okr/useGetDefaultScope';
import { ChooseScope, StaffSelect } from '@/components';
import { formatObjectivesBelongs } from '@/utils/okr';
import { dataTrack } from '@/utils/track';
import { getParams } from '@/utils/tools';
import '../card.less';
import './index.less';

const { TextArea } = Input;

interface IProps {
  okrInfo: IOkrItem;
  userInfo: IUserInfo;
  userRoles: any;
  index: number;
  onRefreshList?: () => void;
  onDelOKR: (id: number) => void;
}

const OkrDraftCard = (props: IProps) => {
  const { okrInfo, onRefreshList, userInfo, userRoles, index, onDelOKR } = props;

  const urlParams: any = getParams();

  const {
    id,
    krList: _krList,
    objectivesName,
    principalVO,
    authShareVO,
    objectivesType,
    objectivesBelongsVOList,
  } = okrInfo || {};

  const { defaultScope, defaultVirScope } = useGetDefaultScope(id);
  const [checkPublish, setCheckPublish] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { modal, message } = App.useApp();

  const [form] = Form.useForm();
  const krList = _krList || [];

  const onDeleteOkr = () => {
    okrApi.delObj(id).then(() => {
      onDelOKR(id);
    });
  };

  const onDelObj = () => {
    modal.confirm({
      title: '确定删除此条Objective吗',
      content: '删除后本条 OKR 内容将全部丢失',
      onOk: onDeleteOkr,
    });
  };

  const onCancel = () => {
    modal.confirm({
      title: '未发布的内容将丢失，确定离开吗',
      onOk: onDeleteOkr,
    });
  };

  const _onDelKr = (fieldName: any, removeFunc: any) => {
    const { krList = [] } = form.getFieldsValue();
    const { id } = krList[fieldName];
    okrApi.delKr(id).then(() => {
      removeFunc(fieldName);
    });
  };

  const onClickSubmit = () => {
    setSubmitLoading(true);
    const { objectivesType, objectivesName, authShare } = form.getFieldsValue();
    const { deptList, userList, virDeptList } = authShare || {};

    const defaultIds = defaultScope.filter((i) => i.defaultAuth).map((i) => i.deptId);
    const defaultVirIds = defaultVirScope.filter((i: any) => i.defaultAuth).map((i) => i.deptId);
    const deptIds = deptList.map((i: IAuthDeptVO) => i.deptId);
    const virDeptIds = virDeptList.map((i: IAuthDeptVO) => i.deptId);

    okrApi
      .publishOKR({
        id,
        objectivesName,
        objectivesType: objectivesType || OkrTypeEnum.personal,
        authShareList: {
          deptIdList: [...new Set(defaultIds.concat(deptIds))],
          userIdList: userList.map((i: IUserInfo) => i.userId),
          virDeptIdList: [...new Set(defaultVirIds.concat(virDeptIds))],
        },
      })
      .then(() => {
        onRefreshList?.();
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const { run: onONameChange } = useDebounceFn(
    (value) => {
      if (!value?.trim()) {
        message.error('内容不可为空！');
        return;
      }
      okrApi.updateOkrName({ id, objectivesName: value });
    },
    { wait: 500 },
  );

  const onClickAddKr = () => {
    okrApi
      .addKr({
        objectivesId: id,
        keyResultName: '',
      })
      .then((krData) => {
        // 添加后回填新增KR的id
        const { krList = [] } = form.getFieldsValue();
        form.setFieldsValue({
          krList: [...krList, krData || {}],
        });
        setCheckPublish(false);
      });

    dataTrack(urlParams?.tab === 'org' ? 'click_org_okr_add_kr' : 'click_okr_add_kr');
  };

  // 改kr
  const { run: onKrNameChange } = useDebounceFn(
    (fieldName, value) => {
      if (!value?.trim()) {
        message.error('内容不可为空！');
        return;
      }
      const { krList = [] } = form.getFieldsValue();
      const item: IKrItem = krList[fieldName];
      const principalIdList = item.userList.map((i: IUserInfo) => i.id);
      okrApi.updateKr({ id: item.id, keyResultName: value, principalIdList });
    },
    { wait: 500 },
  );

  const staffNormalize = (value: any, preValue: any, fieldName: any) => {
    if (!value?.length) {
      message.info('负责人不能为空！');
      return preValue;
    }
    if (value.length > 10) {
      message.info('负责人最多不能超过10个！');
      return preValue;
    }
    const { krList = [] } = form.getFieldsValue();
    const item = krList[fieldName];
    okrApi.updateKr({
      id: item.id,
      keyResultName: item.keyResultName,
      principalIdList: value.map((i: any) => i.id),
    });
    return value;
  };

  const onGroupChange = (value: any) => {
    const { deptList = [], userList = [], virDeptList = [] } = value;
    const values = form.getFieldsValue();
    const { objectivesType } = values;
    okrApi.objGroupChange({
      id,
      objectivesType:
        objectivesType === OkrTypeEnum.organization
          ? OkrTypeCodeEnum.GROUP_OKR
          : OkrTypeCodeEnum.OKR,
      deptIdList: deptList.map((i: any) => i.deptId),
      virDeptIdList: virDeptList.map((i: any) => i.deptId),
      userIdList: userList.map((i: any) => i.userId),
    });
  };

  const onShareChange = (value: any) => {
    const { deptList = [], userList = [], virDeptList = [] } = value;
    authApi.authShareUpdate({
      authRelId: id, // okr的id
      sourceType: AuthShareType.OKR, // okr类型
      deptIdList: deptList.map((i: IAuthDeptVO) => i.deptId),
      userIdList: userList.map((i: any) => i.userId),
      virDeptIdList: virDeptList.map((i: IAuthDeptVO) => i.deptId),
    });
  };

  const validatorPublish = (values: any) => {
    const { objectivesName, krList, objectivesType, group } = values;
    const krPass = krList.every((i: IKrItem) => i.keyResultName?.trim() && i.userList?.length);
    const groupPass =
      objectivesType === OkrTypeEnum.organization ? Object.values(group || {}).flat().length : true;
    const check = objectivesName?.trim() && krPass && groupPass;
    setCheckPublish(check);
  };

  useEffect(() => {
    validatorPublish(form.getFieldsValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onValuesChange = () => {
    validatorPublish(form.getFieldsValue());
  };

  const onChangeOkrType = (e: RadioChangeEvent) => {
    if (e.target.value === OkrTypeEnum.personal) {
      okrApi.objGroupChange({
        id,
        objectivesType: OkrTypeCodeEnum.OKR,
      });
    }
  };

  const objUserInfo = principalVO ? { name: principalVO?.name, id: principalVO?.id } : userInfo;

  const initialValues = {
    krList,
    objectivesName,
    objectivesType: objectivesType || OkrTypeEnum.personal,
    objUser: objUserInfo,
    authShare: {
      deptList: authShareVO?.authDeptList || [],
      userList: authShareVO?.authUserList?.map((item) => ({
        userId: item.id,
        userName: item.name,
      })),
      virDeptList: authShareVO?.authVirDeptList || [],
    },
    group: formatObjectivesBelongs(objectivesBelongsVOList),
  };

  return (
    <div className="m-draft-card m-okr-card">
      <Form form={form} initialValues={initialValues} onValuesChange={onValuesChange}>
        <div className="m-okr-card-content">
          <div className="m-okr-card-tag">O{index + 1}</div>
          <div className="m-okr-card-list">
            <div className="m-okr-card-item m-o-list">
              <div className="m-okr-card-left">
                <Form.Item noStyle name="objectivesName">
                  <TextArea
                    className="m-obj-name"
                    autoSize={{ minRows: 1 }}
                    placeholder="一个好的Objective应符合：清晰、关键、简洁、激励、挑战"
                    onChange={(e) => onONameChange(e.target.value)}
                    maxLength={100}
                  />
                </Form.Item>
              </div>
              <div className="m-okr-card-operator">
                <span className="m-operator-item owner">
                  <span className="m-operator-item-title">负责人</span>
                  <span>{objUserInfo?.name}</span>
                </span>
                <span className="m-operator-item other status">
                  <span className="m-operator-item-title">状态</span>
                  <span className="m-empty-text">暂无</span>
                </span>
                <span className="m-operator-item other">
                  <span className="m-operator-item-title">打分</span>
                  <span className="m-empty-text">暂无</span>
                </span>
                <span className="m-operator-item other">
                  <span className="m-operator-item-title">进展记录</span>
                  <span className="m-empty-text">暂无</span>
                </span>
                <span className="m-operator-extra">
                  <CloseOutlined className="f-cp" onClick={onDelObj} />
                </span>
              </div>
            </div>

            <Form.List name="krList">
              {(fields, { remove }) => (
                <>
                  {fields.map((field, index) => {
                    return (
                      <div key={field.key} className="m-okr-card-item m-kr-list">
                        <div className="m-okr-card-kr-left">
                          <div className="m-kr-index">KR{index + 1}</div>
                          <Form.Item noStyle {...field} name={[field.name, 'keyResultName']}>
                            <TextArea
                              autoSize={{ minRows: 1 }}
                              onChange={(e) => {
                                onKrNameChange(field.name, e.target.value);
                              }}
                              placeholder="一个好的KR应符合：清晰、关键、简洁、可衡量、支撑性"
                              maxLength={500}
                            />
                          </Form.Item>
                        </div>
                        <div className="m-okr-card-operator">
                          <span className="m-operator-item owner">
                            <Form.Item
                              noStyle
                              {...field}
                              name={[field.name, 'userList']}
                              normalize={(cur, pre) => staffNormalize(cur, pre, field.name)}
                            >
                              <StaffSelect
                                className="m-staff-select"
                                style={{ width: 115 }}
                                placeholder="请输入"
                                mode="multiple"
                              />
                            </Form.Item>
                          </span>
                          <span className="m-operator-item other status">
                            <span className="m-empty-text">暂无</span>
                          </span>
                          <span className="m-operator-item other">
                            <span className="m-empty-text">暂无</span>
                          </span>
                          <span className="m-operator-item other">
                            <span className="m-empty-text">暂无</span>
                          </span>
                          <span className="m-operator-extra">
                            <Popconfirm
                              title="确定删除此条Key Result吗"
                              okText="删除"
                              cancelText="保留"
                              onConfirm={() => _onDelKr(field.name, remove)}
                            >
                              <CloseOutlined className="f-cp" />
                            </Popconfirm>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                  <div className="m-add-kr m-t-12 f-cp" onClick={onClickAddKr}>
                    <PlusCircleOutlined /> 添加 Key Result
                  </div>
                </>
              )}
            </Form.List>
          </div>
        </div>
        <div className="m-more-operator">
          <div className="m-more-text">更多设置</div>
          <Row gutter={[50, 0]} className="items-start">
            <Col span={12} className="m-flex">
              <span className="m-share-label">共享范围：</span>
              <Form.Item name="authShare" className="m-value">
                <ChooseScope
                  defaultScope={defaultScope}
                  defaultVirDeptScope={defaultVirScope}
                  onChange={onShareChange}
                />
              </Form.Item>
            </Col>
            {(userRoles.groupOkrAdmin || userRoles.virDeptAdmin) && (
              <Col span={12}>
                <div className="m-flex">
                  <span className="g-font-gray">是否同时将此OKR设置为某组织的OKR？</span>
                  <Form.Item name="objectivesType" className="m-value">
                    <Radio.Group name="radioGroup" className="m-l-20" onChange={onChangeOkrType}>
                      <Radio value={OkrTypeEnum.personal}>否</Radio>
                      <Radio value={OkrTypeEnum.organization}>是</Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <Form.Item noStyle dependencies={['objectivesType']}>
                  {({ getFieldValue }) =>
                    getFieldValue('objectivesType') === OkrTypeEnum.organization && (
                      <div className="m-flex m-t-16">
                        <span className="m-required g-font-gray-weak">所属部门(团队)：</span>
                        <Form.Item name="group" className="m-value">
                          <ChooseScope onChange={onGroupChange} hideUserSelect />
                        </Form.Item>
                      </div>
                    )
                  }
                </Form.Item>
              </Col>
            )}
          </Row>
        </div>
        <ConfigProvider
          theme={{
            components: {
              Button: {
                colorBgContainerDisabled: '#95CCFF',
                colorTextDisabled: '#fff',
              },
            },
          }}
        >
          <div className="m-draft-under">
            <Button
              type="primary"
              size="small"
              onClick={onClickSubmit}
              disabled={!checkPublish}
              loading={submitLoading}
            >
              发布
            </Button>
            <Button size="small" onClick={onCancel}>
              取消
            </Button>
          </div>
        </ConfigProvider>
      </Form>
    </div>
  );
};

export default memo(OkrDraftCard);
