import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Button } from 'antd';
import { useModel } from 'umi';
import Avatar from '@/components/avatar';
import authApi, { IUserInfo } from '@/api/auth';
import api, { OkrTypeEnum, OkrPeriodEnum, PeriodTypeEnum } from '@/api/okr';
import { SideMemberSelect, OkrCycles } from '@/components';
import { useRequest } from 'ahooks';
import useGetDefaultPeriod from '@/components/okr/okr-cycles/useGetDefaultPeriod';
import { ImportOutlined, PlusOutlined } from '@ant-design/icons';
import ImportOkr from '@/components/okr/import-okr';
import { dataTrack } from '@/utils/track';
import PersonalOkrList from './components/personal-okr-list';
import { Attention } from '../components';
import './index.less';

const Personal = () => {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const selectRef = useRef();

  const { defaultYear, defaultPeriodId } = useGetDefaultPeriod();

  const [year, setYear] = useState(defaultYear);
  const [periodId, setPeriodId] = useState(defaultPeriodId);
  const [member, setMember] = useState<IUserInfo | undefined>();

  const { photoUrl, name, id } = member || {};

  const { data: selectUserInfo, mutate: mutateUser } = useRequest(() => authApi.getUserInfo(id), {
    ready: !!id,
    refreshDeps: [id],
  });

  const isSelf = userInfo?.id === id;

  const onSelectMember = (user: IUserInfo) => {
    setMember(user);
  };

  const onAttentionChange = useCallback(() => {
    if (selectUserInfo) {
      mutateUser({
        ...selectUserInfo,
        ifAttentioned: !selectUserInfo?.ifAttentioned,
      });
    }
    //@ts-ignore
    selectRef.current?.refreshAttentionList();
  }, [selectRef, selectUserInfo]);

  const { runAsync: runAddDraft, loading: draftLoading } = useRequest(api.addObjDraft, {
    manual: true,
  });

  // 周期O个数
  const { data: count, run: runCount } = useRequest(
    () =>
      api.getPersonalOkrCount({
        userId: id!,
        year,
      }),
    {
      refreshDeps: [year, id, periodId],
      debounceWait: 500,
    },
  );

  // 个人周期列表
  const { data: period } = useRequest(
    () =>
      api.getPeriodUser({
        userId: id,
        periodType: PeriodTypeEnum.okr,
        year,
        needCustomize: true,
      }),
    {
      ready: !!id,
      refreshDeps: [year, id],
    },
  );

  const {
    data: dataList,
    refreshAsync: refreshOKRList,
    loading,
    mutate,
  } = useRequest(
    () =>
      api.getPersonalOkrList({
        userId: id,
        dateId: periodId,
        customize: true,
        year,
      }),
    {
      ready: !!id && !!periodId,
      refreshDeps: [id, periodId],
      debounceWait: 500,
    },
  );

  const mutateList = useCallback((payload: any) => {
    mutate(payload);
    runCount();
  }, []);

  const onRefresh = useCallback(() => {
    refreshOKRList();
    runCount();
  }, []);

  const onClickAddOkr = () => {
    dataTrack('click_okr_add');
    runAddDraft({
      dateId: periodId,
      objectivesType: OkrTypeEnum.personal,
    }).then((data) => {
      const newData = dataList?.concat(data);
      mutate(newData);
      window.scrollTo(0, document.body.scrollHeight + 317);
    });
  };

  useEffect(() => {
    // @ts-ignore
    mutate(null);
  }, [id]);

  // 当前选择周期
  const periodInfo = period?.find((i) => i.id === periodId);
  // 选择自定义周期
  const isCustomPeriod = periodInfo?.type === OkrPeriodEnum.custom;

  const listFooter =
    isSelf && !isCustomPeriod ? (
      <div className="m-personal-footer">
        <Button type="link" className="m-footer-btn" onClick={onClickAddOkr} loading={draftLoading}>
          <PlusOutlined className="m-footer-icon" />
          新建OKR
        </Button>
        <span className="m-footer-split">|</span>
        <ImportOkr importDateId={periodId} refreshOKRList={onRefresh}>
          <Button type="link" className="m-footer-btn">
            <ImportOutlined className="m-footer-icon" /> 从其他周期导入
          </Button>
        </ImportOkr>
      </div>
    ) : null;

  const emptyFooter =
    id && isSelf && !isCustomPeriod ? (
      <div>
        <Button
          className="m-empty-foot-btn"
          type="primary"
          style={{ marginRight: 10 }}
          onClick={onClickAddOkr}
          loading={draftLoading}
        >
          <PlusOutlined /> 新建OKR
        </Button>
        <ImportOkr importDateId={periodId} refreshOKRList={onRefresh}>
          <Button className="m-empty-foot-btn m-empty-import">
            <ImportOutlined /> 从其他周期导入
          </Button>
        </ImportOkr>
      </div>
    ) : null;

  return (
    <div className="m-okr-common">
      {!!userInfo && (
        <SideMemberSelect ref={selectRef} onSelect={onSelectMember} userInfo={userInfo} />
      )}
      <div className="m-okr-content">
        <div className="m-personal-top">
          <div className="m-header-left">
            <Avatar imgPath={photoUrl} className="m-attention-avatar" userName={name} />
            <span className="m-attention-name">{id ? name : userInfo?.name}</span>
            {!!id && !isSelf && (
              <Attention
                value={!!selectUserInfo?.ifAttentioned}
                attentionUserId={id}
                onChange={onAttentionChange}
              />
            )}
          </div>
        </div>
        {!!id && (
          <div className="m-personal-okr">
            <div className="m-personal-okr-header">
              <OkrCycles
                year={year}
                periodId={periodId}
                onYearChange={setYear}
                onPeriodChange={setPeriodId}
                statisticsList={count}
                periodList={period}
              />
            </div>
            <PersonalOkrList
              loading={loading}
              dataList={dataList}
              draggable
              mutateList={mutateList}
              onRefresh={onRefresh}
              listFooter={listFooter}
              emptyFooter={emptyFooter}
              isCustomPeriod={isCustomPeriod}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Personal;
