import React, { memo, useState, useEffect } from 'react';
import { useModel } from 'umi';
import { Popover } from 'antd';
import { LikeFilled } from '@ant-design/icons';
import { IUserInfo } from '@/api/auth';
import { Avatar, AnimateUp } from '@/components';
import LikeUserList from './like-user-list';
import './index.less';

const MAX_DISPLAY_COUNT = 10;

interface IProps {
  isThumbUp: boolean;
  style?: React.CSSProperties;
  thumbUpCount: number;
  onLike?: () => void;
  userList?: IUserInfo[];
}

const Like = (props: IProps) => {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { isThumbUp, thumbUpCount, onLike, style = {}, userList } = props;
  const [isLiked, setIsLiked] = useState(isThumbUp);
  const [count, setCount] = useState(thumbUpCount || 0);
  const [showAnimateUp, setShowAnimateUp] = useState(false);
  const [displayUserList, setDisplayUserList] = useState<any>(userList);

  useEffect(() => {
    setIsLiked(isThumbUp);
  }, [isThumbUp]);

  useEffect(() => {
    setCount(thumbUpCount || 0);
  }, [thumbUpCount]);

  useEffect(() => {
    setDisplayUserList(userList || []);
  }, [userList]);

  const onClick = () => {
    if (isLiked) return;
    setDisplayUserList([
      { ...userInfo, name: userInfo?.name, id: userInfo?.id },
      ...(displayUserList || []),
    ]);
    onLike?.();
    setIsLiked(true);
    setCount((thumbUpCount || 0) + 1);
    setShowAnimateUp(true);
    setTimeout(() => {
      setShowAnimateUp(false);
    }, 500);
  };

  const avatarLength = displayUserList?.length || 0;

  return (
    <div className="m-like">
      <Popover
        content={<LikeUserList userList={displayUserList} />}
        getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
      >
        <div className="m-like-avatar-group">
          {displayUserList?.slice(0, MAX_DISPLAY_COUNT)?.map((item: any, index: number) => (
            <Avatar
              key={index}
              imgPath={item?.photoUrl}
              userName={item?.name}
              className="m-like-avatar"
              style={{
                right: (avatarLength - index - 1 + (avatarLength > MAX_DISPLAY_COUNT ? 1 : 0)) * -4,
                zIndex: avatarLength - index + 1,
              }}
            />
          ))}
          {avatarLength > MAX_DISPLAY_COUNT && (
            <div className="m-avatar-ellipse">
              <span className="relative bottom-1">...</span>
            </div>
          )}
        </div>
      </Popover>
      <a onClick={onClick} className={`m-like-icon ${isLiked ? 'active' : ''}`}>
        <LikeFilled />
        {!!count && (
          <span className="m-l-4">
            <AnimateUp show={showAnimateUp}>{count}</AnimateUp>
          </span>
        )}
      </a>
    </div>
  );
};

export default memo(Like);
