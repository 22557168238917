/**
 * 动态详情，实际上是消息详情
 */
import React, { useEffect } from 'react';
import { useParams, history } from 'umi';
import { useRequest } from 'ahooks';
import { Spin, Empty } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import api from '@/api/message';
import { getParams } from '@/utils/tools';
import { DiscoverCard } from '../components';
import styles from './index.less';

const DiscoverDetail = () => {
  const { id }: { id: string } = useParams();
  const { type, userId } = getParams() as { type: string; userId: string };

  const { data, loading, refresh, error } = useRequest(
    () => api.getMessageDetail({ id, type, userId }),
    {
      refreshDeps: [id, type, userId],
    },
  );

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        history.goBack();
      }, 1000);
    }
  }, [error]);

  return (
    <Spin spinning={loading}>
      <div className={styles.container}>
        {error ? (
          <Empty className="mt-10" />
        ) : (
          <div>
            {history?.length > 2 && (
              <div className={styles.back} onClick={history.goBack}>
                <LeftOutlined style={{ marginRight: 4 }} />
                返回
              </div>
            )}
            <DiscoverCard data={data!} refresh={refresh} />
          </div>
        )}
      </div>
    </Spin>
  );
};

export default DiscoverDetail;
