//@ts-nocheck

export const formatObjectivesBelongs = (belongsList: any) => {
  const initBelongList = { deptList: [], userList: [], virDeptList: [] };
  (belongsList || []).map((item) => {
    // 1 部门  2 人员 3 虚拟部门
    if (item.belongType === 1) {
      initBelongList.deptList.push({
        deptId: item.belongId,
        deptName: item.belongName,
      });
    } else if (item.belongType === 2) {
      initBelongList.userList.push({
        userId: item.belongId,
        userName: item.belongName,
      });
    } else if (item.belongType === 3) {
      initBelongList.virDeptList.push({
        deptId: item.belongId || item.virDeptCode,
        deptName: item.belongName || item.virDeptName,
      });
    }
  });

  return initBelongList;
};

// 权限信息
export const getEditAuthMap = (authMap = {}, userInfo = {}, notEditable = false) => {
  return notEditable
    ? { updateAuthKrFunc: () => false } // 无任何编辑权限
    : {
        updateOName: authMap?.updateName, // O名称
        upDateOPrincipal: authMap?.updatePrincipal, // O负责人
        updateOProcess: authMap?.updateProcess, // O进展
        deleteO: authMap?.delete, // 删除O
        updateKr: authMap?.updateKr, // 添加KR
        updateAuthKrFunc: (krUserList = []) => {
          // 更新KR信息 负责人 进展
          const idList = krUserList.map((i) => i.id);
          // 当前登录人是否在KR负责人列表内，如果在其中的话，则有编辑该 KR 的权限
          return authMap?.updateKr || idList.includes(userInfo.id);
        },
        updateDate: authMap?.updateDate, // OKR周期
        updateBelong: authMap?.updateAuth, // 所属部门
        updateShare: authMap?.updateAuth, // 共享范围
        updateRel: authMap?.updateRel, // 添加对齐关系
        updateGroupOkr: authMap?.updateGroupOkr, // 调整OKR所属类型 个人/组织
      };
};
