import React, { useState, useEffect } from 'react';
import { Checkbox, Row, Col } from 'antd';
import { IQueryAlignOkrItem } from '@/api/okr';
import { KrLabel } from '@/components';

interface IProps {
  list: IQueryAlignOkrItem[];
  onChange: (value: any) => void;
}

const KRList = (props: IProps) => {
  const { list, onChange } = props;
  const [checkbox, setCheckbox] = useState<number[]>([]);

  const onCheckChange = (value: any) => {
    setCheckbox(value);
  };

  useEffect(() => {
    const arr = list
      .map((i) => ({ ...i, id: checkbox.includes(i.id) ? i.id : undefined }))
      .filter((i: any) => i.id || i.relType);
    onChange?.(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkbox]);

  return (
    <Checkbox.Group style={{ width: '100%' }} onChange={onCheckChange}>
      <Row>
        {list.map((c, index: number) => (
          <Col span={24} className="m-alignment-kr-list" key={c.id} style={{ marginBottom: 12 }}>
            <Checkbox value={c.id}>
              <span className="m-alignment-kr-name flex">
                <KrLabel />
                {c.name}
              </span>
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};

export default KRList;
