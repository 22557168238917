import React, { memo } from 'react';
import { Typography } from 'antd';
import { DiscoverTypeEnum, IDiscoverItem } from '@/api/discover';
import styles from './index.less';

interface IProps {
  data: IDiscoverItem;
  onOpenDrawer: (data: IDiscoverItem) => void;
}

const OAndKrTitle = (props: IProps) => {
  const { data, onOpenDrawer } = props;

  const { type, name, objectivesId, oSorts, krSorts } = data || {};

  const logoText =
    // @ts-ignore
    {
      [DiscoverTypeEnum.OKR]: `O${oSorts || 1}`,
      [DiscoverTypeEnum.KEY_RESULT]: `KR${krSorts || 1}`,
      [DiscoverTypeEnum.GROUP_OKR]: `O${oSorts || 1}`,
      [DiscoverTypeEnum.GROUP_OKR_KEY_RESULT]: `KR${krSorts || 1}`,
    }[type] || `O${oSorts || 1}`;

  const onClick = () => {
    onOpenDrawer(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.img}>{logoText}</div>
      <Typography.Text ellipsis={{ tooltip: true }} className={styles.name} onClick={onClick}>
        {name}
      </Typography.Text>
    </div>
  );
};

export default memo(OAndKrTitle);
