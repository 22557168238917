import dayjs from 'dayjs';
import { IProcessDetail } from '@/api/process';

const isoWeek = require('dayjs/plugin/isoWeek');
dayjs.extend(isoWeek);

/**
 * 获取周报邮件的标题
 * @param {string} userName
 * @param {object} processInfo
 * @returns {string} // 戴浪浪的周报（2022 W15 04月10日~04月16日）
 */
export function getEmailTitle(userName: string, processInfo: IProcessDetail) {
  if (!processInfo) return `${userName || ''}的周报`;
  const { startDate, endDate, year } = processInfo;
  const MONTH_DAY = 'MM月DD日';
  // @ts-ignore
  const week = dayjs(endDate).isoWeek();
  const start = dayjs(startDate).format(MONTH_DAY);
  const end = dayjs(endDate).format(MONTH_DAY);
  return `${userName || ''}的周报（${year} W${week} ${start}~${end}）`;
}
