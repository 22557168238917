import { useEffect, useRef } from 'react';
import { useLocation } from 'umi';
import { getParams } from '@/utils/tools';
import storage from '@/utils/storage';

export const PROCESS_LIST_QUERY_KEY = 'process-list-query-values';

export function setProcessListParams(params: any) {
  storage.session.set(PROCESS_LIST_QUERY_KEY, params);
}

function useDefaultQuery(): any {
  const cacheRef = useRef({});
  const location = useLocation();

  useEffect(() => {
    const queryParams = getParams(location.search);
    const sessionParams = storage.session.get(PROCESS_LIST_QUERY_KEY, {});
    const temp = { ...queryParams, ...sessionParams };
    cacheRef.current = { ...temp, periodId: temp.periodId ? +temp.periodId : undefined };
    storage.session.remove(PROCESS_LIST_QUERY_KEY);
  }, [location.search]);

  return cacheRef.current;
}

export default useDefaultQuery;
