// 1、创建一个日志类
// @params: {
//     userId: string;
//     email: string;
//     userName: string;
//     systemCode: string;
// }
export interface ILog {
  userId?: string;
  email?: string;
  userName?: string;
  systemCode: string;
}

declare global {
  interface Window {
    Logger: Logger;
    $$ehr_trace_info: ILog;
  }
}

export class Logger {
  init(params: ILog): void {
    window.$$ehr_trace_info = params;
    this.log(window.$$ehr_trace_info);
  }

  log(params: Record<string, any>): void {
    this.trace(params, 'INFO');
  }

  error(params: Record<string, any>): void {
    this.trace(params, 'ERROR');
  }

  warn(params: Record<string, any>): void {
    this.trace(params, 'WARN');
  }

  trace(params: Record<string, any>, logLevel: `DEBUG` | `INFO` | `WARN` | `ERROR`): void {
    var log = {
      logLevel,
      ...params,
      ...(window.$$ehr_trace_info || {}),
    };

    if (location.origin.indexOf('localhost') > -1) return;
    fetch(`/log.gif?` + new URLSearchParams(log), {
      mode: 'no-cors',
      method: 'head',
    });
  }
}

// 如何使用
// 方法1：将Logger实例挂载到全局对象上
// window.Logger = new Logger();
// window.Logger.init({ systemCode: 'meeting' });

// 方法2：
// 创建 Logger 的实例
// const loggerInstance = new Logger();
// // 调用 init 方法
// loggerInstance.init({ systemCode: 'meeting' });
