import React, { memo } from 'react';
import { transferComment } from '@/utils/tools';
import './index.less';

interface IProps {
  children: string;
  className?: string;
}

const CommentContent = ({ children, className }: IProps) => {
  const stopPropagation = (e: any) => {
    if (e.target && e.target.tagName.toLowerCase() === 'a') {
      e.stopPropagation();
    }
  };

  return (
    <pre
      className={`u-comment-content ${className || ''}`}
      dangerouslySetInnerHTML={{ __html: transferComment(children) }}
      onClick={stopPropagation}
    />
  );
};

export default memo(CommentContent);
