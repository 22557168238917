export enum ProcessTabEnum {
  // 我的OKR进展
  // @deprecated
  mine = 'mine',
  // 团队成员OKR进展
  member = 'member',
  // v3.0 新增
  user = 'user',
  // 组织OKR进展
  org = 'org',
  // OKR 详情页
  detail = 'detail',
}
export const PROCESS_TAB_LIST = [
  // {
  //     tab: '我的OKR进展',
  //     key: ProcessTabEnum.mine,
  //     icon: 'icon-wodeOKR'
  // },
  {
    tab: '个人OKR进展',
    key: ProcessTabEnum.user,
    icon: 'icon-wodeOKR',
  },
  {
    tab: '组织OKR进展',
    key: ProcessTabEnum.org,
    icon: 'icon-tuanduiOKR',
  },
];

/**
 * 时间范围枚举，自定义枚举时，from和to必填
 * @type {{OneYear: string, Custom: string, OneHalf: string, ThreeMonth: string, OneMonth: string}}
 */
export const DateTimeRangeEnum = {
  // 最近3月
  ThreeMonth: 'ThreeMonth',
  // 最近一月
  OneMonth: 'OneMonth',
  // 最近半年
  OneHalf: 'HalfYear',
  // 最近一年
  OneYear: 'OneYear',
  // 自定义
  Custom: 'Customer',
};

// 周进展范围选项
export const DateTimeRangeOptions = [
  {
    label: '最近一个月',
    value: DateTimeRangeEnum.OneMonth,
  },
  {
    label: '最近三个月',
    value: DateTimeRangeEnum.ThreeMonth,
  },
  {
    label: '最近半年',
    value: DateTimeRangeEnum.OneHalf,
  },
  {
    label: '最近一年',
    value: DateTimeRangeEnum.OneYear,
  },
  {
    label: '自定义',
    value: DateTimeRangeEnum.Custom,
  },
];

// 周报填写引导插画
export const WEEKLY_GUIDE_IMG =
  'https://nos.netease.com/entrantproduct1/9617-0eea90c4ae6b4a7ea2a5828b21a55867';

// OKR 为空时，占位插画
export const OKR_EMPTY_PLACEHOLDER =
  'https://nos.netease.com/entrantproduct1/514408-ee6a0f58c7dc4c40bcba2e540cbd9806';

export const STORAGE_KEY_MAP = {
  weeklyCustomRange: 'weeklyTimeRange',
};

export enum ProcessPublishStatusEnum {
  // null 空数据
  // 草稿
  draft = 0,
  // 发布
  published = 1,
  // 过期未发布
  overdueForRelease = 2,
}

/**
 * 总结周期
 * @type {{month: number, year: number, halfYear: number, quarter: number}}
 */
export const SummaryPeriodTypeEnum = {
  // 月度
  month: 1,
  // 季度
  quarter: 2,
  // 半年度
  halfYear: 3,
  // 年度
  year: 4,
};

export const ProcessOtherKey = 'processOther';
