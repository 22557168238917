import React, { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { OkrTypeEnum } from '@/api/okr';
import { DateTimeRangeEnum } from '@/constants';
import processListApi, { IProcessPeriodItem, ProcessSourceTypeEnum } from '@/api/process';
import { Row, Col } from 'antd';
import { getProcessMoment } from '@/utils/tools';
import { IconFont } from '@/components';
import './weekly-period-select.less';
import * as Sentry from '@sentry/react';

const STATUS_TEXT_ENUM = {
  0: 'icon-caogao',
  1: 'icon-fabu',
  2: 'icon-weifabu',
};

interface IProps {
  type: OkrTypeEnum;
  year?: string | number;
  dateClass?: string;
  userId?: number;
  deptId?: string;
  processSourceType?: ProcessSourceTypeEnum;
  selectedDateId?: number;
  onSelect: (value: any) => void;
  weeklyTimeRange?: any;
  open: boolean;
}

function WeeklyPeriodSelect(props: IProps) {
  const {
    open,
    // 组织 | 个人进展
    type = OkrTypeEnum.personal,
    year,
    dateClass,
    userId,
    deptId,
    weeklyTimeRange = { activeKey: DateTimeRangeEnum.OneMonth },
    processSourceType = ProcessSourceTypeEnum.PROCESS_WEEK_REPORT,
    selectedDateId = 0,
    onSelect,
  } = props;

  const [options, setOptions] = useSafeState<IProcessPeriodItem[]>();

  useEffect(() => {
    const payload = {
      type,
      year,
      processSourceType,
      dateClass,
      ...weeklyTimeRange,
    };
    if (type === OkrTypeEnum.organization) {
      Object.assign(payload, { deptId });
    } else {
      Object.assign(payload, { userId });
    }
    processListApi.getPeriodList(payload).then((data) => {
      if (!Array.isArray(data)) {
        Sentry.captureException({
          message: '/process/query/period/list 返回值出错了',
          extra: {
            data,
          },
        });
      }
      setOptions(data);
    });
  }, [year, userId, deptId, processSourceType, weeklyTimeRange]);

  useEffect(() => {
    if (open && selectedDateId && options?.length) {
      const idx = options?.findIndex((item) => item.dateId === selectedDateId);
      if (idx) {
        // 行数
        const rowCount = parseInt((idx / 3).toString());
        const scrollTop = (52 + 10) * rowCount - 30;
        document.getElementById('periodContentRef')?.scrollTo({ top: scrollTop });
      }
    }
  }, [open, selectedDateId, options]);

  return (
    <div className="weekly-period-select">
      <div className="pl-period-options-year">{year}年</div>
      <Row gutter={[12, 12]} className="weekly-period-select_content" id="periodContentRef">
        {options?.map((item) => {
          const { name, dateId, startDate, endDate, status } = item;
          // @ts-ignore
          const iconType = STATUS_TEXT_ENUM[status];
          return (
            <Col span={8} key={dateId}>
              <div
                className={`wps_ellipsis-item ${dateId === +selectedDateId ? 'active' : ''}`}
                onClick={() => onSelect(dateId)}
              >
                <div className="wps_ellipsis-item-title">{name}</div>
                <div className="wps_ellipsis-item-title-date">
                  {startDate && getProcessMoment(startDate)} -{' '}
                  {endDate && getProcessMoment(endDate)}
                </div>
                <IconFont className="wps_ellipsis-item-icon" type={iconType} />
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default WeeklyPeriodSelect;
