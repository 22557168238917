import { useRequest } from 'ahooks';
import { OkrTypeEnum } from '@/api/okr';
import { SummaryPeriodTypeEnum } from '@/constants';
import processListApi, { ProcessSourceTypeEnum } from '@/api/process';
import { PeriodTypeEnum } from '@/api/okr';

const ENTRY_LIST = [
  {
    value: SummaryPeriodTypeEnum.month,
    label: '月度总结',
    count: 30,
    className: 'pl-summary-entry-item-month',
    eventParam: 1,
  },
  {
    value: SummaryPeriodTypeEnum.quarter,
    label: '季度总结',
    count: 90,
    className: 'pl-summary-entry-item-quarter',
    eventParam: 2,
  },
  {
    value: SummaryPeriodTypeEnum.halfYear,
    label: '半年度总结',
    count: 182,
    className: 'pl-summary-entry-item-half',
    eventParam: 3,
  },
  {
    value: SummaryPeriodTypeEnum.year,
    label: '年度总结',
    count: 365,
    className: 'pl-summary-entry-item-year',
    eventParam: 4,
  },
];

function formatList(periodCodes: number[]) {
  return ENTRY_LIST.filter((item) => periodCodes?.includes(item.value));
}

/**
 * 获取总结周期，选中部门或者选中人员
 * @param {number} okrType - OkrTypeEnum.user | OkrTypeEnum.org
 * @param {object} option
 * @param {number} [option.userId] - 选中人员
 * @param {string} [option.deptId] - 选中部门
 * @param {PeriodTypeEnum} [option.periodType] - 类型，默认
 */
function useSummaryPeriod(option: any) {
  const { year, userId, periodType = PeriodTypeEnum.summary, okrDateId } = option;

  const { data: yearPeriod, run: getYearPeriod } = useRequest(
    async () => {
      const payload = {
        type: OkrTypeEnum.personal,
        year,
        okrDateId,
        userId,
        processSourceType: ProcessSourceTypeEnum.PROCESS_SUMMARY,
        periodType: SummaryPeriodTypeEnum.year,
      };
      const data = await processListApi.getPeriodList(payload);
      return data?.[0];
    },
    {
      manual: true,
    },
  );

  const { data, loading } = useRequest(
    async () => {
      const data = await processListApi.getPeriodTypeList({ userId, periodType });
      const periodTypes = data.map((item) => item.periodType);
      if (periodTypes?.includes(SummaryPeriodTypeEnum.year)) {
        getYearPeriod?.();
      }
      return formatList(periodTypes);
    },
    {
      ready: !!userId,
      refreshDeps: [userId, year],
    },
  );

  return {
    list: data || [],
    loading,
    yearPeriod,
  };
}

export default useSummaryPeriod;
