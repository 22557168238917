import { GATEWAY } from '@/api/config';
import api from '@/api';
import { ResponsePromise, ResponsePagePromise } from '../interface';
import { IDeptItem } from '@/api/auth';
import { IDeptManagerConfigItem, IOutsourcingItem } from './interface';

export default {
  // 人员设置-管理员配置-部门树
  getDeptTree: (deptId: string): ResponsePromise<IDeptItem[]> =>
    api.get(`${GATEWAY}/admin/dept/tree`, { id: deptId }),
  // 人员设置-管理员配置-获取管理员信息
  getConfigUserList: (deptId: string): ResponsePromise<IDeptManagerConfigItem[]> =>
    api.get(`${GATEWAY}/admin/dept/config/list`, { id: deptId }),
  // 人员配置-管理员配置-绑定管理员
  bindAdminToDept: (payload: any) => api.post(`${GATEWAY}/admin/dept/manager/bind`, payload),
  // 人员配置-管理员配置-解绑管理员
  unbindAdminToDept: (payload: any) => api.post(`${GATEWAY}/admin/dept/manager/unbind`, payload),

  // 人员配置-外包人员配置-列表
  getOutsourcingList: (params: any): ResponsePagePromise<IOutsourcingItem> =>
    api.get(`${GATEWAY}/admin/outsourcing/page/list`, params),
  // 人员配置-外包人员配置-添加
  addOutsourcing: (payload: any) => api.post(`${GATEWAY}/admin/outsourcing/add`, payload),
  // 人员配置-外包人员配置-更新
  updateOutsourcing: (payload: any) => api.post(`${GATEWAY}/admin/outsourcing/update`, payload),
  // 人员配置-外包人员配置-删除
  deleteOutsourcing: (id: number) => api.post(`${GATEWAY}/admin/outsourcing/delete`, { id }),

  // 运维工具-周报统计导出
  exportWeekReport: (payload: { deptIdList: string[]; dateIdList: number[] }) =>
    api.post(`${GATEWAY}/admin/weekReport/statistics/export`, payload),
};

export * from './interface';
