export const TABS = {
  team: {
    icon: 'icon-tuanduiOKR',
    label: '团队动态',
    key: 'team',
    eventParam: 1,
    color: '#1890FF',
    hoverColor: 'rgba(24, 144, 255, .1)',
  },
  follow: {
    icon: 'icon-Subtract',
    label: '我关注的',
    key: 'follow',
    eventParam: 2,
    color: '#FCA30A',
    hoverColor: 'rgba(252, 163, 10, .1)',
  },
  current: {
    icon: 'icon-zuzhiOKR',
    label: '全部动态',
    key: 'current',
    eventParam: 3,
    color: '#EF6B0B',
    hoverColor: 'rgba(239, 107, 11, .1)',
  },
};

export const LEVEL_FILTER_TYPE = {
  ALL: 'all',
  SUP: 1,
  SUB: 2,
  SIBLING: 3,
};

export const LEVEL_FILTER_TYPE_LIST = [
  {
    value: '',
    label: '全部',
  },
  {
    value: LEVEL_FILTER_TYPE.SUP,
    label: '我的上级',
  },
  {
    value: LEVEL_FILTER_TYPE.SUB,
    label: '我的下属',
  },
  {
    value: LEVEL_FILTER_TYPE.SIBLING,
    label: '我的同级',
  },
];

export const DynamicRequireTypeEnum = {
  /**
   * 空：全部
   * o:目标
   * k:关键成果
   * w:周报
   * s:总结
   */
  ALL: 'all',
  OKR: 'O',
  KR: 'K',
  WEEKLY: 'W',
  SUMMARY: 'S',
};

export const DynamicRequireTypeEnumEventParamMap = {
  [DynamicRequireTypeEnum.OKR]: 2,
  [DynamicRequireTypeEnum.KR]: 3,
  [DynamicRequireTypeEnum.WEEKLY]: 4,
  [DynamicRequireTypeEnum.SUMMARY]: 5,
};

export const DISCOVER_FILTER_TYPE_MAP = {
  [DynamicRequireTypeEnum.ALL]: '全部',
  [DynamicRequireTypeEnum.OKR]: '目标',
  [DynamicRequireTypeEnum.KR]: '关键成果',
  [DynamicRequireTypeEnum.WEEKLY]: '周报',
  [DynamicRequireTypeEnum.SUMMARY]: '总结',
};

export const DISCOVER_FILTER_TYPE_LIST = [
  {
    value: '',
    label: '全部',
  },
  {
    value: DynamicRequireTypeEnum.OKR,
    label: '目标',
  },
  {
    value: DynamicRequireTypeEnum.KR,
    label: '关键成果',
  },
  {
    value: DynamicRequireTypeEnum.WEEKLY,
    label: '周报',
  },
  {
    value: DynamicRequireTypeEnum.SUMMARY,
    label: '总结',
  },
];
