import { useHistory } from 'umi';
import { useRequest } from 'ahooks';
import api, { IReportItem } from '@/api/setting';
import './index.less';

export default function Report() {
  const history = useHistory();

  const { data } = useRequest(api.getReportList);

  const onClick = (record: IReportItem) => {
    history.push(`/setting/report/detail?id=${record.id}&title=${record.name}`);
  };

  return (
    <div className="m-report">
      <div className="title">OKR报表</div>
      <div className="list">
        {data?.map((item: IReportItem) => {
          const { id, name, description } = item;
          return (
            <div className="item" key={id} onClick={() => onClick(item)}>
              <span className="name">{name}</span>
              <span className="tip">{description}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
