import { PageRequestParams } from '@/api/interface';
import { IUserInfo } from '@/api/auth';

export enum MessageTargetTypeEnum {
  OKR = 'OKR',
  GROUP_OKR = 'GROUP_OKR',
  WORK_REPORT = 'WORK_REPORT',
  DYNAMIC = 'DYNAMIC',
  SUMMARY_FEEDBACK = 'SUMMARY_FEEDBACK',
}

export interface IMessageParams extends PageRequestParams {
  messageReadFlag?: boolean; // 是否已读
}

export interface IMessageItem {
  id: number;
  relId: number; // 关联id, 评论或者分享
  messageReadFlag: boolean; // 是否已读
  updateTime: string;
  sysTime: string;
  type: number;
  createTime: string;
  targetType: MessageTargetTypeEnum;
  targetId: number;
  content: string;
  userVO: IUserInfo; // 消息创建人
  receiverVO: IUserInfo; // 消息接收人
  titleName: string;
  targetUserVO: IUserInfo; // 主贴创建人
  processSourceType: string;
  mainContent: string; // 主贴内容
}
