// 点赞评论操作记录组件
import React, { memo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { Like } from '@/components';
import { MessageFilled } from '@ant-design/icons';
import { CommentMain } from '@/components/comment/comment-modal';
import { CommentTypeEnum, ICommentItem } from '@/api/common';
import { IDynamicRecordItem } from '@/api/discover';
import { commentArray2Tree } from '@/utils/tools';
import { IUserInfo } from '@/api/auth';
import RecordList from './record-list';
import './index.less';

interface IProps {
  recordList: IDynamicRecordItem[];
  commentList: ICommentItem[];
  userInfo: IUserInfo;
  id: number;
  targetType: CommentTypeEnum;
  onRefreshComment: (comment?: ICommentItem) => void;
  onDeleteCommentSuccess?: (count: number, id: number) => void;
  thumbUpCount: number;
  isThumbUp: boolean;
  onLike: () => void;
  likeUserList?: any;
  beforeSubmit?: () => Promise<number>;
}

const LikeCommentRecord = (props: IProps) => {
  const {
    recordList,
    commentList,
    userInfo,
    id,
    targetType,
    onRefreshComment,
    onDeleteCommentSuccess,
    onLike,
    isThumbUp,
    thumbUpCount,
    likeUserList,
    beforeSubmit,
  } = props;

  const [hideInput, setHideInput] = useState(true);

  const commentTreeList = commentArray2Tree(cloneDeep(commentList || []));

  const onClickComment = () => {
    setHideInput(!hideInput);
  };

  const onCloseComment = () => {
    setHideInput(true);
  };

  const handleSuccess = (data?: ICommentItem) => {
    onRefreshComment?.(data);
    onCloseComment();
  };

  return (
    <div className="m-like-comment-record">
      <div className="m-discover-card-bottom">
        {recordList?.length ? <RecordList dataList={recordList} /> : <div />}
        <div className="m-like-comment-group">
          <Like
            isThumbUp={isThumbUp}
            thumbUpCount={thumbUpCount}
            onLike={onLike}
            userList={likeUserList}
          />
          <div className="m-discover-comment" onClick={onClickComment}>
            <MessageFilled />
            {!!commentTreeList?.length && (
              <span className="m-discover-comment-count">{commentTreeList?.length}</span>
            )}
          </div>
        </div>
      </div>
      {(!hideInput || !!commentTreeList?.length) && (
        <div
          className="m-discover-comment-container"
          style={hideInput ? {} : { borderTop: '1px solid #f0f0f0', marginTop: 12 }}
        >
          <CommentMain
            style={{ height: 'auto', maxHeight: 'none' }}
            userInfo={userInfo!}
            list={commentTreeList}
            autoFocus
            autoSize={{ minRows: 5, maxRows: 5 }}
            id={id}
            targetType={targetType}
            handleDelete={onDeleteCommentSuccess}
            handleSuccess={handleSuccess}
            onCancel={onCloseComment}
            hideInput={hideInput}
            beforeSubmit={beforeSubmit}
          />
        </div>
      )}
    </div>
  );
};

export default memo(LikeCommentRecord);
