import React, { useEffect, useMemo } from 'react';
import { useHistory, useModel } from 'umi';
import { useRequest, useSafeState } from 'ahooks';
import * as querystring from 'querystring';
import { Spin } from 'antd';
import { OkrTypeEnum } from '@/api/okr';
import authApi from '@/api/auth';
import processListApi, {
  IProcessPeriodItem,
  IQueryPeriodParam,
  ProcessSourceTypeEnum,
} from '@/api/process';
import { ProcessTabEnum, STORAGE_KEY_MAP } from '@/constants';
import storage from '@/utils/storage';
import { OkrEmptyPlaceholder, ProcessCard, SummaryEditEntry, WeeklyReportGuide } from '..';
import { formatProcessList } from './utils';

interface IProps {
  readonly?: boolean;
  hasOkrs: boolean;
  year: number;
  userId?: number;
  deptId?: string;
  processSourceType: ProcessSourceTypeEnum;
  periodId?: number;
  weeklyExtraFilters?: any;
  onClickGuide: () => void;
  onAddOkr: () => void;
}

function ProcessListWrap(props: IProps) {
  const {
    readonly,
    // 选中周期是否有OKR
    hasOkrs,
    // 组织 | 个人进展
    year,
    userId,
    deptId,
    processSourceType,
    periodId,
    weeklyExtraFilters,
    onClickGuide,
    onAddOkr,
  } = props;

  const { initialState } = useModel('@@initialState');
  const { userInfo: currentUser } = initialState || {};

  const [loading, setLoading] = useSafeState(false);
  const [processList, setProcessList] = useSafeState<IProcessPeriodItem[] | null>(null);

  const history = useHistory();

  const { data: userInfo } = useRequest(() => authApi.getUserInfo(userId), {
    ready: !!userId,
    refreshDeps: [userId],
  });

  useEffect(() => {
    if (!periodId) return;
    setLoading(true);
    const payload: IQueryPeriodParam = {
      type: OkrTypeEnum.personal,
      year,
      processSourceType,
      okrDateId: periodId,
      userId,
      notNull: processSourceType !== ProcessSourceTypeEnum.PROCESS_WEEK_REPORT,
    };
    // 周报有个快捷筛选：周进展范围
    if (processSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT) {
      Object.assign(payload, weeklyExtraFilters);
    }
    const fetch = async () => {
      const data = await processListApi.getPeriodList(payload);
      setProcessList(formatProcessList(data, processSourceType));
      setLoading(false);
    };
    fetch();
  }, [periodId, year, userId, deptId, processSourceType, weeklyExtraFilters]);

  const onJumpDetail = (process: any) => {
    const { dateId } = process;
    const query = {
      dateId,
      type: processSourceType,
      year,
      periodId,
      tab: ProcessTabEnum.user,
      userId,
    };
    if (processSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT) {
      storage.session.set(STORAGE_KEY_MAP.weeklyCustomRange, weeklyExtraFilters);
    }
    history.push(`/process/dashboard?${querystring.stringify(query)}`);
  };
  // @ts-ignore 没有OKR列表或者没有进展列表则为空
  const isEmpty = !hasOkrs || (!loading && (!processList || processList?.length === 0));

  const isCurrentUser = currentUser?.id === userId;

  const hasWeeklyGuide = useMemo(() => {
    if (readonly) return false;
    return (
      processSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT && isCurrentUser && hasOkrs
    );
  }, [readonly, processSourceType, isCurrentUser, hasOkrs]);

  const hasSummaryGuide = useMemo(() => {
    if (readonly) return false;
    return processSourceType === ProcessSourceTypeEnum.PROCESS_SUMMARY && hasOkrs && isCurrentUser;
  }, [readonly, processSourceType, isCurrentUser, hasOkrs]);

  return (
    <div className="pl-process-list-wrap">
      <Spin spinning={loading}>
        {hasWeeklyGuide && <WeeklyReportGuide onJumpDetail={onJumpDetail} />}
        {hasSummaryGuide && (
          <SummaryEditEntry
            userId={userId}
            deptId={deptId}
            year={year}
            onJumpDetail={onJumpDetail}
            okrDateId={periodId}
          />
        )}
        {processList &&
          (isEmpty ? (
            <OkrEmptyPlaceholder
              simple={!isCurrentUser || hasOkrs}
              hasEditAuth={isCurrentUser && !readonly}
              onSelect={onClickGuide}
              onAdd={onAddOkr}
            />
          ) : (
            <>
              {processList?.map((item) => {
                return (
                  <ProcessCard
                    readonly={readonly}
                    year={year}
                    okrDateId={periodId!}
                    dateId={item.dateId}
                    userId={userId}
                    deptId={deptId}
                    key={userId + '_' + item.dateId}
                    userOrDeptInfo={userInfo}
                    info={item}
                    type={OkrTypeEnum.personal}
                    processSourceType={processSourceType}
                    onJumpDetail={onJumpDetail}
                  />
                );
              })}
            </>
          ))}
      </Spin>
    </div>
  );
}

export default ProcessListWrap;
