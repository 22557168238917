import React, { memo, useState } from 'react';
import { LikeFilled } from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import api, { LikeTypeEnum } from '@/api/common';
import { AnimateUp } from '@/components';
import './index.less';

interface IProps {
  value?: any;
  id: number;
  belongUserId: number;
  defaultValue?: any;
}

const OkrThumb = (props: IProps) => {
  const { id, belongUserId } = props;
  const [value, setValue] = useControllableValue(props);

  const [showAnimateUp, setShowAnimateUp] = useState(false);

  const onClick = () => {
    if (value?.isThumbUp) return;
    api
      .thumbUp({
        objType: LikeTypeEnum.OKR,
        objId: id,
        belongUserId: +belongUserId,
      })
      .then(() => {
        setValue({
          isThumbUp: true,
          thumbUpCount: (value?.thumbUpCount || 0) + 1,
        });
        setShowAnimateUp(true);
        setTimeout(() => {
          setShowAnimateUp(false);
        }, 500);
      });
  };

  return (
    <span
      className={`m-okr-thumb ${value?.isThumbUp ? 'm-thumb' : 'm-not-thumb'}`}
      onClick={onClick}
    >
      <LikeFilled className="m-okr-thumb-icon" />
      <AnimateUp show={showAnimateUp}>{value?.thumbUpCount || 0}</AnimateUp>
    </span>
  );
};

export default memo(OkrThumb);
