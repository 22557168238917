import React, { memo, useState } from 'react';
import { Button, Modal, Row, Col, Popover, Tooltip } from 'antd';
import { useRequest } from 'ahooks';
import api, { IUserInfo } from '@/api/auth';
import {
  SettingOutlined,
  RocketOutlined,
  FileTextOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { SearchUser, Avatar } from '@/components';
import { dataTrack } from '@/utils/track';
import './index.less';

const SettingModal = () => {
  const [open, setOpen] = useState(false);

  const { data: leader } = useRequest(api.getLeader, { ready: open });
  const { data, refresh } = useRequest(api.getAuthSelf, { ready: open });

  const { OKR = [], WORK_REPORT = [] } = data || {};

  const openSetting = () => {
    setOpen(true);

    dataTrack('click_okr_share_center_setting');
  };

  const onCancel = () => {
    setOpen(false);
  };

  const delUser = async (id: number, type: string) => {
    await api.delAuthSelf(id, type);
    refresh();
  };

  const selectUser = async (user: any, type: string) => {
    if (data?.[type]?.some((item: any) => item.shareId === user.id)) return;
    await api.addAuthSelf(user.id, type);
    refresh();
  };

  // 展示/增加/删除共享人员
  const renderSettingItem = (sharedUser: IUserInfo[], type: string) => {
    return (
      <Row>
        <Col span={3} className="col u-col-center col-add ">
          <Popover
            placement="leftTop"
            content={
              <SearchUser
                style={{ width: '200px' }}
                selectUser={(id: number, option: any) => selectUser(option.props.user, type)}
              />
            }
            trigger="click"
          >
            <PlusCircleOutlined className="icon-add" />
          </Popover>
          <br />
          添加
        </Col>
        {!!leader && (
          <Col span={3} className="col u-col-center col-type ">
            <Tooltip placement="right" title={`${leader?.name}(${leader?.uNo})`}>
              <Avatar
                imgPath={leader?.photoUrl}
                userName={leader?.name}
                className="emp-avatar avatar-32"
              />
              <br />
              {leader?.name || '名字'}
            </Tooltip>
          </Col>
        )}
        {sharedUser?.map((user: any) => {
          const { id, name, uNo, photoUrl } = user;
          const toolTip = `删除：${name}(${uNo || ''})`;
          return (
            <Col key={id} span={3} className="col u-col-center col-type ">
              <Avatar imgPath={photoUrl} userName={name} className="emp-avatar-share avatar-32" />
              <Tooltip placement="right" title={toolTip}>
                <CloseCircleOutlined
                  onClick={() => delUser(user?.id, type)}
                  className="icon-head-del"
                />
              </Tooltip>

              <br />
              {name}
            </Col>
          );
        })}
      </Row>
    );
  };

  return (
    <>
      <div className="u-text-right share-setting-btn">
        <Button type="primary" className="c-dark-btn" onClick={openSetting}>
          <SettingOutlined title="共享设置" />
          共享设置
        </Button>
      </div>
      <Modal footer={null} title="共享设置" open={open} onCancel={onCancel}>
        <div className="m-share-setting">
          <div className="setting-modal-tip">
            注：在此设置的OKR数据共享将会共享该类型下的所有时间的所有数据，若只需要共享部分数据给某人，请在需要共享的数据下编辑其“共享范围”即可
          </div>
          <div className="title" style={{ marginTop: 20 }}>
            <RocketOutlined style={{ fontSize: 16, color: '#2ED0A2', margin: '0 10px' }} />
            将我的全部OKR数据共享给
          </div>
          {renderSettingItem(OKR, 'OKR')}
          <div className="title" style={{ marginTop: 32 }}>
            <FileTextOutlined style={{ fontSize: 16, color: '#FD986A', margin: '0 10px' }} />
            将我的全部周报数据共享给
          </div>
          {renderSettingItem(WORK_REPORT, 'WORK_REPORT')}
        </div>
      </Modal>
    </>
  );
};

export default memo(SettingModal);
