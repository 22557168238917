import React, { memo } from 'react';
import { useRequest } from 'ahooks';
import api from '@/api/okr';
import './index.less';

const QuickSelect = (props: any) => {
  const { value, onChange } = props;

  const { data } = useRequest(api.getQuickUserList);

  const onClick = (i: any) => {
    onChange?.(
      {
        id: i.id,
        name: i.name,
      },
      i,
    );
  };

  return (
    <div className="m-quick-select">
      <span className="m-quick-text">与我相关：</span>
      <span className="m-quick-select-content">
        {data?.map((i) => (
          <span
            className={value?.id === i.id ? 'm-quick-active' : ''}
            onClick={() => {
              onClick(i);
            }}
            key={i.id}
          >
            {i.name}
          </span>
        ))}
      </span>
    </div>
  );
};

export default memo(QuickSelect);
