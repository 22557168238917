/**
 * 从其他周期导入OKR
 */
import React, { useMemo, useState, memo } from 'react';
import { Spin, Checkbox, Empty, Tree, Modal } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ImportOutlined } from '@ant-design/icons';
import okrApi, { IOkrItem } from '@/api/okr';
import { useRequest } from 'ahooks';
import { CyclesCascader } from '@/components';
import emptyImg from '@/assets/img/empty.png';
import { dataTrack } from '@/utils/track';
import './index.less';
import { useModel } from '@@/plugin-model/useModel';

interface IProps {
  children: any;
  importDateId: number;
  refreshOKRList?: () => void;
}

const ImportOkr = (props: IProps) => {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { children, refreshOKRList, importDateId } = props;

  const [checked, setChecked] = useState('NONE'); // 选中状态 NONE-未选中 HALF-半选 ALL-全选
  const [checkedKeys, setCheckedKeys] = useState<number[]>([]); // 选中的O
  const [cyclesValue, setCyclesValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const onClickOpen = () => {
    dataTrack('click_okr_import');
    beforeShow();
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  // 查询OKR列表
  const {
    loading,
    run,
    data: okrList,
    mutate,
  } = useRequest(okrApi.getPersonalOkrList, {
    ready: open,
    manual: true,
    onSuccess: () => {
      setCheckedKeys([]);
      setChecked('NONE');
    },
  });

  const allKeys = useMemo(() => okrList?.map((item) => item.id) || [], [okrList]);

  const onChange = (value: any) => {
    setCyclesValue(value);
    run({ year: value[0], dateId: value?.[1], customize: true, userId: userInfo?.id });
  };

  // 渲染OKR树
  const getTreeData = (data: IOkrItem[]) =>
    data?.map((item, index) => {
      return {
        ...item,
        key: item.id,
        title: (
          <div className="tree-node-o">
            O{index + 1}：{item.objectivesName}
          </div>
        ),
        children: item.krList?.map((kr, krIndex) => {
          const { keyResultName } = kr;
          return {
            ...kr,
            key: kr.id,
            checkable: false,
            disableCheckbox: true,
            title: (
              <div className="tree-node-kr">
                KR{krIndex + 1}：{keyResultName}
              </div>
            ),
          };
        }),
      };
    });

  // 全选
  const onCheckAll = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked ? 'ALL' : 'NONE');
    setCheckedKeys(e.target.checked ? allKeys : []);
  };

  // 选中树节点
  const onCheckNode = (checkedKeys: any) => {
    setChecked(
      checkedKeys.length === allKeys.length ? 'ALL' : checkedKeys.length === 0 ? 'NONE' : 'HALF',
    );
    setCheckedKeys(checkedKeys);
  };

  // 保存
  const onSubmit = () => {
    setBtnLoading(true);
    okrApi
      .postImportOkr({
        idList: checkedKeys,
        dateId: importDateId,
      })
      .then(() => {
        refreshOKRList?.();
        onCancel();
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const beforeShow = () => {
    setCheckedKeys([]);
    setChecked('NONE');
    setCyclesValue([]);
    mutate([]);
  };

  return (
    <span>
      <span onClick={onClickOpen}>
        {children || (
          <a>
            <ImportOutlined /> 从其他周期导入
          </a>
        )}
      </span>
      <Modal
        open={open}
        maskClosable={false}
        destroyOnClose
        className="m-import-okr"
        width={800}
        title="从其他周期导入"
        onOk={onSubmit}
        onCancel={onCancel}
        okButtonProps={{
          loading: btnLoading,
          disabled: !checkedKeys.length,
        }}
      >
        <Spin spinning={loading}>
          <div className="head">
            <Checkbox
              disabled={!okrList?.length}
              checked={checked === 'ALL'}
              indeterminate={checked === 'HALF'}
              onChange={onCheckAll}
            >
              全选
            </Checkbox>
            <div>
              <CyclesCascader onChange={onChange} />
            </div>
          </div>
          <div className="content">
            {okrList?.length ? (
              <Tree
                checkable
                selectable={false}
                checkedKeys={checkedKeys}
                onCheck={onCheckNode}
                treeData={getTreeData(okrList)}
              />
            ) : (
              <Empty
                image={emptyImg}
                description={cyclesValue?.length ? '该周期下暂无OKR' : '请选择周期'}
              />
            )}
          </div>
        </Spin>
      </Modal>
    </span>
  );
};

export default memo(ImportOkr);
