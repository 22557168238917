import React from 'react';
import { Link } from 'umi';
import { Badge, App } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { IMessageItem, MessageTargetTypeEnum } from '@/api/message';
import { Avatar } from '@/components';
import { REPLY_TYPE, OKR_TYPE } from '@/constants/message';
import { changeDateDesc } from '@/utils/date';
import { transferComment } from '@/utils/tools';
import './index.less';

const MAX_DISPLAY_COUNT = 3;

interface IProps {
  onReadMessage: (id?: number) => void;
  messageList?: IMessageItem[];
  messageCount: number;
}

export function getLink(info: IMessageItem) {
  const { type, targetType, processSourceType, targetId, targetUserVO } = info || {};
  const { id: targetUserId } = targetUserVO || {};
  const AtTypes = [12, 13, 14];
  const ProcessTargets = ['PROCESS', 'PROCESS_OTHER'];
  let link = '';
  if (!targetId) return;
  if (targetType === MessageTargetTypeEnum.SUMMARY_FEEDBACK) return '';
  if (AtTypes.includes(type) || ProcessTargets.includes(targetType)) {
    // 跳转OKR进展详情页
    link = `/process/dashboard?tab=detail&type=${processSourceType}&id=${targetId}`;
  } else {
    link = `/discover/${targetId}?type=${targetType}&userId=${targetUserId}`;
  }
  return link;
}

function MessageItem(props: { info: IMessageItem }) {
  const {
    type,
    targetType,
    titleName,
    updateTime,
    sysTime,
    mainContent,
    content,
    userVO,
    targetUserVO,
    receiverVO,
  } = props.info || {};

  const { name: userName, id: userId } = userVO || {};
  const { name: targetUserName, id: targetUserId } = targetUserVO || {};
  const { id: receiverId } = receiverVO || {};

  //@ts-ignore
  const targetStr = OKR_TYPE[targetType] || '';
  //@ts-ignore
  const actionStr = REPLY_TYPE[type] || '';
  const getMessageTitle = () => {
    // @
    if ([3, 4, 8, 12, 13, 14].includes(type)) {
      return `在${targetStr}【${titleName}】中${actionStr}了你`;
    }
    // 回复评论
    if (type === 2) {
      return `回复了你的评论【${mainContent ? transferComment(mainContent) : '--'}】`;
    }
    // 指定OKR负责人
    if (titleName.includes('负责人')) {
      return `指定你为【${content}】负责人`;
    }
    if (type === 9) {
      return content;
    }
    return `${actionStr}了${
      targetUserId === receiverId ? '你' : targetUserName
    }的${targetStr}【${titleName}】`;
  };

  return (
    <div className="flex items-center m-message-item-container">
      <div className="m-message-card-left">
        <Link
          to={{ pathname: `/team/${userId}`, search: '?tab=okr' }}
          className="message-item-link"
          onClick={(e) => e.stopPropagation()}
        >
          <span>{userName}</span>
          <div className="message-item-dot" />
        </Link>
        <div className="message-item-title">
          <span dangerouslySetInnerHTML={{ __html: getMessageTitle() }} />
        </div>
        <div className="message-item-time">{changeDateDesc(updateTime, sysTime)}</div>
      </div>
      <RightOutlined className="message-item-icon-to" />
    </div>
  );
}

function MessagePopover(props: IProps) {
  const { messageList, onReadMessage, messageCount } = props;
  let list = messageList ? [...messageList] : [];

  const { modal } = App.useApp();

  const onClickAllRead = () => {
    modal.confirm({
      title: '确定将全部新消息设置为已读吗？',
      onOk: () => {
        onReadMessage();
      },
    });
  };

  if (list?.length > MAX_DISPLAY_COUNT) {
    list = list.slice(0, MAX_DISPLAY_COUNT);
  }
  return (
    <div className="m-message-popover">
      <div className="m-message-popover-title">
        <Badge count={messageCount} overflowCount={99} offset={[5, 0]}>
          消息中心
        </Badge>
        <span className="m-message-popover-title-right" onClick={onClickAllRead}>
          一键已读
        </span>
      </div>
      <div className="m-message-popover-list">
        {list?.map?.((info) => {
          const { id, userVO } = info || {};
          const { name, id: userId, photoUrl } = userVO || {};
          const link = getLink(info);
          return (
            <div className="message-popover-item" key={`message-${id}`}>
              <Link to={{ pathname: `/team/${userId}`, search: '?tab=okr' }}>
                <Avatar imgPath={photoUrl} userName={name} className="message-item-left" />
              </Link>
              <div className="message-item-right">
                {link ? (
                  <Link
                    to={link}
                    className="m-link-block"
                    onClick={() => {
                      onReadMessage?.(id);
                    }}
                  >
                    <MessageItem info={info} />
                  </Link>
                ) : (
                  <span>
                    <MessageItem info={info} />
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <Link to="/message">
        <div className="message-more">
          查看所有消息 <RightOutlined />
        </div>
      </Link>
    </div>
  );
}

export default MessagePopover;
