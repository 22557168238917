import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import storage from '@/utils/storage';
import './index.less';

const STORAGE_GUIDE_KEY = 'OKR_ADD_ALIGN';

interface IProps {
  isFirst?: boolean;
  children: React.ReactElement;
}

const AlignGuide = ({ children, isFirst }: IProps) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!storage.get(STORAGE_GUIDE_KEY) && storage.get('guide') === 1 && isFirst) {
      setVisible(true);
    }
  }, []);

  const onClickBtn = () => {
    setVisible(false);
    storage.set(STORAGE_GUIDE_KEY, true);
  };

  return (
    <Popover
      overlayClassName="m-add-alignment-guide"
      open={visible}
      placement="bottom"
      overlayStyle={{
        zIndex: 90,
      }}
      // @ts-ignore
      getPopupContainer={() => document.getElementsByClassName('g-main')?.[0] || document.body}
      content={
        <div>
          <div>你可以在这里添加对齐关系</div>
          <div className="m-add-alignment-btn" onClick={onClickBtn}>
            我知道了
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export default AlignGuide;
