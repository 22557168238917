/**
 * 获取默认年份和周期
 * 优先级 1：url参数 2. 用户操作localstorage本地存储
 */
import { getParams } from '@/utils/tools';

const CURRENT_YEAR = new Date().getFullYear();

const useGetDefaultPeriod = () => {
  const { year: urlYear, periodId: urlPeriodId }: any = getParams();
  return {
    defaultYear: urlYear || CURRENT_YEAR,
    defaultPeriodId: urlPeriodId,
  };
};

export default useGetDefaultPeriod;
