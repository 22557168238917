import React, { memo, useState } from 'react';
import { useRequest } from 'ahooks';
import api, { OkrPeriodEnum, PeriodTypeEnum } from '@/api/okr';
import useGetDefaultPeriod from '@/components/okr/okr-cycles/useGetDefaultPeriod';
import { OkrCycles } from '@/components';
import PersonalOkrList from '@/pages/okr/personal/components/personal-okr-list';
import './index.less';

interface IProps {
  id: number;
}

const PersonalOkr = (props: IProps) => {
  const { id } = props;

  const { defaultYear, defaultPeriodId } = useGetDefaultPeriod();
  const [periodId, setPeriodId] = useState(defaultPeriodId);
  const [year, setYear] = useState(defaultYear);

  // 周期O个数
  const { data: count, run: runCount } = useRequest(
    () =>
      api.getPersonalOkrCount({
        userId: id,
        year,
      }),
    {
      refreshDeps: [year, id, periodId],
      debounceWait: 500,
    },
  );

  // 个人周期列表
  const { data: period } = useRequest(
    () =>
      api.getPeriodUser({
        userId: id,
        periodType: PeriodTypeEnum.okr,
        year,
        needCustomize: true,
      }),
    {
      ready: !!id,
      refreshDeps: [year, id],
    },
  );

  const {
    data: dataList,
    refreshAsync: refreshOKRList,
    loading,
    mutate,
  } = useRequest(
    () =>
      api.getPersonalOkrList({
        userId: id,
        dateId: periodId,
        year,
        customize: true,
      }),
    {
      ready: !!id && !!periodId,
      refreshDeps: [id, periodId],
      debounceWait: 500,
    },
  );

  // 当前选择周期
  const periodInfo = period?.find((i) => i.id === periodId);
  // 选择自定义周期
  const isCustomPeriod = periodInfo?.type === OkrPeriodEnum.custom;

  return (
    <div className="m-personal-center-okr">
      <OkrCycles
        year={year}
        periodId={periodId}
        onYearChange={setYear}
        onPeriodChange={setPeriodId}
        statisticsList={count}
        periodList={period}
      />
      <PersonalOkrList
        loading={loading}
        dataList={dataList}
        draggable={false}
        onRefresh={refreshOKRList}
        mutateList={mutate}
        isCustomPeriod={isCustomPeriod}
      />
    </div>
  );
};

export default memo(PersonalOkr);
