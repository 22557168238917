import React, { memo } from 'react';
import { Popover } from 'antd';
import { IObjectivesKrProcess } from '@/api/discover';
import { KrProcessStatusEnum, OkrProcessTypeEnum } from '@/api/okr';
import { O_KR_ENUM, SCORE_OPTION, STATUS_OPTION } from '@/constants';
import KRSummaryLabel, { STATUS_SCORE_CODE_MAP } from './label';
import './index.less';

const initColor = (num: any, list: any) => {
  let newNum = num;
  // num有值时
  if (num >= 0) {
    // 总结 整数（0 - 10）；
    // 小结 整数（101，102，103）；
    newNum = Math.floor(num);
    return (list.find((item: any) => `${item.key}` === `${newNum}`) || {}).color;
  }
  return null;
};

const excludeEmpty = (value: any) => {
  // null,  undefined， 会被模板字符串转成 字符；'null'可能是外部传入的
  const emptyValues = [null, 'null', undefined, ''];
  if (emptyValues.indexOf(value) >= 0) {
    // + (''|null|false|0) 都是0 ；
    return '';
  }
  return `${value}`;
};

function getPopoverByScore(score: number) {
  const scoreOption = SCORE_OPTION.find((option) => score >= option.key);
  return scoreOption ? scoreOption.popover : '';
}

interface IProps {
  data:
    | IObjectivesKrProcess
    | { status: KrProcessStatusEnum; processType: OkrProcessTypeEnum; score: number };
  style?: React.CSSProperties;
  oOrKrType?: O_KR_ENUM;
}

const KrScoreOrStatus = (props: IProps) => {
  const { style = {}, data, oOrKrType } = props;
  const { status, processType, score } = data || {};

  // @ts-ignore
  const isStatus = STATUS_SCORE_CODE_MAP[processType] === 1;
  const list = isStatus ? STATUS_OPTION : SCORE_OPTION;
  const value = isStatus ? excludeEmpty(status) : excludeEmpty(score);
  const textColor = initColor(value, list);
  const getDisplayText = () => {
    if (!status && !score) return isStatus ? '暂无状态' : '暂无评分';
    if (isStatus) return list.find((item) => `${item.key}` === `${value}`)?.label;
    return value;
  };

  // 状态的popover
  let popoverValue = list.find((item) => item.key === +value)?.popover;
  if (!isStatus) {
    // 评分的popover
    popoverValue = [null, undefined, ''].includes(value) ? '' : getPopoverByScore(+value);
  }

  const renderValue = () => (
    <span className="text tag" style={value ? { color: textColor } : {}}>
      ● {getDisplayText()}
    </span>
  );

  return (
    <div style={style} className="kr-score-or-status">
      <KRSummaryLabel type={processType as OkrProcessTypeEnum} oOrKrType={oOrKrType} />
      {popoverValue ? (
        <Popover content={popoverValue} placement="left" arrow>
          {renderValue()}
        </Popover>
      ) : (
        renderValue()
      )}
    </div>
  );
};

export default memo(KrScoreOrStatus);
