import { history } from 'umi';
import React, { useRef, useState, useEffect } from 'react';
import { Alert } from 'antd';
import { getParams } from '@/utils/tools';
import { IconFont } from '@/components';
import { ReportDefeatFilterMaps, ReportMaps } from '@/constants';
import api from '@/api/setting';
import Table from '@/pages/setting/report/detail/table';
import { Search } from './components';
import styles from './index.less';

const ReportDetail = () => {
  const params: any = getParams();

  const { title, id } = params || {};
  //@ts-ignore
  const reportType = ReportMaps[id]; // OKR报表类型

  const searchParams = useRef({});
  const tableParams = useRef({
    currentPage: 1,
    pageSize: 10,
    totalResults: 0,
  });
  const subRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([] as any[]);

  const getTableData = async () => {
    try {
      setLoading(true);
      const data = await api.getReportData({
        exportQueryDTO: {
          ...searchParams.current,
          ...tableParams.current,
        },
        id: +id,
      });
      const { dataList, page } = data || {};
      setDataSource(dataList || []);
      tableParams.current = { ...tableParams.current, totalResults: page?.totalCount || 0 };
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // @ts-ignore
    searchParams.current = subRef.current?.getSearchParams();
    getTableData();
  }, []);

  const onSearch = (values: any) => {
    searchParams.current = { ...searchParams.current, ...values };
    tableParams.current = { ...tableParams.current, currentPage: 1 };
    getTableData();
  };

  const onReset = () => {
    //@ts-ignore
    searchParams.current = ReportDefeatFilterMaps[id];
    tableParams.current = { ...tableParams.current, currentPage: 1 };
    getTableData();
  };

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    tableParams.current = {
      ...tableParams.current,
      ...pagination,
      ...filters,
      ...sorter,
      currentPage:
        tableParams.current.currentPage === pagination.currentPage ? 1 : pagination.currentPage, // 除了切换分页页码，否则回到第一页
    };
    getTableData();
  };

  const pagination = {
    showSizeChanger: true,
    current: tableParams.current.currentPage,
    pageSize: tableParams.current.pageSize,
    total: tableParams.current.totalResults,
  };

  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <span className={styles.back} onClick={history.goBack}>
          <IconFont type="icon-jiantou1-copy-copy-copy" className={styles.backIcon} />
          back
        </span>
        <Alert message="数据每日凌晨更新，可能存在延迟。" type="info" banner className="mt-2" />
        <div className={styles.title}>{title}</div>
      </div>
      <Search
        fileName={title}
        type={reportType}
        onSearch={onSearch}
        onReset={onReset}
        params={{
          exportQueryDTO: searchParams.current,
          id: +id,
        }}
        ref={subRef}
      />
      <Table
        type={reportType}
        dataSource={dataSource}
        loading={loading}
        pagination={pagination}
        onChange={onTableChange}
      />
    </div>
  );
};

export default ReportDetail;
