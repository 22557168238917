import React, { memo } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import './okr-period-guide.less';

function OkrPeriodGuide(props: { onClose: () => void }) {
  const { onClose } = props;
  return (
    <div className="pl_okr-period-guide">
      <div className="pl_okr-period-guide-arrow" />
      <CloseOutlined className="pl_okr-period-guide-icon" onClick={onClose} />
      <div className="pl_okr-period-guide-content">选择有OKR的周期</div>
      <div className="pl_okr-period-guide-btn" onClick={onClose}>
        我知道了
      </div>
    </div>
  );
}

export default memo(OkrPeriodGuide);
