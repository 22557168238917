/**
 * 组织OKR部门树
 * @param {string} year 查询的年份
 * @param {(type: REAL_DEPT | VIR_DEPT, dataRef) => void} onChange REAL_DEPT: 我的部门  VIR_DEPT: 虚拟组织；dataRef有值时表示选中树状节点，否则为选中顶部按钮
 */
import React, { useMemo, useState, memo } from 'react';
import classNames from 'classnames';
import { Spin, Tree } from 'antd';
import { useRequest, useToggle } from 'ahooks';
import { DeptTypeEnum } from '@/api/okr';
import commonApi, { IDeptTreeItem } from '@/api/common';
import { IconFont } from '@/components';
import './index.less';

const { TreeNode } = Tree;

const TAB_LIST = [
  { key: DeptTypeEnum.REAL_DEPT, name: '我的部门' },
  { key: DeptTypeEnum.VIR_DEPT, name: '虚拟组织' },
];

interface IProps {
  year: number;
  hasVirDept?: boolean;
  onChange: (type: DeptTypeEnum, selectedData?: any | IDeptTreeItem) => void;
  defaultDept?: any;
}

const DeptTreePanel = (props: IProps) => {
  const { defaultDept, hasVirDept = true, onChange } = props;

  const [tab, setTab] = useState(DeptTypeEnum.REAL_DEPT);
  const [selectedDept, setSelectedDept] = useState<IDeptTreeItem | undefined>(defaultDept); // 选中的部门节点
  const [selectedVirDept, setSelectedVirDept] = useState<IDeptTreeItem | undefined>(); // 选中的虚拟组织节点

  const [deptType, setDeptType] = useState<DeptTypeEnum | undefined>(DeptTypeEnum.REAL_DEPT); // REAL_DEPT：真实部门 VIR_DEPT：虚拟部门
  const [deptTree, setDeptTree] = useState<IDeptTreeItem[]>([]); // 部门树
  const [virDeptTree, setVirDeptTree] = useState<IDeptTreeItem[]>([]); // 虚拟组织树

  const [state, { toggle }] = useToggle(false);

  // 查询部门列表
  const { loading } = useRequest(commonApi.getDeptTree, {
    ready: !!selectedDept || deptType === DeptTypeEnum.REAL_DEPT,
    onSuccess: (res) => {
      setDeptTree(res);
      onChange?.(DeptTypeEnum.REAL_DEPT); // 默认根据“我所在的部门”查询数据
      setDeptType(DeptTypeEnum.REAL_DEPT);
      setSelectedDept(undefined);
    },
  });

  // 查询虚拟组织列表，立即请求，需要动态判断是否有虚拟组织
  const { loading: loadingVir } = useRequest(commonApi.getVirDeptTree, {
    onSuccess: (res) => {
      setVirDeptTree(res);
      setSelectedVirDept(undefined);
    },
  });

  const tabList = useMemo(() => {
    if (!hasVirDept || !virDeptTree?.length)
      return TAB_LIST.filter((item) => item.key !== DeptTypeEnum.VIR_DEPT);
    return TAB_LIST;
  }, [hasVirDept, virDeptTree]);

  // 异步加载子集数据
  const onLoadData = (treeNode: any) =>
    new Promise<void>((resolve) => {
      if (treeNode.props.children) {
        resolve();
        return;
      }

      commonApi.getDeptTree(treeNode.props.eventKey).then((res) => {
        treeNode.props.dataRef.children = res;
        setDeptTree([...deptTree]);
        resolve();
      });
    });

  const onLoadVirData = (treeNode: any) =>
    new Promise<void>((resolve) => {
      if (treeNode.props.children) {
        resolve();
        return;
      }

      commonApi.getVirDeptTree(treeNode.props.eventKey).then((res) => {
        treeNode.props.dataRef.children = res;
        setVirDeptTree([...virDeptTree]);
        resolve();
      });
    });

  // 渲染真实部门子节点
  const renderTreeNodes = (data: IDeptTreeItem[]) =>
    data?.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            key={item.deptId}
            title={item.deptName}
            isLeaf={!item.hasChildren}
            //@ts-ignore
            dataRef={item}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.deptId}
          title={item.deptName}
          isLeaf={!item.hasChildren}
          //@ts-ignore
          dataRef={item}
        />
      );
    });

  // 渲染虚拟组织子节点
  const renderVirtualTreeNodes = (data: IDeptTreeItem[]) =>
    data?.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            key={item.id}
            title={item.deptName}
            isLeaf={!item.hasChildren}
            //@ts-ignore
            dataRef={item}
          >
            {renderVirtualTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.id}
          title={item.deptName}
          isLeaf={!item.hasChildren}
          //@ts-ignore
          dataRef={item}
        />
      );
    });

  // 渲染顶部操作按钮
  const renderBtn = (type: DeptTypeEnum, title: string) => (
    <div
      className={classNames('btn', { 'btn-active': deptType === type })}
      onClick={() => onClick(type)}
    >
      {title}
    </div>
  );

  // 切换TAB
  const onChangeTab = (key: DeptTypeEnum) => {
    setTab(key);

    // 当前TAB下没有选中的节点则默认选中“我所在的部门/虚拟组织”
    key === DeptTypeEnum.REAL_DEPT && !selectedDept && setDeptType(DeptTypeEnum.REAL_DEPT);
    key === DeptTypeEnum.VIR_DEPT && !selectedVirDept && setDeptType(DeptTypeEnum.VIR_DEPT);

    // 触发onChange函数，业务层可拉新数据
    onChange?.(key, key === DeptTypeEnum.REAL_DEPT ? selectedDept : selectedVirDept);
  };

  // 选择树节点
  const onSelectNode = (type: DeptTypeEnum, dataRef: any) => {
    type === DeptTypeEnum.REAL_DEPT && setSelectedDept(dataRef);
    type === DeptTypeEnum.VIR_DEPT && setSelectedVirDept(dataRef);
    onChange?.(type, dataRef);
    setDeptType(undefined); // 清除顶部按钮的选中状态
  };

  // 点击 我所在的部门/我所在的虚拟组织
  const onClick = (type: DeptTypeEnum) => {
    if (deptType === type) return;
    // 清除树节点选中状态
    type === DeptTypeEnum.REAL_DEPT && setSelectedDept(undefined);
    type === DeptTypeEnum.VIR_DEPT && setSelectedVirDept(undefined);
    onChange?.(type);
    setDeptType(type);
  };

  return (
    <Spin spinning={loading || loadingVir}>
      <div className="m-department-tree-wrap" style={{ marginLeft: state ? '-208px' : '0' }}>
        <div className="m-department-tree">
          <div className="m-tab">
            {tabList.map((item) => (
              <span
                key={item.key}
                onClick={() => onChangeTab(item.key)}
                className={classNames('tab-item', { 'tab-item-active': item.key === tab })}
              >
                {item.name}
              </span>
            ))}
          </div>

          {/* 我的部门 */}
          <section style={{ display: tab === DeptTypeEnum.REAL_DEPT ? 'block' : 'none' }}>
            {renderBtn(tab, '我所在的部门')}
            <div className="title">全部部门</div>
            {!loading && (
              <div className="m-tree-scroll">
                <Tree
                  onSelect={(key, e) => onSelectNode(tab, e.node.props.dataRef)}
                  loadData={onLoadData}
                  selectedKeys={selectedDept?.deptId ? [selectedDept.deptId] : []}
                >
                  {renderTreeNodes(deptTree)}
                </Tree>
              </div>
            )}
          </section>
          {/* 虚拟组织 */}
          <section style={{ display: tab === DeptTypeEnum.VIR_DEPT ? 'block' : 'none' }}>
            {renderBtn(tab, '我所在的虚拟组织')}
            <div className="title">全部虚拟组织</div>
            {!loadingVir && (
              <div className="m-tree-scroll">
                <Tree
                  selectedKeys={selectedVirDept?.id ? [selectedVirDept?.id] : []}
                  loadData={onLoadVirData}
                  onSelect={(key, e) => {
                    //@ts-ignore
                    return onSelectNode(tab, e.node.props?.dataRef);
                  }}
                >
                  {renderVirtualTreeNodes(virDeptTree)}
                </Tree>
              </div>
            )}
          </section>
          <IconFont
            className="m-member-select-trigger"
            type={state ? 'icon-shouqi' : 'icon-moren'}
            onClick={toggle}
          />
        </div>
      </div>
      <div
        className="m-department-tree-placeholder"
        style={{ marginLeft: state ? '-208px' : '0' }}
      />
    </Spin>
  );
};

export default memo(DeptTreePanel);
