import React, { memo } from 'react';
import { Typography } from 'antd';
import { IKrProcessItem, IOProcessItem } from '@/api/okr';
import { ReadonlyRichText, KrScoreOrStatus } from '@/components';
import { generateUUID } from '@/utils/tools';
import { changeDateDesc } from '@/utils/date';
import { O_KR_ENUM } from '@/constants';
import './index.less';

interface IProps {
  list: IOProcessItem[] | IKrProcessItem[];
  type?: O_KR_ENUM;
}

const KrProcessList = (props: IProps) => {
  const { list, type = O_KR_ENUM.KR } = props;
  return (
    <>
      {list?.map((krProcess) => {
        const { title, content, processType, score, status, user, modifyTime } = krProcess;
        return (
          <div key={generateUUID()} className="weekly">
            <div className="weekly-title">
              <div className="m-process-title">
                <Typography.Text ellipsis={{ tooltip: true }}>{title}</Typography.Text>
              </div>
              {user && (
                <div className="weekly-last-operator">
                  {user?.name} ｜ {changeDateDesc(new Date(modifyTime), Date.now())}
                </div>
              )}
            </div>
            <div className="m-process-content">
              {/* 下列数据目前只有通过以下途径生成：1.对O的直接修改状态，2.对KR的直接修改状态和评分，3.添加进展周报，4.添加进展总结 */}
              {/* 只有周报不展示状态/评分这栏，其他都需要展示，周报数据不返回processType */}
              {/* 然后根据processType来判断展示状态还是评分 */}
              {!!processType && (
                <KrScoreOrStatus data={{ processType, score, status }} oOrKrType={type} />
              )}
              <ReadonlyRichText content={content} updateTime={modifyTime} />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default memo(KrProcessList);
