import React, { useState } from 'react';
import { useRequest, useAntdTable } from 'ahooks';
import { Form, Row, Col, Input, Button, Table, Popconfirm, TreeSelect, Modal, App } from 'antd';
import type { TableColumnsType } from 'antd';
import { IconFont } from '@/components';
import operationApi, { IOutsourcingItem } from '@/api/operation';
import { IDeptItem } from '@/api/auth';
import { formatDate } from '@/utils/date';
import './index.less';

const { TreeNode } = TreeSelect;

const OutsourcingManage = () => {
  const { message } = App.useApp();

  const [editingRow, setEditingRow] = useState<IOutsourcingItem>();
  const [deptTree, setDeptTree] = useState<IDeptItem[]>([]);
  const [open, setOpen] = useState(false);

  const [form] = Form.useForm();
  const [addForm] = Form.useForm();
  const [tableForm] = Form.useForm();

  useRequest(operationApi.getDeptTree, {
    onSuccess: (data) => {
      setDeptTree(data);
    },
  });
  const { tableProps, search, refresh } = useAntdTable(
    async ({ current, pageSize }, formData) => {
      const data = await operationApi.getOutsourcingList({
        currentPage: current,
        pageSize,
        ...formData,
      });
      return {
        list: data?.dataList,
        total: data?.page?.totalCount,
      };
    },
    {
      form,
      defaultPageSize: 50,
    },
  );

  const isEditing = (record: IOutsourcingItem) => record.id === editingRow?.id;

  const renderTreeNodes = (data: IDeptItem[]) => {
    return data?.map((item) => {
      if (item.childList) {
        return (
          <TreeNode
            key={item.deptId}
            title={item.deptName}
            fullPath={item.deptPath}
            isLeaf={!item.childList}
            dataRef={item}
            value={item.deptId}
          >
            {renderTreeNodes(item.childList)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.deptId}
          title={item.deptName}
          fullPath={item.deptPath}
          isLeaf={!item.hasChildren}
          dataRef={item}
          value={item.deptId}
        />
      );
    });
  };

  const onSave = async (values: any) => {
    await operationApi.updateOutsourcing({
      ...values,
      deptId: values?.deptId?.value,
      id: editingRow?.id,
    });
    message.success('修改成功！');
    refresh();
    cancelEdit();
  };

  const onFinishAdd = async (values: any) => {
    await operationApi.addOutsourcing(values);
    message.success('添加成功！');
    refresh();
    onCancel();
  };

  const cancelEdit = () => {
    setEditingRow(undefined);
  };

  const onClickEdit = (record: IOutsourcingItem) => {
    setEditingRow(record);
    tableForm.setFieldsValue({
      deptId: {
        label: record.deptFullPathName,
        value: record.deptId,
      },
    });
  };

  const onDelete = async (id: number) => {
    await operationApi.deleteOutsourcing(id);
    message.success('删除成功！');
    refresh();
  };

  const columns: TableColumnsType<IOutsourcingItem> = [
    {
      dataIndex: 'name',
      title: '姓名',
      width: 150,
    },
    {
      dataIndex: 'jobNumber',
      title: '工号',
      width: 100,
    },
    {
      dataIndex: 'deptId',
      title: '所属部门',
      render: (values, record) => {
        const editable = isEditing(record);
        if (editable) {
          return (
            <div className="m-edit-ceil">
              <Form.Item
                noStyle
                required
                name="deptId"
                rules={[{ required: true, message: '请输入' }]}
              >
                <TreeSelect
                  labelInValue
                  loadData={loadData}
                  treeNodeLabelProp="fullPath"
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  className="m-edit-form-control"
                  placeholder="请选择"
                >
                  {renderTreeNodes(deptTree)}
                </TreeSelect>
              </Form.Item>
              <Popconfirm title="确定取消？" onConfirm={cancelEdit}>
                <a onClick={cancelEdit} style={{ marginLeft: 8 }}>
                  取消
                </a>
              </Popconfirm>
              <a onClick={tableForm.submit} style={{ marginLeft: 8 }}>
                保存
              </a>
            </div>
          );
        }
        return (
          <span>
            {record?.deptFullPathName}{' '}
            <IconFont
              type="icon-bianji1"
              className="m-edit-icon"
              onClick={() => onClickEdit(record)}
            />
          </span>
        );
      },
    },
    {
      dataIndex: 'updateTime',
      title: '最近更新时间',
      width: 200,
      render: (val) => formatDate(new Date(val)),
    },
    {
      dataIndex: 'id',
      title: '操作',
      width: 150,
      render: (value) => {
        return (
          <Popconfirm title="确认删除该人员？" onConfirm={() => onDelete(value)}>
            <a className="f-delete">删除</a>
          </Popconfirm>
        );
      },
    },
  ];

  const onClickAdd = () => {
    setOpen(true);
  };

  const onCancel = () => {
    addForm.resetFields();
    setOpen(false);
  };

  const loadData = async (treeNode: any) => {
    if (treeNode.props.childList) return;
    const data = await operationApi.getDeptTree(treeNode.props.dataRef.id);
    treeNode.props.dataRef.childList = data;
    setDeptTree([...deptTree]);
  };

  return (
    <div className="p-outsourcing">
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} className="m-search-form">
        <Row>
          <Col span={8}>
            <Form.Item label="所属部门" name="deptId">
              <TreeSelect
                loadData={loadData}
                style={{ width: '100%' }}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                allowClear
                placeholder="请选择"
              >
                {renderTreeNodes(deptTree)}
              </TreeSelect>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="姓名/工号" name="key">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button onClick={search.reset}>重置</Button>
            <Button type="primary" style={{ marginLeft: 8 }} onClick={search.submit}>
              搜索
            </Button>
          </Col>
        </Row>
      </Form>
      <Form form={tableForm} onFinish={onSave} className="m-outsourcing-table">
        <div className="m-btn-add">
          <Button onClick={onClickAdd} type="primary">
            添加
          </Button>
        </div>
        <Table rowKey="id" columns={columns} {...tableProps} />
      </Form>
      <Modal
        visible={open}
        title="添加"
        onCancel={onCancel}
        maskClosable={false}
        destroyOnClose
        onOk={addForm.submit}
        className="m-manager-modal"
      >
        <Form
          form={addForm}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinishAdd}
        >
          <Form.Item
            label="工号"
            required
            name="userIdString"
            rules={[{ required: true, message: '请输入' }]}
          >
            <Input placeholder="工号用英文”,”间隔可批量导入" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default OutsourcingManage;
