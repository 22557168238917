import { useAntdTable as useOriginAntdTable } from 'ahooks';
import { AntdTableOptions, Data, Params, AntdTableResult } from 'ahooks/es/useAntdTable/types';
import { ResponseListPromise, ResponsePagePromise } from '@/api/interface';

export const PageSizeOptions = ['10', '30', '50', '100'];

export const DEFAULT_PAGE_SIZE = 10;

export declare type Service<TData extends Data, TParams extends Params> = (
  ...args: TParams
) => ResponseListPromise<any> | ResponsePagePromise<any>;

const useAntdTable = <TData extends Data, TParams extends Params>(
  service: Service<TData, TParams>,
  options: AntdTableOptions<TData, TParams> & { useNewPagination?: boolean } = {},
) => {
  const { defaultPageSize = DEFAULT_PAGE_SIZE, useNewPagination, ...rest } = options;
  const result = useOriginAntdTable(
    async (...args: TParams) => {
      const res: any = await service?.(...args);
      return {
        ...(res || {}),
        total: useNewPagination ? res?.page?.totalCount : res?.totalResults,
        current: useNewPagination ? res?.page?.currentPage : res?.currentPage,
        list: useNewPagination ? res?.dataList : res?.result,
      };
      // return res;
    },
    {
      ...rest,
      defaultPageSize,
    },
  );

  const { pagination: originPagination, tableProps, ...restResult } = result;

  const pagination = originPagination && {
    ...originPagination,
    pageSizeOptions: PageSizeOptions,
    showSizeChanger: originPagination.total > DEFAULT_PAGE_SIZE,
  };

  return {
    pagination,
    tableProps: {
      ...tableProps,
      pagination: {
        pageSizeOptions: PageSizeOptions,
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
        totalPage: pagination.totalPage,
        showSizeChanger: originPagination.total > DEFAULT_PAGE_SIZE,
        showTotal: (total: number) => `共 ${total} 条数据`,
      },
    },
    ...restResult,
  } as AntdTableResult<TData, TParams>;
};

export default useAntdTable;
