import React, { memo, useState } from 'react';
import cls from 'classnames';
import { useRequest } from 'ahooks';
import api from '@/api/okr';
import './index.less';

interface IProps {
  onClickItem: (item: any, index: number) => void;
  userId: number;
  currentSelectedId: any;
}

const POSTFIX = '的OKR';

const QuickView = (props: IProps) => {
  const { onClickItem, userId, currentSelectedId } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { data } = useRequest(api.getOkrMapQuickViewList);

  const onClickQuickLabel = (item: any, index: number) => {
    setSelectedIndex(index);
    onClickItem?.(item, index);
  };
  const displayData = data?.length
    ? data.map((item: any) => ({
        ...item,
        name: item.id === `${userId}` ? '我' : item.name,
        namePostfix: POSTFIX,
      }))
    : [];

  return (
    <div className="filter-quick">
      <span className="filter-name">快捷查看：</span>
      {!!displayData?.length && (
        <div className="filter-quick-labels">
          {displayData.map((item: any, index: number) => {
            const { id, name, namePostfix } = item;
            const displayName = namePostfix ? `${name}${namePostfix}` : name;
            return (
              <div
                className={cls('filter-item', {
                  'filter-item-selected': currentSelectedId
                    ? `${currentSelectedId}` === id
                    : selectedIndex === index,
                })}
                key={id}
                onClick={() => onClickQuickLabel(item, index)}
              >
                {displayName}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default memo(QuickView);
