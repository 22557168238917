import React from 'react';
import type { TableProps } from 'antd';
import { COLLECT, TARGET, KEY_RESULT } from '@/constants/report';
import Table1 from './table1'; // OKR 汇总统计表
import Table2 from './table2'; // 目标（O）明细表
import Table3 from './table3'; // 关键成果（KR）明细表

interface IProps extends TableProps<any> {
  onChange?: (pagination: any, filters: any, sorter: any) => void;
  type: string;
}

const TableIndex = (props: IProps) => {
  const { onChange, type, ...rest } = props;

  const onChangeTable: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    const { current, pageSize, total } = pagination;
    onChange?.(
      {
        currentPage: current || 1,
        pageSize,
        totalResults: total,
      },
      filters,
      sorter,
    );
  };

  return (
    <div className="m-report-table">
      {
        {
          [COLLECT]: <Table1 key={COLLECT} {...rest} onChange={onChangeTable} />,
          [TARGET]: <Table2 key={TARGET} {...rest} onChange={onChangeTable} />,
          [KEY_RESULT]: <Table3 key={KEY_RESULT} {...rest} onChange={onChangeTable} />,
        }[type]
      }
    </div>
  );
};

export default TableIndex;
