import React, { useEffect, useMemo } from 'react';
import { Tabs } from 'antd';
import type { Dayjs } from 'dayjs';
import { useControllableValue, useRequest, useSafeState } from 'ahooks';
import { IUserInfo } from '@/api/auth';
import okrApi, { DeptTypeEnum, PeriodTypeEnum } from '@/api/okr';
import processListApi, { ProcessSourceTypeEnum } from '@/api/process';
import { DateTimeRangeEnum } from '@/constants';
import { OkrCycles } from '@/components';
import { getParams } from '@/utils/tools';
import { ProcessPeriodRangeSelect } from '..';
import ProcessListTab from '../process-list/process-list-tab';
import ListWrap from './list-wrap';

interface IProps {
  defaultProcessSource?: ProcessSourceTypeEnum;
  defaultPeriodId?: number;
  currentUser?: IUserInfo;
  deptId?: string;
  deptType?: DeptTypeEnum;
  year?: number;
  onChangeYear?: (year: number) => void;
}

function OrganizationProcessList(props: IProps) {
  const params: any = getParams();

  const {
    defaultProcessSource = params.processSourceType || ProcessSourceTypeEnum.PROCESS_WEEK_REPORT,
    defaultPeriodId,
    deptId,
    deptType = DeptTypeEnum.REAL_DEPT,
  } = props;

  const [year, setYear] = useControllableValue(props, {
    defaultValue: new Date().getFullYear(),
    valuePropName: 'year',
    trigger: 'onChangeYear',
  });

  // 进展类型-processSourceType
  const [activeTab, setActiveTab] = useSafeState(defaultProcessSource);
  // OKR 周期：id - 周期id | year - 周期年份
  const [okrPeriodId, setOkrPeriodId] = useSafeState(defaultPeriodId);

  // 周进展范围快捷筛选
  const [processRange, setProcessRange] = useSafeState({
    timeRange: undefined,
    activeKey: DateTimeRangeEnum.OneMonth,
  });

  useEffect(() => {
    setOkrPeriodId(defaultPeriodId);
  }, [defaultPeriodId]);

  useEffect(() => {
    if (defaultProcessSource) {
      setActiveTab(defaultProcessSource);
    }
  }, [defaultProcessSource]);

  const { data: deptInfo } = useRequest(
    () => processListApi.getDeptInfoById({ deptId: deptId!, deptType }),
    {
      ready: !!deptId,
      refreshDeps: [deptId],
    },
  );

  const { data: periodList } = useRequest(
    () =>
      okrApi.getPeriodDept({
        deptId,
        periodType: PeriodTypeEnum.okr,
        year,
      }),
    {
      ready: !!deptId,
      refreshDeps: [year, deptId],
    },
  );

  const targetPeriod = useMemo(() => {
    if (!okrPeriodId || !periodList || periodList.length === 0) return undefined;
    return periodList?.find((item) => item.id === okrPeriodId);
  }, [okrPeriodId, periodList]);

  const { id: dateId } = targetPeriod || {};

  const weeklyExtraFilters = useMemo(() => {
    const { activeKey, timeRange } = processRange;
    if (activeKey === DateTimeRangeEnum.Custom) {
      const [start, end]: Dayjs[] = timeRange || [];
      return {
        dateTimeRange: activeKey,
        from: start?.startOf('month')?.format?.('YYYY-MM-DD'),
        to: end?.endOf('month')?.format?.('YYYY-MM-DD'),
      };
    }
    return { dateTimeRange: activeKey };
  }, [processRange]);

  console.log('deptId', deptId);

  return (
    <div className="pl-process-list">
      <ProcessListTab
        isPersonalCenter={false}
        activeKey={activeTab}
        onChange={setActiveTab}
        extra={
          <>
            {activeTab === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT && (
              <ProcessPeriodRangeSelect value={processRange} onChange={setProcessRange} />
            )}
            <div style={{ marginLeft: 32 }}>
              <OkrCycles
                year={year}
                periodId={dateId!}
                onYearChange={(year) => setYear(year)}
                onPeriodChange={setOkrPeriodId}
                periodList={periodList}
              />
            </div>
          </>
        }
      />
      <Tabs
        activeKey={activeTab}
        style={{ marginTop: 12 }}
        tabBarStyle={{ display: 'none' }}
        animated
      >
        <Tabs.TabPane key={ProcessSourceTypeEnum.PROCESS_WEEK_REPORT} tab="周进展">
          <ListWrap
            deptId={deptId}
            deptInfo={deptInfo}
            year={year}
            okrDateId={dateId!}
            processSourceType={ProcessSourceTypeEnum.PROCESS_WEEK_REPORT}
            weeklyExtraFilters={weeklyExtraFilters}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={ProcessSourceTypeEnum.PROCESS_SUMMARY} tab="总结自评">
          <ListWrap
            deptId={deptId}
            deptInfo={deptInfo}
            year={year}
            okrDateId={dateId!}
            processSourceType={ProcessSourceTypeEnum.PROCESS_SUMMARY}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default OrganizationProcessList;
