import React, { memo } from 'react';
import { TreeSelect } from 'antd';
import type { TreeSelectProps } from 'antd';
import { useTreeData } from '@/hooks';
import api from '@/api/common';

interface IProps extends TreeSelectProps {
  style?: React.CSSProperties;
  hasAllOption?: boolean;
}

const DeptTreeSelect = (props: IProps) => {
  const { hasAllOption, ...rest } = props;

  const allOption = {
    title: '全部',
    value: '',
    key: '',
    isLeaf: true,
  };

  const { treeData, onLoadData } = useTreeData(api.getDeptTree, {
    getPayload: (params: any) => params?.deptId,
    // @ts-ignore
    listToNodes: (deptList) => {
      return deptList.map((item) => {
        const { deptId, deptName, hasChildren } = item;
        return {
          ...item,
          key: deptId,
          value: deptId,
          title: deptName,
          isLeaf: !hasChildren,
        };
      });
    },
  });

  const data = hasAllOption ? [allOption, ...(treeData || [])] : treeData;

  return (
    <TreeSelect
      showSearch
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto', minWidth: 200 }}
      popupMatchSelectWidth={false}
      treeData={data}
      defaultValue={''}
      {...rest}
      // @ts-ignore
      loadData={onLoadData}
    />
  );
};

export default memo(DeptTreeSelect);
