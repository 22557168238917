import dayjs from 'dayjs';
import { KrProcessStatusEnum, OkrProcessTypeEnum, RelTypeEnum } from '@/api/okr';

export const SUMMARY_TYPE_CODE = {
  STAGE: 1, // '小结',
  SUM: 2, // '总结'
};

export const PROCESS_TYPE_TEXT_MAP = {
  [OkrProcessTypeEnum.STATUS]: 'KR状态',
  [OkrProcessTypeEnum.SCORE]: 'KR评分',
  [OkrProcessTypeEnum.OBJECTIVES_STATUS]: 'O状态',
  [OkrProcessTypeEnum.OBJECTIVES_SCORE]: 'O评分',
  [OkrProcessTypeEnum.OBJECTIVES_GROUP_STATUS]: 'O状态',
  [OkrProcessTypeEnum.OBJECTIVES_GROUP_SCORE]: 'O评分',
};

export enum O_KR_ENUM {
  O = 'O',
  KR = 'KR',
}

// 周期年份选择范围 17年 - 今天加20天的年份 (与周期展示逻辑保持一致)
const START_YEAR = 2017;
const END_YEAR = dayjs().add(20, 'days').year();
export const YEAR_LIST = Array.from({ length: END_YEAR - START_YEAR + 1 }).map((item, index) => ({
  value: END_YEAR - index,
  label: END_YEAR - index,
  isLeaf: false,
}));

export const RelType = {
  [RelTypeEnum.U]: '向上对齐',
  [RelTypeEnum.M]: '横向对齐',
  [RelTypeEnum.W]: '向下对齐',
};

const COLOR = {
  red: '#f53f3f',
  yellow: '#eeb14d',
  blue: '#1890ff',
  green: '#2FBB60',
};

export const OKR_STATUS = {
  [KrProcessStatusEnum.smooth]: {
    label: '绿灯',
    color: COLOR.green,
  },
  [KrProcessStatusEnum.delay]: {
    label: '黄灯',
    color: COLOR.yellow,
  },
  [KrProcessStatusEnum.hindered]: {
    label: '红灯',
    color: COLOR.red,
  },
};

export const STATUS_OPTION = [
  {
    key: KrProcessStatusEnum.smooth,
    label: '绿灯',
    color: COLOR.green,
    popover: '绿灯：进展良好，或者目前没有到交付时间点，但主观判断会顺利完成',
  },
  {
    key: KrProcessStatusEnum.delay,
    label: '黄灯',
    color: COLOR.yellow,
    popover: '黄灯：进展一般，遇到一些障碍，正在寻找解决方案，完成有一定风险',
  },
  {
    key: KrProcessStatusEnum.hindered,
    label: '红灯',
    color: COLOR.red,
    popover: '红灯：进展较少，遇到很大障碍，完成有很大风险',
  },
];

export const SCORE_OPTION = [
  { key: 10, label: '10', color: COLOR.blue, popover: '10分：完成且远超预期' },
  { key: 9, label: '9', color: COLOR.green, popover: '7-9分：有难度的任务完成了或完成得不错' },
  { key: 8, label: '8', color: COLOR.green, popover: '7-9分：有难度的任务完成了或完成得不错' },
  { key: 7, label: '7', color: COLOR.green, popover: '7-9分：有难度的任务完成了或完成得不错' },
  { key: 6, label: '6', color: COLOR.yellow, popover: '4-6分：有难度的任务努力后部分达成' },
  { key: 5, label: '5', color: COLOR.yellow, popover: '4-6分：有难度的任务努力后部分达成' },
  { key: 4, label: '4', color: COLOR.yellow, popover: '4-6分：有难度的任务努力后部分达成' },
  { key: 3, label: '3', color: COLOR.red, popover: '1-3分：没有完成任务' },
  { key: 2, label: '2', color: COLOR.red, popover: '1-3分：没有完成任务' },
  { key: 1, label: '1', color: COLOR.red, popover: '1-3分：没有完成任务' },
  { key: 0, label: '0', color: COLOR.red, popover: '0分：无任何进展' },
];
