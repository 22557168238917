import React, { memo } from 'react';
import './index.less';

interface IProps {
  children: React.ReactNode;
  show: boolean;
}

const AnimateUp = (props: IProps) => {
  const { show } = props;

  return (
    <span className="relative">
      {props.children}
      {show && <span className="m-animate-up">+1</span>}
    </span>
  );
};

export default memo(AnimateUp);
