import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { useControllableValue, useToggle } from 'ahooks';
import { ChooseScope } from '@/components';
import './index.less';

interface IProps {
  authEdit?: boolean;
  value?: any;
  onChange?: (value?: any) => void;
}

const OkrGroup = (props: IProps) => {
  const { authEdit = false } = props;
  const [value, setValue] = useControllableValue(props);
  const [state, { toggle }] = useToggle(false);
  const [choose, setChoose] = useState(props.value);

  useEffect(() => {
    setChoose(value);
  }, [value]);

  const onConfirm = () => {
    setValue(choose);
    toggle();
  };

  const text = Object.values(value || {})
    .flat()
    .map((i: any) => i.deptName || i.userName)
    .join('，');

  return authEdit ? (
    state ? (
      <span className="m-group-edit">
        <div className="m-group-choose-scope">
          {/* okr 所属组织选择时，隐藏选择人员 https://overmind-project.netease.com/v2/my_workbench/bugdetail/Bug-132709 */}
          <ChooseScope value={choose} onChange={(value) => setChoose(value)} hideUserSelect />
        </div>
        <div>
          <a onClick={toggle} style={{ color: '#666' }}>
            取消
          </a>
          <a onClick={onConfirm}>确认</a>
        </div>
      </span>
    ) : (
      <Tooltip title={text ? `所属团队：${text}` : '修改所属团队'}>
        <div className="m-okr-group m-is-edit" onClick={toggle}>
          <span className="m-group-text">
            {text || <span style={{ color: '#999' }}>暂无</span>}
          </span>
        </div>
      </Tooltip>
    )
  ) : (
    <div className="m-okr-group">
      <span className="m-group-text">{text || <span style={{ color: '#999' }}>暂无</span>}</span>
    </div>
  );
};

export default OkrGroup;
