import React, { memo } from 'react';
import { IconFont } from '@/components';
import './index.less';

interface IProps {
  style?: React.CSSProperties;
  text?: string;
}

function Empty(props: IProps) {
  const { style, text } = props;
  return (
    <div className="process-okr-empty-wrap" style={style}>
      <IconFont type="icon-1px-copy" className="process-okr-empty-wrap-icon" />
      {text ? (
        <div className="process-okr-empty-wrap-extra">{text}</div>
      ) : (
        <>
          <div className="process-okr-empty-wrap-extra">该周期下暂未设定OKR，暂无OKR进展</div>
          <div className="process-okr-empty-wrap-extra">请重新选择“OKR周期”</div>
        </>
      )}
    </div>
  );
}

export default memo(Empty);
