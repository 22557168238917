import React, { memo } from 'react';
import { OAndKrTitle } from '..';
import { IDiscoverItem } from '@/api/discover';
import { KrProcessTypeEnum, OkrProcessTypeEnum } from '@/api/okr';
import { dataTrack } from '@/utils/track';
import { getParams } from '@/utils/tools';
import { KrScoreOrStatus } from '@/components';
import styles from './kr-card.less';

const EVENT_ID_MAP = {
  team: 'click_discover_team_kr',
  follow: 'click_discover_follow_kr',
};

interface IProps {
  data: IDiscoverItem;
  onOpenDrawer: (data: IDiscoverItem) => void;
}

const KrCard = (props: IProps) => {
  const { data, onOpenDrawer } = props;

  const urlParams: any = getParams();

  const { objectivesKrProcess } = data || {};
  const { processType, content } = objectivesKrProcess || {};

  const onClickTitle = (data: IDiscoverItem) => {
    onOpenDrawer(data);

    // @ts-ignore
    const eventId = EVENT_ID_MAP[urlParams?.tab] || '';
    if (!eventId) return;
    dataTrack(eventId);
  };

  const showScoreOrStatus = [OkrProcessTypeEnum.STATUS, OkrProcessTypeEnum.SCORE].includes(
    processType,
  );

  return (
    <div>
      <OAndKrTitle data={data} onOpenDrawer={onClickTitle} />
      {objectivesKrProcess && (
        <div className={styles.krProcess}>
          {showScoreOrStatus && <KrScoreOrStatus data={objectivesKrProcess} />}
          {!!content && content !== '<p></p>' && (
            <div className="discover-card-bd" dangerouslySetInnerHTML={{ __html: content }} />
          )}
        </div>
      )}
    </div>
  );
};

export default memo(KrCard);
