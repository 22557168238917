import React, { memo } from 'react';
import { Link } from 'umi';
import { DiscoverTypeEnum, IDiscoverItem } from '@/api/discover';

const PROCESS_DYNAMIC = [
  DiscoverTypeEnum.PROCESS_WEEK_REPORT,
  DiscoverTypeEnum.PROCESS_CONCLUSION,
  DiscoverTypeEnum.PROCESS_SUMMARY,
];

interface IProps {
  data: IDiscoverItem;
}

function DiscoverInfoTitle(props: IProps) {
  const { data } = props;
  const { type, relId, name, userId, year, dateId, objectivesId } = data || {};

  if (
    type === DiscoverTypeEnum.OKR ||
    type === DiscoverTypeEnum.GROUP_OKR ||
    type === DiscoverTypeEnum.KEY_RESULT
  ) {
    return (
      <Link
        to={{
          pathname: `/team/${userId}`,
          search: `?tab=okr&year=${year}&periodId=${dateId}&id=${objectivesId}`,
        }}
      >
        <span className="hd-name">{name}</span>
      </Link>
    );
  }
  if (type === DiscoverTypeEnum.SUMMARY) {
    return (
      <Link to={{ pathname: `/summary/detail/${relId}` }}>
        <span className="hd-name">{name}</span>
      </Link>
    );
  }

  if (PROCESS_DYNAMIC.includes(type)) {
    return (
      <Link
        to={{ pathname: `/process/dashboard?tab=detail&type=${type}&id=${relId}` }}
        target="_blank"
      >
        <span className="hd-name">
          {name}
          {type === DiscoverTypeEnum.PROCESS_WEEK_REPORT ? '周报' : ''}
        </span>
      </Link>
    );
  }
  return <span className="hd-name">{name}</span>;
}

export default memo(DiscoverInfoTitle);
