//@ts-nocheck
import dayjs from 'dayjs';
import { EMOJI_LIST } from '@/constants';
// 获取路由参数
export const getParams = (url?: string) => {
  url = decodeURIComponent(url || window.location.hash);

  if (!url.includes('?')) return {};

  let params = {};

  url
    .split('?')[1]
    .split('&')
    .forEach((item) => {
      params = {
        ...params,
        [item.split('=')[0]]: item.split('=')[1],
      };
    });

  return params;
};

// 将@人员的格式 转化为 蓝色可点击跳转的标签
export const initUserLInk = (content = '') => {
  // 正则解释: 在 @{xxx-xxx} 的结构中 x 不可以是‘@’，‘{’，‘}’ 三个符号之一;
  // 、解决问题格式 @{叶金福-4324@{韩丽园-85376}}
  content = content || '';
  const reg = /@\{([^@{}]+?)-([^@{}]+?)\}/;
  const regGlobal = /@\{([^@{}]+?)-([^@{}]+?)\}/g;
  const result: any = [...new Set(content.match(regGlobal) || [])];
  if (!result.length) return content;

  for (let i = 0; i < result.length; i++) {
    const item = result[i];
    // 全量匹配
    const regItem = new RegExp(item.match(reg)[0], 'g');
    const href = `${window.location.origin}#/team/${item.match(reg)[2]}?tab=okr`;
    content = content.replace(
      regItem,
      `<a href=${href} target="_blank">@${item.match(reg)[1]}</a>`,
    );
  }
  return content;
};

export function generateUUID() {
  let d = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
  });
}

export function addUUID(list, key = 'uuid') {
  if (!list || !list.map) return list;
  return list.map((item) => ({
    ...item,
    [key]: generateUUID(),
  }));
}

export function formatList(arr: any) {
  if (!Array.isArray(arr)) return [];
  let temp: any = [];
  arr.forEach((item) => {
    const { list, name } = item;
    if (list && list.length) {
      temp = temp.concat(
        list.map((child: any, idx: number) => {
          const uid = generateUUID();
          if (idx === 0) {
            return {
              uid,
              title: name,
              ...child,
            };
          }
          return {
            uid,
            ...child,
          };
        }),
      );
    }
  });
  return temp;
}

export function filterDuplicates(objArr, filterKey, fn?: any) {
  // 为对象数组 arr 按 key字段 去重 同时优先保留满足fn返回的条件 的对象;
  let realArr = objArr;
  if (fn) {
    realArr = realArr.map((a, index) => ({ ...a, sortIndex: index }));
    realArr = realArr.sort((a, b) => (fn(a, b) ? -1 : 1));
  }
  let arrIds = realArr.map((a) => a[filterKey]);
  let resArr = realArr.filter((a, index) => arrIds.indexOf(a[filterKey]) === index);
  resArr = resArr.sort((a, b) => (a.sortIndex > b.sortIndex ? 1 : -1));
  return resArr;
}

export const pickUserIdFromHTML = (html: string) => {
  const regex = /data-id="(\d+)"/g;
  const match = html?.match(regex);
  if (!match) return [];
  const result = match.map((m) => {
    return m.replace('data-id="', '').replace('"', '');
  });
  return result;
};

// 获取人员ID
export const getUserIdList = (content = '') => {
  // 正则解释: 在 @{xxx-xxx} 的结构中 x 不可以是‘@’，‘{’，‘}’ 三个符号之一;
  // 、解决问题格式 @{叶金福-4324@{韩丽园-85376}}
  content = content || '';
  const reg = /@\{([^@{}]+?)-([^@{}]+?)\}/;
  const regGlobal = /@\{([^@{}]+?)-([^@{}]+?)\}/g;
  const result = content.match(regGlobal) || [];
  if (!result.length) return [];
  const list = [];
  for (let i = 0; i < result.length; i++) {
    const item = result[i];
    list.push(item.match(reg)[2]);
  }
  return list.map((item) => +item);
};
export function getProcessMoment(date) {
  return dayjs(date).format('MMDD');
}

export function commentArray2Tree(arr: any) {
  if (!Array.isArray(arr) || !arr.length) return;
  const map: any = {};
  arr.forEach((item) => {
    map[item.id] = item;
  });
  const roots: any = [];
  arr.forEach((item) => {
    const parent = map[item.parentId];
    if (parent) {
      (parent.children || (parent.children = [])).unshift(item);
    } else {
      roots.push(item);
    }
  });
  return roots;
}

export const transferComment = (text: string) => {
  if (!text) return '';
  // 替换表情
  const regexEmoji = new RegExp(`\\[(${EMOJI_LIST.join('|')})?\\]`, 'g');
  const path = 'http://ehr-web.netease.com/ehr-web/okr/gif';

  const regexColleague = /@\{(.+?)-(.+?)}/g;

  return text
    .replace(regexEmoji, `<img src=${path}/$1.gif width="25px" height="25px">`)
    .replace(regexColleague, '<a class="colleage-link" href="/#/team/$2?tab=okr">@$1</a>');
};
