/**
 * 兼容BraftEditor的缩进样式，将text-indent:Xem转换为data-indent:X / 2
 * @param htmlStr
 */

export function addDataIndentAttrToStyles(htmlStr: string) {
  if (!htmlStr) return htmlStr;
  // 创建一个临时的div元素
  const tempDiv = document.createElement('div');
  // 将HTML字符串设置为div元素的innerHTML
  tempDiv.innerHTML = htmlStr;

  // 使用DOM解析器遍历所有元素
  const walker = document.createTreeWalker(tempDiv, NodeFilter.SHOW_ELEMENT);
  let node: any = walker.nextNode();
  while (node) {
    // 检查元素的style属性是否包含text-indent:Xem
    const style = node.getAttribute('style');
    if (style && !node.hasAttribute('data-indent')) {
      const match = /text-indent:\s*(\d+)em;/i.exec(style);
      if (match) {
        const indent = parseInt(match[1], 10) / 2;
        node.setAttribute('data-indent', `${indent}em`);
      }
    }
    node = walker.nextNode();
  }

  // 返回更新后的HTML字符串
  return tempDiv.innerHTML;
}
