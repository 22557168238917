import React, { useState } from 'react';
import { Tabs, Table, Select, message } from 'antd';
import type { TableColumnsType } from 'antd';
import { Split } from '@/components';
import { useRequest } from 'ahooks';
import settingApi, {
  IDeptShareItem,
  IDeptShareDetailItem,
  AuthSourceTypeEnum,
} from '@/api/setting';
import { DeptTree } from '../components';
import './index.less';

const authList = [
  { authLevel: 1, authName: '一级组织内共享' },
  { authLevel: 2, authName: '二级组织内共享' },
  { authLevel: 3, authName: '三级组织内共享' },
  { authLevel: 4, authName: '四级组织内共享' },
  { authLevel: 5, authName: '五级组织内共享' },
  { authLevel: 10, authName: '无最小共享范围' },
];

export default function Share() {
  const [currentTab, setCurrentTab] = useState<string>('dept');

  const {
    data: deptData,
    loading,
    runAsync,
    refresh,
  } = useRequest((id: string) => settingApi.getDefaultDeptList(id), {
    manual: true,
  });
  const {
    data: virDeptData,
    loading: virLoading,
    runAsync: virRunAsync,
    refresh: refreshVir,
  } = useRequest((id: string) => settingApi.getDefaultVirDeptList(id), {
    manual: true,
  });

  const onChangeTab = (key: string) => {
    setCurrentTab(key);
  };

  const initAuthValue = (list: IDeptShareDetailItem[], authType: AuthSourceTypeEnum) => {
    return list.find((item) => item.authType === authType)?.authLevel;
  };

  const onChangeAuth = async (value: number, id: string, authType: AuthSourceTypeEnum) => {
    const apiFn =
      currentTab === 'dept' ? settingApi.updateDefaultDept : settingApi.updateDefaultVirDept;
    await apiFn({
      authType,
      authLevel: value,
      id,
    });
    message.success('修改成功');
    if (currentTab === 'dept') {
      refresh();
    } else {
      refreshVir();
    }
  };

  const checkOptionDisabled = (authLevel: number) => {
    return currentTab === 'virDept' && authLevel === 1;
  };

  const selectOptions = authList.map((item) => ({
    label: item.authName,
    value: item.authLevel,
    disabled: checkOptionDisabled(item.authLevel),
  }));

  const columns: TableColumnsType<IDeptShareItem> = [
    {
      title: '部门名称',
      dataIndex: 'deptPath',
      width: 400,
      render: (value: string) => <span className="dept-name">{value}</span>,
    },
    {
      title: 'OKR共享范围',
      dataIndex: 'defaultDetailVOList',
      render: (value: IDeptShareDetailItem[], record) => {
        return (
          <Select
            bordered={false}
            dropdownMatchSelectWidth={false}
            defaultValue={initAuthValue(value, AuthSourceTypeEnum.OKR)}
            options={selectOptions}
            onChange={(value) => onChangeAuth(value, record.id, AuthSourceTypeEnum.OKR)}
            disabled={currentTab === 'virDept' && record.id?.startsWith('D')}
          />
        );
      },
    },
    {
      title: '周报共享范围',
      dataIndex: 'defaultDetailVOList',
      render: (value: IDeptShareDetailItem[], record) => {
        return (
          <Select
            bordered={false}
            dropdownMatchSelectWidth={false}
            defaultValue={initAuthValue(value, AuthSourceTypeEnum.WORK_REPORT)}
            options={selectOptions}
            onChange={(value) => onChangeAuth(value, record.id, AuthSourceTypeEnum.WORK_REPORT)}
            disabled={currentTab === 'virDept' && record.id?.startsWith('D')}
          />
        );
      },
    },
    {
      title: '总结共享范围',
      dataIndex: 'defaultDetailVOList',
      render: (value: IDeptShareDetailItem[], record) => {
        return (
          <Select
            bordered={false}
            dropdownMatchSelectWidth={false}
            defaultValue={initAuthValue(value, AuthSourceTypeEnum.SUMMARY)}
            options={selectOptions}
            onChange={(value) => onChangeAuth(value, record.id, AuthSourceTypeEnum.SUMMARY)}
            disabled={currentTab === 'virDept' && record.id?.startsWith('D')}
          />
        );
      },
    },
    {
      title: '组织人事共享范围',
      dataIndex: 'defaultDetailVOList',
      render: (value: IDeptShareDetailItem[], record) => {
        return (
          <Select
            bordered={false}
            dropdownMatchSelectWidth={false}
            defaultValue={initAuthValue(value, AuthSourceTypeEnum.DEPT)}
            options={selectOptions}
            onChange={(value) => onChangeAuth(value, record.id, AuthSourceTypeEnum.DEPT)}
            disabled={currentTab === 'virDept' && record.id?.startsWith('D')}
          />
        );
      },
    },
  ];

  const getSettingDept = (deptId: string) => {
    if (!deptId) return;
    runAsync(deptId);
  };

  const getSettingVirDept = (deptId: string) => {
    if (!deptId) return;
    virRunAsync(deptId);
  };

  return (
    <div className="m-manage-share">
      <header className="share-header">
        <div style={{ fontSize: 16, fontWeight: 500 }}>OKR共享设置</div>
      </header>
      <Tabs animated onChange={onChangeTab}>
        <Tabs.TabPane tab="实体组织" key="dept">
          <Split className="share-content-container" minSize={[188, 1000]}>
            <div className="share-sider">
              <DeptTree deptTreeChange={getSettingDept} />
            </div>
            <div className="share-content">
              <Table
                // @ts-ignore
                key={loading}
                dataSource={deptData}
                loading={loading}
                pagination={false}
                columns={columns}
                rowKey="deptId"
                rowClassName={(record, index) => (index ? '' : 'first-row')}
              />
            </div>
          </Split>
        </Tabs.TabPane>
        <Tabs.TabPane tab="虚拟组织" key="virDept">
          <Split className="share-content-container" minSize={[188, 1000]}>
            <div className="share-sider">
              <DeptTree deptTreeChange={getSettingVirDept} isVirtual />
            </div>
            <div className="share-content">
              <Table
                // @ts-ignore
                key={virLoading}
                dataSource={virDeptData}
                loading={virLoading}
                pagination={false}
                columns={columns}
                rowKey="deptId"
                rowClassName={(record, index) => (index ? '' : 'first-row')}
              />
            </div>
          </Split>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
