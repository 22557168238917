import React, { useMemo, useEffect } from 'react';
import { Tabs, Dropdown } from 'antd';
import { LeftOutlined, CaretDownOutlined } from '@ant-design/icons';
import { history, useLocation } from 'umi';
import { useSafeState } from 'ahooks';
import classnames from 'classnames';
import { useStore } from '@/store';
import { OkrTypeEnum } from '@/api/okr';
import { IProcessDetail, ProcessSourceTypeEnum } from '@/api/process';
import { ProcessTabEnum, SummaryPeriodTypeEnum, STORAGE_KEY_MAP } from '@/constants';
import { getParams } from '@/utils/tools';
import storage from '@/utils/storage';
import * as querystring from 'querystring';
import { SummaryPeriodSelect, WeeklyPeriodSelect } from '@/pages/process/list/components';
import { setProcessListParams } from '../list/hooks';
import { getProcessTitle } from '../list/components/process-card/utils';
import ProcessDetail from './detail';
import { ProcessDashboard, SwitchMode, CacheEditingInfo } from '../components';
import { getSummaryPeriodType } from './utils';
import './index.less';

const { TabPane } = Tabs;

function OkrProcessDashboard() {
  const location = useLocation();

  // 进展基本信息
  const [processVO, setProcessVO] = useSafeState<IProcessDetail>();
  const [weeklyTimeRange, setWeeklyTimeRange] = useSafeState();
  const [open, setOpen] = useSafeState(false);

  const defaultParams = getParams(location.search);

  const {
    tab,
    userId: queryUserId,
    deptId,
    type: processSource,
    dateId: queryDateId,
    periodId,
    year,
    id,
  }: any = defaultParams;

  const userId = queryUserId ? +queryUserId : undefined;
  const dateId = +queryDateId;

  const { setStoreValues } = useStore();

  const activeKey = useMemo(() => {
    const isValidTab = [ProcessTabEnum.user, ProcessTabEnum.org, ProcessTabEnum.detail].includes(
      tab,
    );
    return isValidTab ? tab : ProcessTabEnum.user;
  }, [tab]);

  useEffect(() => {
    setStoreValues({
      currentProcessType:
        activeKey === ProcessTabEnum.org ? OkrTypeEnum.organization : OkrTypeEnum.personal,
      currentProcessUserId: userId,
      currentProcessDeptId: deptId,
      currentProcessDateId: dateId,
      currentProcessYear: year || new Date().getFullYear(),
      currentProcessOkrDateId: periodId,
      currentProcessSourceType: processSource,
    });
    return () => {
      setStoreValues({
        currentProcessType: undefined,
        currentProcessUserId: undefined,
        currentProcessDeptId: '',
        currentProcessDateId: undefined,
        currentProcessYear: new Date().getFullYear(),
        currentProcessOkrDateId: undefined,
        currentProcessSourceType: undefined,
      });
    };
  }, [activeKey, userId, deptId, dateId, year, periodId, processSource]);

  useEffect(() => {
    CacheEditingInfo.getInstance().initEditingInfo();
    if (processSource === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT) {
      setWeeklyTimeRange(storage.session.get(STORAGE_KEY_MAP.weeklyCustomRange));
    }
  }, [processSource]);

  const periodType = useMemo(() => {
    return getSummaryPeriodType(processVO);
  }, [processVO]);

  const onConfirmRouteChange = (onOkCb: () => void) => {
    onOkCb();
  };

  const onSelectDateId = (nextDateId: number) => {
    if (nextDateId === dateId) return;
    onConfirmRouteChange(() => {
      const payload = { ...defaultParams, dateId: nextDateId };
      CacheEditingInfo.getInstance().initEditingInfo();
      history.replace(`/process/dashboard?${querystring.stringify(payload)}`);
    });
  };

  const isDetailTab = tab === ProcessTabEnum.detail;

  return (
    <div className={classnames('okr-process-container')}>
      <div className="okr-process_top-bar">
        <div className="okr-process_top-bar-left">
          {history?.length > 2 && !isDetailTab && (
            <div
              className="okr-process_top-btn-back"
              onClick={() => {
                onConfirmRouteChange(() => {
                  setProcessListParams(defaultParams);
                  history.replace(`/process/list?tab=${tab}`);
                });
              }}
            >
              <LeftOutlined style={{ marginRight: 4 }} />
              返回
            </div>
          )}
        </div>
        <div className="okr-process_top-bar-middle">
          {!isDetailTab && (
            <>
              {periodType === SummaryPeriodTypeEnum.year ? (
                <div className="okr-process-title">{getProcessTitle(processVO)}</div>
              ) : (
                <Dropdown
                  trigger={['click']}
                  onOpenChange={setOpen}
                  //@ts-ignore
                  getPopupContainer={(triggerNode) => triggerNode?.parentElement}
                  overlay={
                    <div
                      className={classnames('okr-process_top-bar-dropdown', {
                        'okr-process_top-bar-dropdown_half':
                          periodType === SummaryPeriodTypeEnum.halfYear,
                      })}
                    >
                      {processSource === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT ? (
                        <WeeklyPeriodSelect
                          open={open}
                          type={
                            tab === ProcessTabEnum.user
                              ? OkrTypeEnum.personal
                              : OkrTypeEnum.organization
                          }
                          weeklyTimeRange={weeklyTimeRange}
                          userId={userId}
                          deptId={deptId}
                          year={year}
                          selectedDateId={dateId}
                          onSelect={onSelectDateId}
                        />
                      ) : (
                        <SummaryPeriodSelect
                          type={
                            tab === ProcessTabEnum.user
                              ? OkrTypeEnum.personal
                              : OkrTypeEnum.organization
                          }
                          periodType={periodType!}
                          userId={userId}
                          deptId={deptId}
                          year={year}
                          selectedDateId={dateId}
                          onSelect={onSelectDateId}
                        />
                      )}
                    </div>
                  }
                  placement="bottom"
                  arrow
                >
                  <div className="okr-process-title">
                    {getProcessTitle(processVO)}
                    <CaretDownOutlined style={{ marginLeft: 8 }} />
                  </div>
                </Dropdown>
              )}
            </>
          )}
        </div>
        <div className="okr-process_top-bar-right">
          <SwitchMode tip="查看他人进展时，仅展示有进展的OKR" />
        </div>
      </div>

      <Tabs
        tabBarStyle={{
          display: 'none',
        }}
        activeKey={activeKey}
        id="process-dashboard-tab"
        destroyInactiveTabPane
      >
        <TabPane
          tab="个人OKR进展"
          key={ProcessTabEnum.user}
          style={{ minHeight: 'calc(100vh - 300px)' }}
        >
          <ProcessDashboard onCallbackProcessVO={setProcessVO} />
        </TabPane>
        <TabPane
          tab="组织OKR进展"
          key={ProcessTabEnum.org}
          style={{ minHeight: 'calc(100vh - 300px)' }}
        >
          <ProcessDashboard onCallbackProcessVO={setProcessVO} />
        </TabPane>
        <TabPane tab="详情" key={ProcessTabEnum.detail}>
          <ProcessDetail id={+id} />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default OkrProcessDashboard;
