import React, { useState, useEffect } from 'react';
import { useToggle, useControllableValue } from 'ahooks';
import { Input, App } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { dataTrack } from '@/utils/track';
import { getParams } from '@/utils/tools';
import './index.less';

const { TextArea } = Input;

const config = {
  O: {
    maxLength: 100,
    confirmTitle: '确定删除此条Objective吗',
    confirmContent: '删除后本条 OKR 内容将全部丢失',
    textArea: {
      style: { fontWeight: 500, fontSize: 16 },
      placeholder: '一个好的Objective应符合：清晰、关键、简洁、激励、挑战',
    },
  },
  KR: {
    maxLength: 500,
    confirmTitle: '确定删除此条Key Result吗',
    confirmContent: '删除后本条 Key Result 内容将全部丢失',
    textArea: {
      placeholder: '一个好的KR应符合：清晰、关键、简洁、可衡量、支撑性',
    },
  },
};

interface IProps {
  authEdit?: boolean;
  type?: 'O' | 'KR';
  editStatus?: boolean;
  isDetail?: boolean;
  onClickText?: () => void;
  onDelete?: () => void;
  onChangeEmpty?: (() => void) | null;
  value?: string;
  onChange?: (value: string) => void;
}

const OkrName = (props: IProps) => {
  const { modal, message } = App.useApp();

  const urlParams: any = getParams();

  const {
    authEdit,
    type = 'KR',
    editStatus = false,
    isDetail = false,
    onClickText,
    onDelete,
    onChangeEmpty,
  } = props;
  const { maxLength, confirmTitle, confirmContent, textArea } = config[type] || {};

  const [value, setValue] = useControllableValue(props);
  const [state, { toggle }] = useToggle(editStatus);
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onCLickEdit = () => {
    toggle();
  };

  const onBlur = () => {
    toggle();
    if (!inputValue?.trim()) {
      setInputValue(value);
      onChangeEmpty?.();
      return message.error('内容不可为空！');
    }
    setValue(inputValue);
  };

  const onChange = ({ target: { value } }: any) => {
    setInputValue(value);
  };

  const onClick = () => {
    onClickText?.();

    const oEventId = urlParams?.tab === 'org' ? 'click_org_okr_card_title' : 'click_okr_card_title';
    const krEventId =
      urlParams?.tab === 'org' ? 'click_org_okr_card_kr_title' : 'click_okr_card_kr_title';
    dataTrack(type === 'O' ? oEventId : krEventId);
  };

  const onClickDelete = () => {
    modal.confirm({
      title: confirmTitle,
      content: confirmContent,
      onOk: onDelete,
    });
  };

  const text =
    type === 'O' ? (
      <span className="m-o-name-text">{value}</span>
    ) : (
      <span className="m-kr-name-text">{value}</span>
    );

  return authEdit ? (
    state ? (
      <TextArea
        className="m-okr-name-input"
        autoSize={{ minRows: 1 }}
        value={inputValue}
        onChange={onChange}
        autoFocus
        onBlur={onBlur}
        maxLength={maxLength}
        {...textArea}
      />
    ) : (
      <div className="m-okr-name">
        <span className={!isDetail ? 'm-okr-name-link' : ''} onClick={onClick}>
          {text}
        </span>
        <div style={{ flexShrink: 0 }}>
          <EditOutlined className="m-okr-edit-icon" onClick={onCLickEdit} />
          {!!onDelete && <DeleteOutlined className="m-okr-edit-icon" onClick={onClickDelete} />}
        </div>
      </div>
    )
  ) : (
    <div className="m-okr-name" onClick={onClick}>
      <span className={!isDetail ? 'm-okr-name-link' : ''}>{text}</span>
    </div>
  );
};

export default OkrName;
