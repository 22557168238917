import {
  KrProcessTypeEnum,
  KrProcessStatusEnum,
  IRelResultItem,
  OkrProcessTypeEnum,
} from '@/api/okr';
import { ProcessSourceTypeEnum } from '@/api/process';
import { ICommentItem } from '@/api/common';
import { PageRequestParams } from '../interface';

export enum DiscoverTypeEnum {
  OKR = 'OKR',
  GROUP_OKR = 'GROUP_OKR', // 组织OKR
  KEY_RESULT = 'KEY_RESULT', // 关键成果
  GROUP_OKR_KEY_RESULT = 'GROUP_OKR_KEY_RESULT',
  PROCESS_SUMMARY = 'PROCESS_SUMMARY', // OKR进展
  PROCESS_WEEK_REPORT = 'PROCESS_WEEK_REPORT', // OKR进展
}

export interface IRequestDiscover extends PageRequestParams {
  type?: 'O' | 'K' | 'W' | 'S'; // null全部，o目标，k关键成功，w周报
  level?: 1 | 2 | 3; // null全部 1:上级 2：下级 3：同级
  deptId?: string; // 全部动态部门范围筛选
  userId?: number; // 查看某人个人中心的进展
}

export interface IObjectivesKrProcess {
  id: number;
  keyResultId: number;
  title: string;
  content: string;
  belongUser: number;
  processType: OkrProcessTypeEnum;
  status: KrProcessStatusEnum; // 进展状态：101-顺利进行 102-有点延迟  103-很大障碍
  score: number; // 进展评分：0-10
}

export interface IProcessCommon {
  content: string;
  createTime: string;
  dateId: number;
  id: number;
  name: string;
  status: number;
  targetId: number;
  type: number;
  updateTime: string;
}

export interface IProcessOkr {
  content: string;
  createTime: string;
  dateId: number;
  evaluationScore: string;
  evaluationStatus: number;
  id: number;
  operatorId: number;
  sourceId: number;
  sourceType: ProcessSourceTypeEnum;
  status: number;
  type: string;
  typeSourceId: number;
  updateTime: string;
}

export interface IDynamicProcessKrVO {
  id: number;
  name: string;
  process: IProcessOkr;
}

export interface IDynamicProcessOkrVOItem {
  dynamicProcessKrVOList: IDynamicProcessKrVO[];
  id: number;
  name: string;
  process: IProcessOkr;
}

export interface IDynamicProcessVO {
  processOther: any;
  processSourceType: DiscoverTypeEnum;
  processCommon: IProcessCommon;
  dynamicProcessOkrVOList: IDynamicProcessOkrVOItem[];
}

export interface IDynamicRecordItem {
  id: number;
  createTime: string;
  dsc: string;
  userName: string;
}

export interface IObjectVO {
  id: number;
  objectivesName: string;
  objectivesType: number;
  principal: string;
  photoUrl: string;
  principalId: number;
}

export interface IThumbUpVO {
  thumbUpCount: number;
  isThumbUp: boolean;
  userList: {
    userId: number;
    userName: string;
    photoUrl: string;
    uNo: string;
  }[];
}

// 对齐关系
export interface IORelResultItem {
  relType: string;
  userList: {
    IRelResultItem: string;
    photoUrl: string;
    relResult: IRelResultItem;
  }[];
}

// 动态列表
export interface IDiscoverItem {
  commentVOList: ICommentItem[];
  userId: number;
  photoUrl: string;
  userName: string;
  lastRecordContent: string;
  modifyTime: string;
  sysTime: string;
  id: number;
  type: DiscoverTypeEnum; // 动态类型（OKR:OKR；SUMMARY：总结；WORK_REPORT：周报；GROUP_OKR：组织OKR KEY_RESULT:关键成果 GROUP_OKR_KEY_RESULT：组织关键成果）
  dynamicProcessVO: IDynamicProcessVO;
  dynamicRecordList: IDynamicRecordItem[];
  relId: number;
  thumbUpVO: IThumbUpVO;
  oSorts: number;
  krSorts: number;
  relResultList: IORelResultItem[];
  name: string; // O 或 KR 的名字
  objectivesKrProcess: IObjectivesKrProcess;
  objectivesId: number;
  objectivesVO: IObjectVO;
}
