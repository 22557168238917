import React, { useCallback, useState } from 'react';
import { Link, useModel } from 'umi';
import { Typography } from 'antd';
import commonApi, { ICommentItem } from '@/api/common';
import { DiscoverTypeEnum, IDiscoverItem } from '@/api/discover';
import { Avatar, DetailDrawer, LikeCommentRecord } from '@/components';
import { O_KR_ENUM } from '@/constants';
import { useOkrStore } from '@/store';
import { changeDateDesc } from '@/utils/date';
import OCard from './o-card';
import KrCard from './kr-card';
import ProcessCard from './process-card';
import { UGLY_splitDiscoverTitle } from './utils';
import './index.less';

const PROCESS_DYNAMIC = [DiscoverTypeEnum.PROCESS_WEEK_REPORT, DiscoverTypeEnum.PROCESS_SUMMARY];

interface IProps {
  data: IDiscoverItem;
  refresh?: (discoverId: number, comment?: ICommentItem) => void;
  handleDeleteComment?: (discoverId: number, id: number) => void;
  refreshLike?: (discoverId: number) => void;
}

const DiscoverCard = (props: IProps) => {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { data, refresh, handleDeleteComment, refreshLike } = props;

  const { setReadonly, setDetailInfo } = useOkrStore();

  const {
    userId,
    photoUrl,
    userName,
    lastRecordContent,
    modifyTime,
    sysTime,
    type,
    dynamicProcessVO,
    dynamicRecordList,
    relId,
    commentVOList,
    id,
    thumbUpVO,
    oSorts,
    krSorts,
  } = data || {};

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentOkrId, setCurrentOkrId] = useState(0);

  const onLike = useCallback(async () => {
    await commonApi.thumbUp({
      objId: relId,
      belongUserId: userId,
      // @ts-ignore
      objType: type,
    });
    refreshLike?.(id);
  }, [relId, userId, type]);

  const [, ...actionTextArr] = (lastRecordContent || '').split(' ');

  const onRefreshComment = (comment?: ICommentItem) => {
    refresh?.(id, comment);
  };

  const onDeleteCommentSuccess = (count: number, id: number) => {
    handleDeleteComment?.(data?.id, id);
  };

  const onOpenDrawer = useCallback(
    (info: IDiscoverItem, tabActivityKey?: string) => {
      const { relId, type, objectivesId } = info || {};
      setDrawerVisible(true);
      setReadonly(true);
      const typeStr = type === DiscoverTypeEnum.KEY_RESULT ? O_KR_ENUM.KR : O_KR_ENUM.O;
      setDetailInfo({
        type: typeStr,
        detailId: relId,
        index: (type === DiscoverTypeEnum.KEY_RESULT ? krSorts : oSorts) - 1,
        tabActivityKey: tabActivityKey || '',
      });
      setCurrentOkrId(objectivesId);
    },
    [oSorts, krSorts],
  );

  const onCloseDrawer = () => {
    setDrawerVisible(false);
    setCurrentOkrId(0);
    setDetailInfo({ type: O_KR_ENUM.O, detailId: 0, index: 0, tabActivityKey: '' });
  };

  const actionStr = actionTextArr.join('  ');

  const discoverTitle: string | React.ReactNode = UGLY_splitDiscoverTitle(
    actionStr,
    data,
    onOpenDrawer,
  );

  return (
    <div className="m-discover-card">
      <div className="discover-left">
        <Link
          to={{
            pathname: `/team/${userId}`,
            search: `?tab=okr`,
          }}
          target="_blank"
          onFocus={() => undefined}
        >
          <Avatar type="middle" imgPath={photoUrl} userName={userName} />
        </Link>
      </div>
      <div className="discover-right">
        <p className="discover-card-intro">
          <Typography.Link onClick={() => window.open(`/#/team/${userId}?tab=okr`)}>
            {userName}
          </Typography.Link>
          &nbsp;
          <span>{discoverTitle}</span>
        </p>
        <div className="discover-time">{changeDateDesc(modifyTime, sysTime)}</div>
        <main className="discover-content-main">
          {[DiscoverTypeEnum.OKR, DiscoverTypeEnum.GROUP_OKR].includes(type) && (
            <OCard data={data} onOpenDrawer={onOpenDrawer} />
          )}
          {[DiscoverTypeEnum.KEY_RESULT, DiscoverTypeEnum.GROUP_OKR_KEY_RESULT].includes(type) && (
            <KrCard data={data} onOpenDrawer={onOpenDrawer} />
          )}
          {PROCESS_DYNAMIC.includes(type) && <ProcessCard data={dynamicProcessVO} type={type} />}
        </main>
        <LikeCommentRecord
          id={relId}
          // @ts-ignore
          targetType={type}
          isThumbUp={thumbUpVO?.isThumbUp}
          thumbUpCount={thumbUpVO?.thumbUpCount}
          onLike={onLike}
          commentList={commentVOList}
          onRefreshComment={onRefreshComment}
          onDeleteCommentSuccess={onDeleteCommentSuccess}
          userInfo={userInfo!}
          recordList={dynamicRecordList}
          likeUserList={thumbUpVO?.userList}
        />
      </div>
      <DetailDrawer open={drawerVisible} id={currentOkrId} onClose={onCloseDrawer} />
    </div>
  );
};

export default DiscoverCard;
