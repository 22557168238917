import React from 'react';
import { Select, DatePicker } from 'antd';
import { STATUS_LIST, COLLECT, TARGET, KEY_RESULT } from '@/constants/report';
import Cycle from './chose-cycle'; // 周期
import TreeSelect, { ALL, UNDERLING } from './tree-select'; // 人员范围
import Grades from './grade';

const { RangePicker } = DatePicker;

// OKR 状态
const Status = {
  key: 'summaryStatus',
  label: 'OKR状态',
  component: (
    <Select style={{ width: 200 }} placeholder="全部">
      {STATUS_LIST.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  ),
};

// OKR 评分
const Grade = {
  key: 'grade',
  label: 'OKR评分',
  component: <Grades />,
};

// 周期
const Cycles = {
  key: 'cycle',
  label: '周期',
  formItemApi: {
    initialValue: [new Date().getFullYear(), '全部'],
  },
  component: <Cycle />,
};

// 时间
const DateRange = {
  key: 'date',
  label: '时间',
  //@ts-ignore
  component: <RangePicker style={{ width: 300 }} />,
};

// 人员范围
const StaffRange = {
  key: 'staff',
  label: '人员范围',
  formItemApi: {
    initialValue: [UNDERLING],
  },
  component: <TreeSelect />,
};

export const SearchItem = {
  [COLLECT]: [StaffRange, DateRange], // OKR汇总统计表
  [TARGET]: [StaffRange, Cycles, Status, Grade], // 目标（O）明细表
  [KEY_RESULT]: [StaffRange, Cycles], // 关键成果（KR）明细表
};

export const formatData = (data: any) => {
  const dataCopy = { ...data };
  if (dataCopy.date) {
    // 时间
    if (dataCopy.date.length > 0) {
      dataCopy.startDate = dataCopy.date[0].format('YYYY-MM-DD');
      dataCopy.endDate = dataCopy.date[1].format('YYYY-MM-DD');
    } else {
      dataCopy.startDate = undefined;
      dataCopy.endDate = undefined;
    }
    delete dataCopy.date;
  }
  if (dataCopy.grade) {
    // OKR 评分
    dataCopy.summaryBeginScore = dataCopy.grade.split('~')[0];
    dataCopy.summaryEndScore = dataCopy.grade.split('~')[1];
    delete dataCopy.grade;
  } else {
    dataCopy.summaryBeginScore = null;
    dataCopy.summaryEndScore = null;
    delete dataCopy.grade;
  }

  if (dataCopy.cycle) {
    // 周期
    dataCopy.year = dataCopy.cycle[0];
    dataCopy.dateClass = dataCopy.cycle[1];
    delete dataCopy.cycle;
  }

  if (dataCopy.staff) {
    // 人员范围
    dataCopy.userScopeType = undefined;
    dataCopy.deptList = undefined;
    [ALL, UNDERLING].includes(dataCopy.staff[0])
      ? (dataCopy.userScopeType = dataCopy.staff[0])
      : (dataCopy.deptList = dataCopy.staff);
    delete dataCopy.staff;
  }
  return dataCopy;
};
