/**
 * 个人OKR列表，专属组件
 * 问：为什么不用 @/components/okr/okr-list 组件？
 * 答：数据结构的不同，个人OKR列表组件因为有表单重新渲染，所以选择IOkrItem[]结构，而不是IOkrListResponse[]，为了解决新建OKR，拖拽OKR卡片，删除OKR时，过度刷新导致数据会丢失的问题
 */
import React, { memo } from 'react';
import { useAccess, useModel } from 'umi';
import { Spin } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import type { DropResult } from 'react-beautiful-dnd';
import api, { IOkrItem } from '@/api/okr';
import { IconFont, OkrDraftCard, OkrPublishCard, OkrListEmpty } from '@/components';
import '@/components/okr/okr-list/index.less';

interface IProps {
  loading: boolean;
  dataList?: IOkrItem[];
  mutateList?: (dataList: IOkrItem[]) => void;
  onRefresh?: () => void;
  draggable?: boolean;
  listFooter?: React.ReactNode;
  emptyFooter?: React.ReactNode;
  showOTitle?: boolean;
  isCustomPeriod: boolean;
}

const OkrList = (props: IProps) => {
  const {
    loading,
    dataList,
    mutateList,
    onRefresh,
    draggable,
    listFooter,
    emptyFooter,
    showOTitle,
    isCustomPeriod,
  } = props;

  const { userRoles } = useAccess();
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const reorder: any = (list: IOkrItem[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };
    const sortedList = reorder(dataList, result.source.index, result.destination.index);
    mutateList?.(sortedList);
    api.sortOKR({
      idList: sortedList.map((i: IOkrItem) => i.id),
    });
  };

  const onDeleteOKR = (id: number) => {
    const newList = [...(dataList || [])];
    const index = newList?.findIndex((i) => i.id === id);
    newList.splice(index, 1);
    mutateList?.(newList);
  };

  return (
    <div className="m-okr-list-wrap">
      <Spin spinning={loading} className="m-okr-list-spinning">
        {dataList?.length ? (
          <div>
            <div className="m-okr-list" style={{ paddingTop: showOTitle ? 48 : 0 }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => {
                    //@ts-ignore
                    const dragHandleProps = provided.dragHandleProps;
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        {...dragHandleProps}
                      >
                        {dataList?.map((item: IOkrItem, index) => {
                          const cardProps = {
                            key: item.id,
                            onRefreshList: onRefresh,
                            userRoles,
                            userInfo: userInfo!,
                            okrInfo: item,
                            index,
                            onDelOKR: onDeleteOKR,
                            isCustomPeriod,
                          };
                          // 拖拽权限：O的编辑权限，个人中心不允许编辑
                          const dragAuth = item.authMap?.updateName && draggable;
                          return (
                            <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                              {(provided) => (
                                <div
                                  className="m-card-drag-item"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={provided.draggableProps.style}
                                >
                                  {dragAuth && (
                                    <span
                                      {...provided.dragHandleProps}
                                      role="img"
                                      className="m-card-drag-trigger"
                                    >
                                      <IconFont style={{ fontSize: 20 }} type="icon-yidong" />
                                    </span>
                                  )}
                                  {item.status ? (
                                    <OkrPublishCard {...cardProps} />
                                  ) : (
                                    <OkrDraftCard {...cardProps} />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </div>
            {listFooter}
          </div>
        ) : loading || !dataList ? null : (
          <OkrListEmpty footer={emptyFooter} />
        )}
      </Spin>
    </div>
  );
};

export default memo(OkrList);
