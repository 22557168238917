import { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { history, useAccess } from 'umi';
import type { MenuProps } from 'antd';
import { dataTrack } from '@/utils/track';
import MENU from './config';
import styles from './index.less';

const checkAuth = (data: any, auth: any) => {
  return !data.needAuth || (data.needAuth && data.role?.some((item: string) => auth[item]));
};

const HeaderMenu = () => {
  const openKey = location.hash.substr(1, location.hash.lastIndexOf('/'));
  const pathname = history.location.pathname;
  const [current, setCurrent] = useState(pathname);

  // q: 为什么要使用useAccess？
  // a: 因为菜单是根据用户角色动态生成的，所以需要获取用户角色
  // q: useAccess的原理是什么？
  // a: useAccess的原理是通过useModel获取accessRef，然后通过accessRef.current获取用户角色
  const { userRoles } = useAccess();

  useEffect(() => {
    setCurrent(pathname?.startsWith('/setting') ? '/setting' : pathname);
  }, [pathname]);

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key?.startsWith('/setting')) {
      const targetMenu = menuItems?.find((item: any) => item.key === '/setting');
      const eventType = targetMenu?.children?.findIndex((item: any) => item.key === e.key) + 1;
      dataTrack('click_menu_setting', {
        type: eventType,
      });
    } else {
      const eventType = menuItems?.findIndex((item: any) => item.key === e.key) + 1;
      dataTrack('click_menu', {
        type: eventType,
      });
    }
    setCurrent(e.key);
    history.push(e.key);
  };

  // q: 为什么要过滤菜单？
  // a: 因为菜单是根据用户角色动态生成的，所以需要过滤
  // q: 翻译下面这个函数
  // a: 过滤菜单项，如果菜单项有子菜单，则递归过滤子菜单
  // q: 不使用递归，重新实现一下这个函数
  // a: 可以使用reduce函数实现
  // q: 写出具体实现的代码
  const filterItems = (items: any, userRoles: any) => {
    return items.filter((item: any) => {
      if (item.children) {
        item.children = filterItems(item.children, userRoles);
      }
      return checkAuth(item, userRoles);
    });
  };
  const authItems = filterItems(MENU, userRoles);

  // q: 为什么要删除needAuth和role属性？
  // a: 因为Menu组件的items属性不支持自定义属性，所以需要删除
  const removeNeedAuth = (authItems: any) => {
    return authItems.map((item: any) => {
      if (item.children) {
        item.children = removeNeedAuth(item.children);
      }
      delete item.needAuth;
      delete item.role;
      return item;
    });
  };

  const menuItems = removeNeedAuth(authItems);

  return (
    <Menu
      className={styles.menu}
      onClick={onClick}
      selectedKeys={[current, openKey]}
      mode="horizontal"
      items={menuItems}
    />
  );
};

export default HeaderMenu;
