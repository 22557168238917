/**
 * 申明：这代码不是我写的！从老项目中，基于Class Component改造来的
 **/
import React, { useState, useCallback, useRef, useLayoutEffect } from 'react';
import { useModel } from 'umi';
import { Button } from 'antd';
import { useFullscreen } from 'ahooks';
import okrApi, { IOkrAlignMapItem, IOkrAlignMapParams } from '@/api/okr';
import dragDom from '@/utils/drag-dom';
import storage from '@/utils/storage';
import { COMMON_FILTERS_KEY } from '@/constants';
import { dataTrack } from '@/utils/track';
import { CyclesCascader } from '@/components';
import { SearchAll, QuickView, Basic } from './components';
import './index.less';

const AlignMap = () => {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const ref = useRef(null);
  const [isFullscreen, { toggleFullscreen }] = useFullscreen(ref);

  const localFilters = storage.get(COMMON_FILTERS_KEY, {});

  const [year, setYear] = useState(new Date().getFullYear());
  const [dateClass, setDateClass] = useState(localFilters.dateClass || '全部');
  const [name, setName] = useState('我');
  const [headerType, setHeaderType] = useState('人员');
  const [currentSelectedId, setCurrentSelectedId] = useState();
  const [cyclesType, setCyclesType] = useState<'user' | 'dept' | 'virDept'>('user');
  const [dateId, setDateId] = useState<number>();
  const [virDeptCode, setVirDeptCode] = useState();
  const [deptId, setDeptId] = useState();
  const [userId, setUserId] = useState();
  const [dataSource, setDataSource] = useState<IOkrAlignMapItem[]>([]);
  const [key, setKey] = useState();

  useLayoutEffect(() => {
    // 列表视图可拖拽
    dragDom(document.getElementById('drag-wrap'), {
      onDragEnd: () => {
        dataTrack('drag_okr_align_map');
      },
    });
  }, []);

  const BUTTON_TYPE_TO_PARAMS = {
    USER: {
      key: 'userId',
      name: '人员',
      setFunc: setUserId,
    },
    DEPT: {
      key: 'deptId',
      name: '部门',
      setFunc: setDeptId,
    },
    VIR_DEPT: {
      key: 'virDeptId',
      name: '虚拟',
      setFunc: setVirDeptCode,
    },
  };

  const getDataSource = async (params: IOkrAlignMapParams) => {
    setDataSource([]);
    const res = await okrApi.getOkrMap(params);
    setDataSource(res || []);
    //@ts-ignore
    dragDom?.clean?.({ left: 40, top: 38 });
  };

  const onSelect = (value: any, options: any) => {
    const { name, userId, deptId, virDeptCode } = options.props;
    getDataSource({
      userId,
      deptId,
      virDeptId: virDeptCode,
      dateId: dateId!,
    });
    setUserId(userId);
    setDeptId(deptId);
    setVirDeptCode(virDeptCode);
    setName(name);
    setHeaderType(virDeptCode ? '虚拟' : userId ? '人员' : deptId ? '部门' : '');
    setCurrentSelectedId(value);
    setCyclesType(userId ? 'user' : deptId ? 'dept' : 'virDept');
  };

  const onChangeCycle = (value: any, options: any) => {
    const [year, dateId] = value;
    const dateClass = options?.[1].dateClass;
    getDataSource({ userId, deptId, virDeptId: virDeptCode, dateId: dateId! });
    setYear(year);
    setDateClass(dateClass);
    setDateId(dateId);
  };

  const onClickQuickButton = useCallback(
    (item: any) => {
      const { buttonType, id, name } = item;
      // @ts-ignore
      const stateField = BUTTON_TYPE_TO_PARAMS[buttonType]?.key;
      // @ts-ignore
      const headerType = BUTTON_TYPE_TO_PARAMS[buttonType]?.name;
      // @ts-ignore
      const setFunc = BUTTON_TYPE_TO_PARAMS[buttonType]?.setFunc;
      getDataSource({
        dateId: dateId!,
        [stateField]: id,
      });
      setUserId(undefined);
      setDeptId(undefined);
      setVirDeptCode(undefined);
      setFunc(id);
      setKey(id);
      setName(name);
      setHeaderType(headerType);
      setCurrentSelectedId(undefined);
      setCyclesType(buttonType === 'USER' ? 'user' : buttonType === 'DEPT' ? 'dept' : 'virDept');
    },
    [dateId],
  );

  const onSelectUser = (value: any, options: any) => {
    // 从多个数据来源 传入的value可能类型不同，统一处理一下
    const userId = typeof value === 'object' ? value.userId : value;
    const user = typeof value === 'object' ? value.option.user : options.props.user;
    const name = user.name;
    getDataSource({ userId, dateId: dateId! });
    setUserId(userId);
    setName(name);
    setHeaderType('人员');
    setCurrentSelectedId(userId);
  };

  return (
    <div className="m-share-center-okr-relative" id="share-center-okr-relative-wrap" ref={ref}>
      <div className="org-okr-filter">
        <SearchAll
          key={key}
          style={{ position: 'relative', top: 1, marginRight: 16 }}
          onSelect={onSelect}
          getPopupContainer={() => document.getElementById('share-center-okr-relative-wrap')!}
        />
        <CyclesCascader
          value={[year, dateId]}
          type={cyclesType}
          userId={userId}
          deptId={cyclesType === 'dept' ? deptId : virDeptCode}
          onChange={onChangeCycle}
          currentCycles
        />
        <Button onClick={toggleFullscreen} className="c-dark-btn" style={{ float: 'right' }}>
          {isFullscreen ? '取消全屏' : '全屏'}
        </Button>
        <QuickView
          onClickItem={onClickQuickButton}
          userId={userInfo?.id!}
          currentSelectedId={currentSelectedId}
        />
      </div>
      <div
        className={`org-okr-body okr-relative-map ${isFullscreen && 'full-screen'}`}
        id="okr-relative-map"
      >
        <div id="drag-wrap" style={{ position: 'absolute', left: '40px', top: '38px' }}>
          <Basic
            dataSource={dataSource}
            name={name}
            headerType={headerType}
            onSelectUser={onSelectUser}
          />
        </div>
      </div>
    </div>
  );
};

export default AlignMap;
