import React from 'react';
import { history } from 'umi'
import './index.less'

const NotFind = () => (
  <div className="not-find">
    <p className="oops">oops! - Could Not Find it</p>
    <img src={require('@/assets/img/404.jpg')} alt="404" />
    <p className="back" onClick={history.goBack}>go back</p>
  </div>
);

export default NotFind;
