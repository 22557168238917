import React, { useEffect, useState } from 'react';
import { useModel } from 'umi';
import processApi, { IProcessHistoryItem } from '@/api/process';
import commonApi, { ICommentItem } from '@/api/common';
import { OkrTypeEnum } from '@/api/okr';
import { commentArray2Tree } from '@/utils/tools';
import { LikeCommentRecord } from '@/components';
import { IThumbUpVO } from '@/api/discover';
import { useStore } from '@/store';

interface IProps {
  id: number;
  likeInfo?: IThumbUpVO;
  modifyProcessVO?: (partialProcessVO: any) => void;
  onRefreshProcessInfo?: () => void;
}

function RecordWrapper(props: IProps) {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { likeInfo, id, onRefreshProcessInfo, modifyProcessVO } = props;

  const {
    currentProcessType,
    currentProcessUserId = 0,
    currentProcessDeptId,
    currentProcessDateId,
    currentProcessYear,
    currentProcessSourceType,
    currentProcessOkrDateId,
  } = useStore();

  const { isThumbUp, thumbUpCount, userList: likeUserList } = likeInfo || {};

  const [operations, setOperations] = useState<IProcessHistoryItem[]>([]);
  const [comments, setComments] = useState<ICommentItem[]>([]);

  const defaultFilters = {
    dateId: currentProcessDateId,
    year: currentProcessYear,
    processSourceType: currentProcessSourceType,
    type: currentProcessType,
    userId: currentProcessUserId,
    deptId: currentProcessDeptId,
    okrDateId: currentProcessOkrDateId,
  };

  const getOperations = () => {
    if (!currentProcessDateId || !currentProcessSourceType || !currentProcessOkrDateId) return;
    processApi
      //@ts-ignore
      .getProcessOperations(defaultFilters)
      .then((data) => {
        setOperations(data);
      })
      .catch(() => {
        setOperations([]);
      });
  };

  const getComments = () => {
    if (!id || !currentProcessSourceType) return;
    commonApi
      .getCommentList({ targetId: id, targetType: currentProcessSourceType })
      .then((data) => {
        setComments(commentArray2Tree(data));
      })
      .catch(() => {
        setComments([]);
      });
  };

  useEffect(() => {
    setComments([]);
    getComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentProcessSourceType]);

  useEffect(() => {
    setOperations([]);
    getOperations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentProcessDateId,
    currentProcessYear,
    currentProcessSourceType,
    currentProcessType,
    currentProcessUserId,
    currentProcessDeptId,
  ]);

  const onAddCommentSuccess = () => {
    if (comments?.length === 0) {
      onRefreshProcessInfo?.();
    }
    getComments();
  };

  const beforeSubmit = async () => {
    const processId = await processApi.createProcessWhenEmpty({
      processSourceType: currentProcessSourceType!,
      // @ts-ignore
      operateTarget: currentProcessSourceType,
      dateId: currentProcessDateId,
      targetId:
        currentProcessType === OkrTypeEnum.organization
          ? currentProcessDeptId
          : currentProcessUserId,
    });
    return processId;
  };

  const onLike = async () => {
    if (isThumbUp) return;
    const processId = await beforeSubmit();
    await commonApi.thumbUp({
      objId: processId,
      // @ts-ignore
      objType: currentProcessSourceType,
      belongUserId: currentProcessUserId,
    });
    modifyProcessVO?.({
      thumbUpCount: (thumbUpCount || 0) + 1,
      thumbUpEd: true,
    });
  };

  return (
    <LikeCommentRecord
      id={id}
      recordList={operations}
      commentList={comments}
      userInfo={userInfo!}
      beforeSubmit={beforeSubmit}
      onRefreshComment={onAddCommentSuccess}
      thumbUpCount={thumbUpCount || 0}
      isThumbUp={isThumbUp!}
      likeUserList={likeUserList}
      onLike={onLike}
      //@ts-ignore
      targetType={currentProcessSourceType}
    />
  );
}

export default RecordWrapper;
