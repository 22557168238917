/**
 * 编辑okr状态
 */

import React, { useState } from 'react';
import { Input, Modal, Tooltip } from 'antd';
import { useControllableValue } from 'ahooks';
import { O_KR_ENUM, OKR_STATUS } from '@/constants/okr';
import StatusSelect from '../select/status-select';
import './index.less';

const { TextArea } = Input;

interface IProps {
  authEdit?: boolean;
  onOk: ({ content, score }: any) => Promise<any>;
  onChange?: (value?: any) => void;
  type: O_KR_ENUM;
}

const OkrStatus = (props: IProps) => {
  const { authEdit = false, onOk: _onOk, type = O_KR_ENUM.KR } = props;

  const [value, setValue] = useControllableValue(props);
  const [status, setStatus] = useState();
  const [content, setContent] = useState();
  const [open, setOpen] = useState(false);

  const onContentChange = ({ target: { value } }: any) => {
    setContent(value);
  };

  const onOk = () => {
    return _onOk({ content, status }).then(() => {
      setValue(status);
      setOpen(false);
    });
  };

  // @ts-ignore
  const { color = '', label } = OKR_STATUS[value] || {};

  const showModal = () => {
    setStatus(undefined);
    setOpen(true);
  };

  return authEdit ? (
    <div>
      <Tooltip title="修改状态">
        <span className="m-okr-status m-is-edit" onClick={showModal}>
          {value ? (
            <span style={{ color }}>{'● ' + label}</span>
          ) : (
            <span className="m-empty">暂无</span>
          )}
        </span>
      </Tooltip>
      <Modal
        onOk={onOk}
        width={500}
        okButtonProps={{ disabled: !status }}
        onCancel={() => setOpen(false)}
        open={open}
        destroyOnClose
        maskClosable={false}
      >
        <div className="mb-4 flex items-center">
          {type}状态：
          <StatusSelect onChange={setStatus} />
        </div>
        <TextArea
          autoSize={{
            minRows: 6,
          }}
          maxLength={5000}
          onChange={onContentChange}
          placeholder="有哪些关键进展？是什么阻碍了它取得更大进步？我们需要做什么来改善OKR结果？"
        />
      </Modal>
    </div>
  ) : (
    <span className="m-okr-score">
      {value ? (
        <span style={{ color }}>{'● ' + label}</span>
      ) : (
        <span className="m-empty">暂无</span>
      )}
    </span>
  );
};

export default OkrStatus;
