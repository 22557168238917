import React from 'react';
import { RockEditorPlugin, PluginKey } from '@ehr-ui/bedrock-editor';
import { mentionSpec } from './mention-spec';
import createSelectToolbar from './mention-tool-bar';
import createMentionNodeView from './mention-node-view';
import './index.less';

interface IOption {
  editorId: string;
}

function createMentionPlugin(option: IOption) {
  const { editorId } = option || {};

  return new RockEditorPlugin({
    key: new PluginKey('mention'),
    nodeSpecs: {
      mention: mentionSpec,
    },
    nodeViews: {
      mention: createMentionNodeView(),
    },
    getToolbarConfig: (config) => {
      config[3].push(createSelectToolbar(editorId));
      return config;
    },
  });
}

export default createMentionPlugin;
