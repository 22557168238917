import { useRequest } from 'ahooks';
import okrApi, { PeriodTypeEnum } from '@/api/okr';

export interface IOption {
  userId?: number;
  year: number;
  periodType?: PeriodTypeEnum;
}

/**
 * OKR 周期筛选
 */
function useOkrPeriods(option: IOption) {
  const { userId, year, periodType = PeriodTypeEnum.okr } = option;

  // 获取 OKR 周期
  const { data: periodList } = useRequest(
    () =>
      okrApi.getPeriodUser({
        userId,
        periodType,
        year,
      }),
    {
      ready: !!userId,
      refreshDeps: [userId, year, periodType],
    },
  );

  // 获取 OKR 周期中对应数字
  const { data: statisticList } = useRequest(
    () =>
      okrApi.getPersonalOkrCount({
        userId: userId!,
        year,
      }),
    {
      ready: !!userId,
      refreshDeps: [year, userId],
    },
  );

  return {
    periodList: periodList || [],
    statisticList,
  };
}

export default useOkrPeriods;
