import React, { useState, useMemo, useRef, forwardRef, useImperativeHandle } from 'react';
import {
  Editor,
  createTodoListPlugin,
  createAppendixPlugin,
  convertToHTML,
} from '@ehr-ui/bedrock-editor';
import type {
  EditorState,
  EditorConfig,
  RichTextEditorProps,
  RockEditorView,
} from '@ehr-ui/bedrock-editor';
import { generateUUID } from '@/utils/tools';
import nosUpload from '@/components/upload/nos-upload';
import createMentionPlugin from './plugin-mention';
import './index.less';

export type EditorRef = {
  getEditorHtml: () => string;
  getEditorState: () => EditorState;
  getEditorView: () => RockEditorView;
};

interface IProps extends RichTextEditorProps {
  id?: string;
  onChange?: (value: EditorState) => void;
}

const BedrockEditor = (props: IProps, ref: any) => {
  const { id, onChange, ...rest } = props;

  const [editorState, setEditorState] = useState<EditorState>();

  const editorRef = useRef(null);

  const editorId = useMemo(() => id || generateUUID(), [id]);

  useImperativeHandle(ref, () => ({
    getEditorHtml: () => convertToHTML(editorState!),
    getEditorState: () => editorState,
    // @ts-ignore
    getEditorView: () => editorRef?.current?.editorRef?.getEditorView?.(),
  }));

  const onEditorChange = (value: EditorState) => {
    setEditorState(value);
    onChange?.(value);
  };

  // @ts-ignore
  const uploadFile: EditorConfig['uploadImage'] = async (file, { onProgress }: any) => {
    if (!file) return Promise.reject('no file');
    const onProgressWrap = (percent: number) => {
      let progress = 0;
      const timer = setInterval(() => {
        progress += 1;
        // 更新进度条
        onProgress(progress);
        if (progress >= 90) {
          clearInterval(timer);
        }
      }, 100);
    };
    const src = await nosUpload({
      file,
      onProgress: onProgressWrap,
    });
    return {
      src,
    };
  };

  const editorConfig: EditorConfig = useMemo(
    () => ({
      uploadImage: uploadFile,
      getPlugins: (ps) => {
        return [
          ...ps,
          createTodoListPlugin({ insertAfter: 'UnorderedList' }),
          createMentionPlugin({ editorId }),
          // @ts-ignore
          createAppendixPlugin({ uploadAppendix: uploadFile }),
        ].filter((item: any) => !['Emoji$', 'popo_link$'].includes(item.key));
      },
    }),
    [],
  );

  return (
    <Editor
      id={editorId}
      ref={editorRef}
      className="m-okr-editor"
      editorState={editorState}
      onChange={onEditorChange}
      config={editorConfig}
      style={{
        // @ts-ignore
        '--r-editor-content-font-size': '14px',
        '--r-editor-content-min-height': '120px',
        '--r-editor-content-color': '#333',
        '--text-color-1': '#333',
        '--primary-1': '#1890ff',
        '--br60': '#1890ff',
      }}
      {...rest}
    />
  );
};

export default forwardRef(BedrockEditor);
