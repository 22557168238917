import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import { useToggle } from 'ahooks';
import cls from 'classnames';
import { Spin, Tooltip, Typography } from 'antd';
import { Collapse } from 'react-collapse';
import { IconFont, Avatar } from '@/components';
import { IUserInfo } from '@/api/auth';
import { dataTrack } from '@/utils/track';
import useMembers, { MEMBER_TYPES } from './use-members';
import './index.less';

interface IProps {
  defaultUserId?: number;
  style?: React.CSSProperties;
  onSelect: (user: IUserInfo) => void;
  userInfo: IUserInfo;
  isProcess?: boolean;
}

// okr-个人okr左侧 成员选择组件
const SideMemberSelect = (props: IProps, ref: any) => {
  const { defaultUserId, style = {}, onSelect, userInfo, isProcess } = props;
  const {
    loading,
    selectedUser,
    isTopLevel,
    unfoldUser,
    topShowList,
    members,
    setSelectedUser,
    onOpen,
    onBack,
    onNext,
    getMemberList,
    refreshAttentionList,
  } = useMembers(defaultUserId!);

  useImperativeHandle(ref, () => ({
    refreshAttentionList: () => {
      refreshAttentionList(userInfo);
    },
  }));

  useEffect(() => {
    getMemberList(userInfo, false, (user: IUserInfo) => {
      onSelect?.(user);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectUser = (user: IUserInfo) => {
    setSelectedUser(user);
    onSelect?.(user);
  };

  const renderList = (
    { list, title, openKey, eventParam }: any,
    currentKey: string,
    loadingContent = false,
  ) => {
    const isOpen = openKey === currentKey;
    const isFollow = openKey === MEMBER_TYPES.follow;
    const showEmpty = isFollow && (!list || list.length <= 0);

    const onClickCollapse = () => {
      onOpen(openKey);

      dataTrack(isProcess ? 'click_process_side_menu' : 'click_okr_side_menu', {
        type: eventParam,
      });
    };
    return (
      <div className="process-member-list" key={openKey}>
        {title && (
          <div className="process-member-list-title" onClick={onClickCollapse}>
            {title}
            <IconFont
              type="icon-unie6612"
              style={{ transform: `rotate(${isOpen ? '0' : '-90deg'})`, transition: 'all 0.3s' }}
            />
          </div>
        )}
        <Collapse isOpened={isOpen}>
          <Spin spinning={loadingContent}>
            <div className="process-member-list-content">
              {isFollow && (
                <Typography.Link
                  href="#/okr?tab=share"
                  target="_blank"
                  className="btn-follow-manage"
                >
                  管理我关注的同事
                </Typography.Link>
              )}
              {list?.map?.((item: IUserInfo) => {
                const { id, name, photoUrl, hasUndered } = item;
                const isSelected = selectedUser.id === id;
                const showSub =
                  hasUndered &&
                  (currentKey === MEMBER_TYPES.subMore || currentKey === MEMBER_TYPES.sub);
                return (
                  <div
                    className={cls('process-member-list-user', {
                      'process-member-list-user-selected': isSelected,
                    })}
                    onClick={() => onSelectUser(item)}
                    key={id}
                  >
                    <Avatar
                      imgPath={photoUrl}
                      userName={name}
                      className={
                        isSelected ? 'ml-user-avatar' : 'ml-user-avatar ml-user-avatar__selected'
                      }
                    />
                    <div className="ml-user-name" title={name}>
                      {name}
                    </div>
                    {showSub && (
                      <Tooltip title="点击查看ta的下属">
                        <IconFont
                          className="ml-user-sub"
                          type="icon-xiashudikuai"
                          onClick={(e) => {
                            e.stopPropagation();
                            onNext(item);
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                );
              })}
              {showEmpty && (
                <div className="member-select-empty">
                  <IconFont type="icon-1px-copy" className="empty-icon" />
                  <div className="member-select-empty__text">暂未关注任何同事</div>
                </div>
              )}
            </div>
          </Spin>
        </Collapse>
      </div>
    );
  };

  const { id, name, photoUrl, corpMail } = userInfo || {};

  const [state, { toggle }] = useToggle(false);

  const onClickTrigger = () => {
    toggle();
  };

  return (
    <>
      <div
        className="process-member-select-wrap"
        style={{ ...style, marginLeft: state ? '-208px' : '0' }}
      >
        <div className="process-member-select">
          {isTopLevel ? (
            <>
              {/*我的OKR*/}
              {name && (
                <div className="process-member-list">
                  <div className="process-member-list-title">我的OKR</div>
                  <div
                    className={cls('process-member-list-user', {
                      'process-member-list-user-selected': selectedUser.id === id,
                    })}
                    onClick={() => onSelectUser(userInfo || {})}
                  >
                    <Avatar
                      imgPath={photoUrl}
                      userName={name}
                      className={
                        selectedUser.id === id
                          ? 'ml-user-avatar'
                          : 'ml-user-avatar ml-user-avatar__selected'
                      }
                    />
                    <div className="ml-user-name" title={name}>
                      {name}
                    </div>
                  </div>
                </div>
              )}
              {topShowList.map((item: any) => {
                const { openKey, list } = item;
                const isFollow = openKey === MEMBER_TYPES.follow;
                if (!isFollow && (!list || list.length <= 0)) return null;
                return renderList(item, members.openKey);
              })}
            </>
          ) : (
            <>
              <div className="back-up-one-level" onClick={() => onBack(false)}>
                ← 返回到上一层
              </div>
              <div className="back-top-level" onClick={() => onBack(true)}>
                ← 返回到顶层
              </div>
              {renderList(
                {
                  list: unfoldUser.children || [],
                  title: `${unfoldUser.name}的下属`,
                  openKey: MEMBER_TYPES.subMore,
                },
                MEMBER_TYPES.subMore,
                loading,
              )}
            </>
          )}
          {state ? (
            <IconFont
              className="m-member-select-trigger"
              type="icon-shouqi"
              onClick={onClickTrigger}
            />
          ) : (
            <IconFont
              className="m-member-select-trigger"
              type="icon-moren"
              onClick={onClickTrigger}
            />
          )}
        </div>
      </div>
      <div
        className="process-member-select-placeholder"
        style={{ marginLeft: state ? '-208px' : '0' }}
      />
    </>
  );
};

export default forwardRef(SideMemberSelect);
