import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useModel } from 'umi';
import { useEventEmitter } from 'ahooks';
import { useStore } from '@/store';
import processApi, {
  IProcessDetail,
  IProcessOkrItem,
  IProcessOkrQuery,
  IProcessVO,
  ProcessSourceTypeEnum,
} from '@/api/process';
import { OkrTypeEnum } from '@/api/okr';
import { WeeklyOtherContent, RecordWrapper, Empty, PdLayoutHeader, DashboardOkr } from '..';
import {
  checkShouldRequest,
  getProcessApi,
  addProcessInfo2OkrList,
  transformProcessList2Map,
} from './utils';
import './index.less';

interface IProps {
  userId?: number;
  deptId?: string;
  year?: number;
  type: OkrTypeEnum;
  dateId: number;
  processSource: ProcessSourceTypeEnum;
  okrDateId?: number;
}

// OKR 进展看板
function ProcessDashboardDisplay(props: IProps) {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { isEmptyKrProcessHide } = useStore();

  const { userId, deptId, year, type, dateId, processSource, okrDateId } = props;

  const isSelf = userInfo?.id === +userId!;

  // 一键发布事件
  const releaseAll$ = useEventEmitter();
  // 缓存正在编辑的进展项
  const cacheEditingProcess = useRef({});

  const [loading, setLoading] = useState(true);
  // OKR 列表
  const [okrList, setOkrList] = useState<IProcessOkrItem[]>();
  // OKR 进展列表
  const [processVO, setProcessVO] = useState<IProcessDetail>();
  // OKR 进展列表Map
  const [processListMap, setProcessListMap] = useState<{ [key in string]: IProcessVO }>({});

  const filters: IProcessOkrQuery = useMemo(() => {
    const p = {
      year,
      okrDateId,
    };
    if (type === OkrTypeEnum.organization) {
      Object.assign(p, {
        deptId,
      });
    } else {
      Object.assign(p, {
        userId,
      });
    }
    return p;
  }, [type, year, deptId, userId]);

  // 获取OKR列表
  useEffect(() => {
    if (!checkShouldRequest(filters, type)) return;
    setLoading(true);
    const fetchApi =
      type === OkrTypeEnum.organization ? processApi.getGroupOkrList : processApi.getOkrList;
    fetchApi(filters)
      .then((data) => {
        setOkrList(data);
      })
      .finally(() => setLoading(false));
  }, [type, filters]);

  const getProcessVO = () => {
    if (!checkShouldRequest(filters, type)) return;
    if (!dateId || !processSource) return;
    const fetchApi = getProcessApi(processSource, type);
    if (!fetchApi) return;
    //@ts-ignore
    return fetchApi({ ...filters, dateId }).then((data) => {
      cacheEditingProcess.current = {};
      setProcessVO(data);
      setProcessListMap(transformProcessList2Map(data?.processVOList, data?.processOther));
      return data?.processVOList;
    });
  };

  const modifyProcessVO = useCallback(
    (partialProcessVO: IProcessDetail) => {
      setProcessVO({ ...processVO, ...partialProcessVO });
    },
    [processVO],
  );

  const modifyProcessListMap = (partialProcessListMap: { [key in string]: IProcessVO }) => {
    setProcessListMap({ ...processListMap, ...partialProcessListMap });
  };

  // 获取对应进展列表
  useEffect(() => {
    if (!type || !processSource || !dateId) return;
    getProcessVO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, processSource, filters, dateId]);

  const okrListWithProcess = useMemo(
    () =>
      addProcessInfo2OkrList(okrList || [], processListMap, isSelf ? false : isEmptyKrProcessHide),
    [okrList, processListMap, isEmptyKrProcessHide, isSelf],
  );

  const noOkr = okrListWithProcess.length === 0 && !processVO?.processOther;

  return (
    <div className="process-dashboard">
      <div className="process-dashboard-main">
        {(!loading && noOkr) || !processSource ? (
          <Empty style={{ margin: '0 auto' }} />
        ) : (
          <div className="process-dashboard-wraps">
            <div className="process-dashboard-content">
              <div className="pd-layout">
                <PdLayoutHeader processSource={processSource} />
                {okrListWithProcess?.map((item) => {
                  const { objectivesId } = item;
                  return (
                    <DashboardOkr
                      cacheEditingProcess={cacheEditingProcess}
                      releaseAll$={releaseAll$}
                      key={objectivesId}
                      readonly
                      info={item}
                      modifyProcessListMap={modifyProcessListMap}
                      getProcessVO={getProcessVO}
                    />
                  );
                })}
              </div>
            </div>

            {/* 组织OKR 不展示"其他"模块 */}
            {!noOkr &&
              processSource === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT &&
              type === OkrTypeEnum.personal && (
                <WeeklyOtherContent
                  cacheEditingProcess={cacheEditingProcess}
                  releaseAll$={releaseAll$}
                  readonly
                  auth={processVO?.otherContentAuth!}
                  processOther={processListMap?.processOther}
                  modifyProcessListMap={modifyProcessListMap}
                  getProcessVO={getProcessVO}
                />
              )}
          </div>
        )}
      </div>

      {/* 无任何进展发布时，不可进行点赞和评论（置灰或者不显示） */}
      {!noOkr && (
        <RecordWrapper
          id={processVO?.id!}
          likeInfo={processVO?.thumbUpVO}
          onRefreshProcessInfo={getProcessVO}
          modifyProcessVO={modifyProcessVO}
        />
      )}
    </div>
  );
}

export default ProcessDashboardDisplay;
