import React, { memo, useRef, useLayoutEffect } from 'react';
import ReactDom from 'react-dom';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { FileLink } from '@netease-ehr/ui';
import { EditorDisplay } from '@ehr-ui/bedrock-editor';
import { initUserLInk } from '@/utils/tools';
import './index.less';

interface IProps {
  className?: any;
  style?: React.CSSProperties;
  content: string;
  updateTime: string;
}

// 富文本只读组件
function ReadonlyRichText(props: IProps) {
  const { className, style, content, updateTime } = props;
  const displayContent = initUserLInk(content);

  const doc = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    //@ts-ignore
    const domElement = doc.current?.element;

    if (!domElement) return;

    const mentionLinks = domElement.querySelectorAll('.okr-mention');
    const appendixList = domElement.querySelectorAll('.appendix-node');

    mentionLinks.forEach((item: any) => {
      const id = item.getAttribute('data-id');
      item.addEventListener('click', () => {
        window.open(`${location.origin}/#/team/${id}?tab=okr`);
      });
    });
    appendixList.forEach((item: any) => {
      const title = item.getAttribute('title');
      const src = item.getAttribute('src');
      const $dom = document.createElement('div');
      ReactDom.render(<FileLink name={title!} url={src!} canDownload />, $dom);
      item.parentNode?.replaceChild($dom, item);
    });
  }, [displayContent, doc]);

  // todo 临时方案，为了适配以前的编辑器写的样式
  const isOldEditor = dayjs(updateTime).isBefore('2023-09-18');

  return (
    <EditorDisplay
      ref={doc}
      content={displayContent}
      style={{
        ...(style || {}),
        // @ts-ignore
        '--text-color-1': '#333',
      }}
      className={classnames('readonly-rich-text', className, {
        adapter_old_editor: isOldEditor,
        'rock-editor__renderer': !isOldEditor,
      })}
    />
  );
}

export default memo(ReadonlyRichText);
