import { ProcessSourceTypeEnum } from '@/api/process';

export enum AuthShareType {
  OKR = 'OKR',
  PROCESS_WEEK_REPORT = 'PROCESS_WEEK_REPORT', // 周报
  PROCESS_CONCLUSION = 'PROCESS_CONCLUSION', // 小结
  PROCESS_SUMMARY = 'PROCESS_SUMMARY', // 总结
}

export interface ISourceAuthParams {
  authType: AuthShareType | ProcessSourceTypeEnum; // 资源类型
  id?: number; // 资源ID（通过资源Id获取默认共享范围）
  userId?: number; // 人员Id（通过人员Id获取默认共享范围）
}

// 共享范围部门
export interface IAuthDeptVO {
  deptId: string;
  deptName: string;
  defaultAuth: boolean; // 是否默认共享
  level: string;
  isRealDept: boolean; // 是否实体部门
}

export interface IUserRoleItem {
  id: number;
  name: string;
  nameCn: string;
}

export interface IUserInfo {
  id: number;
  userId: number;
  userName: string;
  uNo: string; // 工号
  name: string;
  corpMail: string;
  defaultDeptId: string;
  deptId: string;
  deptName: string;
  deptNameStr: string;
  deptPath: string;
  hasLeader: boolean; // 是否有上级
  hasPartner: boolean; // 是否有同级
  hasUndered: boolean; // 是否有下属
  hasVO: boolean; // 是否有虚拟组织的管理权限
  ifAttentioned: boolean; // 是否为关注
  isGrayscaleUser: boolean; // 是否灰度用户
  photoUrl: string;
  superAdmin: boolean; // 是否超级管理员
  thumbUpCount: number; // 被点赞数
  userRoleList: IUserRoleItem[];
  fansCount: number; // 粉丝数
  seeReport: boolean; // 是否有查看报表的权限
}

export interface IDeptItem {
  default: boolean;
  hasChildren: boolean;
  childList: IDeptItem[];
  children?: IDeptItem[];
  deptId: string;
  deptName: string;
  nameEn: string;
  parentId?: string;
  level: number;
  deptLeaderUNo: string;
  deptPath: string;
}

export interface IUserTree {
  leaderList: IUserInfo[]; // 我的主管和间接主管
  attentionList: IUserInfo[]; // 我的关注
  partnerList: IUserInfo[]; // 我的同级
  underedList: IUserInfo[]; // 我的下属
}

// 修改OKR共享范围
export interface IAuthShareUpdatePayload {
  authRelId: number; // OKR的主键
  sourceType: AuthShareType; // 分享类型
  deptIdList: string[]; // 部门id列表
  virDeptIdList: string[]; // 虚拟部门id列表
  userIdList: number[]; // 用户id列表
}

export interface IUserPermVO {
  bountyHunterPerm: boolean; // 用户姓名
}
