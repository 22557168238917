import React, { memo } from 'react';
import { Menu, Dropdown } from 'antd';

interface IProps {
  tag: any;
  children: any;
}

const Actions = (props: IProps) => {
  const { tag, children } = props;
  const menu = (
    <Menu>
      {React.Children.map(
        children?.filter((item: any) => item),
        (item, index) => (
          <Menu.Item key={index}>{item}</Menu.Item>
        ),
      )}
    </Menu>
  );

  return <Dropdown overlay={menu}>{tag}</Dropdown>;
};

export default memo(Actions);
