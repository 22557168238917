import { GATEWAY } from '@/api/config';
import { IDiscoverItem } from '@/api/discover';
import api from '..';
import { IMessageItem, IMessageParams } from './interface';
import {
  ResponsePromise,
  PageRequestParams,
  ResponseListPromise,
  ResponsePagePromise,
} from '../interface';

export default {
  // 消息中心-新消息
  getNewMessage: (): ResponsePagePromise<IMessageItem> =>
    api.get(`${GATEWAY}/message/list`, { currentPage: 1, pageSize: 5, messageReadFlag: false }),
  // 已读消息 有ID该条消息已读；未传全部已读
  readMessage: (idList?: number[]) => api.post(`${GATEWAY}/message/read`, { idList }),
  getAllMessage: (params: IMessageParams): ResponsePagePromise<IMessageItem> =>
    api.get(`${GATEWAY}/message/list`, params),
  // 消息详情
  getMessageDetail: (data: any): ResponsePromise<IDiscoverItem> =>
    api.get(`${GATEWAY}/message/detail`, data),
};

export * from './interface';
