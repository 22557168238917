import React, { useState, useEffect, memo } from 'react';
import { App } from 'antd';
import { LikeFilled } from '@ant-design/icons';
import { AnimateUp } from '@/components';
import { ProcessSourceOperateTypeEnum } from '@/api/process';
import commonApi from '@/api/common';
import './index.less';

interface IProps {
  isLiked?: boolean;
  count: number;
  operateTarget: ProcessSourceOperateTypeEnum;
  belongUserId: number;
  id?: number;
  beforeSubmit?: () => Promise<number>;
}

function Like(props: IProps) {
  const { isLiked, count, belongUserId, operateTarget, id, beforeSubmit } = props;

  const { message } = App.useApp();

  const [displayCount, setCount] = useState(count || 0);
  const [displayActive, setActive] = useState(isLiked);
  const [showAnimateUp, setShowAnimateUp] = useState(false);

  useEffect(() => {
    setActive(isLiked);
  }, [isLiked]);

  useEffect(() => {
    setCount(count || 0);
  }, [count]);

  const onClick = async () => {
    try {
      if (displayActive) return;
      let objId = id;
      if (!id) {
        const processId = (await beforeSubmit?.()) as number;
        objId = processId;
      }

      await commonApi.thumbUp({
        objId: objId!,
        // @ts-ignore
        objType: operateTarget,
        belongUserId,
      });
      setCount(displayCount + 1);
      setActive(true);
      setShowAnimateUp(true);
      setTimeout(() => {
        setShowAnimateUp(false);
      }, 500);
    } catch (e: any) {
      message.error(e?.msg);
    }
  };

  return (
    <span className={`other-content_action ${displayActive ? 'active' : ''}`} onClick={onClick}>
      <LikeFilled className="action-icon" />
      <AnimateUp show={showAnimateUp}>{displayCount || ''}</AnimateUp>
    </span>
  );
}

export default memo(Like);
