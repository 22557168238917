import api from '@/api';
import { GATEWAY } from '@/api/config';
import { IDeptPeriodConfigItem, IDeptTreeItem } from '@/api/common';
import { ResponsePagePromise, ResponsePromise } from '../interface';
import {
  IDeptShareItem,
  AuthSourceTypeEnum,
  IUpdatePeriodPayload,
  IReportItem,
  IQueryReportDataParams,
  IReportDataItem,
} from './interface';

export default {
  // 设置-共享设置/周期设置-部门树 带权限
  getDeptTree: (parentId?: string): ResponsePromise<IDeptTreeItem[]> =>
    api.get(`${GATEWAY}/admin/deptTree`, { parentId }),
  // 部门默认共享设置-部门列表
  getDefaultDeptList: (id: string): ResponsePromise<IDeptShareItem[]> =>
    api.get(`${GATEWAY}/dept/default/list`, { id }),
  // 设置-共享设置-虚拟部门树
  getVirDeptTree: (parentId?: string): ResponsePromise<IDeptTreeItem[]> =>
    api.get(`${GATEWAY}/admin/virDeptTree`, { parentId }),
  // 部门默认共享设置-虚拟组织列表
  getDefaultVirDeptList: (id: string): ResponsePromise<IDeptShareItem[]> =>
    api.get(`${GATEWAY}/dept/default/virDept/list`, { id }),
  // 部门默认共享设置-更新-部门
  updateDefaultDept: (payload: { authType: AuthSourceTypeEnum; authLevel: number; id: string }) =>
    api.put(`${GATEWAY}/dept/default/update`, payload),
  // 部门默认共享设置-更新-虚拟部门
  updateDefaultVirDept: (payload: {
    authType: AuthSourceTypeEnum;
    authLevel: number;
    id: string;
  }) => api.put(`${GATEWAY}/dept/default/virDept/update`, payload),

  // 周期设置-获取部门周期配置
  getDeptPeriodList: (deptId: string): ResponsePromise<IDeptPeriodConfigItem[]> =>
    api.get(`${GATEWAY}/config/dept/period/list`, { deptId }),
  // 周期设置-更新部门周期配置
  updateDeptPeriod: (payload: IUpdatePeriodPayload) =>
    api.post(`${GATEWAY}/config/dept/period`, payload),

  // 获取看板数据
  getViewPannelBI: (): ResponsePromise<string> => api.get(`${GATEWAY}/youdata/okr/kanban`),

  // 报表
  // 获取报表列表
  getReportList: (): ResponsePromise<IReportItem[]> => api.get(`${GATEWAY}/dataExport/list`),
  // 查询报表数据（报表详情）
  getReportData: (params: IQueryReportDataParams): ResponsePagePromise<IReportDataItem> =>
    api.post(`${GATEWAY}/dataExport/getData`, params),
  // 导出报表 获取任务进度id
  getTaskId: (params: IQueryReportDataParams): ResponsePromise<string> =>
    api.post(`${GATEWAY}/dataExport/newExport`, params),
  // 获取导出的任务进度
  getTaskProgress: (params: any) => api.get(`${GATEWAY}/export/progress`, params),
};

export * from './interface';
