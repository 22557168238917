import React, { useState } from 'react';
import { Tree, Modal } from 'antd';
import type { TreeProps } from 'antd';
import type { DataNode } from 'antd/es/tree';
import { useRequest } from 'ahooks';
import api from '@/api/setting';
import { useTreeData } from '@/hooks';

interface IProps {
  open: boolean;
  deptTreeHidden: () => void;
  deptTreeChange: (checked: any) => void;
  isVir?: boolean;
}

function isNumeric(str: string) {
  return /^\d+$/.test(str);
}

const DeptTreeModal = (props: IProps) => {
  const { open, deptTreeHidden, deptTreeChange, isVir } = props;

  const [checked, setChecked] = useState<DataNode[]>([]);

  const { treeData, onLoadData } = useTreeData(isVir ? api.getVirDeptTree : api.getDeptTree, {
    getPayload: (params: any) => params?.id,
    // @ts-ignore
    listToNodes: (deptList) => {
      return deptList.map((item) => {
        const { id, deptName, hasChildren, deptId } = item;
        const isVirDept = isNumeric(id);
        return {
          ...item,
          key: id,
          value: id,
          title: deptName,
          isLeaf: !hasChildren,
          disabled: isVir && !isVirDept, // Bug-132929: 【V0.12】共享范围和所属部门选择虚拟组织时都应该不能选择虚拟组织的一级部门（实体部门） https://overmind-project.netease.com/v2/my_workbench/bugdetail/Bug-132929
        };
      });
    },
  });

  const { data: virList } = useRequest(() => api.getVirDeptTree(), {
    ready: open && isVir,
  });

  const ok = () => {
    deptTreeChange(checked);
    deptTreeHidden();
  };

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    setChecked(info.checkedNodes);
  };

  return (
    <Modal
      width={400}
      title={isVir ? '虚拟组织' : '选择部门'}
      open={open}
      onOk={ok}
      onCancel={deptTreeHidden}
      maskClosable={false}
      destroyOnClose
    >
      {open && (
        <div style={{ height: '410px', overflowY: 'scroll' }}>
          <Tree
            checkable
            checkStrictly
            onCheck={onCheck}
            selectable={false}
            treeData={treeData}
            loadData={onLoadData}
          />
        </div>
      )}
    </Modal>
  );
};

export default DeptTreeModal;
