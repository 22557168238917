import React, { memo } from 'react';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { O_KR_ENUM, PROCESS_TYPE_TEXT_MAP } from '@/constants';
import './index.less';
import { OkrProcessTypeEnum } from '@/api/okr';

export const STATUS_SCORE_CODE_MAP = {
  [OkrProcessTypeEnum.STATUS]: 1,
  [OkrProcessTypeEnum.SCORE]: 2,
  [OkrProcessTypeEnum.OBJECTIVES_STATUS]: 1,
  [OkrProcessTypeEnum.OBJECTIVES_SCORE]: 2,
  [OkrProcessTypeEnum.OBJECTIVES_GROUP_STATUS]: 1,
  [OkrProcessTypeEnum.OBJECTIVES_GROUP_SCORE]: 2,
};

const SUMMARY_SCORE_TIP = {
  1: (
    <div>
      <p>红灯：进展较少，遇到很大障碍，完成有很大风险</p>
      <p>黄灯：进展一般，遇到一些障碍，正在寻找解决方案，完成有一定风险</p>
      <p>绿灯：进展良好，或者目前没有到交付时间点，但主观判断会顺利完成</p>
    </div>
  ),
  2: (
    <div>
      <p>0分：无任何进展</p>
      <p>1-3分：没有完成任务</p>
      <p>4-6分：有难度的任务努力后部分达成</p>
      <p>7-9分：有难度的任务完成了或完成得不错</p>
      <p>10分：完成且远超预期</p>
    </div>
  ),
};

interface IProps {
  type: OkrProcessTypeEnum;
  oOrKrType?: O_KR_ENUM;
}

const KRSummaryLabel = ({ type, oOrKrType }: IProps) => {
  let displayType = type;
  if (oOrKrType === O_KR_ENUM.O) {
    if (type === OkrProcessTypeEnum.STATUS) {
      displayType = OkrProcessTypeEnum.OBJECTIVES_STATUS;
    }
    if (type === OkrProcessTypeEnum.SCORE) {
      displayType = OkrProcessTypeEnum.OBJECTIVES_SCORE;
    }
  }
  return (
    <span className="kr-summary-label">
      {/* @ts-ignore */}
      {PROCESS_TYPE_TEXT_MAP[displayType]}
      {/* @ts-ignore */}
      <Popover content={SUMMARY_SCORE_TIP[STATUS_SCORE_CODE_MAP[type]]} placement="topLeft" arrow>
        <QuestionCircleOutlined className="summary-label-icon m-l-4" />
      </Popover>
    </span>
  );
};

export default memo(KRSummaryLabel);
