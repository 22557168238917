import React, { useState, useEffect, useRef, memo } from 'react';
import { Select, Tooltip } from 'antd';
import { WarningOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { getCurrentPeriod } from '@/utils/cycles';
import { YEAR_LIST } from '@/constants/okr';
import { IPeriodItem, IOkrStatisticsItem } from '@/api/okr';
import './index.less';

const MAX_COUNT = 4;

interface IProps {
  maxCount?: number;
  periodList?: IPeriodItem[];
  statisticsList?: IOkrStatisticsItem[];
  year: number;
  periodId: number;
  onYearChange: (year: number) => void;
  onPeriodChange: (periodId: number) => void;
}

const OkrCycles = (props: IProps) => {
  const { maxCount = MAX_COUNT, periodList, statisticsList = [] } = props;
  const isFirst = useRef(false);

  const [year, setYear] = useControllableValue(props, {
    valuePropName: 'year',
    trigger: 'onYearChange',
  });
  const [periodId, setPeriodId] = useControllableValue(props, {
    valuePropName: 'periodId',
    trigger: 'onPeriodChange',
  });

  const [page, setPage] = useState(1);
  const isFirstPage = page === 1;
  const isLastPage = page >= Math.ceil((periodList?.length || 0) / maxCount);

  const onClickItem = (id: number) => {
    setPeriodId(id);
  };

  useEffect(() => {
    // console.log('periodList', periodList);
    if (!periodList?.length) return;
    const curIndex = periodList?.findIndex((i) => i.id == periodId);
    // console.log('curIndex', curIndex, periodId);
    // 无周期id自动获取当前时间对应周期
    if (!periodId || curIndex === -1) {
      const [curId, page] = getCurrentPeriod(periodList || [], maxCount);
      setPeriodId(curId);
      setPage(page);
    } else {
      // 有周期定位page页数信息
      setPage(curIndex === -1 ? 1 : Math.ceil((curIndex + 1) / maxCount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodList, periodId]);

  const onSelect = (value: number) => {
    setYear(value);
  };

  const onClickPage = (type: string) => {
    if (type === 'pre') {
      return !isFirstPage && setPage((pre) => --pre);
    }
    !isLastPage && setPage((pre) => ++pre);
  };

  const CyclesItem = ({ item }: { item: IPeriodItem }) => {
    const count = statisticsList?.find((i) => i.dateId === item.id)?.count;

    return (
      <div
        className={`m-okr-range-opt m-cycles-btn ${periodId == item.id ? 'm-cycles-active' : ''}`}
      >
        <span className="m-cycles-text">{item.period}</span>
        {!!count && <span className="m-cycles-num">{count}</span>}
        {!item.deptFlag && <WarningOutlined className="m-cycles-icon" type="warning" />}
      </div>
    );
  };

  return (
    <div className="m-okr-cycles">
      <div className="m-okr-cycle-text">OKR周期：</div>
      <Select value={year} onSelect={onSelect} bordered={false} options={YEAR_LIST} />
      <div className="m-okr-cycles-range">
        <div
          className={`m-okr-range-opt ${isFirstPage ? 'm-okr-range-opt-disabled' : ''}`}
          onClick={() => {
            onClickPage('pre');
          }}
        >
          <LeftOutlined />
        </div>
        {periodList?.slice(maxCount * (page - 1), page * maxCount)?.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() => {
                onClickItem(item.id);
              }}
            >
              {!item.deptFlag ? (
                <Tooltip
                  placement="topRight"
                  title={
                    <div>
                      管理员已为部门设置新的OKR周期， 建议将该周期的OKR调整至管理员新设置的OKR周期内
                    </div>
                  }
                >
                  <div>
                    <CyclesItem item={item} />
                  </div>
                </Tooltip>
              ) : (
                <CyclesItem item={item} />
              )}
            </div>
          );
        })}
        <div
          className={`m-okr-range-opt ${isLastPage ? 'm-okr-range-opt-disabled' : ''}`}
          onClick={() => {
            onClickPage('next');
          }}
        >
          <RightOutlined />
        </div>
      </div>
    </div>
  );
};

export default memo(OkrCycles);
