import React, { useState, useMemo, useRef } from 'react';
import { Select, Tooltip, Spin, Empty } from 'antd';
import type { SelectProps } from 'antd';
import debounce from 'lodash/debounce';
import api from '@/api/common';

const { Option } = Select;

export interface ISearchUserProps extends SelectProps {
  multiple?: boolean;
  users?: any;
  getSearchList?: any;
  defaultUserList?: any;
  selectUser?: any;
  deselectUser?: any;
  style?: any;
  className?: any;
}

const SearchUser = (props: ISearchUserProps) => {
  const {
    multiple,
    disabled,
    defaultUserList,
    getSearchList,
    selectUser,
    deselectUser,
    className,
    users,
    ...rest
  } = props;

  const [searchList, setSearchList] = useState(defaultUserList || []);
  const [fetching, setFetching] = useState(false);

  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (!value) return;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setSearchList([]);
      setFetching(true);

      api
        .searchUser({ searchTerm: value })
        .then((res) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          const list = res.dataList?.map((item: any) => ({
            ...item,
            userId: item.id,
            value: item.name,
            text: item.name,
          }));
          setSearchList(list || []);
        })
        .finally(() => {
          setFetching(false);
        });
    };

    return debounce(loadOptions, 500);
  }, []);

  const handleSelectUser = (value: any, option: any) => {
    selectUser?.(option.key, option);
  };
  const handleDeselects = (value: any, option: any) => {
    deselectUser?.(option.key, option);
  };

  return (
    <div className={className}>
      <Select
        mode={multiple ? 'multiple' : undefined}
        disabled={disabled}
        showSearch={!disabled}
        defaultValue={
          defaultUserList?.length
            ? defaultUserList?.map((item: any) => item.userId || item.id)
            : undefined
        }
        notFoundContent={
          fetching ? (
            <div className="p-4">
              <Spin size="small" />
            </div>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        placeholder="通过姓名/工号/邮箱搜索"
        optionLabelProp="userName"
        optionFilterProp="search"
        // @ts-ignore
        onSearch={debounceFetcher}
        onSelect={handleSelectUser}
        onDeselect={handleDeselects}
        style={{ width: '100%' }}
        {...rest}
        filterOption={false}
      >
        {(users || searchList)?.map((i: any) => (
          <Option
            key={i.id || i.userId}
            value={i.id || i.userId}
            userName={i.name}
            user={i}
            search={(i.id || i.userId) + i.name + i.uNo + i.deptPath}
          >
            <Tooltip
              placement="leftTop"
              title={
                <span>
                  {' '}
                  {i.name} ({i.uNo}) <br />
                  {i.deptPath}
                </span>
              }
            >
              {i.name} ({i.uNo}) <br />
              {i.deptPath}
            </Tooltip>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SearchUser;
