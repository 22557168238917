import React, { useRef } from 'react';
import { useClickAway } from 'ahooks';
import './index.less';

interface IProps {
  onClose: () => void;
  onMute: () => void;
  defaultVisible: boolean;
}

export default function KnowledgeTopic({
  onClose,
  onMute,
  defaultVisible,
}: IProps) {
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(() => {
    onClose();
  }, ref);

  const onEnterTopic = () => {
    onClose();
    window.open('https://kms.netease.com/topics/topic/36');
  };

  return (
    <div className="knowledge-topic" ref={ref}>
      <a className="knowledge-topic-link" onClick={onEnterTopic}>
        <img src={require('@/assets/img/knowledge_topic.png')} alt="知识专题" />
      </a>
      {defaultVisible && (
        <div className="knowledge-topic-bottom">
          <span className="knowledge-topic-mute" onClick={onMute}>
            不再提示
          </span>
        </div>
      )}
    </div>
  );
}
