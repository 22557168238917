import React, { useState, useEffect } from 'react';
import { List } from 'antd';
import api, { IOkrAlignMapItem, RelTypeEnum } from '@/api/okr';
import { Avatar } from '@/components';
import { RelType } from '@/constants';
import { dataTrack } from '@/utils/track';
import OKRStatusItem from '../okr-status-item';
import OKRPopover from '../popover';

export const RelClass = {
  [RelTypeEnum.U]: 'u-info',
  [RelTypeEnum.W]: 'u-purple',
  [RelTypeEnum.M]: 'u-blue',
};

interface IProps {
  childCount: number;
  id: number;
  parentIdList?: number[];
  currentClickId: number;
  onSelectUser: (user: any) => void;
  reRender: (id?: number) => void;
}

const GridList = (props: IProps) => {
  const { childCount, id, currentClickId, onSelectUser, parentIdList } = props;

  const [currentChildClickId, setCurrentChildClickId] = useState<number>();
  const [dataSource, setDataSource] = useState<
    (IOkrAlignMapItem & { isFirst?: boolean; isLast?: boolean })[]
  >([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentClickId !== id) {
      setOpen(false);
    }
  }, [currentClickId, id]);

  const initList = (
    list: IOkrAlignMapItem[],
    relType: RelTypeEnum,
  ): (IOkrAlignMapItem & { isFirst?: boolean; isLast?: boolean })[] => {
    const firstIndex = list?.findIndex((item) => item.relType === relType);
    if (firstIndex >= 0) {
      // @ts-ignore
      list[firstIndex].isFirst = true;
    }
    if (list[firstIndex - 1]) {
      // @ts-ignore
      list[firstIndex - 1].isLast = true;
    }
    return list;
  };

  const fetchRelMapChild = async (id: number, idList?: number[]) => {
    let res = await api.getRelMapChild(id, idList);
    [RelTypeEnum.W, RelTypeEnum.M, RelTypeEnum.U].forEach((item) => {
      res = initList(res || [], item);
    });
    if (res[res.length - 1]) {
      // @ts-ignore
      res[res.length - 1].isLast = true;
    }
    setDataSource(res);
    setOpen(!open);
  };

  const getRelMapChild = (id: number) => {
    // 不再发起请求
    if (open) {
      setOpen(!open);
      return false;
    }
    if (!open) {
      // 关闭的时候不处理
      props.reRender?.(id);
    }
    fetchRelMapChild(id, parentIdList);
  };

  // 展开或者收缩
  const toggle = () => {
    getRelMapChild(id); // 获取新的list

    dataTrack('click_align_map_extend');
  };

  const reRender = (id: number) => {
    setCurrentChildClickId(id);
  };

  if (!childCount) return null; // 是否有子元素
  return (
    <div className="more-object">
      <div onClick={toggle} className="extend">
        <span className="extend-type">{open ? '-' : '+'}</span>
      </div>
      {open && currentClickId === id && (
        <>
          <div className="line" />
          <List
            className="object-list"
            itemLayout="horizontal"
            style={{
              top: '-38.5px',
            }}
            dataSource={dataSource}
            renderItem={(item, index) => {
              let className = 'u-cell ';
              if (index === 0) {
                className += '-half-horizontal ';
                if (dataSource.length > 1) {
                  className += '-half-vertical-bottom-left ';
                }
              } else if (index === dataSource.length - 1) {
                className += '-half-horizontal-bottom-left ';
              } else {
                className += '-half-horizontal-bottom-left -full-vertical-bottom-left ';
              }
              const {
                isFirst,
                isLast,
                relType,
                userId,
                photoUrl,
                userName,
                objectivesName,
                id: okrId,
                summaryStatus,
              } = item;
              if (isFirst) {
                className += ' first ';
              }
              if (isLast) {
                className += ' last ';
              }
              const title = RelType[relType]; // 标题显示
              const tagClass = RelClass[relType]; // 标签样式
              className += tagClass;
              return (
                <List.Item
                  className="object-list-item"
                  actions={[
                    <GridList
                      {...(item as any)}
                      currentClickId={currentChildClickId}
                      onSelectUser={onSelectUser}
                      reRender={reRender}
                      parentIdList={[...(parentIdList || []), okrId]}
                    />,
                  ]}
                >
                  <div className={className}>
                    {/* 暂时先全部展示 */}
                    {(isFirst || true) && <div className={`tag ${tagClass}`}>{title}</div>}
                    <div
                      className="u-avatar"
                      onClick={() =>
                        onSelectUser({
                          userId,
                          option: {
                            user: {
                              photoUrl,
                              name: userName,
                            },
                          },
                        })
                      }
                    >
                      <Avatar imgPath={photoUrl} userName={userName} className="avatar-32" />
                      <div className="u-name">{userName || '--'}</div>
                    </div>
                    <OKRPopover info={item}>
                      <div className="u-eclipse u-okr" title={objectivesName}>
                        <OKRStatusItem
                          {...(item as any)}
                          objectivesName={objectivesName}
                          okrId={okrId}
                          summaryStatus={summaryStatus}
                          hideStatusLabel
                        />
                      </div>
                    </OKRPopover>
                  </div>
                </List.Item>
              );
            }}
          />
        </>
      )}
    </div>
  );
};

export default GridList;
