import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import { Button, Tree, Modal, TreeSelect, Table, Form, App } from 'antd';
import type { TableColumnsType } from 'antd';
import { SearchUser, Split } from '@/components';
import { IDeptItem } from '@/api/auth';
import operationApi, { IDeptManagerConfigItem, IUserDeptVO } from '@/api/operation';
import './index.less';

const { TreeNode } = Tree;
const { TreeNode: TreeSelectNode } = TreeSelect;

const Manager = () => {
  const { message } = App.useApp();

  const [selectedDept, setSelectedDept] = useState<IDeptItem>();
  const [open, setOpen] = useState(false);
  const [deptTree, setDeptTree] = useState<IDeptItem[]>([]);

  useRequest(operationApi.getDeptTree, {
    onSuccess: (data) => {
      setDeptTree(data);
      if (data?.length) {
        run(data?.[0]?.deptId);
        setSelectedDept(data?.[0]);
      }
    },
  });
  const { data, run, loading, refresh } = useRequest(
    (params) => operationApi.getConfigUserList(params),
    {
      manual: true,
    },
  );
  const [form] = Form.useForm();

  const onBindUser = async (deptId: string, id: number) => {
    await operationApi.bindAdminToDept({ deptId, id });
    message.success('绑定成功！');
    refresh();
  };

  const onUnbindUser = async (deptId: string, id: number) => {
    await operationApi.unbindAdminToDept({ deptId, id });
    message.success('解绑成功！');
    refresh();
  };

  const columns: TableColumnsType<IDeptManagerConfigItem> = [
    {
      dataIndex: 'deptName',
      title: '部门名称',
      width: 300,
    },
    {
      dataIndex: 'userDeptVOList',
      title: '管理员',
      render: (values: IUserDeptVO[], record) => {
        const defaultList = values?.map((item) => ({
          ...item,
          id: item.userId,
          uNo: item.jobNumber,
          deptL1Name: record.deptL1Name,
          deptName: record.deptName,
          name: item.userName,
        }));
        const valueList = defaultList?.map((item) => item.id);
        return (
          <div className="m-edit-ceil">
            <SearchUser
              className="m-edit-form-control"
              multiple
              defaultUserList={defaultList || []}
              value={valueList}
              deselectUser={(value: number) => onUnbindUser(record.deptId, value)}
              selectUser={(value: number) => onBindUser(record.deptId, value)}
            />
          </div>
        );
      },
    },
  ];

  const loadData = async (treeNode: any) => {
    if (treeNode.props.childList) return;
    const data = await operationApi.getDeptTree(treeNode.props.dataRef.id);
    treeNode.props.dataRef.childList = data;
    setDeptTree([...deptTree]);
  };

  const onSelectNode = (key: React.Key[], e: any) => {
    setSelectedDept(e.node.props.dataRef);
    if (key?.[0]) {
      run(key?.[0]);
    }
  };

  const renderTreeNodes = (data: IDeptItem[], isTreeSelect?: boolean) => {
    const NodeTag = isTreeSelect ? TreeSelectNode : TreeNode;
    return data?.map((item) => {
      const title = <div className="dept-item-title">{item.deptName}</div>;
      if (item.childList) {
        return (
          <NodeTag
            key={item.deptId}
            title={title}
            isLeaf={!item.childList}
            dataRef={item}
            value={item.deptId}
          >
            {renderTreeNodes(item.childList)}
          </NodeTag>
        );
      }
      return (
        <NodeTag
          key={item.deptId}
          title={title}
          isLeaf={!item.hasChildren}
          dataRef={item}
          value={item.deptId}
        />
      );
    });
  };

  const onClickAddManage = () => {
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    await operationApi.bindAdminToDept(values);
    message.success('添加成功！');
    refresh();
    onCancel();
  };

  return (
    <div className="m-manager">
      <div className="m-btn-add">
        <Button onClick={onClickAddManage} type="primary">
          添加
        </Button>
      </div>
      <Split className="m-manage-container" expandToMin minSize={[200, 1100]}>
        <Tree
          onSelect={onSelectNode}
          className="m-dept-tree"
          loadData={loadData}
          selectedKeys={selectedDept?.deptId ? [selectedDept.deptId] : []}
        >
          {renderTreeNodes(deptTree)}
        </Tree>
        <Table
          className="m-manage-table"
          rowKey="deptId"
          dataSource={data}
          columns={columns}
          loading={loading}
          scroll={{ y: 'calc(100vh - 344px)' }}
        />
      </Split>
      <Modal
        open={open}
        title="添加"
        onCancel={onCancel}
        maskClosable={false}
        destroyOnClose
        onOk={form.submit}
        className="m-manager-modal"
      >
        <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={onFinish}>
          <Form.Item
            label="姓名"
            required
            name="id"
            rules={[{ required: true, message: '请输入' }]}
          >
            <SearchUser className="m-edit-form-control" />
          </Form.Item>
          <Form.Item
            label="部门"
            required
            name="deptId"
            rules={[{ required: true, message: '请选择' }]}
          >
            <TreeSelect
              loadData={loadData}
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              allowClear
              placeholder="请选择"
            >
              {renderTreeNodes(deptTree, true)}
            </TreeSelect>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Manager;
