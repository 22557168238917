import React, { useState } from 'react';
import { App, Form, Popconfirm, Tooltip, Typography } from 'antd';
import { PlusCircleOutlined, TeamOutlined } from '@ant-design/icons';
import { useUpdateEffect } from 'ahooks';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import okrApi, { IBelongTypeVO, IKrItem, IOkrItem, ProcessAddTypeEnum } from '@/api/okr';
import { CommentTypeEnum } from '@/api/common';
import { useOkrStore } from '@/store';
import { IUserInfo } from '@/api/auth';
import { Comment, IconFont } from '@/components';
import { O_KR_ENUM, RelType } from '@/constants/okr';
import { getEditAuthMap } from '@/utils/okr';
import { getParams } from '@/utils/tools';
import { dataTrack } from '@/utils/track';
import DetailDrawer from '../detail-drawer'; // 详情抽屉
import {
  Actions,
  AlignGuide,
  AlignmentStaff,
  OkrName,
  OkrProcess,
  OkrScore,
  OkrStatus,
  OkrThumb,
  OkrPrincipal,
} from '../operate';
import './index.less';
import '../card.less';

interface IProps {
  index: number;
  okrInfo: IOkrItem;
  userInfo: IUserInfo;
  userRoles: any;
  onRefreshList?: () => void;
  onDelOKR: (id: number) => void;
  className?: any;
  isCustomPeriod?: boolean;
}

const OkrPublishCard = (props: IProps) => {
  const {
    index,
    okrInfo,
    onRefreshList,
    userInfo,
    className = '',
    onDelOKR,
    isCustomPeriod,
  } = props;

  const urlParams: any = getParams();

  const { modal } = App.useApp();

  const { setDetailInfo } = useOkrStore();

  const [form] = Form.useForm();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const {
    id, // okrID
    krList,
    objectivesName,
    principalVO,
    processStatusVO,
    relResultList = [],
    messageCount,
    authMap,
    objectivesBelongsVOList,
    thumbUpCount,
  } = okrInfo || {};

  const { id: principalId, name: principalName } = principalVO || {};
  const { processCount, processScore, processStatus } = processStatusVO || {};

  // 获取OKR编辑权限
  const editAuthMap = getEditAuthMap(authMap, userInfo, false);

  const onONameChange = (value: string) => {
    okrApi.updateOkrName({ id, objectivesName: value });
  };
  const onOStaffChange = (value: IUserInfo) => {
    okrApi.updateOkrPrincipal({ id, principal: value.id }).then(() => {
      onRefreshList?.();
    });
  };
  // 修改状态/评分 -> 进展+1
  const addObjProcess = () => {
    const count = form.getFieldValue('processCount');
    form.setFieldsValue({
      processCount: count + 1,
    });
  };
  const onOStatusOk = ({ content, status }: any) => {
    return okrApi.addObjProcess({
      content,
      status,
      processAddType: ProcessAddTypeEnum.OBJECTIVES_STATUS,
      objectivesId: id,
    });
  };

  const onOStatusChange = () => {
    addObjProcess();
  };

  const onKrNameChange = (fieldName: any, value: string) => {
    const { krList = [] } = form.getFieldsValue();
    const item = krList[fieldName];
    const principalIdList = item.userList.map((i: IUserInfo) => i.id);

    if (item.id) {
      // 有id为编辑kr
      okrApi.updateKr({ id: item.id, keyResultName: value, principalIdList });
    } else {
      // 无id为新增走添加kr逻辑
      okrApi
        .addKr({
          objectivesId: id,
          keyResultName: value,
        })
        .then((krData) => {
          // 添加成功后回填返回的KrId
          const arr: any = [...krList];
          arr[fieldName] = { ...arr[fieldName], id: krData?.id, editStatus: false };
          form.setFieldsValue({
            krList: arr,
          });
        });
    }
  };
  const staffNormalize = (value: IUserInfo[], preValue: IUserInfo[], fieldName: any) => {
    const { krList = [] } = form.getFieldsValue();
    const item = krList[fieldName];
    okrApi.updateKr({
      id: item.id,
      keyResultName: item.keyResultName,
      principalIdList: value.map((i) => i.id),
    });
    return value;
  };

  // 增加kr进展
  const addKRProcess = (fileName: any) => {
    const list = form.getFieldValue('krList');
    const krItem = list[fileName];
    const { krProcessStatusVO } = krItem;
    const arr = [...list];
    arr[fileName] = {
      ...krItem,
      krProcessStatusVO: {
        ...(krProcessStatusVO || {}),
        processCount: (krProcessStatusVO?.processCount || 0) + 1,
      },
    };
    form.setFieldsValue({ krList: arr });
  };
  const onKRStatusOk = ({ status, content }: any, fileName: any) => {
    const krItem = form.getFieldValue(['krList', fileName]);

    return okrApi.addKrProcess({
      content,
      status,
      processAddType: ProcessAddTypeEnum.STATUS,
      keyResultId: krItem.id,
    });
  };

  const onKRStatusChange = (value: any, fileName: any) => {
    addKRProcess(fileName);
  };

  const onKRScoreOk = ({ score, content }: any, fileName: any) => {
    const krItem = form.getFieldValue(['krList', fileName]);

    return okrApi.addKrProcess({
      content,
      score,
      processAddType: ProcessAddTypeEnum.SCORE,
      keyResultId: krItem.id,
    });
  };

  // 根据kr评分的平均分计算o的评分
  const getOScore = () => {
    const krListValues: IKrItem[] = form
      .getFieldValue(['krList'])
      .filter((i: IKrItem) => i.krProcessStatusVO.processScore);
    const averageScore = krListValues.length
      ? (
          krListValues
            .map((i) => +i.krProcessStatusVO.processScore)
            .reduce((acc, val) => acc + val, 0) / krListValues.length
        ).toFixed(2)
      : null;
    form.setFieldsValue({
      processScore: averageScore,
    });
  };

  const onKRScoreChange = (value: any, fileName: any) => {
    getOScore();
    addKRProcess(fileName);
  };

  const onDelObj = () => {
    modal.confirm({
      title: '确定删除此条Objective吗',
      content: '删除后本条 OKR 内容将全部丢失',
      onOk: () => {
        okrApi.delObj(id).then(() => {
          onDelOKR(id);
        });
      },
    });
  };
  const onClickAddKr = () => {
    const { krList, objUser = {} } = form.getFieldsValue();
    console.log('objUser', objUser);
    const addKr = { userList: [objUser], editStatus: true };
    form.setFieldsValue({ krList: [...krList, addKr] });

    dataTrack(urlParams?.tab === 'org' ? 'click_org_okr_add_kr' : 'click_okr_add_kr');
  };
  const onDelKr = (fileName: any, remove: any) => {
    const krId = form.getFieldValue(['krList', fileName, 'id']);
    if (krId) {
      okrApi.delKr(krId).then(() => {
        remove(fileName);
        getOScore();
      });
    } else {
      remove(fileName);
    }
  };

  const asset = {
    krList,
    objectivesName,
    objUser: { name: principalName, id: principalId },
    processStatus,
    processScore,
    processCount,
  };

  useUpdateEffect(() => {
    form.setFieldsValue(asset);
  }, [okrInfo]);

  const relText = (relResultList || []).map(
    (item) =>
      // @ts-ignore
      RelType[item.relType] +
      '：' +
      item.userList.map((u) => u.belongName + `(${u.relResult?.length || 0})`).join('，'),
  );

  const belongText = objectivesBelongsVOList?.map((i: IBelongTypeVO) => i.belongName).join('，');

  const onClickDetail = ({ type, detailId, index, tabActivityKey }: any) => {
    setDrawerVisible(true);
    setDetailInfo({ type, detailId, index, tabActivityKey });
  };

  const onRefresh = (needRefresh = true) => {
    setDrawerVisible(false);
    needRefresh && onRefreshList?.();
  };

  // KR 拖拽回调
  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    const krList = form.getFieldValue('krList');

    const reorder = (list: any, startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    const sortedList = reorder(krList, result.source.index, result.destination.index);
    form.setFieldsValue({
      krList: sortedList,
    });

    okrApi.sortKR(sortedList.map((i: any) => i.id));
  }

  const onClickAlign = () => {
    onClickDetail({
      type: 'O',
      detailId: id,
      index,
      tabActivityKey: 'align',
    });

    dataTrack(urlParams?.tab === 'org' ? 'click_org_okr_add_align' : 'click_okr_add_align');
  };

  const onClickViewMore = () => {
    onClickDetail({
      type: 'O',
      detailId: id,
      index,
    });

    dataTrack(
      urlParams?.tab === 'org' ? 'click_org_okr_card_view_more' : 'click_okr_card_view_more',
    );
  };

  const onClickViewMoreKr = (name: number) => {
    onClickDetail({
      type: 'KR',
      detailId: form.getFieldValue(['krList', name, 'id']),
      index: name,
    });

    dataTrack('click_okr_card_view_more');
  };

  return (
    <div className={`m-okr-card m-publish-card ${className}`}>
      <Form form={form} initialValues={asset}>
        <div className="m-okr-card-content">
          <div style={{ position: 'relative' }}>
            <div className="m-okr-card-tag">O{index + 1}</div>
            {(relResultList?.length || editAuthMap.updateRel) && (
              <IconFont className="m-card-tag-icon" type="icon-duiqi" />
            )}
          </div>
          <div className="m-okr-card-list">
            <div className="m-okr-card-item m-o-list">
              <div className="m-okr-card-left m-publish-card-left">
                <span className="m-publish-align">
                  {relResultList?.length ? (
                    <Tooltip title="查看详情" placement="top">
                      <span className="m-publish-align-btn" onClick={onClickAlign}>
                        {relText.map((item: string) => item + ' ')}
                      </span>
                    </Tooltip>
                  ) : (
                    editAuthMap.updateRel && (
                      <AlignGuide isFirst={index === 0}>
                        <AlignmentStaff oId={id} onSuccess={onRefreshList}>
                          <span className="m-publish-align-btn">+ 添加对齐</span>
                        </AlignmentStaff>
                      </AlignGuide>
                    )
                  )}
                </span>
                <Form.Item noStyle name="objectivesName">
                  <OkrName
                    type="O"
                    authEdit={editAuthMap.updateOName}
                    onChange={onONameChange}
                    onClickText={() => onClickDetail({ type: 'O', detailId: id, index })}
                  />
                </Form.Item>
                {!!objectivesBelongsVOList?.length && (
                  <div className="m-publish-belong-group">
                    <TeamOutlined className="m-publish-belong-icon" />
                    <Typography.Text
                      ellipsis={{ tooltip: { placement: 'topLeft' } }}
                      className="m-publish-belong-text"
                    >
                      {belongText}
                    </Typography.Text>
                  </div>
                )}
              </div>
              <div className="m-okr-card-operator">
                <span className="m-operator-item owner">
                  <span className="m-operator-item-title">负责人</span>
                  <Form.Item noStyle name="objUser">
                    <OkrPrincipal
                      // 有编辑权限&非自定义周期，可编辑
                      authEdit={editAuthMap.upDateOPrincipal && !isCustomPeriod}
                      onChange={onOStaffChange}
                      secondConfirm
                    />
                  </Form.Item>
                </span>
                <span className="m-operator-item other status">
                  <span className="m-operator-item-title">状态</span>
                  <Form.Item noStyle name="processStatus">
                    <OkrStatus
                      authEdit={editAuthMap.updateOProcess}
                      onOk={onOStatusOk}
                      onChange={onOStatusChange}
                      type={O_KR_ENUM.O}
                    />
                  </Form.Item>
                </span>
                <span className="m-operator-item other">
                  <span className="m-operator-item-title">打分</span>
                  <Form.Item noStyle name="processScore">
                    <OkrScore authEdit={false} />
                  </Form.Item>
                </span>
                <span className="m-operator-item other">
                  <span className="m-operator-item-title">进展记录</span>
                  <Form.Item noStyle name="processCount">
                    <OkrProcess
                      onClickItem={() =>
                        onClickDetail({
                          type: 'O',
                          detailId: id,
                          index,
                        })
                      }
                    />
                  </Form.Item>
                </span>
                <span className="m-operator-extra">
                  <Actions tag={<b className="f-cp">...</b>}>
                    {editAuthMap.deleteO && <div onClick={onDelObj}>删除</div>}
                    <div onClick={onClickViewMore}>查看更多</div>
                  </Actions>
                </span>
              </div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="alist">
                {(provided) => {
                  //@ts-ignore
                  const dragHandleProps = provided.dragHandleProps;
                  return (
                    <div ref={provided.innerRef} {...provided.droppableProps} {...dragHandleProps}>
                      <Form.List name="krList">
                        {(fields, { remove }) => (
                          <>
                            {fields.map((field, index) => {
                              const krValues = form.getFieldValue([
                                'krList',
                                field.name,
                                'userList',
                              ]);
                              const hasKrUpdateAuth = editAuthMap.updateAuthKrFunc(krValues);
                              const krItemInfo = form.getFieldValue(['krList', field.name]);

                              return (
                                <Draggable
                                  draggableId={krItemInfo.id + ''}
                                  index={index}
                                  key={krItemInfo.id}
                                >
                                  {(provided) => (
                                    <div
                                      className="m-kr-drag-item"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={{
                                        position: 'relative',
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      {editAuthMap.updateKr && (
                                        <span
                                          className="m-kr-drag-trigger"
                                          {...provided.dragHandleProps}
                                          role="img"
                                        >
                                          <IconFont type="icon-yidong" />
                                        </span>
                                      )}
                                      <div key={field.key} className="m-okr-card-item m-kr-list">
                                        <div className="m-okr-card-kr-left">
                                          <div className="m-kr-index">KR{index + 1}</div>
                                          <Form.Item
                                            noStyle
                                            {...field}
                                            name={[field.name, 'keyResultName']}
                                          >
                                            <OkrName
                                              type="KR"
                                              authEdit={hasKrUpdateAuth}
                                              onChange={(value) =>
                                                onKrNameChange(field.name, value)
                                              }
                                              editStatus={form.getFieldValue([
                                                'krList',
                                                field.name,
                                                'editStatus',
                                              ])}
                                              onClickText={() =>
                                                onClickDetail({
                                                  type: 'KR',
                                                  detailId: krItemInfo.id,
                                                  index: field.name,
                                                })
                                              }
                                              // 点击添加kr时无id 未输入内容则删除此条kr
                                              onChangeEmpty={
                                                !krItemInfo.id ? () => remove(field.name) : null
                                              }
                                            />
                                          </Form.Item>
                                        </div>
                                        <div className="m-okr-card-operator">
                                          <span className="m-operator-item owner">
                                            <Form.Item
                                              noStyle
                                              {...field}
                                              name={[field.name, 'userList']}
                                              normalize={(cur, pre) =>
                                                staffNormalize(cur, pre, field.name)
                                              }
                                            >
                                              <OkrPrincipal
                                                authEdit={hasKrUpdateAuth}
                                                isMultipleSelect
                                              />
                                            </Form.Item>
                                          </span>
                                          <span className="m-operator-item other status">
                                            <Form.Item
                                              noStyle
                                              {...field}
                                              name={[
                                                field.name,
                                                'krProcessStatusVO',
                                                'processStatus',
                                              ]}
                                            >
                                              <OkrStatus
                                                authEdit={hasKrUpdateAuth}
                                                onOk={(value) => onKRStatusOk(value, field.name)}
                                                onChange={(value) =>
                                                  onKRStatusChange(value, field.name)
                                                }
                                                type={O_KR_ENUM.KR}
                                              />
                                            </Form.Item>
                                          </span>
                                          <span className="m-operator-item other">
                                            <Form.Item
                                              noStyle
                                              {...field}
                                              name={[
                                                field.name,
                                                'krProcessStatusVO',
                                                'processScore',
                                              ]}
                                            >
                                              <OkrScore
                                                authEdit={hasKrUpdateAuth}
                                                onChange={(value) =>
                                                  onKRScoreChange(value, field.name)
                                                }
                                                onOk={(value) => onKRScoreOk(value, field.name)}
                                              />
                                            </Form.Item>
                                          </span>
                                          <span className="m-operator-item other">
                                            <Form.Item
                                              noStyle
                                              {...field}
                                              name={[
                                                field.name,
                                                'krProcessStatusVO',
                                                'processCount',
                                              ]}
                                            >
                                              <OkrProcess
                                                onClickItem={() =>
                                                  onClickDetail({
                                                    type: 'KR',
                                                    detailId: form.getFieldValue([
                                                      'krList',
                                                      field.name,
                                                      'id',
                                                    ]),
                                                    index: field.name,
                                                  })
                                                }
                                              />
                                            </Form.Item>
                                          </span>
                                          <span className="m-operator-extra">
                                            <Actions tag={<b className="f-cp">...</b>}>
                                              {hasKrUpdateAuth && (
                                                <Popconfirm
                                                  title="确定删除此条Key Result吗"
                                                  okText="删除"
                                                  cancelText="保留"
                                                  onConfirm={() => onDelKr(field.name, remove)}
                                                >
                                                  <div>删除</div>
                                                </Popconfirm>
                                              )}
                                              <div onClick={() => onClickViewMoreKr(field.name)}>
                                                查看更多
                                              </div>
                                            </Actions>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                            <div className="m-publish-under">
                              {/*有编辑权限并且非自定义周期*/}
                              {editAuthMap.updateKr && !isCustomPeriod ? (
                                <div className="m-add-kr f-cp" onClick={onClickAddKr}>
                                  <PlusCircleOutlined /> 添加 Key Result
                                </div>
                              ) : (
                                <div />
                              )}
                              <div className="m-publish-under-right">
                                <OkrThumb
                                  id={id}
                                  belongUserId={principalId}
                                  defaultValue={thumbUpCount}
                                />
                                <Comment
                                  principalId={principalId}
                                  count={messageCount}
                                  id={id}
                                  targetType={CommentTypeEnum.OKR}
                                  userInfo={userInfo}
                                  placement="left"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </Form.List>
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </Form>
      <DetailDrawer
        open={drawerVisible}
        id={id}
        onRefresh={onRefresh}
        onRefreshList={onRefreshList}
      />
    </div>
  );
};

export default OkrPublishCard;
