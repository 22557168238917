import React from 'react';
import { Drawer } from 'antd';
import { useOkrStore } from '@/store';
import { O_KR_ENUM } from '@/constants';
import KrDetail from './kr';
import ODetail from './o';
import './index.less';

interface IProps {
  open: boolean;
  id: number;
  onRefresh?: (refresh?: boolean) => void;
  onRefreshList?: () => void;
  onClose?: () => void;
}

const DetailDrawer = (props: IProps) => {
  const { detailInfo, clearAll, hasEdit } = useOkrStore();

  const { open, onClose, id, onRefresh, onRefreshList } = props;

  const onCloseDrawerCallback = () => {
    // 是否编辑 未编辑关闭弹窗时不刷新列表
    onRefresh?.(hasEdit);
    onClose?.();
    clearAll();
  };

  return (
    <Drawer
      className="m-okr-drawer"
      open={open}
      width={800}
      destroyOnClose
      onClose={onCloseDrawerCallback}
    >
      {open &&
        (detailInfo?.type === O_KR_ENUM.O ? (
          <ODetail
            oId={id}
            visible={open}
            onRefresh={onRefresh}
            onCloseDrawer={onCloseDrawerCallback}
            onRefreshList={onRefreshList}
          />
        ) : (
          <KrDetail onRefresh={onRefresh} onRefreshList={onRefreshList} />
        ))}
    </Drawer>
  );
};

export default DetailDrawer;
