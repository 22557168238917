import React, { useState } from 'react';
import { Popover } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import Popo from './popo';

interface IProps {
  onChangeInput: Function;
}

const EmojiPanel = (props: IProps) => {
  const { onChangeInput } = props;

  const [open, setOpen] = useState(false);

  const onOpenChange = (visible: boolean) => {
    setOpen(visible);
  };

  const onChange = (code: string) => {
    onChangeInput(code);
    setOpen(false);
  };

  return (
    <span className="emoji-btn">
      <Popover
        open={open}
        onOpenChange={onOpenChange}
        placement="bottom"
        content={<Popo onChange={onChange} />}
        trigger="click"
      >
        <SmileOutlined />
      </Popover>
    </span>
  );
};

export default EmojiPanel;
