import React, { useCallback } from 'react';
import { useSafeState } from 'ahooks';
import { history } from 'umi';
import { Tabs } from 'antd';
import { ProcessTabEnum } from '@/constants';
import { ProcessTab, OrganizationList, PersonalList } from './components';
import useDefaultQuery from './hooks';
import { getParams } from '@/utils/tools';
import './index.less';

// OKR 进展列表
function ProcessListPage() {
  const { tab } = useDefaultQuery();

  const urlParams: { tab?: string } = getParams();
  // 个人 | 组织OKR进展
  const [activeTab, setActiveTab] = useSafeState(tab ? tab : urlParams?.tab || ProcessTabEnum.user);

  const onChangeTab = useCallback(
    (nextTab) => {
      if (nextTab === activeTab) return;
      setActiveTab(nextTab);
      history.replace(`/process/list?tab=${nextTab}`);
    },
    [activeTab],
  );

  return (
    <div className="process-list-page">
      <ProcessTab activeKey={activeTab} onChange={onChangeTab} />
      <Tabs tabBarStyle={{ display: 'none' }} activeKey={activeTab}>
        <Tabs.TabPane key={ProcessTabEnum.user} tab="个人">
          <PersonalList />
        </Tabs.TabPane>
        <Tabs.TabPane key={ProcessTabEnum.org} tab="组织">
          <OrganizationList />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default ProcessListPage;
