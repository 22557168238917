import React, { useState } from 'react';
import { Modal, Button, Input, App } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import processApi from '@/api/process';
import EmailWeekly from './email-weekly';

interface IProps {
  id: number;
  title: string;
  onSuccess?: (data: any) => void;
}

function ModalMailTo(props: IProps) {
  const { id, title, onSuccess } = props;
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');

  const { message } = App.useApp();

  const onCancel = () => {
    setVisible(false);
    setValue('');
  };

  const onOK = () =>
    new Promise(async (resolve, reject) => {
      if (!value) return;
      const htmlContent = document.getElementById('process-email-content')?.innerHTML;
      if (!htmlContent) return;
      try {
        const data = await processApi.emailTo({
          title,
          mailList: value?.replaceAll('；', ';')?.split(';'),
          content: htmlContent,
        });
        message.success('发送成功！');
        onCancel();
        onSuccess?.(data);
        resolve(data);
      } catch (e) {
        reject(e);
        message.error('发送失败！');
      }
    });

  return (
    <span>
      <Button
        type="link"
        icon={<MailOutlined className="relative" style={{ top: 1 }} />}
        onClick={() => setVisible(true)}
      >
        邮件发送
      </Button>
      <Modal
        title="将已发布的周报通过邮件形式发送给："
        okText="确定发送"
        width={720}
        okButtonProps={{ disabled: !value }}
        open={visible}
        onCancel={onCancel}
        onOk={onOK}
        maskClosable={false}
        destroyOnClose
      >
        {visible && (
          <>
            <Input
              placeholder="可同时输入多个邮箱，用“;”分开"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <EmailWeekly id={id} title={title} />
          </>
        )}
      </Modal>
    </span>
  );
}

export default ModalMailTo;
