import React, { useState } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import type { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { Collapse } from 'react-collapse';
import { useStore } from '@/store';
import processApi, { IProcessVO, IProcessOkrItem, IProcessKrItem } from '@/api/process';
import { O_KR_ENUM } from '@/constants';
import { OCard, KrCard, ContentEditCard } from '..';
import { getUniqKey } from './utils';
import { OkrTypeEnum } from '@/api/okr';

interface IProps {
  info: IProcessOkrItem & { processInfo: IProcessVO };
  readonly?: boolean;
  modifyProcessListMap: (partialProcessListMap: { [key in string]: IProcessVO }) => void;
  cacheEditingProcess: any;
  releaseAll$: EventEmitter<any>;
  getProcessVO: () => void;
}

function DashboardOkr(props: IProps) {
  const { info, readonly, modifyProcessListMap, getProcessVO, cacheEditingProcess, releaseAll$ } =
    props;

  const { currentProcessDateId, currentProcessSourceType, currentProcessType } = useStore();

  const [isOpened, setIsOpened] = useState(true);

  const toggle = () => setIsOpened(!isOpened);

  if (!info) return null;

  const { auth: oAuth, krList, objectivesId, processInfo: oProcessInfo, principalId } = info || {};

  const updateOAverageScore = (nextKrProcessInfo: { [key in string]: IProcessVO }) => {
    const okrType = O_KR_ENUM.O;
    processApi
      .getSingleProcess({
        dateId: currentProcessDateId,
        processSourceType: currentProcessSourceType!,
        okrType,
        typeSourceId: objectivesId,
      })
      .then((data) => {
        const uniqKey = getUniqKey(okrType, objectivesId);
        // 更新O的进展评分
        modifyProcessListMap?.({
          ...nextKrProcessInfo,
          [uniqKey]: { ...oProcessInfo, evaluationScore: data?.evaluationScore },
        });
      })
      .catch(() => modifyProcessListMap?.(nextKrProcessInfo));
  };

  if (!currentProcessSourceType) return null;

  // 组织 OKR 特殊 targetId 处理
  const orgTargetId = currentProcessType === OkrTypeEnum.organization ? principalId : undefined;

  console.log('orgTargetId', orgTargetId);

  return (
    <div className="pd-okr">
      {/* 这里是 OKR 行  */}
      <div className="pd-row pd-row-okr">
        <div className="pd-col-okr pd-col-okr-card">
          <div onClick={toggle}>
            <CaretDownOutlined
              className={classnames('icon', {
                'icon-open': isOpened,
              })}
            />
          </div>
          <OCard data={info} />
        </div>
        <div className="pd-col-card">
          <ContentEditCard
            modifyProcessListMap={modifyProcessListMap}
            getProcessVO={getProcessVO}
            cacheEditingProcess={cacheEditingProcess}
            releaseAll$={releaseAll$}
            okrAuth={oAuth}
            okrType={O_KR_ENUM.O}
            readonly={readonly}
            typeSourceId={objectivesId}
            detail={oProcessInfo}
            orgTargetId={orgTargetId}
          />
        </div>
      </div>
      {/* 这里是对应的 KR 列表 */}
      <Collapse isOpened={isOpened}>
        {krList?.map((kr, index: number) => {
          const {
            id: krId,
            auth: krAuth,
            processInfo: krProcessInfo,
          } = kr as IProcessKrItem & { processInfo: IProcessVO };
          return (
            <div className="pd-row" key={krId}>
              <div className="pd-col-okr">
                <KrCard data={kr} index={index} />
              </div>
              <div className="pd-col-card">
                <ContentEditCard
                  modifyProcessListMap={modifyProcessListMap}
                  getProcessVO={getProcessVO}
                  cacheEditingProcess={cacheEditingProcess}
                  releaseAll$={releaseAll$}
                  updateOAverageScore={updateOAverageScore}
                  okrAuth={krAuth}
                  okrType={O_KR_ENUM.KR}
                  readonly={readonly}
                  typeSourceId={krId}
                  detail={krProcessInfo}
                  orgTargetId={orgTargetId}
                />
              </div>
            </div>
          );
        })}
      </Collapse>
    </div>
  );
}

export default DashboardOkr;
