/**
 * 编辑okr评分
 */

import React, { useState } from 'react';
import { Modal, Input, Tooltip } from 'antd';
import { useControllableValue } from 'ahooks';
import { SCORE_OPTION } from '@/constants/okr';
import ScoreSelect from '../select/score-select';
import './index.less';

const { TextArea } = Input;

interface IProps {
  authEdit?: boolean;
  onOk?: ({ content, score }: any) => Promise<any>;
  value?: number;
  onChange?: (value?: number) => void;
}

const OkrScore = (props: IProps) => {
  const { authEdit = false, onOk: _onOk } = props;

  const [value, setValue] = useControllableValue(props);
  const [score, setScore] = useState<number>();
  const [content, setContent] = useState();
  const [open, setOpen] = useState(false);

  const onContentChange = ({ target: { value } }: any) => {
    setContent(value);
  };

  const onOk = () => {
    return _onOk?.({ content, score }).then(() => {
      setValue(score);
      setOpen(false);
    });
  };

  const color = SCORE_OPTION.find((i) => i.key === Math.floor(+value))?.color || '';

  const showModal = () => {
    setScore(undefined);
    setOpen(true);
  };

  return authEdit ? (
    <div>
      <Tooltip title="修改评分">
        <span className="m-okr-score m-is-edit" onClick={showModal}>
          {value ? (
            <span style={{ color }}>
              {'● '} {parseFloat(value)}
            </span>
          ) : (
            <span className="m-empty">暂无</span>
          )}
        </span>
      </Tooltip>
      <Modal
        onOk={onOk}
        width={500}
        open={open}
        onCancel={() => setOpen(false)}
        okButtonProps={{ disabled: !score }}
        destroyOnClose
        maskClosable={false}
      >
        <div className="mb-4 flex items-center">
          KR评分：
          <ScoreSelect onChange={setScore} />
        </div>
        <TextArea
          autoSize={{
            minRows: 6,
          }}
          maxLength={5000}
          onChange={onContentChange}
          placeholder="有哪些关键进展？是什么阻碍了它取得更大进步？我们需要做什么来改善OKR结果？"
        />
      </Modal>
    </div>
  ) : (
    <span className="m-okr-score">
      {value ? (
        <span style={{ color }}>
          {'● '} {parseFloat(value)}
        </span>
      ) : (
        <span className="m-empty">暂无</span>
      )}
    </span>
  );
};

export default OkrScore;
