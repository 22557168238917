// @ts-nocheck
// 动态、消息中心时间描述
/*
    1、<=10分钟：刚刚
   2、10-60分钟(含)：XX分钟前
   3、1-24小时（不跨天的情况）：X小时前（满1小时才算）
   4、跨天：
       昨天：昨天 12:23
       前天：前天 23:23
       再往前：04-08 12：00
   5、跨年：
       2019-12-09
   */

const zeroize = (num) => (num >= 10 ? num : `0${num}`);
const slashDate = (date) => (date && typeof date === 'string' ? date.replace(/-/g, '/') : date);

export function formatDate(value, format = 'yyyy-MM-dd HH:mm') {
  if (value === undefined || value === null) return undefined;

  const maps = {
      yyyy: (data) => data.getFullYear(),
      MM: (data) => zeroize(data.getMonth() + 1),
      dd: (data) => zeroize(data.getDate()),
      HH: (data) => zeroize(data.getHours()),
      mm: (data) => zeroize(data.getMinutes()),
    },
    trunk = new RegExp(Object.keys(maps).join('|'), 'g'),
    date = new Date(slashDate(value));
  return format.replace(trunk, (capture) => (maps[capture] ? maps[capture](date) : ''));
}

export function changeDateDesc(createTime, nowTime) {
  const formatCreateTime = createTime && slashDate(createTime),
    formatNowTime = nowTime && slashDate(nowTime),
    createDate = new Date(formatCreateTime),
    nowDate = new Date(formatNowTime),
    createYear = createDate.getFullYear(),
    createMonth = createDate.getMonth() + 1,
    createDay = createDate.getDate(),
    createHours = createDate.getHours(),
    createMinutes = createDate.getMinutes(),
    nowYear = nowDate.getFullYear(),
    nowMonth = nowDate.getMonth() + 1,
    nowDay = nowDate.getDate(),
    nowHours = nowDate.getHours(),
    nowMinutes = nowDate.getMinutes(),
    yesterday = nowDate.setDate(nowDay - 1),
    beforeYesterday = nowDate.setDate(nowDay - 2);
  if (nowYear === createYear) {
    if (nowDay === createDay && nowMonth === createMonth) {
      // 不跨天
      const hourGap = nowHours - createHours,
        minutesGap = nowMinutes - createMinutes;

      if (hourGap >= 2 || (hourGap === 1 && nowMinutes >= createMinutes)) {
        // 不跨天 超过一小时
        return `${hourGap}小时前`;
      }
      // 不跨天 不超过一个小时
      if ((hourGap === 1 && 60 + minutesGap < 10) || (minutesGap <= 10 && minutesGap >= 0)) {
        return '刚刚';
      }
      return `${minutesGap > 0 ? minutesGap : 60 + minutesGap}分钟前`;
    }
    // 昨天
    if (formatDate(createDate, 'yyyy-MM-dd') === formatDate(yesterday, 'yyyy-MM-dd')) {
      return `昨天 ${zeroize(createHours)}:${zeroize(createMinutes)}`;
    }
    if (formatDate(createDate, 'yyyy-MM-dd') === formatDate(beforeYesterday, 'yyyy-MM-dd')) {
      return `前天 ${zeroize(createHours)}:${zeroize(createMinutes)}`;
    }
    return `${zeroize(createMonth)}-${zeroize(createDay)} ${zeroize(createHours)}:${zeroize(
      createMinutes,
    )}`;
  }
  // 跨年 返回创建时期的年月日
  return `${createYear}-${zeroize(createMonth)}-${zeroize(createDay)}`;
}
