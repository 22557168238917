import React, { forwardRef, useImperativeHandle } from 'react';
import { Spin, Empty } from 'antd';
import { useRequest } from 'ahooks';
import okrApi from '@/api/okr';
import { O_KR_ENUM } from '@/constants';
import KrProcessList from '../kr-process-list';
import './index.less';

interface IProps {
  id: number;
  type: string;
}

const fetch = {
  O: {
    searchFetch: (id: number) => okrApi.getObjectiveProcess(id),
  },
  KR: {
    searchFetch: (id: number) => okrApi.getKrProcessList(id),
  },
};

const Process = (props: IProps, ref: any) => {
  const { id, type = 'O' } = props;
  //@ts-ignore
  const { searchFetch } = fetch[type] || {};
  const { data, run, loading }: any = useRequest(() => searchFetch(id));

  useImperativeHandle(ref, () => ({
    refresh: run,
  }));

  return (
    <div className="m-okr-detail-process">
      <Spin spinning={loading}>
        {data?.length ? <KrProcessList list={data} type={type as O_KR_ENUM} /> : <Empty />}
      </Spin>
    </div>
  );
};

export default forwardRef(Process);
