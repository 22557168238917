import api from '@/api';
import { DeptTypeEnum, PeriodTypeEnum } from '@/api/okr';
import { GATEWAY } from '@/api/config';
import { IUserInfo } from '@/api/auth';
import {
  IProcessPeriodItem,
  IPeriodConfigItem,
  IProcessDetailQuery,
  IProcessDetail,
  IProcessOkrQuery,
  IProcessOkrItem,
  ProcessSourceTypeEnum,
  IProcessDetailCompose,
  IProcessHistoryItem,
  ICreateProcessWhenEmptyPayload,
  IProcessSavePayload,
  IProcessResult,
  IQueryPeriodParam,
  IQueryHistoryParam,
  ISendEmailPayload,
  IReleaseAllPayload,
  ISingleProcessParams,
  IProcessVO,
} from './interface';
import { ResponsePromise } from '../interface';

export default {
  /**
   * 获取部门信息
   */
  getDeptInfoById: (params: { deptId: string; deptType: DeptTypeEnum }) =>
    api.get(`${GATEWAY}/process/dept`, params),
  /**
   * 我的进展-获取进展周期(个人&组织)
   */
  getPeriodList: (params: IQueryPeriodParam): ResponsePromise<IProcessPeriodItem[]> =>
    api.get(`${GATEWAY}/process/period/list`, params),
  /**
   * 个人进展-周报-获取当前所在的周信息
   */
  getCurrentWeeklyInfo: (): ResponsePromise<IProcessPeriodItem> =>
    api.get(`${GATEWAY}/process/week/current/user`),
  /**
   * 我的进展-进展周期类型列表-员工
   */
  getPeriodTypeList: (params: {
    periodType: PeriodTypeEnum;
    userId: number;
  }): ResponsePromise<IPeriodConfigItem[]> => api.get(`${GATEWAY}/config/period/type/user`, params),
  /**
   * 我的进展-组织OKR-周报进展
   */
  getGroupWeeklyProcess: (params: IProcessDetailQuery): ResponsePromise<IProcessDetail> =>
    api.get(`${GATEWAY}/process/group/week/report`, params),
  /**
   * 我的进展-组织OKR-总结进展
   */
  getGroupSummaryProcess: (params: IProcessDetailQuery): ResponsePromise<IProcessDetail> =>
    api.get(`${GATEWAY}/process/group/summary`, params),
  /**
   * 我的进展-我的OKR/团队成员OKR-周报进展
   */
  getWeeklyProcess: (params: IProcessDetailQuery): ResponsePromise<IProcessDetail> =>
    api.get(`${GATEWAY}/process/week/report`, params),
  /**
   * 我的进展-我的OKR/团队成员OKR-总结进展
   */
  getSummaryProcess: (params: IProcessDetailQuery): ResponsePromise<IProcessDetail> =>
    api.get(`${GATEWAY}/process/summary`, params),
  /**
   * 我的进展-我的OKR/团队成员OKR-OKR列表
   */
  getOkrList: (params: IProcessOkrQuery): ResponsePromise<IProcessOkrItem[]> =>
    api.get(`${GATEWAY}/process/okr/list`, params),
  /**
   * 我的进展-组织OKR-OKR列表
   */
  getGroupOkrList: (params: IProcessOkrQuery): ResponsePromise<IProcessOkrItem[]> =>
    api.get(`${GATEWAY}/process/group/okr/list`, params),
  /**
   * 获取单条进展的数据
   */
  getSingleProcess: (params: ISingleProcessParams): ResponsePromise<IProcessVO> =>
    api.get(`${GATEWAY}/process/item/detail`, params),
  /**
   * 我的进展-我的OKR/团队成员okr-个人信息
   */
  getProcessInfo: (userId: number): ResponsePromise<IUserInfo> =>
    api.get(`${GATEWAY}/process/single/info`, { userId }),
  /**
   * 我的进展-组织OKR-进展信息统计
   */
  getOrgProcessInfo: (deptId: string) => api.get(`${GATEWAY}/process/group/info`, { deptId }),
  /**
   * 我的进展-一键发布
   */
  releaseAllProcess: (payload: IReleaseAllPayload) =>
    api.post(`${GATEWAY}/process/releaseAll`, payload),
  /**
   * 我的进展-动态跳转详情页
   * @param params
   * @param {number} params.id 进展主键
   * @param {string} params.processSourceType 进展类型
   */
  getProcessDetail: (params: {
    id: number;
    processSourceType: ProcessSourceTypeEnum;
  }): ResponsePromise<IProcessDetailCompose> => api.get(`${GATEWAY}/process/detail`, params),
  /**
   * 进展框锁-尝试新增锁定/上报 - https://nei.hz.netease.com/interface/detail/?pid=58800&id=379663
   * @param {object} params
   * @param {("O")|("KR")|("OTHERS")|("PROCESS_OTHER")} params.okrType 进展所属类别 O/ KR
   * @param {number} [params.typeSourceId] 进展类型主键 目标 或 KR
   * @param {number} params.dateId
   * @param {('PROCESS_WEEK_REPORT'|'PROCESS_SUMMARY'|'PROCESS_CONCLUSION')} params.sourceType 来源类型，PROCESS_WEEK_REPORT - 周报，PROCESS_SUMMARY - 总结,PROCESS_CONCLUSION-小结
   */
  lockProcess: (params: any) => api.post(`${GATEWAY}/process/lock`, params),
  /**
   * 我的进展-单条进展-编辑历史
   * @param params
   * @param {number} params.processId 进展id
   * @param {('1'|'2')} params.type 进展：1，其他:2
   */
  getSingleProcessHistory: (params: {
    processId: number;
    type?: string;
  }): ResponsePromise<IProcessHistoryItem[]> =>
    api.get(`${GATEWAY}/process/history/edit/list`, params),
  /**
   * 周报其他-保存/更新
   * @param params
   * @param {number} [params.id] 进展主键，不传，则新增
   * @param {string} params.dateId 周期关联主键
   * @param {string} params.type 1 个人/2组织
   * @param {string} [params.userId] 用户ID
   * @param {string} [params.deptId] 部门ID
   * @param {string} params.content 进展内容
   * @param {string[]} [params.userIds]
   */
  // temporarySaveWeeklyOther: (params: any) => api.post('/process/other/storage', params),
  // /**
  //  * 周报其他-保存/更新
  //  * @param params
  //  * @param {number} [params.id] 进展主键，不传，则新增
  //  * @param {string} params.dateId 周期关联主键
  //  * @param {string} params.type 1 个人/2组织
  //  * @param {string} [params.userId] 用户ID
  //  * @param {string} [params.deptId] 部门ID
  //  * @param {string} params.content 进展内容
  //  * @param {string[]} [params.userIds]
  //  */
  // updateWeeklyOther: (params: any) => api.post('/process/other/release', params),
  /**
   * 进展-创建进展记录并返回主键
   * why? 点赞之前，如果没有进展记录，需要先创建进展记录
   */
  createProcessWhenEmpty: (payload: ICreateProcessWhenEmptyPayload): ResponsePromise<number> =>
    api.post(`${GATEWAY}/process/create`, payload),

  /**
   * 我的进展-暂存进展
   */
  temporaryStorageProcess: (payload: IProcessSavePayload): ResponsePromise<IProcessResult> =>
    api.post(`${GATEWAY}/process/storage`, payload),
  /**
   * 我的进展-发布进展
   */
  releaseProcess: (payload: IProcessSavePayload) => api.post(`${GATEWAY}/process/release`, payload),
  /**
   * 发送进展邮件
   */
  emailTo: (payload: ISendEmailPayload) => api.post(`${GATEWAY}/process/mail`, payload),
  // // 我的进展-整个进展评论
  // postProcessComment: (params: any) => api.post('/process/comment', params),
  /**
   * 我的进展-我的okr/团队成员okr/组织okr-操作历史
   */
  getProcessOperations: (params: IQueryHistoryParam): ResponsePromise<IProcessHistoryItem[]> =>
    api.get(`${GATEWAY}/process/history/list`, params),
};

export * from './interface';
