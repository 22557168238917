import { PeriodTypeEnum } from '@/api/okr';

export enum AuthSourceTypeEnum {
  OKR = 'OKR',
  WORK_REPORT = 'WORK_REPORT',
  SUMMARY = 'SUMMARY',
  DEPT = 'DEPT',
}

export interface IDeptShareDetailItem {
  id: string;
  deptId: string;
  deptName: string;
  authLevel: number; // 权限范围 0/10：无最小共享范围 1~5 一到五级部门共享范围
  authType: AuthSourceTypeEnum; // OKR：okr WORK_REPORT：周报 SUMMARY：总结  DEPT：人事
}

export interface IDeptShareItem {
  id: string;
  deptId: string;
  deptName: string;
  defaultDetailVOList: IDeptShareDetailItem[];
}

export interface IUpdatePeriodPayload {
  deptId: string;
  periodType: PeriodTypeEnum;
  periodList: number[];
}

export interface IReportItem {
  id: number; // 报表ID
  name: string; // 报表名称
  description: string; // 报表描述
  modelClass: string; // 报表对应实体类
  serviceClass: string; // 报表导出服务类名
  method: string; // 数据查询方法
}

export interface IExportQueryDTO {
  currentPage: number;
  dateClass?: string;
  pageSize: number;
  summaryBeginScore?: number;
  summaryEndScore?: number;
  summaryStatus?: number;
  year?: number;
  startDate?: string;
  endDate?: string;
  userScopeType?: string; // 人员范围类型（1全部，2我的下属）
  deptList?: string[]; // 部门列表
}

export interface IQueryReportDataParams {
  id: number;
  exportQueryDTO: IExportQueryDTO;
}

export interface IReportDataItem {
  objectDept: string; // 所属团队
  objectType: string; // 目标类型
  objectCycle: string; // 目标周期
  objectName: string; // 目标(O)名称
  objectResponsible: string; // 目标(O)负责人
  objectNewestStatus: string; // 最新状态
  objectNewestScore: string; // 最新评分
  objectParticipant: string; // 目标(O)参与人
  krObjectCycle: string; // 所属目标(O)周期
  krObjectType: string; // 目标(O)类型
  krResponsible: string; // 负责人
  krNewestStatus: string; // 最新状态
  krNewestScore: string; // 最新评分
  krObjectName: string; // 所属目标(O)
  krObjectResponsible: string; // 目标(O)负责人
  krObjectDept: string; // 所属团队 （仅组织OKR）
  briefSummaryNum: number; // 过程小结数量
  sixeScoreObjectNum: number; // 人员的3-6分OKR目标数量
  noSummaryObjectNum: number; // 人员的无状态OKR目标数量
  three_score_object_num: number; // 人员的0-3分OKR目标数量
  userName: string; // 姓名
  userDeptName: string; // 部门
  summaryNum: number; // 过程小结数量
  yellowObjectNum: number; // 黄灯OKR目标数量
  objectNum: number; // OKR目标数量
  noScoreObjectNum: number; // 无评分OKR目标数量
  weekReportNum: number; // 周报数量
  tenScoreObjectNum: number; // 9-10分OKR目标数量
  redObjectNum: number; // 红灯OKR目标数量
  greenObjectNum: number; // 绿灯OKR目标数量
  nineScoreObjectNum: number; // 6-9分OKR目标数量
  userId: number; // 用户ID
  objectId: number; // 目标ID
  krId: number; // 关键成果id
  userNo: string; // 工号
  objectResponsibleEmpIds: string; // 目标(O)负责人工号
  responsibleEmpIds: string; // 关键成果(KR)负责人工号
}
