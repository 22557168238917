import React, { memo } from 'react';
import { history } from 'umi';
import { OkrScoreStatus } from '@/components';
import { SUMMARY_TYPE_CODE } from '@/constants';

function OKRStatusItem({ objectivesName, userId, okrId, year, dateId, summaryStatus }: any) {
  const onClickCard = () => {
    history.push(`/team/${userId}?tab=okr&year=${year}&periodId=${dateId}&id=${okrId}`);
  };

  return (
    <div className="okr-card-container f-pointer" onClick={onClickCard}>
      <span className="u-eclipse obj-name">
        <OkrScoreStatus
          noBackgroundColor
          type={SUMMARY_TYPE_CODE.STAGE}
          summaryStatus={summaryStatus}
          hideStatusLabel
        />
        {objectivesName || '--'}
      </span>
    </div>
  );
}

export default memo(OKRStatusItem);
