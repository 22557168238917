import React, { memo } from 'react';
import { Switch } from 'antd';
import { useStore } from '@/store';
import './index.less';

interface IProps {
  style?: React.CSSProperties;
  tip?: string;
  onChange?: (checked: boolean) => void;
}

function SwitchMode(props: IProps) {
  const { style, tip = '仅展示有进展的OKR', onChange } = props;

  const { isEmptyKrProcessHide, toggleEmptyKrProcessHide } = useStore();

  return (
    <div className="okr-process-switch-empty" style={style}>
      <span className="okr-process-switch-empty-tip">{tip}</span>
      <Switch checked={isEmptyKrProcessHide} onChange={toggleEmptyKrProcessHide} />
    </div>
  );
}

export default memo(SwitchMode);
