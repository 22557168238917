import * as Sentry from '@sentry/react';
import processApi, { IProcessOkrItem, IProcessVO, ProcessSourceTypeEnum } from '@/api/process';
import { OkrTypeEnum } from '@/api/okr';
import { ProcessOtherKey, ProcessPublishStatusEnum } from '@/constants';

export function checkShouldRequest(filters: any, type: OkrTypeEnum) {
  // 未选中周期筛选
  if (!filters.year || !filters.okrDateId) {
    return false;
  }
  // 组织OKR进展，未选中部门
  if (type === OkrTypeEnum.organization && !filters.deptId) {
    return false;
  }
  // 成员OKR进展，未选中成员
  return !(type === OkrTypeEnum.personal && !filters.userId);
}

/**
 * 获取请求进展的API
 * @param {('PROCESS_WEEK_REPORT')|('PROCESS_CONCLUSION')|('PROCESS_SUMMARY')} processSourceType
 * @param {(1)|(2)} type 1: 个人，2：组织
 * @returns {*}
 */
export function getProcessApi(processSourceType: ProcessSourceTypeEnum, type: OkrTypeEnum) {
  if (processSourceType === ProcessSourceTypeEnum.PROCESS_CONCLUSION) {
    return null;
  }
  if (type === OkrTypeEnum.organization) {
    const apiGroupMap = {
      [ProcessSourceTypeEnum.PROCESS_WEEK_REPORT]: processApi.getGroupWeeklyProcess,
      [ProcessSourceTypeEnum.PROCESS_SUMMARY]: processApi.getGroupSummaryProcess,
    };
    return apiGroupMap[processSourceType];
  }
  const apiUserMap = {
    [ProcessSourceTypeEnum.PROCESS_WEEK_REPORT]: processApi.getWeeklyProcess,
    [ProcessSourceTypeEnum.PROCESS_SUMMARY]: processApi.getSummaryProcess,
  };
  return apiUserMap[processSourceType];
}

/**
 * 获取 O-KR key 值
 * @param {('O'|'KR')} type 类型
 * @param id 主键
 * @returns {string}
 */
export function getUniqKey(type: string, id: number) {
  return `${type}-${id}`;
}

export function getTypeAndIdByUniqKey(uniqKey: string) {
  const [type, id] = uniqKey.split('-');
  return {
    type,
    id,
  };
}

/**
 *
 * @param processList
 * @param processOther
 * @returns {*}
 */
export function transformProcessList2Map(processList: IProcessVO[], processOther?: IProcessVO) {
  const currentProcessMap: { [key in string]: IProcessVO } = (processList || []).reduce(
    (voMap, current) => {
      const { type, typeSourceId } = current;
      return {
        ...voMap,
        [`${type}-${typeSourceId}`]: current,
      };
    },
    {},
  );
  if (processOther) {
    currentProcessMap[ProcessOtherKey] = processOther;
  }
  return currentProcessMap;
}

export function addProcessInfo2OkrList(
  okrList: IProcessOkrItem[],
  processListMap: { [key in string]: IProcessVO },
  hideEmptyKrProcess = false,
) {
  if (!Array.isArray(okrList)) {
    Sentry.captureException({
      message: 'addProcessInfo2OkrList okrList出错了',
      extra: {
        okrList,
      },
    });
    return [];
  }
  return okrList?.map((item) => {
    const { objectivesId, krList = [] } = item;
    const oUniqKey = getUniqKey('O', objectivesId);
    let nextList = (krList || []).map((kr) => {
      const { id: krId } = kr;
      const krUniqKey = getUniqKey('KR', krId);
      return {
        ...kr,
        uniqKey: krUniqKey,
        processInfo: processListMap[krUniqKey],
      };
    });
    // 页面隐藏空白的KrProcess
    if (hideEmptyKrProcess) {
      nextList = nextList.filter((krItem) => krItem.processInfo && krItem.processInfo.content);
    }
    return {
      ...item,
      uniqKey: oUniqKey,
      krList: nextList,
      processInfo: processListMap[oUniqKey],
    };
  });
}

// 判断当前所有进展是否有某种进展状态
export function checkHasProcessByStatus(
  processListMap: { [key in string]: IProcessVO },
  status = ProcessPublishStatusEnum.draft,
) {
  if (!processListMap) return false;
  return Object.keys(processListMap || {})?.some((processKey) => {
    const processItem = processListMap[processKey];
    return processItem?.status === status;
  });
}
