import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import type { TooltipPlacement } from 'antd/es/tooltip';
import { MessageFilled } from '@ant-design/icons';
import api, { CommentTypeEnum } from '@/api/common';
import { IUserInfo } from '@/api/auth';
import { ProcessSourceTypeEnum } from '@/api/process';
import { commentArray2Tree } from '@/utils/tools';
import CommentModal from './comment-modal';
import './index.less';

interface IProps {
  targetType: CommentTypeEnum;
  count: number;
  id: number;
  userInfo: IUserInfo;
  principalId?: number;
  getProcessVO?: () => void;
  domId?: string;
  typeSourceId?: number;
  dateId?: number;
  okrType?: string;
  processType?: ProcessSourceTypeEnum;
  placement?: TooltipPlacement;
  beforeSubmit?: () => Promise<number>;
}

export default function Comment(props: IProps) {
  const {
    placement = 'bottom',
    count,
    id,
    targetType,
    principalId,
    getProcessVO,
    domId,
    ...rest
  } = props;

  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [displayCount, setCount] = useState(count || 0);

  useEffect(() => {
    setCount(count || 0);
  }, [count]);

  const fetchData = async (newId?: number) => {
    const data = await api.getCommentList({
      targetId: newId || id,
      targetType,
      userId: principalId,
    });
    const treeData = commentArray2Tree(data || []);
    setList(treeData);
  };

  useEffect(() => {
    if (!id || !visible) return;
    fetchData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onVisibleChange = (v: boolean) => {
    setVisible(v);
    if (v && id) {
      fetchData();
    }
  };

  const handleSuccess = async () => {
    if (id) {
      fetchData();
    } else {
      // 没有评论的ID，需要重新请求全部进展的数据，更新ID，触发 useEffect 请求评论列表
      getProcessVO?.();
    }
    setCount(displayCount + 1);
  };

  const handleDelete = (deleteLength: number) => {
    setCount(displayCount - deleteLength < 0 ? 0 : displayCount - deleteLength);
  };

  return (
    <Popover
      trigger="click"
      content={
        <CommentModal
          id={id}
          count={displayCount}
          onClose={() => setVisible(false)}
          list={list}
          targetType={targetType}
          handleSuccess={handleSuccess}
          handleDelete={handleDelete}
          {...rest}
        />
      }
      placement={placement}
      overlayClassName="comment-action-overlay"
      onOpenChange={onVisibleChange}
      open={visible}
      //@ts-ignore
      getPopupContainer={(trigger) => (domId ? document.getElementById(domId) : trigger)}
    >
      <span className={`other-content_action comment-action ${displayCount ? 'active' : ''}`}>
        <MessageFilled className="action-icon" />
        {!!displayCount && displayCount}
      </span>
    </Popover>
  );
}
