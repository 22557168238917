import React, { memo } from 'react';
import classnames from 'classnames';
import { IconFont } from '@/components';
import { SwitchMode } from '@/pages/process/components';
import { PROCESS_TAB_LIST } from '@/constants';
import './index.less';

interface IProps {
  onChange?: (key: string) => void;
  activeKey: string;
}

function ProcessTab(props: IProps) {
  const { activeKey, onChange } = props;

  const onChangeTab = (key: string) => {
    if (key === activeKey) return;
    onChange?.(key);
  };

  return (
    <div className="okr-process-tab-wrap">
      <div className="okr-process-tabs">
        {PROCESS_TAB_LIST.map((item, index) => {
          const { key, tab, icon } = item;
          return (
            <div
              key={key}
              className={classnames('okr-process-tab', {
                'okr-process-tab__active': activeKey === key,
                [`okr-process-tab__active__${index}`]: activeKey === key,
              })}
              onClick={() => onChangeTab(key)}
            >
              <IconFont
                type={icon}
                className={`okr-process-tab_icon okr-process-tab_icon_${index}`}
              />
              {tab}
            </div>
          );
        })}
      </div>
      <SwitchMode tip="查看他人进展时，仅展示有进展的OKR" />
    </div>
  );
}

export default memo(ProcessTab);
