export const COLLECT = 'collect'; // OKR 汇总统计表
export const TARGET = 'target'; // 目标（O）明细表
export const KEY_RESULT = 'key_result'; // 关键成果（KR）明细表
export const WEEKLY = 'weekly'; // 周报明细表
export const SUMMARY = 'summary'; // 总结明细表

export enum ReportTypeEnum {
  COLLECT = 'collect',
  TARGET = 'target',
  KEY_RESULT = 'key_result',
  WEEKLY = 'weekly',
  SUMMARY = 'summary',
}

// 不同OKR报表ID与搜索项、table的映射关系
export const ReportMaps = {
  1: COLLECT,
  2: TARGET,
  3: KEY_RESULT,
  4: WEEKLY,
  5: SUMMARY,
};
// 不同OKR报表ID与搜索项 默认值关系
export const ReportDefeatFilterMaps = {
  1: { userScopeType: 2 },
  2: { userScopeType: 2, dateClass: '全部', year: new Date().getFullYear() },
  3: { userScopeType: 2, dateClass: '全部', year: new Date().getFullYear() },
};

// OKR状态
export const STATUS_LIST = [
  {
    id: null,
    name: '全部',
  },
  {
    id: 100,
    name: '无状态',
  },
  {
    id: 103,
    name: '红灯',
  },
  {
    id: 102,
    name: '黄灯',
  },
  {
    id: 101,
    name: '绿灯',
  },
];
