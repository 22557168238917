import React, { memo } from 'react';
import { Select, Spin, Empty, Tooltip } from 'antd';
import type { SelectProps } from 'antd';
import debounce from 'lodash/debounce';
import { useRequest } from 'ahooks';
import api from '@/api/okr';
import DeptOption from './dept-option';
import './index.less';

const { Option, OptGroup } = Select;

const SearchAll = (props: SelectProps) => {
  const { data, loading, run } = useRequest(api.searchForOkrMap, {
    manual: true,
  });

  const { userList, deptList, virDeptList } = data || {};

  const fetchInfo = debounce((value: string) => {
    run(value);
  }, 500);

  const onSearch = (value: string) => {
    if (!value) return;
    fetchInfo(value);
  };

  return (
    <Select
      className="m-search-all"
      showSearch
      placeholder="搜索同事/部门/虚拟组织查看ta的OKR"
      notFoundContent={
        loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
      filterOption={false}
      onSearch={onSearch}
      {...props}
    >
      {userList?.length ? (
        <OptGroup key="同事更多" label={<span style={{ color: '#01A79A' }}>同事</span>}>
          {userList.map((item) => {
            const { id, name, code, deptPath } = item;
            return (
              <Option key={id} name={name} userId={id}>
                <Tooltip title={`${name} (${code || '----'})   ${deptPath}`}>
                  {name} ({code || '----'})
                  <span style={{ marginLeft: 20, color: '#bbb', fontSize: 12 }}>{deptPath}</span>
                </Tooltip>
              </Option>
            );
          })}
        </OptGroup>
      ) : null}
      {deptList?.length ? (
        <OptGroup key="部门更多" label={<span style={{ color: '#01A79A' }}>部门</span>}>
          {deptList.map((d) => (
            <Option key={d.id} name={d.name} deptId={d.id}>
              <Tooltip title={d.deptPath}>
                {d.name}
                <span style={{ marginLeft: 20, color: '#bbb', fontSize: 12 }}>{d.deptPath}</span>
              </Tooltip>
            </Option>
          ))}
        </OptGroup>
      ) : null}
      {virDeptList?.length ? (
        <OptGroup key="虚拟组织更多" label={<span style={{ color: '#01A79A' }}>虚拟组织</span>}>
          {virDeptList.map((d) => (
            <Option key={d.id} name={d.name} virDeptCode={d.id}>
              <Tooltip title={d.deptPath}>
                {d.name}
                <span style={{ marginLeft: 20, color: '#bbb', fontSize: 12 }}>{d.deptPath}</span>
              </Tooltip>
            </Option>
          ))}
        </OptGroup>
      ) : null}
    </Select>
  );
};

export default memo(SearchAll);
