import React, { memo } from 'react';
import { Avatar } from '@/components';
import './index.less';

interface IProps {
  userList?: any;
}

const LikeUserList = (props: IProps) => {
  const { userList } = props;
  return (
    <div className="m-like-user-list">
      {userList?.map((item: any, index: number) => {
        const { name, photoUrl } = item || {};
        return (
          <div className="m-like-user-item" key={index}>
            <Avatar imgPath={photoUrl} key={index} userName={name} className="m-like-user-avatar" />
            <span className="ml-1">{name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default memo(LikeUserList);
