import React, { memo } from 'react';
import { Popover } from 'antd';
import { KrProcessStatusEnum } from '@/api/okr';
import { SUMMARY_TYPE_CODE, SCORE_OPTION, STATUS_OPTION } from '@/constants/okr';
import './index.less';

export const SUMMARY_TYPE_ASSESS = {
  [SUMMARY_TYPE_CODE.STAGE]: '状态',
  [SUMMARY_TYPE_CODE.SUM]: '评分',
};

const initColor = (num: any, list: any) => {
  let newNum = num;
  // num有值时
  if (num >= 0) {
    // 总结 整数（0 - 10）；
    // 小结 整数（101，102，103）；
    newNum = Math.floor(num);
    return (list.find((item: any) => `${item.key}` === `${newNum}`) || {}).color;
  }
  return null;
};

const excludeEmpty = (value: any) => {
  // null,  undefined， 会被模板字符串转成 字符；'null'可能是外部传入的
  const emptyValues = [null, 'null', undefined, ''];
  if (emptyValues.indexOf(value) >= 0) {
    // + (''|null|false|0) 都是0 ；
    return NaN;
  }
  return `${value}`;
};

interface IProps {
  type: number | string; // 总结的类型 1小结 2评价评分
  editable?: boolean;
  isMini?: boolean;
  summaryStatus: KrProcessStatusEnum; // 小结状态
  summaryScore?: number | string; // 总结评分
  onChange?: (value: number) => void;
  hideStatusLabel?: boolean;
  noBackgroundColor?: boolean;
}

const OkrScoreStatus = (props: IProps) => {
  const {
    editable,
    summaryScore,
    summaryStatus,
    type,
    isMini,
    hideStatusLabel,
    noBackgroundColor,
  } = props;

  const renderValue = () => {
    // 应用 小结状态
    const isStage = +type === SUMMARY_TYPE_CODE.STAGE;
    // 应用 总结评分
    const isSum = +type === SUMMARY_TYPE_CODE.SUM;
    // Select 组件选择列表
    const list = isStage ? STATUS_OPTION : SCORE_OPTION;
    const value = isStage ? excludeEmpty(summaryStatus) : excludeEmpty(summaryScore);
    const valueLabel = hideStatusLabel
      ? ''
      : (list.find((item) => `${item.key}` === `${value}`) || {}).label || value;

    const textColor = initColor(value, list);
    return (
      <span
        className={`text ${!isMini && 'tag'}`}
        style={{
          color: textColor,
          background: noBackgroundColor ? 'transparent' : undefined,
        }}
      >
        {/* 是小结 或 没有类型 */}
        {(isStage || !type) && '● '}
        {/* 是总结 且 非迷你展示 */}
        {isSum && !isMini && '● '}
        {/* 是总结 且 是迷你展示 且 没有总结评分 且 没有stateValue  */}
        {isSum && isMini && !summaryScore && '- '}
        {/* 是总结  */}
        {(isSum && valueLabel) || ''}
        {/* 是小结 且 非迷你展示 */}
        {(isStage && !isMini && valueLabel) || ''}
        {/* 没有stateValue 且 没有总结状态 且 没有小结评分 且 非迷你展示 */}
        {!summaryStatus &&
          !summaryScore &&
          !isMini &&
          !hideStatusLabel &&
          `暂无${
            isStage
              ? SUMMARY_TYPE_ASSESS[SUMMARY_TYPE_CODE.STAGE]
              : SUMMARY_TYPE_ASSESS[SUMMARY_TYPE_CODE.SUM]
          }`}
      </span>
    );
  };

  const renderDisplayLabel = () => {
    // 应用 小结状态
    const isStage = +type === SUMMARY_TYPE_CODE.STAGE;
    // 应用 总结评分
    const list = isStage ? STATUS_OPTION : SCORE_OPTION;
    const value = isStage ? excludeEmpty(summaryStatus) : excludeEmpty(summaryScore);
    const popoverValue = list.find((item) => item.key === +value)?.popover;
    if (popoverValue) {
      return (
        <Popover content={popoverValue} placement="left" arrow>
          {renderValue()}
        </Popover>
      );
    }
    return renderValue();
  };

  return (
    <div className="u-common-summary-score-or-status">
      {editable ? <div /> : renderDisplayLabel()}
    </div>
  );
};

export default memo(OkrScoreStatus);
