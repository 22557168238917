import { memo } from 'react';
import { Link, history } from 'umi';
import { useRequest } from 'ahooks';
import { Avatar } from '@/components';
import CommentContent from '@/components/comment/comment-content';
import api, { IMessageItem } from '@/api/message';
import { REPLY_TYPE, OKR_TYPE } from '@/constants/message';
import { getLink } from '@/layouts/message/popover';
import { changeDateDesc } from '@/utils/date';
import { transferComment } from '@/utils/tools';
import { useMessageStore } from '@/store';
import './index.less';

interface IProps {
  data: IMessageItem;
}

const MessageItem = (props: IProps) => {
  const { data } = props;

  const { getNewMessageList } = useMessageStore();

  const {
    type,
    targetType,
    titleName,
    updateTime,
    sysTime,
    content,
    messageReadFlag,
    id,
    mainContent,
    userVO,
    targetUserVO,
    receiverVO,
  } = data || {};

  const { id: userId, photoUrl, name: userName } = userVO || {};
  const { id: targetUserId, name: targetUserName } = targetUserVO || {};
  const { id: receiverId } = receiverVO || {};

  const link = getLink(data);

  const { run } = useRequest(() => api.readMessage([id]), { manual: true });

  const onClickCard = () => {
    if (!link) return;
    onReadMessage();
    history.push(link);
  };

  const onReadMessage = () => {
    if (!messageReadFlag) {
      run();
      getNewMessageList();
    }
  };

  //@ts-ignore
  const targetStr = OKR_TYPE[targetType] || '';
  //@ts-ignore
  const actionStr = REPLY_TYPE[type] || '';
  const getMessageTitle = () => {
    // @
    if ([3, 4, 8, 12, 13, 14].includes(type)) {
      return `在${targetStr}【${titleName}】中${actionStr}了你`;
    }
    // 回复评论
    if (type === 2) {
      return `回复了你的评论【${mainContent ? transferComment(mainContent) : '--'}】`;
    }
    // 指定OKR负责人
    if (titleName.includes('负责人')) {
      return `指定你为【${content}】负责人`;
    }
    if (type === 9) {
      return content;
    }
    return `${actionStr}了${
      targetUserId === receiverId ? '你' : targetUserName
    }的${targetStr}【${titleName}】`;
  };

  const onLinkClick = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div
      className="m-message-item"
      onClick={onClickCard}
      style={messageReadFlag ? {} : { background: '#F7FBFF' }}
    >
      <Link to={{ pathname: `/team/${userId}`, search: '?tab=okr' }} onClick={onLinkClick}>
        <Avatar imgPath={photoUrl} userName={userName} className="message-item-left" />
      </Link>
      <div className="message-item-right">
        <div className="message-item-base">
          <div className="items-center flex">
            <Link
              to={{ pathname: `/team/${userId}`, search: '?tab=okr' }}
              className="message-item-link m-message-name"
              onClick={onLinkClick}
            >
              {userName}
            </Link>
            {!messageReadFlag && <div className="m-new-dot" />}
          </div>
          <div>
            <span
              className="message-item-title"
              dangerouslySetInnerHTML={{ __html: getMessageTitle() }}
            />
          </div>
          {type !== 9 && (
            <div className="message-item-detail">
              <CommentContent>{content}</CommentContent>
            </div>
          )}
          <div className="message-item-time">{changeDateDesc(updateTime, sysTime)}</div>
        </div>
        {link && (
          <div className="message-to-detail">
            <Link to={link} className="message-item-link" onClick={onReadMessage}>
              查看详情
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(MessageItem);
