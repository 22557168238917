import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import uniqBy from 'lodash/uniqBy';
import { ProcessSourceTypeEnum } from '@/api/process';

/**
 * 共享范围展示
 * @param authShareInfo
 * @returns {string | undefined}
 */
export function getAuthShareName(authShareInfo: any) {
  const { authDeptList = [], authVirDeptList = [], authUserList } = authShareInfo || {};
  const shareDeptList = [...(authDeptList || []), ...(authVirDeptList || [])].map((item) => {
    const { deptName, virDeptName, deptId, virDeptCode } = item;
    return {
      id: virDeptCode || deptId,
      name: virDeptName || deptName,
    };
  });
  if (authUserList?.length) {
    shareDeptList.push(...(authUserList || []));
  }
  return uniqBy(shareDeptList, 'id')
    .map((item) => item.name)
    .join('，');
}

export function formatProcessList(list: any, processSourceType: ProcessSourceTypeEnum) {
  let tempMonth = '';
  if (!Array.isArray(list)) {
    Sentry.captureException({
      message: 'formatProcessList list出错了',
      extra: {
        list,
      },
    });
    return [];
  }
  return list?.map((item) => {
    const { authShareList, startDate, month } = item;
    let isShowMonth = false;
    if (month !== tempMonth) {
      isShowMonth = true;
      tempMonth = month;
    }
    return {
      ...item,
      monthText: dayjs(startDate).format('YYYY年 MM月'),
      isShowMonth:
        processSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT ? isShowMonth : false,
      authShareName: getAuthShareName(authShareList),
      authShareList: undefined,
    };
  });
}
