// @ts-nocheck
const DragDom = (dv, options) => {
  let x = 0;
  let y = 0;
  let l = 0;
  let t = 0;
  let isDown = false;
  let isMoving = false;

  const { onDragEnd } = options || {};

  // 鼠标按下事件
  dv.onmousedown = (e) => {
    // 获取x坐标和y坐标
    x = e.clientX;
    y = e.clientY;

    // 获取左部和顶部的偏移量
    l = dv.offsetLeft;
    t = dv.offsetTop;
    // 开关打开
    isDown = true;
    // 设置样式
    dv.style.cursor = 'move';
  };
  // 鼠标移动
  window.onmousemove = (e) => {
    if (isDown == false) {
      return;
    }
    // 获取x和y
    let nx = e.clientX;
    let ny = e.clientY;
    // 计算移动后的左偏移量和顶部的偏移量
    let nl = nx - (x - l);
    let nt = ny - (y - t);
    isMoving = true;
    dv.style.left = `${nl}px`;
    dv.style.top = `${nt}px`;
  };
  // 鼠标抬起事件
  window.onmouseup = (e) => {
    if (isMoving) {
      (e || window.event).stopImmediatePropagation();
      (e || window.event).stopPropagation();
      onDragEnd?.();
    }
    // 开关关闭
    isDown = false;
    isMoving = false;
    dv.style.cursor = 'default';
  };
  DragDom.clean = ({ left, top }) => {
    x = 0;
    y = 0;
    l = 0;
    t = 0;
    isDown = false;
    dv.style.cursor = 'default';
    dv.style.left = `${left}px`;
    dv.style.top = `${top}px`;
  };
};
export default DragDom;
