import React, { useState, useRef, useEffect } from 'react';
import Split from 'react-split';
import type { SplitProps } from 'react-split';

export interface ISplitProps extends SplitProps {
  children: React.ReactNode;
  minSize?: number[];
  direction?: 'horizontal' | 'vertical';
}

export default (props: ISplitProps) => {
  const { children, minSize = [200], direction = 'horizontal', ...rest } = props;

  const [initialSizes, setInitialSizes] = useState<number[]>([]);
  const ref = useRef<any>();

  useEffect(() => {
    if (direction === 'horizontal') {
      const width = ref.current?.getBoundingClientRect().width;
      const leftPercent = (minSize?.[0] / width) * 100;
      setInitialSizes([leftPercent, 100 - leftPercent]);
    } else {
      const height = ref.current?.getBoundingClientRect().height;
      const topPercent = (minSize?.[0] / height) * 100;
      setInitialSizes([topPercent, 100 - topPercent]);
    }
  }, []);

  return (
    <div ref={ref}>
      {/* @ts-ignore */}
      <Split sizes={initialSizes} minSize={minSize} direction={direction} {...rest}>
        {children}
      </Split>
    </div>
  );
};
