/**
 * 获取默认的分享组织 (真实部门，虚拟部门)
 *
 */

import { useState, useEffect } from 'react';
import api, { AuthShareType, IAuthDeptVO } from '@/api/auth';

const useGetDefaultScope = (oId: number, manual = false) => {
  const [defaultScope, setDefaultScope] = useState<IAuthDeptVO[]>([]);
  const [defaultVirScope, setDefaultVirScope] = useState<IAuthDeptVO[]>([]);

  const fetch = () =>
    new Promise((resolve) => {
      Promise.all([
        api.getDefaultScope({ authType: AuthShareType.OKR, id: oId }),
        api.getDefaultVirDeptList({ authType: AuthShareType.OKR, id: oId }),
      ]).then(([deptList, virDeptList]) => {
        setDefaultScope(deptList);
        setDefaultVirScope(virDeptList);
        resolve({
          defaultScope: deptList,
          defaultVirScope: virDeptList,
        });
      });
    });

  useEffect(() => {
    !manual && fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { defaultScope: defaultScope, defaultVirScope: defaultVirScope, run: fetch };
};

export default useGetDefaultScope;
