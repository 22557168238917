import api from '@/api';
import { GATEWAY } from '@/api/config';
import { ResponsePromise } from '../interface';
import {
  IUserInfo,
  IUserTree,
  ISourceAuthParams,
  IAuthDeptVO,
  IAuthShareUpdatePayload,
  IUserPermVO,
} from './interface';

export default {
  getUserInfo: (userId?: number): ResponsePromise<IUserInfo> =>
    api.get(`${GATEWAY}/user/getUserInfo`, { userId }),

  // 查询是否有赏金猎人权限
  getHunterPermission: (): ResponsePromise<IUserPermVO> => api.get('/api/bounty/hunter/user/perm'),

  // 用户树（上级、同级、关注）
  getUserTree: (params: { userId: number; isUnd?: boolean }): ResponsePromise<IUserTree> =>
    api.get(`${GATEWAY}/user/userTree`, params),

  // 是否有关注的人员
  getFollowCount: (): ResponsePromise<number> => api.get(`${GATEWAY}/attention/user/count`),
  // 添加置顶关注
  addAttention: (attentionUserId: number) =>
    api.post(`${GATEWAY}/attention/add`, { attentionUserId }),
  // 取消置顶关注
  delAttention: (attentionUserId: number) =>
    api.get(`${GATEWAY}/attention/del`, { attentionUserId }),
  // OKR/周报/总结-选择共享组织 只查询自己有权限的共享部门，不查询是部门的管理员
  getDefaultScope: (params: ISourceAuthParams): ResponsePromise<IAuthDeptVO[]> =>
    api.get(`${GATEWAY}/auth/share/depts`, params),
  // OKR/周报/总结-选择共享虚拟组织
  getDefaultVirDeptList: (params: ISourceAuthParams): ResponsePromise<IAuthDeptVO[]> =>
    api.get(`${GATEWAY}/auth/share/virDepts`, params),
  // 修改 OKR 共享范围
  authShareUpdate: (payload: IAuthShareUpdatePayload) =>
    api.put(`${GATEWAY}/auth/share/update`, payload),

  // 获取共享设置默认的 获取当前用户直接主管
  getLeader: (): ResponsePromise<IUserInfo> => api.get(`${GATEWAY}/user/leader`),
  // 获取默认个人共享访问列表
  getAuthSelf: () => api.get(`${GATEWAY}/share/center/user/default/auth`),
  // 默认共享访问删除个人
  delAuthSelf: (userId: number, authType: string) =>
    api.delete(`${GATEWAY}/share/center/user/default/auth`, { userId, authType }),
  // 默认共享访问添加个人
  addAuthSelf: (userId: number, authType: string) =>
    api.post(`${GATEWAY}/share/center/user/default/auth`, { userId, authType }),
  // 获取最近联系人列表
  getContactMentionUserList: () => api.get(`${GATEWAY}/user/mention/list`),
};

export * from './interface';
