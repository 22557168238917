import React, { useState } from 'react';
import { Modal } from 'antd';
import type { ModalProps } from 'antd';
import SearchUser from '.';
import type { ISearchUserProps } from '.';

interface IProps extends ISearchUserProps {
  modalProps?: ModalProps;
  handleOk: (list: any) => void;
}

const SearchUserModal = (props: IProps) => {
  const { modalProps, handleOk, ...rest } = props;

  const [selected, setSelected] = useState([]);

  const onOk = () => {
    handleOk?.(selected);
    // @ts-ignore
    modalProps?.onCancel?.();
  };

  const onChangeUser = (value: any, list: any) => {
    setSelected(list);
  };

  return (
    <Modal maskClosable={false} destroyOnClose title="人员选择" onOk={onOk} {...(modalProps || {})}>
      {modalProps?.open && <SearchUser {...rest} multiple onChange={onChangeUser} />}
    </Modal>
  );
};

export default SearchUserModal;
