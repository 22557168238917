/**
 * 周期级联筛选
 *
 * @param userId
 * @param deptId {string}
 * @param type {string} 'user' | 'dept' | 'virDept' 查询周期类型
 * @param currentCycles {boolean} 定位到当前时间周期范围
 */

import React, { useState, useEffect, memo } from 'react';
import { Cascader } from 'antd';
import { YEAR_LIST } from '@/constants/okr';
import { useUpdateEffect } from 'ahooks';
import okrApi, { PeriodTypeEnum } from '@/api/okr';
import { getCurrentPeriod } from '@/utils/cycles';
import './index.less';

interface IProps {
  userId?: number;
  defaultValue?: any;
  onChange?: any;
  type?: 'user' | 'dept' | 'virDept'; // 查询周期类型
  deptId?: string;
  currentCycles?: boolean;
  value?: any;
  disabled?: boolean;
}

const CyclesCascader = (props: IProps) => {
  const {
    defaultValue,
    onChange,
    type = 'user',
    userId,
    deptId,
    currentCycles = false,
    ...rest
  } = props;

  const [options, setOptions] = useState(YEAR_LIST);
  const fetch = (year: number) =>
    type === 'user'
      ? okrApi.getPeriodUser({ userId, periodType: PeriodTypeEnum.okr, year })
      : type === 'dept'
      ? okrApi.getPeriodDept({
          deptId,
          periodType: PeriodTypeEnum.okr,
          year,
        })
      : okrApi.getPeriodVirDept({
          deptId,
          periodType: PeriodTypeEnum.okr,
          year,
        });

  const loadChildData = (year: number, yearList = options, cyclesChange = false) => {
    const index = yearList.findIndex((i) => i.value == year);
    const cur: any = Object.assign({}, yearList[index]);

    fetch(year).then((periodList) => {
      const oldDateId = props?.value?.[1];
      if (!Array.isArray(periodList)) return;
      // 周期列表变更后根据当前时间定位周期
      const setCurrentCycles = !periodList?.some((i) => i.id === oldDateId);
      if (cyclesChange && setCurrentCycles) {
        const [dateId] = getCurrentPeriod(periodList);
        const currentOption = periodList.find((i) => i.id === dateId);
        const newValue = [year, dateId];
        const newOptions = [
          { label: year, value: year },
          {
            label: currentOption?.period,
            value: currentOption?.id,
            ...currentOption,
          },
        ];
        onChange && onChange(newValue, newOptions);
      }
      const childrenArr =
        periodList.map((i) => ({
          ...i,
          label: i.period,
          value: i.id,
        })) || [];

      cur.children = childrenArr;
      const arr = [...yearList];
      arr[index] = cur;
      setOptions(arr);
    });
  };

  const loadData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    loadChildData(targetOption.value);
  };

  useEffect(() => {
    if (currentCycles) {
      loadChildData(new Date().getFullYear(), YEAR_LIST, true);
    } else {
      defaultValue && loadChildData(defaultValue[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, currentCycles]);

  // id改变需重新查询周期列表
  useUpdateEffect(() => {
    const year = props?.value?.[0] || new Date().getFullYear();
    loadChildData(year, YEAR_LIST, true);
  }, [userId, deptId]);

  return (
    <Cascader
      className="m-cycles-cascader"
      defaultValue={defaultValue}
      options={options}
      expandTrigger="hover"
      loadData={loadData}
      allowClear={false}
      placeholder="请选择周期"
      onChange={onChange}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      {...rest}
    />
  );
};

export default memo(CyclesCascader);
