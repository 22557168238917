import create from 'zustand';
import { IOkrDetail } from '@/api/okr';
import { O_KR_ENUM } from '@/constants';

interface IDetailInfo {
  type: O_KR_ENUM;
  detailId: number;
  index: number;
  tabActivityKey: string;
}

export interface IOkrStore {
  clearAll: () => void;
  currentData: IOkrDetail;
  setCurrentData: (data: IOkrDetail) => void;
  detailInfo: IDetailInfo;
  setDetailInfo: (data: IDetailInfo) => void;
  hasEdit: boolean;
  setHasEdit: (hasEdit: boolean) => void;
  readonly: boolean;
  setReadonly: (readonly: boolean) => void;
}

const useOkrStore = create<IOkrStore>((set) => ({
  clearAll: () =>
    set({
      currentData: {} as IOkrDetail,
      detailInfo: {} as IDetailInfo,
      hasEdit: false,
      readonly: false,
    }),
  currentData: {} as IOkrDetail,
  setCurrentData: (data: IOkrDetail) => set({ currentData: data }),
  detailInfo: {} as IDetailInfo,
  setDetailInfo: (data: IDetailInfo) => set({ detailInfo: data }),
  hasEdit: false,
  setHasEdit: (hasEdit: boolean) => set({ hasEdit }),
  readonly: false,
  setReadonly: (readonly: boolean) => set({ readonly }),
}));

export default useOkrStore;
