import React, { useState } from 'react';
import { App, Tooltip } from 'antd';
import { EditOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { CyclesCascader } from '@/components';
import { useToggle } from 'ahooks';
import { useOkrStore } from '@/store';
import okrApi from '@/api/okr';
import './index.less';

interface IProps {
  authEdit?: boolean;
  titleName: string;
  defaultValue: any;
  onRefresh?: () => void;
  cyclesUserId: number;
  id: number;
}

const OkrDate = (props: IProps) => {
  const { authEdit = false, titleName, defaultValue, onRefresh, cyclesUserId, id } = props;

  const { setHasEdit } = useOkrStore();

  const [state, { toggle }] = useToggle(false);
  const [value, setValue] = useState(defaultValue);
  const [dateId, setDateId] = useState(defaultValue?.[1]);

  const { modal } = App.useApp();

  const onSubmit = () => {
    modal.confirm({
      title: '修改周期后，该OKR将会转移到新的OKR周期，是否继续？',
      onOk: () =>
        okrApi
          .updateObjectiveDate({
            dateId,
            id,
          })
          .then(() => {
            toggle();
            setHasEdit(true);
            onRefresh?.();
          }),
    });
  };

  const onChange = (value: any) => {
    const dateId = value?.[1];

    setValue(value);
    setDateId(dateId);
  };

  const onCancel = () => {
    setValue(defaultValue);
    setDateId(defaultValue?.[1]);
    toggle();
  };

  return state ? (
    <div className="m-okr-date-edit">
      <div className="m-edit-flex">
        <CyclesCascader
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          userId={cyclesUserId}
        />
        <a onClick={onCancel} style={{ color: '#666' }}>
          取消
        </a>
        <a onClick={onSubmit}>确认</a>
      </div>
    </div>
  ) : (
    <span className="m-okr-date">
      <Tooltip title="OKR周期" placement="bottom">
        <ClockCircleOutlined /> <span>{titleName}</span>
      </Tooltip>{' '}
      {authEdit && <EditOutlined className="m-okr-date-edit" onClick={toggle} />}
    </span>
  );
};

export default OkrDate;
