import React, { useState } from 'react';
import { Tabs } from 'antd';
import { history } from 'umi';
import { IconFont } from '@/components';
import { getParams } from '@/utils/tools';
import { OkrTabs } from './components';
import Personal from './personal';
import Organization from './organization';
import AlignMap from './align-map';
import OKRShareCenter from './share';
import './index.less';

const { TabPane } = Tabs;

const Okr = () => {
  const routeParam: any = getParams();
  const [activeKey, setActiveKey] = useState(routeParam.tab || 'user');

  const onTabChange = (key: React.Key) => {
    setActiveKey(key);
    history.replace(`/okr?tab=${key}`);
  };

  const TAB_LIST = [
    {
      tab: '个人OKR',
      key: 'user',
      icon: <IconFont type="icon-wodeOKR" className="okr-process-tab_icon m-icon-mine" />,
      eventId: 'tab_okr_mine',
      Component: Personal,
    },
    {
      tab: '组织OKR',
      key: 'org',
      icon: <IconFont type="icon-tuanduiOKR" className="okr-process-tab_icon m-icon-org" />,
      eventId: 'tab_okr_organization',
      activeStyle: {
        color: '#ef6b0b',
        backgroundColor: '#fdede3',
      },
      Component: Organization,
    },
    {
      tab: 'OKR对齐地图',
      key: 'alignMap',
      icon: <IconFont type="icon-zuzhiOKR" className="okr-process-tab_icon m-icon-map" />,
      eventId: 'tab_okr_alignMap',
      activeStyle: {
        color: '#fca30a',
        backgroundColor: '#fff4e3',
      },
      Component: AlignMap,
    },
    {
      tab: '共享中心',
      key: 'share',
      icon: <IconFont type="icon-gongxiang1" className="okr-process-tab_icon m-icon-share" />,
      eventId: 'tab_okr_share',
      activeStyle: {
        color: '#01A79B',
        backgroundColor: '#E6F6F5',
      },
      Component: OKRShareCenter,
    },
  ];

  return (
    <div className="p-okrs">
      <div className="m-header-tabs">
        <OkrTabs tabList={TAB_LIST} activeKey={activeKey} onChange={onTabChange} />
      </div>
      <div className="m-okt-wrap">
        <Tabs
          animated
          tabBarStyle={{
            display: 'none',
          }}
          activeKey={activeKey}
          onChange={setActiveKey}
          destroyInactiveTabPane
        >
          {TAB_LIST.map((item) => {
            const { tab, key, Component } = item;
            if (!Component) return null;
            return (
              <TabPane tab={tab} key={key}>
                <Component />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default Okr;
