import React, { memo, useState, useRef } from 'react';
import { Popover, Tree } from 'antd';
import type { TreeProps } from 'antd';
import cls from 'classnames';
import { IconFont } from '@/components';
import { ShareQueryTypeEnum } from '@/api/okr';
import commonApi from '@/api/common';
import { useTreeData } from '@/hooks';

interface IProps {
  isSelected: boolean;
  onSelect: (condition: any) => void;
  isVirtual?: boolean;
}

const DeptFilter = (props: IProps) => {
  const { isSelected, onSelect: onSelectTree, isVirtual } = props;

  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();

  const { treeData, onLoadData } = useTreeData(
    isVirtual ? commonApi.getVirDeptTree : commonApi.getDeptTree,
    {
      getPayload: (params: any) => (isVirtual ? params?.id : params?.deptId),
      // @ts-ignore
      listToNodes: (deptList) => {
        return deptList.map((item) => {
          const { deptId, deptName, hasChildren } = item;
          return {
            ...item,
            key: deptId,
            value: deptId,
            title: deptName,
            isLeaf: !hasChildren,
          };
        });
      },
      onSuccess: (res) => {
        !isVirtual &&
          onSelectTree?.({
            type: ShareQueryTypeEnum.REAL_DEPT,
            deptId: res?.[0]?.deptId,
          });
        setSelectedData(res?.[0]);
      },
    },
  );

  const onSelect: TreeProps['onSelect'] = (selectedKeys, { node }) => {
    setSelectedData(node);
    setIsOpen(false);
    onSelectTree?.({
      type: isVirtual ? ShareQueryTypeEnum.VIR_DEPT : ShareQueryTypeEnum.REAL_DEPT,
      deptId: selectedKeys[0],
    });
  };

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  // @ts-ignore
  const selectedKey = selectedData?.deptId || '';

  const onClick = (e: any) => {
    if (e.target !== ref.current) return;
    onSelectTree?.({
      type: isVirtual ? ShareQueryTypeEnum.VIR_DEPT : ShareQueryTypeEnum.REAL_DEPT,
      deptId: selectedKey,
    });
  };

  const displayName = selectedData?.deptName;

  return (
    <div className="dept-list-wrap" onClick={onClick}>
      <Popover
        //@ts-ignore
        getPopupContainer={() => document.getElementById('okr-share-page')}
        content={
          <Tree
            treeData={treeData}
            //@ts-ignore
            selectedKeys={[selectedKey]}
            onSelect={onSelect}
            loadData={onLoadData}
            fieldNames={{
              title: 'deptName',
              key: 'deptId',
            }}
          />
        }
        open={isOpen}
        onOpenChange={onOpenChange}
        placement="bottomLeft"
        trigger="click"
      >
        <div
          className={cls('dept-select__btn', {
            'dept-select__btn-disabled': !isSelected,
          })}
        >
          <span className="dept-name-value" ref={ref}>
            {displayName ? `${isVirtual ? '虚拟：' : ''}${displayName}` : '请选择部门'}
          </span>
          <IconFont type="icon-unie6612" className={isOpen ? 'icon icon-up' : 'icon'} />
        </div>
      </Popover>
    </div>
  );
};

export default memo(DeptFilter);
