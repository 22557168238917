import { TreeSelect } from 'antd';
import api from '@/api/setting';
import { useTreeData } from '@/hooks';

const { SHOW_PARENT } = TreeSelect;

export const ALL = 1;
export const UNDERLING = 2;

interface IProps {
  value?: any;
  onChange?: (value: any) => void;
}

// 人员范围
const StaffRange = (props: IProps) => {
  const { value, onChange } = props;

  const { treeData, onLoadData } = useTreeData(api.getDeptTree, {
    getPayload: (params: any) => params?.deptId,
    //@ts-ignore
    listToNodes: (deptList) => {
      return deptList.map((item) => {
        const { deptId, deptName, hasChildren } = item;
        return {
          ...item,
          key: deptId,
          value: deptId,
          title: deptName,
          isLeaf: !hasChildren,
        };
      });
    },
  });

  const onChangeSelect = (newValue: any) => {
    // 注意全部、我的直接下属、部门是互斥的选项
    // 判断是否是新增操作
    let valueData = newValue;
    if (newValue.length > value.length) {
      // 找到新增节点的value
      const selectedValue = newValue.find((item: any) => !value.includes(item));

      // 如果新增节点是一个互斥选项
      if ([ALL, UNDERLING].includes(selectedValue)) {
        valueData = [selectedValue];
      } else {
        valueData = newValue.filter((item: any) => ![ALL, UNDERLING].includes(item));
      }
    }
    onChange?.(valueData);
  };

  const displayTreeData = [
    {
      title: '全部',
      value: ALL,
      isLeaf: true,
    },
    {
      title: '我的直接下属',
      value: UNDERLING,
      isLeaf: true,
    },
    ...(treeData || []),
  ];

  return (
    <TreeSelect
      treeData={displayTreeData}
      //@ts-ignore
      loadData={onLoadData}
      value={value}
      onChange={onChangeSelect}
      treeCheckable
      showCheckedStrategy={SHOW_PARENT}
      placeholder="请选择"
      style={{ width: 200 }}
      maxTagCount={1}
    />
  );
};

export default StaffRange;
