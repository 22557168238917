import React, { memo, useState, useEffect } from 'react';
import classnames from 'classnames';
import './index.less';

interface IProps {
  userName?: string;
  imgPath?: string;
  className?: any;
  type?: AvatarSize;
  style?: React.CSSProperties;
}

export type AvatarSize = 'small' | 'middle' | 'big';

const Avatar = (props: IProps) => {
  const { userName, style, imgPath, type = 'small', className } = props;

  const [imageSrc, setImageSrc] = useState<string | undefined>(imgPath);

  const handleImageError = () => {
    setImageSrc(undefined);
  };

  useEffect(() => {
    setImageSrc(imgPath);
  }, [imgPath]);

  const getImageContent = () => {
    if (!userName) return '';
    return /[\u4E00-\u9FA5]+/.test(userName)
      ? userName.slice(0, 1)
      : userName.slice(0, 1).toUpperCase();
  };

  const imgContent = getImageContent();
  return (
    <div
      className={classnames({
        'm-avatar': true,
        [className]: true,
        'avatar-24': type === 'small',
        'avatar-48': type === 'middle',
        'avatar-80': type === 'big',
      })}
      style={style}
    >
      {imageSrc ? (
        <img src={imageSrc} alt={imgContent} onError={handleImageError} />
      ) : (
        <span className="none-avatar">{imgContent}</span>
      )}
    </div>
  );
};

export default memo(Avatar);
