import { memo, useState, useEffect, useRef } from 'react';
import { Modal, Input, Typography } from 'antd';
import { useRequest } from 'ahooks';
import authApi, { IUserInfo } from '@/api/auth';
import api from '@/api/common';
import { Avatar } from '@/components';
import './index.less';

interface IProps {
  open: boolean;
  onCancel: () => void;
  handleSelect: (user: IUserInfo) => void;
  getContainer?: any;
  autoFocus?: boolean;
}

const UserList = ({ list, title, onSelect }: any) => {
  return list?.length ? (
    <div className="single-select-bd">
      <div className="single-select-bd-title">{title}</div>
      <div className="single-select-bd-cont">
        {list.map((item: IUserInfo) => (
          <div key={item.id} className="select-user-item" onClick={() => onSelect(item)}>
            <Avatar
              type="small"
              imgPath={item.photoUrl}
              className="select-user-avatar"
              userName={item.name}
            />
            <div className="select-user-item-right">
              <div>
                {item.name} （{item.uNo}）
              </div>
              <Typography.Text ellipsis={{ tooltip: true }} className="user-item-dept">
                {item.deptPath || `${item.deptL1Name}-${item.deptName}}`}
              </Typography.Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

const CommentSelectUser = (props: IProps) => {
  const { open, onCancel, handleSelect, getContainer, autoFocus = true } = props;

  const [searchValue, setSearchValue] = useState('');

  const inputRef = useRef(null);

  const { run, data } = useRequest((searchTerm?: string) => api.searchUser({ searchTerm }), {
    manual: true,
    debounceWait: 500,
  });

  const { data: mentionList } = useRequest(authApi.getContactMentionUserList);

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value || '');
    if (e.target.value) {
      run(e.target.value);
    }
  };

  const handleCheck = (user: IUserInfo) => {
    onCancel?.();
    handleSelect?.(user);
  };

  const afterOpen = () => {
    //@ts-ignore
    inputRef.current?.focus();
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        afterOpen();
      }, 500);
    }
  }, [open, afterOpen]);

  return (
    <Modal
      width={340}
      title="人员选择"
      onCancel={onCancel}
      open={open}
      destroyOnClose
      maskClosable={false}
      footer={null}
      getContainer={getContainer}
    >
      {open && (
        <div className="m-single-select">
          <div className="single-select-input">
            <Input
              placeholder="在此搜索人员"
              onChange={handleSearch}
              autoFocus={autoFocus}
              ref={inputRef}
            />
          </div>
          {searchValue ? (
            <UserList list={data?.dataList} title="搜索结果" onSelect={handleCheck} />
          ) : (
            <UserList list={mentionList} title="最近@" onSelect={handleCheck} />
          )}
        </div>
      )}
    </Modal>
  );
};

export default memo(CommentSelectUser);
