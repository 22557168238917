import React, { memo } from 'react';
import { Typography } from 'antd';
import { DiscoverTypeEnum, IDynamicProcessVO } from '@/api/discover';
import { OkrScoreStatus, ReadonlyRichText, OLabel, KrLabel } from '@/components';
import { getParams } from '@/utils/tools';
import { dataTrack } from '@/utils/track';
import styles from './process-card.less';

interface IProps {
  data: IDynamicProcessVO;
  type: DiscoverTypeEnum;
}

const PROCESS_DYNAMIC_TEXT_MAP = {
  [DiscoverTypeEnum.PROCESS_WEEK_REPORT]: '周报',
  [DiscoverTypeEnum.PROCESS_CONCLUSION]: '小结',
  [DiscoverTypeEnum.PROCESS_SUMMARY]: '总结',
};

const EVENT_ID_MAP = {
  team: 'click_discover_team_process',
  follow: 'click_discover_follow_process',
};

const ProcessContent = ({ processSourceType, process }: any) => {
  return (
    <>
      {processSourceType !== DiscoverTypeEnum.PROCESS_WEEK_REPORT && (
        <div className={styles.krStatus}>
          <OkrScoreStatus
            type={processSourceType === DiscoverTypeEnum.PROCESS_SUMMARY ? 2 : 1}
            summaryScore={process?.evaluationScore}
            summaryStatus={process?.evaluationStatus}
          />
        </div>
      )}
      {!!process?.content && process?.content !== '<p></p>' && (
        <ReadonlyRichText
          content={process?.content}
          className={styles.processContent}
          updateTime={process?.updateTime}
        />
      )}
    </>
  );
};

const ProcessCard = (props: IProps) => {
  const { data, type } = props;

  const { processCommon, processSourceType, dynamicProcessOkrVOList, processOther } = data || {};
  const { name, id } = processCommon || {};

  const urlParams: any = getParams();

  // @ts-ignore
  const logoText = PROCESS_DYNAMIC_TEXT_MAP[type] || '周报';

  const onClick = () => {
    window.open(`/#/process/dashboard?tab=detail&type=${processSourceType}&id=${id}`);

    // @ts-ignore
    const eventId = EVENT_ID_MAP[urlParams?.tab] || '';
    if (!eventId) return;
    dataTrack(eventId);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.img}>{logoText}</div>
        <Typography.Text ellipsis={{ tooltip: true }} className={styles.name} onClick={onClick}>
          {name}
        </Typography.Text>
      </div>
      <div className={styles.process}>
        {dynamicProcessOkrVOList?.map((item) => {
          const { id, name, dynamicProcessKrVOList, process } = item;
          return (
            <div key={id} className={styles.processItem}>
              <div className={styles.processItemTop}>
                <OLabel />
                <div className="flex-1" style={{ overflow: 'hidden' }}>
                  <div className={styles.oNameContainer}>
                    <Typography.Text
                      ellipsis={{ tooltip: true }}
                      className={styles.oName}
                      style={{ lineHeight: '24px' }}
                    >
                      {name}
                    </Typography.Text>
                  </div>
                  <ProcessContent process={process} processSourceType={processSourceType} />
                </div>
              </div>
              {dynamicProcessKrVOList?.map((kr) => {
                const { id: krId, name: krName, process } = kr || {};
                return (
                  <div className={styles.krContainer} key={krId}>
                    <KrLabel />
                    <div className={styles.krContent}>
                      <div>
                        <Typography.Text ellipsis={{ tooltip: true }} className={styles.oName}>
                          {krName}
                        </Typography.Text>
                      </div>
                      <ProcessContent process={process} processSourceType={processSourceType} />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
        {!!processOther && (
          <div
            className={`${styles.other} ${
              dynamicProcessOkrVOList?.length ? '' : styles.noMarginTop
            }`}
          >
            <div className={styles.otherTitle}>
              <span className={styles.otherText}>其他</span>
            </div>
            <div>
              <div className={styles.oName} style={{ lineHeight: '24px' }}>
                其他
              </div>
              {!!processOther?.content && processOther?.content !== '<p></p>' && (
                <ReadonlyRichText
                  content={processOther?.content}
                  className={styles.processContent}
                  updateTime={processOther?.updateTime}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ProcessCard);
