/**
 * @param {string} type 搜索项类型
 * @param {object} params 导出报表参数
 */
import React, { forwardRef, useImperativeHandle } from 'react';
import { Form, Button } from 'antd';
import { ReportTypeEnum } from '@/constants';
import api from '@/api/setting';
import { ExportButton } from '@/components';
import { SearchItem, formatData } from './config';
import './index.less';

interface IProps {
  params: any;
  type: ReportTypeEnum;
  onSearch: (params: any) => void;
  onReset?: () => void;
  fileName: string;
}

const Search = forwardRef((props: IProps, ref) => {
  const { type, fileName, params, onSearch, onReset } = props;
  // 搜索
  const [form] = Form.useForm();

  const handleSearch = () => {
    onSearch(formatData(form.getFieldsValue()));
  };

  // 重置
  const handleReset = () => {
    form.resetFields();
    onReset?.();
  };

  useImperativeHandle(ref, () => ({
    // 获取搜索参数
    getSearchParams() {
      return formatData(form.getFieldsValue());
    },
  }));

  const getFields = () => {
    const children: any = [];
    // @ts-ignore
    const fieldList = SearchItem[type];
    fieldList?.forEach((item: any) => {
      children.push(
        <Form.Item label={item.label} name={item.key} key={item.key} {...(item.formItemApi || {})}>
          {item.component}
        </Form.Item>,
      );
    });

    return children;
  };

  const getTaskId = async () => {
    const processKey = await api.getTaskId(params);
    return processKey;
  };

  return (
    <div className="m-search">
      <Form className="form-wraps" form={form}>
        {getFields()}
      </Form>
      <div className="btn-wraps">
        <Button onClick={handleReset}>重置</Button>
        <Button onClick={handleSearch} type="primary">
          搜索
        </Button>
        <ExportButton getTaskId={getTaskId} />
      </div>
    </div>
  );
});

export default Search;
