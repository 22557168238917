import { useState } from 'react';
import { Select, Divider, InputNumber, Popover, Button } from 'antd';
import React from 'react';

// OKR评分
const GRADE_LIST = [
  {
    id: null,
    name: '全部',
  },
  {
    id: '-1~-1',
    name: '无评分',
  },
  {
    id: '0~1',
    name: '0-1分',
  },
  {
    id: '1~3',
    name: '1-3分',
  },
  {
    id: '3~5',
    name: '3-5分',
  },
  {
    id: '5~7',
    name: '5-7分',
  },
  {
    id: '7~10',
    name: '7-10分',
  },
  {
    id: '10~10',
    name: '10分',
  },
];

interface IProps {
  onChange?: (value: any) => void;
  value?: any;
}

const Grade = (props: IProps) => {
  const { onChange, value } = props;

  // 下拉列表
  const [list, setList] = useState(GRADE_LIST);
  // 自定义弹框显隐
  const [popoverVisible, setPopoverVisible] = useState(false);
  // 下拉框显隐
  const [openVisible, setOpenVisible] = useState(false);
  // 是否在操作状态
  const [isInOperation, setIsInOperation] = useState(false);
  const [range, setRange] = useState({ min: 0, max: 10 });

  const onDropdownVisibleChange = (open: boolean) => {
    !isInOperation && setOpenVisible(open);
  };

  const onPopoverVisible = (open: boolean) => {
    setPopoverVisible(open);
  };

  const onRangeChange = (val: number | null, type: string) => {
    setRange((prev) => ({
      ...prev,
      [type]: val,
    }));
  };

  // 最大、最小区间都为整数时，可点击确定按钮
  const isDisabled = !Number.isInteger(range.min) || !Number.isInteger(range.max);

  const onConfirm = () => {
    const min = Math.min(range.min, range.max);
    const max = Math.max(range.min, range.max);

    // 自定义选项与下拉列表选重复
    if (list.filter((item) => item.id === `${min}~${max}`).length) {
      setPopoverVisible(false);
      onChange?.(`${min}~${max}`);
      return;
    }
    const newList = list.slice(0, GRADE_LIST.length);
    newList.push({
      id: `${min}~${max}`,
      name: min === max ? `${min}分` : `${min}~${max}分`,
    });
    setList(newList);
    setPopoverVisible(false);
    onChange?.(`${min}~${max}`);
  };

  return (
    <Select
      value={value}
      onChange={onChange}
      style={{ width: 200 }}
      placeholder="全部"
      open={openVisible}
      onDropdownVisibleChange={onDropdownVisibleChange}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          <Popover
            open={!openVisible ? false : popoverVisible}
            onOpenChange={onPopoverVisible}
            content={
              <div>
                <InputNumber
                  min={0}
                  max={10}
                  placeholder="最低"
                  onChange={(val) => onRangeChange(val, 'min')}
                />{' '}
                -{' '}
                <InputNumber
                  min={0}
                  max={10}
                  placeholder="最高"
                  onChange={(val) => onRangeChange(val, 'max')}
                />
                <Button
                  type="primary"
                  style={{ marginLeft: 10 }}
                  disabled={isDisabled}
                  onClick={onConfirm}
                >
                  确定
                </Button>
              </div>
            }
            trigger="click"
            placement="bottomLeft"
            overlayStyle={{ zIndex: 9999 }}
          >
            <div
              style={{ padding: '5px 12px' }}
              onMouseEnter={() => setIsInOperation(true)}
              onMouseLeave={() => setIsInOperation(false)}
            >
              <a>自定义</a>
            </div>
          </Popover>
        </div>
      )}
    >
      {list.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default Grade;
