import React, { memo } from 'react';
import { Avatar } from '@/components';
import { IProcessKrItem } from '@/api/process';
import './index.less';

interface IProps {
  data: IProcessKrItem;
  index: number;
}

function KrCard(props: IProps) {
  const { data, index } = props;

  const { keyResultName, userList } = data || {};

  return (
    <div className="process-kr-card">
      <div className="kr-avatar">KR{index + 1}</div>
      <div className="kr-info-container">
        <div className="kr-title">{keyResultName || '--'}</div>
        <div className="kr-users">
          {userList?.map((item) => {
            const { id, name, photoUrl } = item;
            return (
              <div className="kr-user" key={id}>
                <Avatar imgPath={photoUrl} userName={name} className="kr-user-avatar" />
                <span>{name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default memo(KrCard);
