import React, { useState } from 'react';
import { Modal, Row, Col, Spin, Form } from 'antd';
import { useRequest } from 'ahooks';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import api, { IAddOkrRelPayload, IKrItem, IQueryAlignOkrItem, RelTypeEnum } from '@/api/okr';
import emptyImg from '@/assets/img/empty.png';
import { CyclesCascader, StaffSelect, Avatar } from '@/components';
import { dataTrack } from '@/utils/track';
import { getParams } from '@/utils/tools';
import QuickSelect from './quick-select';
import OKRList from './okr-list';
import './index.less';

const Btn = (
  <span className="m-align-add-btn">
    <PlusOutlined /> 添加对齐
  </span>
);

interface IProps {
  children: React.ReactNode;
  oId: number;
  onSuccess?: () => void;
}

const AlignmentStaff = (props: IProps) => {
  const { children = Btn, oId, onSuccess } = props;

  const urlParams: any = getParams();

  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);
  const [validator, seValidator] = useState(false);
  const [period, setPeriod] = useState();
  const [dateClass, setDateClass] = useState();
  const [photoUrl, setPhotoUrl] = useState();
  const [btnLoading, setBtnLoading] = useState(false);

  const {
    data: list = [],
    run: runList,
    loading,
    mutate,
  } = useRequest(api.getAlignUserOkrList, {
    manual: true,
    onSuccess: () => {
      form.setFieldsValue({ okrList: [] });
      seValidator(false);
    },
  });

  const onOk = (value: any) => {
    const { okrList = [] } = value;
    const params: IAddOkrRelPayload['relList'] = [];
    okrList.forEach((o: IQueryAlignOkrItem & { relType: RelTypeEnum }) => {
      // 如果没有O的id，表示O没被选中，只是选了其下的KR
      o?.id && params.push(o);
      if (o?.krList?.length) {
        o.krList.forEach((kr: any) => {
          // O下的kr被选中，对齐方式继承O的
          kr.id && params.push({ ...kr, relType: o.relType });
        });
      }
    });
    setBtnLoading(true);
    const relList = params?.map((item) => ({ ...item, krList: undefined }));
    api
      .addObjectiveRel({
        id: oId,
        relList,
      })
      .then(() => {
        onSuccess?.();
        onCancel();
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const validatorFunc = (list = []) => {
    let result = true;
    const idArr = [];
    list.map((i: any) => {
      i.id && idArr.push(i.id);
      i.id && !i.relType && (result = false);
      if (i.krList?.length) {
        i.krList.map((c: any) => {
          c.id && !i.relType && (result = false);
          c.id && idArr.push(c.id);
        });
      }
    });
    if (!idArr.length) {
      result = false;
    }
    return result;
  };

  const onValuesChange = (changeValues: any, allValues: any) => {
    const { user } = changeValues;
    const { date } = allValues;

    if (user && date?.length) {
      const dateId = [...date].pop();
      runList({
        userId: user?.id,
        dateId,
      });
    }
    const { okrList = [] } = allValues;
    const canSubmit = validatorFunc(okrList.filter((i: any) => Object.keys(i || {}).length));
    seValidator(canSubmit);
  };

  const beforeShow = () => {
    mutate([]);
    seValidator(false);
  };

  const onChangeCycles = (value: any, options: any) => {
    const { user } = form.getFieldsValue();
    const { dateClass, label } = options?.[1] || {};
    if (!user) return;
    const dateId = [...value].pop();
    value?.length &&
      runList({
        dateId,
        userId: user?.id,
      });
    setPeriod(label);
    setDateClass(dateClass);
  };

  const onStaffChange = (value: any, options: any) => {
    setPhotoUrl(options.props.photoUrl);
  };

  const onQuickChange = (value: any, options: any) => {
    setPhotoUrl(options.photoUrl);
  };

  const onClickShow = () => {
    beforeShow();
    setOpen(true);

    dataTrack(urlParams?.tab === 'org' ? 'click_org_okr_add_align' : 'click_okr_add_align');
  };

  const onCancel = () => {
    // form.resetFields();
    setOpen(false);
    form.setFieldsValue({ okrList: undefined, date: undefined, user: undefined });
  };

  return (
    <>
      <span onClick={onClickShow}>{children}</span>
      <Modal
        className="m-alignment-staff"
        title="添加对齐关系"
        width={800}
        onOk={form.submit}
        open={open}
        onCancel={onCancel}
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ disabled: !validator, loading: btnLoading }}
      >
        {open && (
          <Spin spinning={loading}>
            <Form form={form} onValuesChange={onValuesChange} onFinish={onOk}>
              <Row gutter={[12, 0]}>
                <Col span={16}>
                  <Form.Item name="user" noStyle>
                    <StaffSelect
                      onChange={onStaffChange}
                      style={{ width: '100%' }}
                      suffixIcon={<SearchOutlined rotate={1} />}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item noStyle dependencies={['user']}>
                    {({ getFieldsValue }) => {
                      const { user } = getFieldsValue();
                      return (
                        <Form.Item name="date" noStyle>
                          <CyclesCascader
                            userId={user?.id}
                            disabled={!user}
                            onChange={onChangeCycles}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="user" noStyle>
                <QuickSelect onChange={onQuickChange} />
              </Form.Item>
              <Form.Item noStyle dependencies={['user']}>
                {({ getFieldsValue }) => {
                  const { user } = getFieldsValue();
                  const { id, name } = user || {};
                  const notSearch = !id;
                  return list?.length ? (
                    <div className="m-alignment-staff-content">
                      <div className="m-user-tag">
                        <Avatar className="m-user-photo" imgPath={photoUrl} userName={name} />
                        <span>{name}</span>
                        <span className="m-user-tag-range">{period}</span>
                      </div>
                      <div className="m-user-desc">
                        <span>目标</span>
                        <span>对齐方式</span>
                      </div>
                      <div className="m-okr-lst">
                        {list.map((item, index: number) => (
                          <Form.Item noStyle name={['okrList', index]} key={item.id}>
                            <OKRList item={item} index={index} />
                          </Form.Item>
                        ))}
                      </div>
                    </div>
                  ) : notSearch ? (
                    <div className="m-alignment-empty">
                      <img src={emptyImg} alt="empty" />
                      <span>请按目标负责人进行搜索</span>
                    </div>
                  ) : (
                    <div className="m-alignment-empty">
                      <img src={emptyImg} alt="empty" />
                      <span>该负责人该周期下无可关联的OKR</span>
                    </div>
                  );
                }}
              </Form.Item>
            </Form>
          </Spin>
        )}
      </Modal>
    </>
  );
};

export default AlignmentStaff;
