import api from '@/api';
import { GATEWAY } from '@/api/config';
import { IUserInfo } from '../auth';
import { ResponsePromise, ResponsePagePromise } from '../interface';
import {
  IQueryPeriod,
  IPeriodItem,
  IQueryPersonalOkrList,
  IOkrStatisticsItem,
  IOkrAddDraftPayload,
  IOkrListResponse,
  IUpdateKrPayload,
  IAddOProcessPayload,
  IAddKrProcessPayload,
  IRelResultVO,
  IOProcessItem,
  IKrProcessItem,
  IKrItem,
  IOkrItem,
  IOkrAlignMapParams,
  IOkrAlignMapItem,
  IPublishOkrPayload,
  IOkrDetail,
  IUpdateOkrDatePayload,
  IUpdateOkrBelongPayload,
  IKrDetail,
  IAddOkrRelPayload,
  IQueryAlignOkrItem,
  IOkrRecordItem,
  IQueryShareUserListParams,
  IOkrAlignSearchCompose,
} from './interface';

export default {
  /**
   * 获取个人OKR周期
   */
  getPeriodUser: (params: IQueryPeriod): ResponsePromise<IPeriodItem[]> =>
    api.get(`${GATEWAY}/config/period/user`, params),
  /**
   * 个人OKR-统计OKR数量
   */
  getPersonalOkrCount: (params: {
    year: number;
    userId: number;
  }): ResponsePromise<IOkrStatisticsItem[]> => api.get(`${GATEWAY}/okr/count/user`, params),
  /**
   * 个人OKR-OKR列表
   */
  getPersonalOkrList: (params: IQueryPersonalOkrList): ResponsePromise<IOkrItem[]> =>
    api.get(`${GATEWAY}/okr/list/user`, params),
  /**
   * 组织-OKR周期列表-部门
   */
  getPeriodDept: (params: IQueryPeriod): ResponsePromise<IPeriodItem[]> =>
    api.get(`${GATEWAY}/config/period/dept`, params),
  /**
   * 组织-OKR周期列表-虚拟组织
   */
  getPeriodVirDept: (params: IQueryPeriod): ResponsePromise<IPeriodItem[]> =>
    api.get(`${GATEWAY}/config/period/virDept`, params),
  /**
   * 组织OKR列表-部门
   */
  getDeptOkrList: (params: {
    deptId?: string;
    dateId: number;
  }): ResponsePromise<IOkrListResponse[]> => api.get(`${GATEWAY}/group/okr/list/dept`, params),
  /**
   * 组织OKR列表-虚拟组织
   */
  getVirDeptOkrList: (params: {
    deptId?: string;
    dateId: number;
  }): ResponsePromise<IOkrListResponse[]> => api.get(`${GATEWAY}/group/okr/list/virDept`, params),
  /**
   * OKR 排序
   */
  sortOKR: (params: { idList: number[] }) => api.put(`${GATEWAY}/okr/update/sort`, params),
  /**
   * 个人OKR-从其他周期导入
   */
  postImportOkr: (payload: { idList: number[]; dateId: number }) =>
    api.post(`${GATEWAY}/okr/action/draft/import`, payload),
  /**
   * 添加OKR (草稿态)
   */
  addObjDraft: (payload: IOkrAddDraftPayload): ResponsePromise<IOkrItem> =>
    api.post(`${GATEWAY}/okr/action/draft`, payload),
  /**
   * 个人OKR-删除O
   */
  delObj: (id: number) => api.delete(`${GATEWAY}/okr/action/delete`, { id }),
  /**
   * 个人OKR-发布
   */
  publishOKR: (payload: IPublishOkrPayload) =>
    api.post(`${GATEWAY}/okr/action/draft/publish`, payload),
  /**
   * 修改 O 名称
   */
  updateOkrName: (payload: { id: number; objectivesName: string }) =>
    api.put(`${GATEWAY}/okr/update/name`, payload),
  /**
   * 修改 O 负责人, id: okr主键
   */
  updateOkrPrincipal: (payload: { id: number; principal: number }) =>
    api.put(`${GATEWAY}/okr/update/principal`, payload),
  /**
   * 个人OKR-创建KR
   */
  addKr: (payload: IUpdateKrPayload): ResponsePromise<IKrItem> =>
    api.post(`${GATEWAY}/kr/create`, payload),
  /**
   * 个人OKR-删除KR
   */
  delKr: (id: number) => api.delete(`${GATEWAY}/kr/delete`, { id }),
  /**
   * 个人-OKR列表-抽屉-更新KR
   */
  updateKr: (payload: IUpdateKrPayload) => api.put(`${GATEWAY}/kr/update`, payload),
  /**
   * 修改 OKR 所属团队
   */
  objGroupChange: (payload: IUpdateOkrBelongPayload) =>
    api.put(`${GATEWAY}/okr/update/belong`, payload),
  /**
   * O进展-新增
   */
  addObjProcess: (payload: IAddOProcessPayload) => api.post(`${GATEWAY}/okr/process/add`, payload),
  /**
   * KR进展-新增 (修改KR评分，状态)
   */
  addKrProcess: (payload: IAddKrProcessPayload) => api.post(`${GATEWAY}/kr/process/add`, payload),
  /**
   * KR 排序
   */
  sortKR: (idList: number[]) => api.put(`${GATEWAY}/kr/update/sort`, { idList }),
  /**
   * 个人-OKR-获取对齐员工
   */
  getQuickUserList: (): ResponsePromise<{ id: number; name: string }[]> =>
    api.get(`${GATEWAY}/objectives/rel/user/list`),
  /**
   * 个人-OKR-获取对齐员工的OKR列表
   */
  getAlignUserOkrList: (params: {
    userId: number;
    dateId: number;
  }): ResponsePromise<IQueryAlignOkrItem[]> =>
    api.get(`${GATEWAY}/objectives/rel/user/okr/list`, params),
  /**
   *  个人-OKR抽屉-OKR周期更新
   */
  updateObjectiveDate: (payload: IUpdateOkrDatePayload) =>
    api.put(`${GATEWAY}/okr/update/date`, payload),
  /**
   * 个人-OKR抽屉-查询O对齐列表
   */
  getObjectiveRel: (objectivesId: number): ResponsePromise<IRelResultVO[]> =>
    api.get(`${GATEWAY}/objectives/rel/list`, { objectivesId }),
  /**
   * 个人-OKR-添加O的对齐关系
   */
  addObjectiveRel: (payload: IAddOkrRelPayload) =>
    api.post(`${GATEWAY}/objectives/rel/add`, payload),
  /**
   * 个人-OKR-删除O的对齐关系
   * @param {number} id 对齐关系的主键
   */
  deleteOkrQueryDel: (id: number) => api.delete(`${GATEWAY}/objectives/rel/delete`, { id }),
  /**
   * 个人-OKR抽屉-获取O的进展列表
   */
  getObjectiveProcess: (objectivesId: number): ResponsePromise<IOProcessItem[]> =>
    api.get(`${GATEWAY}/okr/process/list`, { objectivesId }),
  /**
   * 个人-OKR抽屉-获取KR的进展列表
   */
  getKrProcessList: (keyResultId: number): ResponsePromise<IKrProcessItem[]> =>
    api.get(`${GATEWAY}/kr/process/list`, { keyResultId }),
  /**
   * OKR详情
   */
  getOkrDetail: (objectiveId: number): ResponsePromise<IOkrDetail> =>
    api.get(`${GATEWAY}/okr/detail`, { objectiveId }),
  /**
   * 获取KR详情
   */
  getKrDetail: (krId: number): ResponsePromise<IKrDetail> =>
    api.get(`${GATEWAY}/kr/detail`, { krId }),

  /**
   * 获取对齐地图-kr列表, id: O的id
   */
  getKrList: (id: number): ResponsePromise<IKrItem[]> =>
    api.get(`${GATEWAY}/okr/map/krList`, { id }),
  /**
   * 组织OKR-统计组织OKR数量-部门
   */
  getDeptGroupOkrCount: (params: {
    year: number;
    deptId?: string;
  }): ResponsePromise<IOkrStatisticsItem[]> => api.get(`${GATEWAY}/group/okr/count/dept`, params),
  /**
   * 组织OKR-统计组织OKR数量-虚拟部门
   */
  getVirGroupOkrCount: (params: {
    year: number;
    deptId?: string | number;
  }): ResponsePromise<IOkrStatisticsItem[]> =>
    api.get(`${GATEWAY}/group/okr/count/virDept`, params),
  // 对齐地图-快捷查看按钮列表
  getOkrMapQuickViewList: () => api.get(`${GATEWAY}/okr/map/searchButton`),
  // okr-对齐地图
  getOkrMap: (params: IOkrAlignMapParams): ResponsePromise<IOkrAlignMapItem[]> =>
    api.get(`${GATEWAY}/okr/map`, params),
  // okr-对齐地图-子节点
  getRelMapChild: (id: number, parentIdList?: number[]): ResponsePromise<IOkrAlignMapItem[]> =>
    api.get(`${GATEWAY}/okr/map/child`, { id, parentIdList }),
  // okr-对齐地图-关键词搜索员工/部门/虚拟组织
  searchForOkrMap: (searchTerm: string): ResponsePromise<IOkrAlignSearchCompose> =>
    api.get(`${GATEWAY}/okr/map/search`, { searchTerm }),
  // 共享中心列表
  getShareCenterList: (params: IQueryShareUserListParams): ResponsePagePromise<IUserInfo> =>
    api.get(`${GATEWAY}/share/center/user/list`, params),

  /**
   * 个人-OKR列表-抽屉-操作记录
   */
  getRecordList: (objectivesId: number): ResponsePromise<IOkrRecordItem[]> =>
    api.get(`${GATEWAY}/okr/record/list`, { objectivesId }),
};

export * from './interface';
