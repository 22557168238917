import React, { useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import okrApi, { DeptTypeEnum, IPeriodItem, PeriodTypeEnum } from '@/api/okr';
import { DeptTreePanel, OkrCycles, OkrList } from '@/components';
import useGetDefaultPeriod from '@/components/okr/okr-cycles/useGetDefaultPeriod';
import './index.less';

const Organization = () => {
  const { defaultYear, defaultPeriodId } = useGetDefaultPeriod();

  const [year, setYear] = useState(defaultYear);
  const [dateId, setDateId] = useState(defaultPeriodId);
  const [deptType, setDeptType] = useState<DeptTypeEnum>(); // 部门类型 : 实体 REAL_DEPT 虚拟 VIR_DEPT
  const [deptId, setDeptId] = useState();
  const [periodList, setPeriodList] = useState<IPeriodItem[]>([]); // 周期列表

  // 部门周期列表
  useRequest(
    () =>
      okrApi.getPeriodDept({
        deptId,
        periodType: PeriodTypeEnum.okr,
        year,
        needCustomize: true,
      }),
    {
      ready: deptType === DeptTypeEnum.REAL_DEPT,
      refreshDeps: [year, deptId, deptType],
      onSuccess: (res) => {
        setPeriodList(res);
      },
    },
  );

  // 虚拟组织周期列表
  useRequest(
    () =>
      okrApi.getPeriodVirDept({
        deptId,
        periodType: PeriodTypeEnum.okr,
        year,
        needCustomize: true,
      }),
    {
      ready: deptType === DeptTypeEnum.VIR_DEPT,
      refreshDeps: [year, deptId, deptType],
      onSuccess: (res) => {
        setPeriodList(res);
      },
    },
  );

  // 部门周期okr数量
  const { data: statisticsList } = useRequest(
    () =>
      okrApi.getDeptGroupOkrCount({
        year,
        deptId,
      }),
    {
      refreshDeps: [year, deptId, deptType, dateId],
      ready: !!deptType && !!year && deptType === DeptTypeEnum.REAL_DEPT,
      debounceWait: 500,
    },
  );

  // 虚拟组织周期okr数量
  const { data: virStatisticsList } = useRequest(
    () =>
      okrApi.getVirGroupOkrCount({
        year,
        deptId,
      }),
    {
      refreshDeps: [year, deptId, deptType, dateId],
      ready: !!deptType && !!year && deptType === DeptTypeEnum.VIR_DEPT,
      debounceWait: 500,
    },
  );

  const {
    data: okrDataList,
    refreshAsync,
    loading,
    mutate: mutateList,
  } = useRequest(
    () => {
      const fetchFunc =
        deptType === DeptTypeEnum.REAL_DEPT ? okrApi.getDeptOkrList : okrApi.getVirDeptOkrList;
      return fetchFunc({ deptId, dateId });
    },
    {
      ready: dateId,
      refreshDeps: [deptId, dateId, deptType],
      debounceWait: 500,
    },
  );

  const dataList = useMemo(() => okrDataList?.filter((item) => item?.list?.length), [okrDataList]);

  const onDeptChange = (type: DeptTypeEnum, data: any) => {
    setDeptType(type);
    setDeptId(data?.deptId);
  };

  return (
    <div className="p-organization">
      <DeptTreePanel year={year} onChange={onDeptChange} />
      <section className="sec-right">
        <div className="m-or-header">
          <OkrCycles
            year={year}
            periodId={dateId}
            statisticsList={
              deptType === DeptTypeEnum.REAL_DEPT ? statisticsList : virStatisticsList
            }
            onYearChange={(year) => setYear(year)}
            onPeriodChange={(id) => setDateId(id)}
            periodList={periodList}
          />
        </div>
        <OkrList
          draggable
          dataList={dataList}
          onRefresh={refreshAsync}
          loading={loading}
          mutateList={mutateList}
          showOTitle
        />
      </section>
    </div>
  );
};

export default Organization;
