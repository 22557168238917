import React, { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { OkrTypeEnum } from '@/api/okr';
import { SummaryPeriodTypeEnum } from '@/constants';
import processListApi, { IProcessPeriodItem, ProcessSourceTypeEnum } from '@/api/process';
import PeriodOptions from './period-options';

interface IProps {
  type: OkrTypeEnum;
  year?: string | number;
  okrDateId?: number;
  userId?: number;
  deptId?: string;
  processSourceType?: ProcessSourceTypeEnum;
  periodType: number;
  selectedDateId?: number;
  onSelect: (value: any) => void;
}

function SummaryPeriodSelect(props: IProps) {
  const {
    // 组织 | 个人进展
    type = OkrTypeEnum.personal,
    year,
    userId,
    deptId,
    okrDateId,
    processSourceType = ProcessSourceTypeEnum.PROCESS_SUMMARY,
    periodType = SummaryPeriodTypeEnum.month,
    selectedDateId,
    onSelect,
  } = props;

  const [options, setOptions] = useSafeState<IProcessPeriodItem[]>();

  useEffect(() => {
    const payload = {
      type,
      year,
      okrDateId,
      processSourceType,
      periodType,
    };
    if (type === OkrTypeEnum.organization) {
      Object.assign(payload, { deptId });
    } else {
      Object.assign(payload, { userId });
    }

    //@ts-ignore
    processListApi.getPeriodList(payload).then((data) => {
      setOptions(data);
    });
  }, [year, userId, deptId, processSourceType, periodType]);

  return (
    <PeriodOptions
      options={options}
      year={year!}
      periodType={periodType}
      activeKey={selectedDateId}
      processSourceType={processSourceType}
      onChange={onSelect}
    />
  );
}

export default SummaryPeriodSelect;
