/**
 * 通用人员搜索 （姓名/工号/邮箱）
 *
 * @params {Object} params.formatLabelValue 转义value: { label:'' , key:'' } 对应key值
 */

import React from 'react';
import { Tooltip, Select, Spin, Empty } from 'antd';
import commonApi from '@/api/common';
import { useRequest } from 'ahooks';

const { Option } = Select;
const PLACE_HOLDER = '通过姓名/工号/邮箱搜索';
const WIDTH = 150;

const getList = (params: any) => {
  return commonApi?.searchUser(params).then((data) =>
    (data?.dataList || []).map((item: any) => {
      item.userId = item.id;
      item.value = item.name;
      item.text = item.name;
      return item;
    }),
  );
};

const DEFAULT_FORMAT = {
  label: 'name',
  key: 'id',
};

const StaffSelect = (props: any) => {
  const {
    defaultUserList,
    users,
    formatOptions,
    formatLabelValue = DEFAULT_FORMAT,
    value: _value,
    onChange: _onChange,
    ...rest
  } = props;
  const {
    data: searchList = [],
    run: runList,
    loading,
  } = useRequest(getList, {
    debounceWait: 500,
    manual: true,
  });

  const onSearch = (value: string) => {
    if (!value) return;
    runList({ searchTerm: value });
  };

  const formatValue = (v: any) =>
    v ? { label: v[formatLabelValue.label], key: v[formatLabelValue.key] } : undefined;

  const formatChangeValue = (v: any) =>
    v
      ? {
          [formatLabelValue.label]: v.label,
          [formatLabelValue.key]: v.key,
        }
      : undefined;

  const value =
    rest.mode === 'multiple' ? (_value || []).map((i: any) => formatValue(i)) : formatValue(_value);

  const onChange = (v: any, options: any) => {
    const newV =
      rest.mode === 'multiple' ? v.map((i: any) => formatChangeValue(i)) : formatChangeValue(v);
    _onChange && _onChange(newV, options);
  };

  return (
    <Select
      onSearch={onSearch}
      style={{ width: WIDTH }}
      filterOption={false}
      placeholder={PLACE_HOLDER}
      dropdownMatchSelectWidth={false}
      notFoundContent={
        loading ? (
          <div className="p-4">
            <Spin size="small" />
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...rest}
      optionLabelProp="label"
      labelInValue
      value={value}
      onChange={onChange}
      showSearch
    >
      {(users || searchList).map((i: any) => (
        <Option
          key={i.id || i.userId}
          value={i.id || i.userId}
          label={i.name}
          photoUrl={i.photoUrl}
        >
          <Tooltip
            placement="leftTop"
            title={
              <span>
                {formatOptions ? formatOptions(i) : `${i.name} (${i.uNo}) | ${i.deptPath}`}
              </span>
            }
          >
            {formatOptions ? formatOptions(i) : `${i.name} (${i.uNo}) | ${i.deptPath}`}
          </Tooltip>
        </Option>
      ))}
    </Select>
  );
};
export default StaffSelect;
