import { OkrPeriodEnum } from '@/api/okr';

export const OkrPeriodOptions = [
  {
    label: '年度',
    value: OkrPeriodEnum.year,
  },
  {
    label: '半年度',
    value: OkrPeriodEnum.halfYear,
  },
  {
    label: '季度',
    value: OkrPeriodEnum.quarter,
  },
  {
    label: '月度',
    value: OkrPeriodEnum.month,
  },
  {
    label: '双月',
    value: OkrPeriodEnum.dMonth,
  },
];
