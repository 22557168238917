import React, { memo } from 'react';
import { useAccess, useModel } from 'umi';
import { Spin } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import type { DropResult } from 'react-beautiful-dnd';
import api, { IOkrListResponse, IOkrItem } from '@/api/okr';
import { IconFont, OkrDraftCard, OkrPublishCard, OkrListEmpty } from '@/components';
import { generateUUID } from '@/utils/tools';
import './index.less';

interface IProps {
  loading: boolean;
  dataList?: IOkrListResponse[];
  mutateList?: (dataList: IOkrListResponse[]) => void;
  onRefresh?: () => void;
  draggable?: boolean;
  listFooter?: React.ReactNode;
  emptyFooter?: React.ReactNode;
  showOTitle?: boolean;
}

const OkrList = (props: IProps) => {
  const {
    loading,
    dataList,
    mutateList,
    onRefresh,
    draggable,
    listFooter,
    emptyFooter,
    showOTitle,
  } = props;

  const { userRoles } = useAccess();
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const onDragEnd = (result: DropResult, dataItem: IOkrListResponse) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const reorder: any = (list: IOkrItem[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const curIndex = dataList?.findIndex((i) => i.name === dataItem.name) as number;
    const curArr = dataList?.[curIndex].list;
    const sortedList = reorder(curArr, result.source.index, result.destination.index) as IOkrItem[];

    const newArr = [...(dataList || [])];
    newArr[curIndex] = { name: dataItem.name, list: sortedList };
    mutateList?.(newArr);

    api.sortOKR({
      idList: sortedList.map((i: IOkrItem) => i.id),
    });
  };

  const onDeleteOKR = (ownerName: string, id: number) => {
    const newList = [...(dataList || [])];
    const okrList = dataList?.find((i) => i.name === ownerName)?.list || [];
    const index = okrList.findIndex((i) => i.id === id);
    okrList.splice(index, 1);
    mutateList?.(newList);
  };

  return (
    <div className="m-okr-list-wrap">
      <Spin spinning={loading} className="m-okr-list-spinning">
        {dataList?.length ? (
          dataList.map((item) => {
            const { name, list } = item || {};
            return (
              <div key={generateUUID()}>
                {showOTitle && (
                  <div className="m-okr-own-name">
                    <span className="m-okr-own-name-avatar">{name?.slice(0, 1)}</span>
                    <span className="m-okr-own-name-text">{name}</span>
                  </div>
                )}
                <div className="m-okr-list" style={{ paddingTop: showOTitle ? 48 : 0 }}>
                  <DragDropContext onDragEnd={(result) => onDragEnd(result, item)}>
                    <Droppable droppableId="list">
                      {(provided) => {
                        //@ts-ignore
                        const dragHandleProps = provided.dragHandleProps;
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            {...dragHandleProps}
                          >
                            {list.map((item: IOkrItem, index) => {
                              const cardProps = {
                                key: item.id,
                                onRefreshList: onRefresh,
                                userRoles,
                                userInfo: userInfo!,
                                okrInfo: item,
                                index,
                                onDelOKR: (id: number) => onDeleteOKR(name, id),
                              };
                              // 拖拽权限：O的编辑权限，个人中心不允许编辑
                              const dragAuth = item.authMap?.updateName && draggable;
                              return (
                                <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                  {(provided) => (
                                    <div
                                      className="m-card-drag-item"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      style={provided.draggableProps.style}
                                    >
                                      {dragAuth && (
                                        <span
                                          {...provided.dragHandleProps}
                                          role="img"
                                          className="m-card-drag-trigger"
                                        >
                                          <IconFont style={{ fontSize: 20 }} type="icon-yidong" />
                                        </span>
                                      )}
                                      {item.status ? (
                                        <OkrPublishCard {...cardProps} />
                                      ) : (
                                        <OkrDraftCard {...cardProps} />
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                </div>
                {listFooter}
              </div>
            );
          })
        ) : loading || !dataList ? null : (
          <OkrListEmpty footer={emptyFooter} />
        )}
      </Spin>
    </div>
  );
};

export default memo(OkrList);
