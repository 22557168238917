import { PeriodTypeEnum } from '@/api/okr';

export enum CommentTypeEnum {
  OKR = 'OKR',
  PROCESS_SUMMARY = 'PROCESS_SUMMARY', // 整个总结的评论
  PROCESS_WEEK_REPORT = 'PROCESS_WEEK_REPORT', // 整个周报的评论
  PROCESS = 'PROCESS', // 进展中的某一行的评论
  PROCESS_OTHER = 'PROCESS_OTHER', // 进展的其他栏的评论
  DYNAMIC = 'DYNAMIC', // 动态的评论
}

export enum LikeTypeEnum {
  PROCESS_WEEK_REPORT = 'PROCESS_WEEK_REPORT', // 周报整个点赞
  PROCESS_OTHER = 'PROCESS_OTHER', // 进展的其他栏的评论
  PROCESS_SUMMARY = 'PROCESS_SUMMARY', // 总结整个点赞
  COMMENT = 'COMMENT', // 评论
  OKR = 'OKR', // okr
  DYNAMIC = 'DYNAMIC', // 动态
  KEY_RESULT = 'KEY_RESULT', // kr
  PROCESS = 'PROCESS', // 单个进展
}

export interface ISearchUserParams {
  searchTerm?: string; // 搜索关键词
  pageSize?: number; // 每页条数
  currentPage?: number; // 当前页
}

export interface ILikePayload {
  objId: number; // 点赞对象id 取relId,如果是评论 取id，如果是进展则取进展id
  objType: LikeTypeEnum; // 操作对象类型  动态类型（OKR:OKR；SUMMARY：总结；WORK_REPORT：周报；GROUP_OKR：组织OKR KEY_RESULT:关键成果 GROUP_OKR_KEY_RESULT：组织关键成果 ，PROCESS-进展，PROCESS_WEEK_REPORT - 周报，PROCESS_SUMMARY - 总结,PROCESS_CONCLUSION-小结）
  belongUserId?: number; // 所属用户id
}

export interface ICommentItem {
  content: string;
  createTime: string;
  createUserId: number;
  createUserName: string;
  deleteAble: boolean;
  email: string;
  id: number;
  isThumbUp: boolean;
  parentId: number;
  photoUrl: string;
  receiver: string;
  receiverId: number;
  replyId: number;
  targetId: number;
  targetType: string;
  targetUserId: number;
  thumbUpCount: number;
  children: ICommentItem[];
}

export interface ICommentPayload {
  targetId: number; // 评论对象id
  targetType: CommentTypeEnum; // 评论对象类型:CommentTypeEnum
  parentId?: number; // 父评论id
  content: string; // 评论内容
  userIds?: number[]; // @的用户id
  replyId?: number; // 被回复的id
}

export interface IDeptPeriodConfigItem {
  code: string;
  deptId: string;
  deptName: string;
  parentCode: string;
  periodMap: {
    [key in PeriodTypeEnum]: number[];
  };
  childList?: IDeptPeriodConfigItem[];
}

// 公共-用户的部门树
export interface IDeptTreeItem {
  id: string;
  deptName: string;
  level: number;
  deptId: string;
  parentId: string;
  ehrDeptId: string; // ehr部门Id
  deptLeaderUNo: string; // 部门领导工号
  deptHrUNo: string; // 部门HR工号
  direct: number; // 是否直属部门
  deptPath: string; // 部门路径
  hasChildren: boolean; // 是否有子部门
  children?: IDeptTreeItem[];
}
