//@ts-nocheck
class CacheEditingInfo {
  constructor(info = {}) {
    // 单例模式的标识
    this.instance = null;
    this.editingInfo = info;
  }

  /**
   * 单例实例
   * @param {object} [info]
   * @returns {CacheEditingInfo}
   */
  static getInstance(info = {}) {
    if (!this.instance) {
      this.instance = new CacheEditingInfo(info);
    }
    return this.instance;
  }

  getInfo() {
    return this.editingInfo;
  }

  initEditingInfo(info = {}) {
    this.editingInfo = info;
  }

  updateEditingInfo(info) {
    this.editingInfo = { ...this.editingInfo, ...info };
  }

  hasEditingItem() {
    return Object.entries(this.editingInfo)?.some((item) => {
      const [, value] = item;
      return value;
    });
  }
}

export default CacheEditingInfo;
