export const REPLY_TYPE = {
  1: '评论',
  2: '回复',
  3: '@',
  4: '@',
  5: '点赞',
  8: '@',
  12: '@',
  13: '@',
  14: '@',
  15: '评论',
  16: '评论',
};

export const OKR_TYPE = {
  GROUP_OKR: '组织目标',
  OKR: 'OKR',
  WORK_REPORT: '进展',
  DYNAMIC: '分享',
  SUMMARY: '总结',
  COMMENT: '评论',
  PROCESS: '进展',
  PROCESS_WEEK_REPORT: '进展',
  PROCESS_OTHER: '进展',
};
