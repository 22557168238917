import React, { useState, useRef, useEffect, memo } from 'react';
import { history, Link, useModel } from 'umi';
import { useRequest } from 'ahooks';
import {
  Spin,
  Row,
  Col,
  Tabs,
  Button,
  Form,
  Collapse,
  Empty,
  Popconfirm,
  Table,
  TableColumnsType,
} from 'antd';
import { CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import authApi, { AuthShareType, IAuthDeptVO, IUserInfo } from '@/api/auth';
import { useOkrStore } from '@/store';
import okrApi, {
  IOkrRecordItem,
  IRelResultItem,
  OkrPeriodEnum,
  OkrTypeCodeEnum,
  ProcessAddTypeEnum,
  RelTypeEnum,
} from '@/api/okr';
import { O_KR_ENUM, RelType } from '@/constants/okr';
import { generateUUID, getParams } from '@/utils/tools';
import { formatObjectivesBelongs, getEditAuthMap } from '@/utils/okr';
import { Avatar, IconFont, KrLabel, OLabel } from '@/components';
import {
  AlignmentStaff,
  OkrDate,
  OkrGroup,
  OkrName,
  OkrScore,
  OkrShareGroup,
  OkrStatus,
  OkrPrincipal,
} from '../operate';
import Process from './process';
import styles from './o.less';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const CONFIG = {
  [RelTypeEnum.U]: { color: '#FF7A45', icon: 'icon-shangji' }, // 上
  [RelTypeEnum.M]: { color: '#9254DE', icon: 'icon-hengxiang' }, // 横向
  [RelTypeEnum.W]: { color: '#36C6C0', icon: 'icon-xiaji' },
};

interface IProps {
  oId: number;
  visible: boolean;
  onRefreshList?: () => void;
  onRefresh?: (refresh?: boolean) => void;
  onCloseDrawer?: () => void;
}

const O = (props: IProps) => {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { oId, onRefresh, onRefreshList, visible, onCloseDrawer } = props;

  const { detailInfo, hasEdit, setHasEdit, readonly } = useOkrStore();
  const { tabActivityKey, index = 0 } = detailInfo || {};

  const [activeKey, setActivityKey] = useState(tabActivityKey || 'process');
  const refreshTabList = useRef(); // 刷新tab下内容
  const urlParams: any = getParams();

  const [form] = Form.useForm();
  const { data, loading, run } = useRequest(() => okrApi.getOkrDetail(oId));

  const {
    data: alignData,
    loading: alignLoading,
    run: runAlign,
  } = useRequest(() => okrApi.getObjectiveRel(oId), {
    manual: true,
  });

  const {
    data: historyData,
    loading: loadingHistory,
    run: getHistory,
  } = useRequest(() => okrApi.getRecordList(oId), {
    manual: true,
  });

  const {
    objectivesName,
    titleName,
    year,
    dateId,
    principalVO,
    processStatusVO,
    objectivesBelongsVOList,
    authShareVO,
    authMap,
    dateType,
  } = data || {};

  const { id: principalId, name: principalName } = principalVO || {};
  const { processScore, processStatus } = processStatusVO || {};

  const editAuthMap = getEditAuthMap(authMap, userInfo, readonly);

  const onRefreshDetail = () => {
    run();
  };

  const onONameChange = (value: string) => {
    okrApi.updateOkrName({ id: oId, objectivesName: value });
  };
  const onDelete = () => {
    okrApi.delObj(oId).then(() => {
      setHasEdit(true);
      onRefresh?.();
    });
  };
  const onShareChange = (value: any) => {
    const { deptList = [], userList = [], virDeptList = [] } = value;

    const deptIds = deptList.map((i: IAuthDeptVO) => i.deptId);
    const virDeptIds = virDeptList.map((i: IAuthDeptVO) => i.deptId);

    const payload = {
      authRelId: oId,
      sourceType: AuthShareType.OKR,
      deptIdList: [...new Set(deptIds)] as string[],
      userIdList: userList.map((i: IUserInfo) => i.userId),
      virDeptIdList: [...new Set(virDeptIds)] as string[],
    };
    authApi.authShareUpdate(payload).then(() => {
      onRefreshDetail();
    });
  };

  const onChangeGroup = (value: any) => {
    const { deptList = [], userList = [], virDeptList = [] } = value;
    const isEmpty = !Object.values(value || {}).flat().length;
    if (isEmpty) {
      // 无值设为个人OKR
      return okrApi
        .objGroupChange({
          id: oId,
          objectivesType: OkrTypeCodeEnum.OKR,
        })
        .then(() => {
          onRefreshDetail();
        });
    }
    // 有值组织OKR
    okrApi
      .objGroupChange({
        id: oId,
        objectivesType: OkrTypeCodeEnum.GROUP_OKR,
        deptIdList: deptList.map((i: any) => i.deptId),
        virDeptIdList: virDeptList.map((i: any) => i.deptId),
        userIdList: userList.map((i: any) => i.userId),
      })
      .then(() => {
        onRefreshDetail();
      });
  };

  const onOStaffChange = (value: any) => {
    okrApi.updateOkrPrincipal({ id: oId, principal: value.id }).then(() => {
      // 不刷新抽屉接口，没有这个 O 的权限会报错
      onCloseDrawer?.();
      !visible && onRefreshList?.();
    });
  };
  const onOStatusOk = ({ content, status }: any) =>
    okrApi.addObjProcess({
      content,
      status,
      processAddType: ProcessAddTypeEnum.OBJECTIVES_STATUS,
      objectivesId: oId,
    });

  const onOStatusChange = () => {
    //@ts-ignore
    refreshTabList.current?.refresh();
  };

  const onClickProcess = () => {
    history.push(
      `/process/list?year=${year}&periodId=${dateId}&tab=${
        (urlParams.tab === 'okr' ? 'user' : urlParams.tab) || 'user' // 修复个人中心tab=okr时，跳转到进展页面，tab显示为okr的bug
      }`,
    );
  };

  const values = {
    objectivesName,
    authShare: {
      deptList: authShareVO?.authDeptList || [],
      userList: authShareVO?.authUserList?.map((item) => ({
        userId: item.id,
        userName: item.name,
      })),
      virDeptList: authShareVO?.authVirDeptList || [],
    },
    group: formatObjectivesBelongs(objectivesBelongsVOList),
    objUser: { name: principalName, id: principalId },
    processStatus,
    processScore,
  };

  const onValuesChange = () => {
    setHasEdit(true);
  };

  // 添加对齐人员
  const onAlignStaffSuccess = () => {
    runAlign();
    setHasEdit(true);
  };

  useEffect(() => {
    form.setFieldsValue(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (activeKey === 'align') {
      runAlign();
    }
    if (activeKey === 'history') {
      getHistory();
    }
  }, [activeKey]);

  const onDelRel = async (id: number) => {
    await okrApi.deleteOkrQueryDel(id);
    runAlign();
    setHasEdit(true);
  };

  const renderAlignHeader = (item: IRelResultItem) => {
    const { id, userId, objectivesId, year, dateId, objectivesName, titleName } = item || {};
    return (
      <div className={styles.oCard}>
        <div className="flex flex-1">
          <OLabel />
          <Link
            className={styles.oLink}
            to={{
              pathname: `/team/${userId}`,
              search: `?tab=okr&id=${objectivesId}&year=${year}&periodId=${dateId}`,
            }}
            target="_blanK"
          >
            {objectivesName}
          </Link>
        </div>
        <div className={styles.title} onClick={(e) => e.stopPropagation()}>
          <span>{titleName}</span>
          {editAuthMap.updateRel && (
            <Popconfirm title="确定解除对齐关系吗" placement="left" onConfirm={() => onDelRel(id)}>
              <DeleteOutlined className={styles.delete} />
            </Popconfirm>
          )}
        </div>
      </div>
    );
  };

  const columns: TableColumnsType<IOkrRecordItem> = [
    {
      title: '操作人',
      width: 100,
      dataIndex: 'operator',
      render: (val) => val?.name,
    },
    {
      title: '详情',
      dataIndex: 'detail',
      render: (val, record) => `${record.operateTime} ${val}`,
    },
  ];

  // 自定义周期
  const isCustomPeriod = dateType === OkrPeriodEnum.custom;

  const showAddBtn = history.location.pathname === '/okr';

  return (
    <div className="m-detail-drawer">
      <Spin spinning={loading}>
        {!!data && (
          <Form form={form} initialValues={values} onValuesChange={onValuesChange}>
            <div className="m-okr-detail-header">
              <div className="m-detail-index">O{index + 1}</div>
              <div className="m-detail-header-right">
                <Form.Item className="m-detail-obj-name" name="objectivesName">
                  <OkrName
                    type="O"
                    authEdit={editAuthMap.updateOName}
                    onChange={onONameChange}
                    onDelete={editAuthMap.deleteO && onDelete}
                    isDetail
                  />
                </Form.Item>
                <div className="m-detail-header-item">
                  {data && (
                    <span style={{ marginRight: 35 }}>
                      <OkrDate
                        authEdit={editAuthMap.updateDate}
                        titleName={titleName!}
                        defaultValue={[+year!, +dateId!]}
                        cyclesUserId={principalId!}
                        id={oId}
                        onRefresh={onRefreshDetail}
                      />
                    </span>
                  )}
                  <Form.Item name="authShare" noStyle>
                    <OkrShareGroup
                      authEdit={editAuthMap.updateShare}
                      onChange={onShareChange}
                      oId={oId}
                    />
                  </Form.Item>
                </div>
                <Row gutter={[10, 0]}>
                  <Col span={6}>
                    <div className="m-edit-title">负责人</div>
                    <Form.Item noStyle name="objUser">
                      <OkrPrincipal
                        secondConfirm
                        authEdit={editAuthMap.upDateOPrincipal && !isCustomPeriod}
                        onChange={onOStaffChange}
                        isDetail
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <div className="m-edit-title">状态</div>
                    <Form.Item noStyle name="processStatus">
                      <OkrStatus
                        authEdit={editAuthMap.updateOProcess}
                        onOk={onOStatusOk}
                        onChange={onOStatusChange}
                        type={O_KR_ENUM.O}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <div className="m-edit-title">评分</div>
                    <Form.Item noStyle name="processScore">
                      <OkrScore authEdit={false} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <div className="m-edit-title">所属团队（组织）</div>
                    <Form.Item noStyle name="group">
                      <OkrGroup
                        authEdit={editAuthMap.updateGroupOkr && !isCustomPeriod}
                        onChange={onChangeGroup}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        )}
      </Spin>
      <div className="m-okr-detail-tab">
        <Tabs
          activeKey={activeKey}
          onChange={(key) => setActivityKey(key)}
          tabBarExtraContent={
            <div>
              {activeKey === 'process' &&
                editAuthMap.updateOProcess &&
                !isCustomPeriod &&
                showAddBtn && (
                  <Button type="link" className="font-12" onClick={onClickProcess}>
                    + 添加进展
                  </Button>
                )}
              {activeKey === 'align' && editAuthMap.updateRel && showAddBtn && (
                <AlignmentStaff oId={oId} onSuccess={onAlignStaffSuccess}>
                  <Button type="link" className="font-12">
                    + 添加对齐
                  </Button>
                </AlignmentStaff>
              )}
            </div>
          }
          destroyInactiveTabPane
        >
          <TabPane tab="进展记录" key="process">
            <Process ref={refreshTabList} id={oId} type="O" />
          </TabPane>
          <TabPane tab="对齐关系" key="align">
            <div className={styles.align}>
              <Spin spinning={alignLoading}>
                {alignData?.length ? (
                  alignData.map((relItem) => {
                    const { relType, userList } = relItem || {};
                    const currentConfig = CONFIG?.[relType] || {};
                    const alignText = RelType[relType] || '';
                    return (
                      <div key={relType}>
                        <div className={styles.relType} style={{ color: currentConfig.color }}>
                          <IconFont type={currentConfig.icon} className={styles.relIcon} />
                          {alignText}
                        </div>
                        {userList?.map((userItem) => {
                          const { belongName, photoUrl, relResult } = userItem || {};
                          return (
                            <div key={generateUUID()}>
                              <div className={styles.user}>
                                <Avatar
                                  className={styles.avatar}
                                  imgPath={photoUrl}
                                  userName={belongName}
                                />
                                <span>{belongName}</span>
                              </div>
                              <div className={styles.list}>
                                {relResult?.map((item) => {
                                  const { krRelVO, id } = item || {};
                                  if (!krRelVO?.length)
                                    return (
                                      <div className={styles.empty}>{renderAlignHeader(item)}</div>
                                    );
                                  return (
                                    <Collapse
                                      bordered={false}
                                      expandIcon={({ isActive }) => (
                                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                                      )}
                                      defaultActiveKey={[id]}
                                      key={id}
                                    >
                                      <Panel header={renderAlignHeader(item)} key={id}>
                                        {krRelVO?.map((krItem, krIndex) => (
                                          <div className={styles.krCard} key={krItem.id}>
                                            <KrLabel />
                                            {krItem.krName}
                                          </div>
                                        ))}
                                      </Panel>
                                    </Collapse>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                ) : (
                  <Empty description="暂未对齐任何目标" />
                )}
              </Spin>
            </div>
          </TabPane>
          <TabPane tab="操作记录" key="history">
            <Table
              rowKey={generateUUID}
              size="small"
              columns={columns}
              className={styles.table}
              dataSource={historyData}
              loading={loadingHistory}
              pagination={false}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default memo(O);
