import React, { useEffect } from 'react';
import { useSafeState } from 'ahooks';
import { UnmountClosed } from 'react-collapse';
import classnames from 'classnames';
import { OkrTypeEnum } from '@/api/okr';
import { useStore } from '@/store';
import { ProcessPublishStatusEnum } from '@/constants';
import { IProcessPeriodItem, ProcessSourceTypeEnum } from '@/api/process';
import { Avatar } from '@/components';
import { ProcessDashboardDisplay } from '@/pages/process/components';
import { dataTrack } from '@/utils/track';
import { getProcessTitle, getStatusText, getTitle } from './utils';
import './index.less';

const EVENT_ID_MAP = {
  [ProcessSourceTypeEnum.PROCESS_WEEK_REPORT]: 'click_process_edit_week_report',
  [ProcessSourceTypeEnum.PROCESS_SUMMARY]: 'click_process_edit_summary_report',
};

interface IProps {
  readonly?: boolean;
  year: number;
  userId?: number;
  deptId?: string;
  processSourceType: ProcessSourceTypeEnum;
  deptInfo?: any;
  periodId?: number;
  dateId: number;
  type: OkrTypeEnum;
  userOrDeptInfo: any;
  info: IProcessPeriodItem;
  onJumpDetail: (info: IProcessPeriodItem) => void;
  okrDateId: number;
}

// 进展卡片，包含周报|总结两种类型
function ProcessCard(props: IProps) {
  const {
    readonly,
    year,
    dateId,
    userId,
    deptId,
    type,
    processSourceType,
    userOrDeptInfo,
    info,
    onJumpDetail,
    okrDateId,
  } = props;
  const { status, auth, isShowMonth, monthText, authShareName } = info || {};
  const [open, setOpen] = useSafeState(false);

  const { setStoreValues } = useStore();

  const statusMap = getStatusText(status);

  const hasPreview =
    status === ProcessPublishStatusEnum.published || status === ProcessPublishStatusEnum.draft;

  // 是否展示共享范围
  const showAuthShare = type === OkrTypeEnum.personal && hasPreview;

  const clearStore = () => {
    // 还原初始化
    setStoreValues({
      currentProcessType: undefined,
      currentProcessUserId: undefined,
      currentProcessDeptId: undefined,
      currentProcessDateId: undefined,
      currentProcessYear: new Date().getFullYear(),
      currentProcessOkrDateId: undefined,
      currentProcessSourceType: undefined,
    });
  };

  useEffect(() => {
    return () => {
      console.log('unmount');
      clearStore();
    };
  }, []);

  const onClickEdit = (e: any) => {
    clearStore();
    onJumpDetail(info);
    e.stopPropagation();

    // @ts-ignore
    const eventId = EVENT_ID_MAP[processSourceType];
    if (!eventId) return;
    dataTrack(eventId);
  };

  const onClickCard = () => {
    setOpen((pre: boolean) => !pre);
    if (open) {
      clearStore();
    } else {
      setStoreValues({
        currentProcessType: type,
        currentProcessUserId: userId,
        currentProcessDeptId: deptId,
        currentProcessDateId: dateId,
        currentProcessYear: year,
        currentProcessOkrDateId: okrDateId,
        currentProcessSourceType: processSourceType,
      });
    }
  };

  return (
    <>
      {isShowMonth && <div className="pl-process-card-month">{monthText}</div>}
      <div className="pl-process-card-wrap">
        <div
          className={classnames('pl-process-card', {
            'pl-process-card-preview': hasPreview,
            'pl-process-card-auth': auth,
          })}
          onClick={() => {
            if (!hasPreview) return;
            onClickCard();
          }}
        >
          <Avatar
            imgPath={userOrDeptInfo?.photoUrl}
            className="avatar-28"
            userName={userOrDeptInfo?.name}
          />
          <div className="pl-process-card_info">
            <div className="pl-process-card_info-top">
              <div className="pl-process-card-item_info-title">
                {getTitle(processSourceType, userOrDeptInfo?.name)}
              </div>
              <div className="pl-process-card-item_info-period">{getProcessTitle(info)}</div>
              {Number.isInteger(status) && (
                <div className={`pl-process-card-item_info-status ${statusMap.className}`}>
                  {statusMap.text}
                </div>
              )}
            </div>
            {showAuthShare && (
              <div className="pl-process-card_info-bottom">共享范围：{authShareName}</div>
            )}
          </div>
          <div className="pl-process-card-item_btn-wrap">
            {hasPreview && (
              <div className="pl-process-card-item_btn">{open ? '收起' : '展开详情'}</div>
            )}
            {auth && !readonly && (
              <div className="pl-process-card-item_btn" onClick={onClickEdit}>
                编辑
              </div>
            )}
          </div>
        </div>
        <UnmountClosed isOpened={open}>
          <div className="pl-process-card-detail">
            <ProcessDashboardDisplay
              year={year}
              okrDateId={okrDateId}
              dateId={dateId}
              userId={userId}
              deptId={deptId}
              type={type}
              processSource={processSourceType}
            />
          </div>
        </UnmountClosed>
      </div>
    </>
  );
}

export default ProcessCard;
