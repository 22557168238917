import dayjs from 'dayjs';
import { SummaryPeriodTypeEnum } from '@/constants';

/**
 * 通过总结周期获取总结周期类型
 * @param {object} info
 * @param {string} info.startDate - 周期开始日期
 * @param {string} info.endDate - 周期结束日期
 * @returns {number} - 周期类型
 */
export function getSummaryPeriodType(info: any) {
  if (!info) return;
  const { startDate, endDate } = info;
  const diffDays = dayjs(endDate).diff(dayjs(startDate), 'days');
  if (diffDays > 360) {
    return SummaryPeriodTypeEnum.year;
  }
  if (diffDays > 175) {
    return SummaryPeriodTypeEnum.halfYear;
  }
  if (diffDays >= 85) {
    return SummaryPeriodTypeEnum.quarter;
  }
  return SummaryPeriodTypeEnum.month;
}
