import React, { useMemo } from 'react';
import { Row, Col } from 'antd';
import classnames from 'classnames';
import { IProcessPeriodItem, ProcessSourceTypeEnum } from '@/api/process';
import { SummaryPeriodTypeEnum } from '@/constants';
import { getProcessMoment } from '@/utils/tools';
import { IconFont } from '@/components';
import './period-options.less';

const STATUS_TEXT_ENUM = {
  0: 'icon-caogao',
  1: 'icon-fabu',
  2: 'icon-weifabu',
};

interface IProps {
  year: string | number;
  periodType: number;
  activeKey?: number;
  options?: IProcessPeriodItem[];
  processSourceType: ProcessSourceTypeEnum;
  onChange: (
    value: any,
    item: IProcessPeriodItem,
    processSourceType: ProcessSourceTypeEnum,
  ) => void;
}

function PeriodOptions(props: IProps) {
  const { year, periodType, activeKey, options, processSourceType, onChange } = props;
  const showDateRange = processSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT;

  const span = useMemo(() => {
    switch (periodType) {
      case SummaryPeriodTypeEnum.month:
        return 4;
      case SummaryPeriodTypeEnum.quarter:
        return 6;
      case SummaryPeriodTypeEnum.halfYear:
        return 24;
      default:
        return 3;
    }
  }, [periodType]);

  return (
    <div
      className={classnames('pl-period-options', {
        'pl-period-options_half': periodType === SummaryPeriodTypeEnum.halfYear,
      })}
    >
      {periodType !== SummaryPeriodTypeEnum.halfYear && (
        <div className="pl-period-options-year">{year}年</div>
      )}
      <Row gutter={[12, 12]} className="pl-period-options-wrap">
        {options?.map((item) => {
          const { dateClass, dateId: value, startDate, endDate, status, year } = item;
          // @ts-ignore
          const iconType = STATUS_TEXT_ENUM[status];
          const label =
            periodType === SummaryPeriodTypeEnum.halfYear ? `${year}年 ${dateClass}` : dateClass;
          return (
            <Col span={span}>
              <div
                key={value}
                className={`pl-po_ellipsis-item ${value === activeKey ? 'active' : ''}`}
                onClick={() => onChange?.(value, item, processSourceType)}
              >
                <div className="pl-po_ellipsis-item-title">{label}</div>
                {showDateRange && (
                  <div className="pl-po_ellipsis-item-title-date">
                    {startDate && getProcessMoment(startDate)} -
                    {endDate && getProcessMoment(endDate)}
                  </div>
                )}
                <IconFont className="pl-po_ellipsis-item-icon" type={iconType} />
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default PeriodOptions;
