import React from 'react';
import MentionCommand from './mention-command';

// 这里可以从外面传一些配置进来，不过目前应该用不到
const createMentionToolbar = (editorId: string) => {
  return new MentionCommand(<span className="text-2xl">@</span>, {
    tooltip: {
      zh: '@',
      en: '@',
    },
    editorId,
  });
};

export default createMentionToolbar;
