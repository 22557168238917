import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { useModel } from 'umi';
import { useEventEmitter } from 'ahooks';
import { useStore } from '@/store';
import processApi, {
  IProcessDetail,
  IProcessOkrItem,
  IProcessOkrQuery,
  IProcessVO,
  ProcessSourceTypeEnum,
} from '@/api/process';
import { OkrTypeEnum } from '@/api/okr';
import { ProcessPublishStatusEnum } from '@/constants';
import {
  ProcessDashboardHeader,
  WeeklyOtherContent,
  RecordWrapper,
  Empty,
  PdLayoutHeader,
  DashboardOkr,
} from '..';
import {
  checkShouldRequest,
  getProcessApi,
  addProcessInfo2OkrList,
  transformProcessList2Map,
  checkHasProcessByStatus,
} from './utils';
import './index.less';

interface IProps {
  isHeaderHidden?: boolean;
  readonly?: boolean;
  onCallbackProcessVO?: (data: IProcessDetail) => void;
}

// OKR 进展看板
function ProcessDashboard(props: IProps) {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const {
    isEmptyKrProcessHide,
    currentProcessType,
    currentProcessUserId = 0,
    currentProcessDeptId,
    currentProcessDateId,
    currentProcessYear,
    currentProcessOkrDateId,
    currentProcessSourceType,
    setStoreValues,
    processDetail,
  } = useStore();

  const {
    isHeaderHidden,
    readonly,
    // 回调展示进展标题
    onCallbackProcessVO,
  } = props;

  const isSelf = userInfo?.id === +currentProcessUserId;

  // 一键发布事件
  const releaseAll$ = useEventEmitter();
  // 缓存正在编辑的进展项
  const cacheEditingProcess = useRef({});

  const [loading, setLoading] = useState(true);
  // OKR 列表
  const [okrList, setOkrList] = useState<IProcessOkrItem[]>();

  // OKR 进展列表Map
  const [processListMap, setProcessListMap] = useState<{ [key in string]: IProcessVO }>({});

  const filters: IProcessOkrQuery = useMemo(() => {
    const p = {
      year: currentProcessYear,
      okrDateId: currentProcessOkrDateId,
    };
    if (currentProcessType === OkrTypeEnum.organization) {
      Object.assign(p, {
        deptId: currentProcessDeptId,
      });
    } else {
      Object.assign(p, {
        userId: currentProcessUserId,
      });
    }
    return p;
  }, [currentProcessType, currentProcessYear, currentProcessDeptId, currentProcessUserId]);

  // 获取OKR列表
  useEffect(() => {
    if (!checkShouldRequest(filters, currentProcessType)) return;
    setLoading(true);
    const fetchApi =
      currentProcessType === OkrTypeEnum.organization
        ? processApi.getGroupOkrList
        : processApi.getOkrList;
    fetchApi(filters)
      .then((data) => {
        setOkrList(data);
      })
      .finally(() => setLoading(false));
  }, [currentProcessType, filters]);

  const getProcessVO = () => {
    if (!checkShouldRequest(filters, currentProcessType)) return;
    if (!currentProcessDateId || !currentProcessSourceType) return;
    const fetchApi = getProcessApi(currentProcessSourceType, currentProcessType);
    if (!fetchApi) return;
    //@ts-ignore
    return fetchApi({ ...filters, dateId: currentProcessDateId }).then((data) => {
      cacheEditingProcess.current = {};
      setStoreValues({
        processDetail: data,
      });
      onCallbackProcessVO?.(data);
      setProcessListMap(transformProcessList2Map(data?.processVOList, data?.processOther));
      return data?.processVOList;
    });
  };

  const modifyProcessVO = useCallback(
    (partialProcessVO: IProcessDetail) => {
      setStoreValues({
        processDetail: { ...processDetail, ...partialProcessVO },
      });
    },
    [processDetail],
  );

  const modifyProcessListMap = (partialProcessListMap: { [key in string]: IProcessVO }) => {
    setProcessListMap({ ...processListMap, ...partialProcessListMap });
  };

  // 获取对应进展列表
  useEffect(() => {
    if (!currentProcessType || !currentProcessSourceType || !currentProcessDateId) return;
    getProcessVO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProcessType, currentProcessSourceType, filters, currentProcessDateId]);

  const okrListWithProcess = useMemo(
    () =>
      addProcessInfo2OkrList(okrList || [], processListMap, isSelf ? false : isEmptyKrProcessHide),
    [okrList, processListMap, isEmptyKrProcessHide, isSelf],
  );

  // 是否有草稿状态的进展，只读状态下不需要判断；
  const processStatusMap = useMemo(() => {
    if (readonly) return { hasDraft: false, hasPublished: false };
    return {
      hasDraft: checkHasProcessByStatus(processListMap, ProcessPublishStatusEnum.draft),
      hasPublished: checkHasProcessByStatus(processListMap, ProcessPublishStatusEnum.published),
    };
  }, [readonly, processListMap]);

  const noOkr = okrListWithProcess.length === 0 && !processDetail?.processOther;

  console.log('processDetail', processDetail);

  return (
    <div className="process-dashboard">
      <div className="process-dashboard-main">
        {(!loading && noOkr) || !currentProcessSourceType ? (
          <Empty style={{ margin: '0 auto' }} />
        ) : (
          <div className="process-dashboard-wraps">
            <div className="process-dashboard-content">
              {!isHeaderHidden && (
                <ProcessDashboardHeader
                  isMine={isSelf}
                  processStatusMap={processStatusMap}
                  loading={loading}
                  cacheEditingProcess={cacheEditingProcess}
                  releaseAll$={releaseAll$}
                  readonly={readonly}
                  processInfo={processDetail!}
                  onRelease={getProcessVO}
                  modifyProcessVO={modifyProcessVO}
                />
              )}
              <div className="pd-layout">
                <PdLayoutHeader processSource={currentProcessSourceType} />
                {okrListWithProcess?.map((item) => {
                  const { objectivesId } = item;
                  return (
                    <DashboardOkr
                      cacheEditingProcess={cacheEditingProcess}
                      releaseAll$={releaseAll$}
                      key={objectivesId}
                      readonly={readonly}
                      info={item}
                      modifyProcessListMap={modifyProcessListMap}
                      getProcessVO={getProcessVO}
                    />
                  );
                })}
              </div>
            </div>

            {/* 组织OKR 不展示"其他"模块 */}
            {!noOkr &&
              currentProcessSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT &&
              currentProcessType === OkrTypeEnum.personal && (
                <WeeklyOtherContent
                  cacheEditingProcess={cacheEditingProcess}
                  releaseAll$={releaseAll$}
                  readonly={readonly}
                  auth={processDetail?.otherContentAuth!}
                  processOther={processListMap?.processOther}
                  modifyProcessListMap={modifyProcessListMap}
                  getProcessVO={getProcessVO}
                />
              )}
          </div>
        )}
      </div>

      {/* 无任何进展发布时，不可进行点赞和评论（置灰或者不显示） */}
      {!noOkr && (
        <RecordWrapper
          id={processDetail?.id!}
          likeInfo={processDetail?.thumbUpVO}
          onRefreshProcessInfo={getProcessVO}
          modifyProcessVO={modifyProcessVO}
        />
      )}
    </div>
  );
}

export default ProcessDashboard;
