/**
 * todo 重构警告⚠️，根据后端返回的中文，筛选出动态类型，渲染不同的动态卡片
 */
import { Typography } from 'antd';
import { DiscoverTypeEnum, IDiscoverItem } from '@/api/discover';

// 判断 改目标周期 动态
export const UGLY_determinePeriodType = (actionStr: string) => {
  if (!actionStr.includes('将目标周期由')) return false;
  return '修改了目标周期';
};

// 判断 改目标共享范围 动态
export const UGLY_determineShareType = (actionStr: string) => {
  if (!actionStr.includes('将目标的共享范围由')) return false;
  return '修改了目标的共享范围';
};

// 判断 设为组织OKR 动态
export const UGLY_determineSetGroupType = (actionStr: string) => {
  if (actionStr.includes('取消组织OKR')) {
    return '修改了目标的所属团队';
  }
  if (actionStr.includes('目标设置') && actionStr.includes('组织OKR')) {
    return '添加了目标的所属团队';
  }
  if (actionStr.includes('更改为') && actionStr.includes('组织OKR')) {
    return '修改了目标的所属团队';
  }
  return false;
};

// 判断 添加/修改关联目标（对齐关系） 动态
export const UGLY_determineAlignType = (actionStr: string) => {
  if (actionStr.includes('将该目标') && actionStr.includes('对齐') && !actionStr.includes('解除')) {
    return '添加/修改了目标的对齐';
  }
  return false;
};

// 判断 添加更新KR进展/状态/评分 动态
export const UGLY_determineKRProcessType = (
  actionStr: string,
  data: IDiscoverItem,
  onOpenDrawer: any,
) => {
  const { type, objectivesVO } = data || {};
  if (type === DiscoverTypeEnum.KEY_RESULT && actionStr.includes('添加了进展')) {
    return (
      <span>
        添加了目标{' '}
        <Typography.Link
          onClick={() => {
            onOpenDrawer({ ...data, type: 'O' });
          }}
        >
          {objectivesVO?.objectivesName}
        </Typography.Link>{' '}
        下的关键成果进展
      </span>
    );
  }
};

export const UGLY_splitDiscoverTitle = (
  actionStr: string,
  data: IDiscoverItem,
  onOpenDrawer: any,
) => {
  if (UGLY_determinePeriodType(actionStr)) {
    return UGLY_determinePeriodType(actionStr);
  }
  if (UGLY_determineShareType(actionStr)) {
    return UGLY_determineShareType(actionStr);
  }
  if (UGLY_determineSetGroupType(actionStr)) {
    return UGLY_determineSetGroupType(actionStr);
  }
  if (UGLY_determineAlignType(actionStr)) {
    return UGLY_determineAlignType(actionStr);
  }
  if (UGLY_determineKRProcessType(actionStr, data, onOpenDrawer)) {
    return UGLY_determineKRProcessType(actionStr, data, onOpenDrawer);
  }
  return actionStr;
};
