import { useState, useMemo } from 'react';
import api, { IUserInfo } from '@/api/auth';

export const MEMBER_TYPES = {
  // 我的上级
  leader: 'leader',
  // 同级
  same: 'sameLevel',
  // 下属
  sub: 'subordinate',
  // 下属的下属
  subMore: 'subAndSub',
  // 我的关注
  follow: 'attention',
};

function flatTree(tree: any, list = []) {
  if (!tree) {
    return list;
  }
  let result: any = [];
  tree.forEach((item: any) => {
    const { children } = item;
    result.push(item);
    if (children) {
      result = result.concat(flatTree(children, result));
    }
  });
  return list.concat(result);
}

const changeSubordinateList = (previousList: any, nextChildren: any, userId: number) => {
  if (!previousList) return [];
  return previousList.map((item: any) => {
    const { id, children } = item;
    if (id === userId) {
      return {
        ...item,
        children: nextChildren,
      };
    }
    if (children) {
      return {
        ...item,
        children: changeSubordinateList(children, nextChildren, userId),
      };
    }
    return item;
  });
};

function useMembers(defaultUserId: number) {
  // 查询谁的团队成员，默认查询自己的
  // const [currentUserId, setCurrentUserId] = useState(initialState.currentUserId);
  // 选中的用户
  const [selectedUser, setSelectedUser] = useState({
    id: defaultUserId,
    name: '',
  });
  // 展开的用户
  const [unfoldUser, setUnfoldUser] = useState({
    id: '',
    parentId: '',
    name: '',
    children: [],
  });
  // 所有的成员
  const [members, setMembers] = useState({
    // leader || sameLevel || attention || subordinate
    openKey: MEMBER_TYPES.leader,
    // 我的上级
    leaderList: [],
    // 我的同级
    partnerList: [],
    // 我的关注
    attentionList: [],
    // 我的下属，请求多级后会成为一个树形结构
    underedList: [],
  });
  const [loading, setLoading] = useState(false);
  // 是否为最顶级显示
  const isTopLevel = useMemo(() => !unfoldUser?.id, [unfoldUser.id]);
  const topShowList = useMemo(() => {
    const { leaderList, partnerList, attentionList, underedList } = members;
    return [
      {
        openKey: MEMBER_TYPES.leader,
        title: '我的上级',
        list: leaderList,
        eventParam: 1,
      },
      {
        openKey: MEMBER_TYPES.sub,
        title: '我的下属',
        list: underedList,
        showSub: true,
        eventParam: 2,
      },
      {
        openKey: MEMBER_TYPES.same,
        title: '我的同级',
        list: partnerList,
        eventParam: 3,
      },
      {
        openKey: MEMBER_TYPES.follow,
        title: '我关注的',
        list: attentionList,
        eventParam: 4,
      },
    ];
  }, [members]);

  const changeUnfoldUser = (user: any) => {
    const { children } = user;
    setUnfoldUser({
      ...unfoldUser,
      children: [],
      ...user,
    });
    if (!children || children.length === 0) {
      // eslint-disable-next-line
      getMemberList(user, true);
    }
  };

  const getMemberList = (userInfo = {}, isUnd = false, cb?: any) => {
    const { id, name } = userInfo as IUserInfo;
    setLoading(true);
    api
      .getUserTree({
        userId: id,
        isUnd,
      })
      .then((data) => {
        const { underedList, leaderList } = data || {};
        if (isUnd) {
          const { underedList: previousList } = members;
          const temp = underedList.map((item) => ({ ...item, parentId: id }));
          changeUnfoldUser({
            ...unfoldUser,
            children: temp,
            id,
            name,
            parentId: id,
          });
          setMembers({
            ...members,
            underedList: changeSubordinateList(previousList, temp, id),
          });
        } else {
          //@ts-ignore
          setMembers({ ...members, ...data });
          if (defaultUserId) {
            return;
          }
          setSelectedUser(userInfo as IUserInfo);
          cb && cb(userInfo);
        }
      })
      .finally(() => setLoading(false));
  };

  const onBack = (isTop = false) => {
    if (isTop) {
      setUnfoldUser({
        id: '',
        parentId: '',
        name: '',
        children: [],
      });
      return;
    }
    if (unfoldUser.parentId) {
      let flatList = flatTree(members.underedList, []);
      const user: any = flatList.find((item: any) => item.id === unfoldUser.parentId);
      if (user) {
        setUnfoldUser({
          ...user,
          parentId: user.parentId,
        });
        return;
      }
    }
    setUnfoldUser({
      id: '',
      parentId: '',
      name: '',
      children: [],
    });
  };

  const onOpen = (key: string) => {
    setMembers({
      ...members,
      openKey: members.openKey === key ? '' : key,
    });
  };

  const refreshAttentionList = (userInfo: IUserInfo) => {
    api.getUserTree({ userId: userInfo?.userId, isUnd: false }).then((data) => {
      const { attentionList } = data || {};
      setMembers({
        ...members,
        //@ts-ignore
        attentionList,
      });
    });
  };

  return {
    loading,
    selectedUser,
    isTopLevel,
    unfoldUser,
    topShowList,
    members,
    setSelectedUser,
    onNext: changeUnfoldUser,
    onBack,
    onOpen,
    getMemberList,
    refreshAttentionList,
  };
}

export default useMembers;
