import React, { useState, useMemo, useEffect } from 'react';
import { useModel, useAccess } from 'umi';
import { Button, Popover, Row, Col } from 'antd';
import { useControllableValue } from 'ahooks';
import { CloseCircleOutlined, CheckOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { DeptTreeModal, SearchUserModal } from '@/components';
import { IAuthDeptVO, IUserInfo } from '@/api/auth';
import { filterDuplicates } from '@/utils/tools';
import './index.less';

function cutString(str: string, len: number) {
  if (str.length <= len) {
    return str;
  } else {
    return str.slice(0, 2) + '...' + str.slice(str.length + 2 - len, str.length);
  }
}

interface IProps {
  defaultScope?: IAuthDeptVO[];
  defaultVirDeptScope?: IAuthDeptVO[];
  onChange?: (value?: any) => void;
  value?: any;
  hideUserSelect?: boolean;
}

const ChooseScope = (props: IProps) => {
  const { defaultScope, defaultVirDeptScope, hideUserSelect } = props;

  const [state, setState] = useControllableValue(props, {
    defaultValue: { deptList: [], userList: [], virDeptList: [] },
  });

  const [authShareDeptIds, setAuthShareDeptIds] = useState<any>([]);
  const [authShareVirDeptIds, setAuthShareVirDeptIds] = useState<any>([]);

  const mustDefaultScope: IAuthDeptVO[] = useMemo(
    () => defaultScope?.filter((dept) => dept.defaultAuth) || [],
    [defaultScope],
  );
  const mustDefaultVirScope = useMemo(
    () => defaultVirDeptScope?.filter((dept) => dept.defaultAuth) || [],
    [defaultVirDeptScope],
  );

  const { userRoles } = useAccess();
  const { groupOkrAdmin, virDeptAdmin } = userRoles || {};
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const isShowVirDept = virDeptAdmin && userInfo?.hasVO;

  const [multiSelectVisible, setMultiSelectVisible] = useState(false);
  const [deptTreeVisible, setDeptTreeVisible] = useState(false);
  const [virDeptTreeVisible, setVirDeptTreeVisible] = useState(false);

  const scopeList: (IAuthDeptVO | IUserInfo)[] = useMemo(() => {
    // scopeList过滤出 defaultScope 或者 defaultVirScope 中存在的部门
    const arr = Object.values(state || {})?.flat?.() || [];

    const defaultId = mustDefaultScope.map((i) => i.deptId);
    const defaultVirId = mustDefaultVirScope.map((i) => i.deptId);
    return arr.filter(
      (i: IAuthDeptVO) => !defaultId.includes(i.deptId) && !defaultVirId.includes(i.deptId),
    );
  }, [state, mustDefaultScope, mustDefaultVirScope]);

  const addScope = (arr: any, key: string, filterKey: string = 'id', defaultArr: any = []) => {
    // @ts-ignore
    const newArr = [...(state?.[key] || []), ...arr, ...defaultArr];
    // 抛出数据时带上 defaultScope defaultVirDeptScope]
    const newState = {
      ...state,
      [key]: filterDuplicates(newArr, filterKey),
    };
    setState(newState);
  };

  const setScopeUsers = (arr: any) => {
    const newArr = arr.map((i: any) => ({ userId: i.value, userName: i.userName }));
    addScope(newArr, 'userList', 'userId');
  };

  // 快捷选择部门
  const setScopeDept = (arr: any) => {
    addScope(arr, 'deptList', 'deptId', mustDefaultScope);
  };

  // 部门树弹窗选择回调
  const setScopeDeptByModal = (arr: any) => {
    const transformArr = arr?.map((item: any) => ({ deptId: item.id, deptName: item.deptName }));
    addScope(transformArr, 'deptList', 'deptId', mustDefaultScope);
  };

  // 快捷选择虚拟组织
  const setScopeVirDept = (arr: any) => {
    addScope(arr, 'virDeptList', 'deptId', mustDefaultVirScope);
  };

  // 虚拟组织树弹窗选择回调
  const setScopeVirDeptByModal = (arr: any) => {
    const transformArr = arr?.map((item: any) => ({
      deptId: item.deptId,
      deptName: item.deptName,
    }));
    addScope(transformArr, 'virDeptList', 'deptId', mustDefaultVirScope);
  };

  const multiSelectShowModal = () => {
    setMultiSelectVisible(true);
  };

  const multiSelectHandleCancel = () => {
    setMultiSelectVisible(false);
  };

  const delAuthShare = (delId: string) => {
    const changeKey = Object.keys(state).find((i) => {
      //@ts-ignore
      return state[i].map((i: IAuthDeptVO & IUserInfo) => i.deptId || i.userId).includes(delId);
    });
    //@ts-ignore
    const arr = state[changeKey].filter(
      (i: IAuthDeptVO & IUserInfo) => (i.deptId || i.userId) !== delId,
    );
    setState({
      ...state,
      //@ts-ignore
      [changeKey]: arr,
    });
  };

  useEffect(() => {
    const idArr = mustDefaultScope.map((i) => i.deptId).concat(scopeList.map((i) => i.deptId));
    const virArr = mustDefaultVirScope.map((i) => i.deptId).concat(scopeList.map((i) => i.deptId));
    setAuthShareDeptIds(idArr);
    setAuthShareVirDeptIds(virArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeList, mustDefaultScope, mustDefaultVirScope]);

  // 传入multiSelect弹层 在点击确定键时 将已选择的带出来
  const multiSelectHandleOk = (checkedList: any) => {
    setScopeUsers(checkedList);
    multiSelectHandleCancel();
  };

  const deptTreeShow = () => {
    setDeptTreeVisible(true);
  };
  const deptTreeHidden = () => {
    setDeptTreeVisible(false);
  };
  const virDeptTreeShow = () => {
    setVirDeptTreeVisible(true);
  };
  const virDeptTreeHidden = () => {
    setVirDeptTreeVisible(false);
  };

  return (
    <div className="m-chose-scope">
      <div className="m-okr-share">
        {!!defaultScope?.length &&
          defaultScope
            ?.filter((dept) => dept.defaultAuth)
            .map((dept) => <span key={dept.deptId}>{dept.deptName}</span>)}
        {!!defaultVirDeptScope?.length &&
          defaultVirDeptScope
            ?.filter((dept) => dept.defaultAuth)
            .map((dept) => <span key={dept.deptId}>{dept.deptName}</span>)}

        {scopeList.map((i: any) => (
          <span key={i.deptId || i.userId}>
            {cutString(i.deptName || i.userName || '', 4)}
            <CloseCircleOutlined
              className="del-item pointer"
              onClick={() => delAuthShare(i.deptId || i.userId)}
            />
          </span>
        ))}
        {/* 选择组织或者指定人员 */}
        {!deptTreeVisible && !multiSelectVisible && !virDeptTreeVisible && (
          <Popover
            overlayClassName="m-choose-scope-popover"
            placement="right"
            trigger="click"
            content={
              <Row>
                {/* 新建组织okr 时会有 选择组织的弹层/而个人okr 没有弹层 */}
                {defaultScope?.map((scope) => (
                  <Col key={scope.deptId} span={24}>
                    <Button
                      disabled={authShareDeptIds.includes(scope.deptId)}
                      style={{ margin: '0', fontSize: '12px' }}
                      size="small"
                      onClick={() =>
                        setScopeDept(defaultScope.filter((i) => i.deptId === scope.deptId))
                      }
                    >
                      {scope.deptName}
                      <CheckOutlined
                        className={
                          authShareDeptIds.includes(scope.deptId) ? '' : 'm-scope-check-icon'
                        }
                      />
                    </Button>
                  </Col>
                ))}
                {defaultVirDeptScope?.map((scope) => (
                  <Col key={scope.deptId} span={24}>
                    <Button
                      disabled={authShareVirDeptIds.includes(scope.deptId)}
                      style={{ margin: '0', fontSize: '12px' }}
                      size="small"
                      onClick={() =>
                        setScopeVirDept(
                          defaultVirDeptScope.filter((i) => i.deptId === scope.deptId),
                        )
                      }
                    >
                      <span>
                        <span
                          style={{
                            paddingRight: 12,
                            fontSize: 12,
                            color: 'black',
                            opacity: 0.25,
                          }}
                        >
                          {' '}
                          虚拟{' '}
                        </span>
                        {scope.deptName}
                      </span>
                      <CheckOutlined
                        className={
                          authShareVirDeptIds.includes(scope.deptId) ? '' : 'm-scope-check-icon'
                        }
                      />
                    </Button>
                  </Col>
                ))}
                {groupOkrAdmin && (
                  <Col span={24}>
                    <Button
                      style={{ margin: '0', fontSize: '12px' }}
                      size="small"
                      onClick={deptTreeShow}
                    >
                      选择部门
                    </Button>
                  </Col>
                )}
                {isShowVirDept && (
                  <Col span={24}>
                    <Button
                      style={{ margin: '0', fontSize: '12px' }}
                      size="small"
                      onClick={virDeptTreeShow}
                    >
                      选择虚拟组织
                    </Button>
                  </Col>
                )}
                {!hideUserSelect && (
                  <Col span={24}>
                    <Button
                      style={{ margin: '0', fontSize: '12px' }}
                      size="small"
                      onClick={multiSelectShowModal}
                    >
                      指定人员
                    </Button>
                  </Col>
                )}
              </Row>
            }
          >
            <PlusCircleOutlined className="m-add-icon" />
          </Popover>
        )}
      </div>
      {/* 部门数树 */}
      <DeptTreeModal
        open={deptTreeVisible}
        deptTreeHidden={deptTreeHidden}
        deptTreeChange={setScopeDeptByModal}
      />
      {/*/!* 虚拟组织树 *!/*/}
      <DeptTreeModal
        isVir
        open={virDeptTreeVisible}
        deptTreeHidden={virDeptTreeHidden}
        deptTreeChange={setScopeVirDeptByModal}
      />

      {/*/!* 多选人弹层 *!/*/}
      <SearchUserModal
        modalProps={{
          open: multiSelectVisible,
          onCancel: multiSelectHandleCancel,
        }}
        handleOk={multiSelectHandleOk}
      />
    </div>
  );
};

export default ChooseScope;
