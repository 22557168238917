import { useState, useCallback } from 'react';
import { history } from 'umi';
import { FloatButton } from 'antd';
import { TABS } from '@/constants/discover';
import { getParams } from '@/utils/tools';
import { dataTrack } from '@/utils/track';
import { LeftMenu } from './components';
import DiscoverTeam from './team';
import DiscoverFollow from './follow';
import DiscoverAll from './all';
import styles from './index.less';

const Discover = () => {
  const params: any = getParams();

  const [activeKey, setActiveKey] = useState(params.tab || TABS.team.key);
  const [isCollapse, setIsCollapse] = useState(false);

  const handleToggle = useCallback(() => {
    setIsCollapse(!isCollapse);
  }, [isCollapse]);

  const getActiveTab = () => {
    const params = getParams();
    const { tab } = params as any;
    //@ts-ignore
    if (TABS[tab]) return TABS[tab];
    return TABS.team;
  };

  const onChangePane = useCallback((tabKey: string) => {
    const active = getActiveTab();
    //@ts-ignore
    if (tabKey === active.key || !TABS[tabKey]) return;
    dataTrack('click_discover_menu', {
      type: active.eventParam,
    });
    history.replace(`/discover?tab=${tabKey}`);
    window.scroll(0, 0);
    setActiveKey(tabKey);
  }, []);

  // @ts-ignore
  const currentTitle = TABS[activeKey]?.label;

  return (
    <div className={styles.container}>
      <LeftMenu
        handleToggle={handleToggle}
        isCollapse={isCollapse}
        onChangePane={onChangePane}
        activeKey={activeKey}
      />
      <div className={styles.right} style={{ marginLeft: isCollapse ? '0' : '208px' }}>
        <div className={styles.rightHeader}>{currentTitle || ''}</div>
        {activeKey === TABS.team.key && <DiscoverTeam />}
        {activeKey === TABS.follow.key && <DiscoverFollow />}
        {activeKey === TABS.current.key && <DiscoverAll />}
      </div>
      <FloatButton.BackTop />
    </div>
  );
};

export default Discover;
