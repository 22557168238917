import React, { useMemo } from 'react';
import { useSafeState, useControllableValue } from 'ahooks';
import type { Dayjs } from 'dayjs';
import { Dropdown, Button, Menu, Popover, DatePicker } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { DateTimeRangeOptions, DateTimeRangeEnum } from '@/constants';
import './index.less';

const { RangePicker } = DatePicker;

interface IProps {
  value: any;
  onChange?: (value: any) => void;
}

// 周进展范围选择
function ProcessPeriodRangeSelect(props: IProps) {
  const [state, setState] = useControllableValue(props, {
    defaultValue: {
      timeRange: undefined,
      activeKey: DateTimeRangeEnum.OneMonth,
    },
  });
  const [visible, setVisible] = useSafeState(false);
  const [timeRange, setTimeRange] = useSafeState([]);

  const [rangePickerOpen, setRangePickerOpen] = useSafeState(false);

  const handleMenuClick = (e: any) => {
    if (e.key === state?.activeKey || e.key === DateTimeRangeEnum.Custom) return;
    setState({
      timeRange: undefined,
      activeKey: e.key,
    });
  };

  const onConfirmTime = () => {
    setVisible(false);
    setState({
      //@ts-ignore
      timeRange,
      activeKey: DateTimeRangeEnum.Custom,
    });
    setRangePickerOpen(false);
  };

  const menu = (
    <Menu selectedKeys={state?.activeKey ? [state.activeKey] : []} onClick={handleMenuClick}>
      {DateTimeRangeOptions.map((item) => {
        const { label, value } = item;
        if (value !== DateTimeRangeEnum.Custom) {
          return <Menu.Item key={value}>{label}</Menu.Item>;
        }
        return (
          <Menu.Item key={value}>
            <Popover
              trigger="hover"
              placement="rightBottom"
              getPopupContainer={(n) => n}
              destroyTooltipOnHide
              content={
                <div className="pl-custom-calendar">
                  <div className="pl-custom-calendar_title">自定义周期</div>
                  {/* @ts-ignore */}
                  <RangePicker
                    open={rangePickerOpen}
                    onOpenChange={setRangePickerOpen}
                    size="small"
                    placeholder={['开始月份', '结束月份']}
                    format="YYYY-MM"
                    mode={['month', 'month']}
                    //@ts-ignore
                    value={timeRange}
                    onChange={(dates) => {
                      // 不会触发，antd3.x 问题
                      //@ts-ignore
                      setTimeRange(null);
                    }}
                    onPanelChange={setTimeRange}
                    renderExtraFooter={() => (
                      <div
                        style={{
                          width: '100%',
                          padding: '8px 16px',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          size="small"
                          type="primary"
                          onClick={onConfirmTime}
                          disabled={!timeRange || timeRange?.length === 0}
                        >
                          确定
                        </Button>
                      </div>
                    )}
                  />
                </div>
              }
            >
              <div>{label}</div>
            </Popover>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const showText = useMemo(() => {
    const { activeKey, timeRange: currentTimeRange } = state;
    if (activeKey === DateTimeRangeEnum.Custom) {
      const [start, end]: Dayjs[] = currentTimeRange || [];
      const displayStr = [start, end]
        .map((day) => (day ? day.format('YYYY-MM') : '-'))
        .join(' 至 ');
      return displayStr;
    }
    return DateTimeRangeOptions.find((item) => item.value === activeKey)?.label;
  }, [state]);

  return (
    <div className="pl-period-range-select">
      <span className="pl-period-range-select_label">周进展范围：</span>
      <Dropdown
        open={visible}
        overlay={menu}
        onOpenChange={(v) => {
          !v && setRangePickerOpen(false);
          setVisible(v);
        }}
      >
        <Button className="pl-period-range-select_trigger">
          {showText} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
}

export default ProcessPeriodRangeSelect;
