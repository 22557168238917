import React, { useState } from 'react';
import { useModel } from 'umi';
import { Input, Dropdown, Checkbox, Menu } from 'antd';
import cls from 'classnames';
import { useRequest, useDebounceFn } from 'ahooks';
import { IconFont } from '@/components';
import api from '@/api/common';
import { RelTypeEnum, ShareQueryTypeEnum } from '@/api/okr';
import DeptFilter from './dept-filter';
import './index.less';

const FilterTypes = [
  {
    name: '我的上级',
    key: ShareQueryTypeEnum.LEADER,
  },
  {
    name: '我的下属',
    key: ShareQueryTypeEnum.SUBORDINATE,
  },
  {
    name: '我的同级',
    key: ShareQueryTypeEnum.COLLEAGUE,
  },
  {
    name: '部门选择',
    key: 'department',
  },
  {
    name: '虚拟组织选择',
    key: 'virDepartment',
  },
  {
    name: '其他与我共享',
    key: ShareQueryTypeEnum.OTHER,
  },
];

interface IProps {
  handleCondition: (condition: any) => void;
  handleEmpType: (empType: string) => void;
  handleSearchTerm: (searchTerm?: string) => void;
}

const FilterList = (props: IProps) => {
  const { handleCondition, handleEmpType, handleSearchTerm } = props;

  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const [currentKey, setCurrentKey] = useState('');
  const [openEmpType, setOpenEmpType] = useState(false);
  const [selectedEmpType, setSelectEmpType] = useState<string[]>([]);
  // const [searchKeyword, setSearchKeyword] = useState('')

  const { data } = useRequest(api.getEmpTypes, {
    onSuccess: (res) => {
      setSelectEmpType([res?.[0]?.code]);
      handleEmpType(res?.[0]?.code);
    },
  });

  const selectedEmpName = data
    ?.filter((item: any) => selectedEmpType.includes(item.code))
    .map((item: any) => item.name)
    .join('，');

  const onOpenChange = (open: boolean) => {
    setOpenEmpType(open);
    if (!open) {
      handleEmpType(selectedEmpType?.join(','));
    }
  };

  // 根据当前用户身份，控制其“我的上级”、“我的下属”、“我的同级”的筛选标签显隐
  const getFilterTypeList = () => {
    if (!userInfo) return [];
    const { hasUndered, hasLeader, hasPartner, hasVO } = userInfo;
    let modifyList = FilterTypes;
    // 不存在下属
    if (!hasUndered) {
      modifyList = modifyList.filter((item) => item.key !== ShareQueryTypeEnum.SUBORDINATE);
    }
    // 不存在上级
    if (!hasLeader) {
      modifyList = modifyList.filter((item) => item.key !== ShareQueryTypeEnum.LEADER);
    }
    // 不存在同级
    if (!hasPartner) {
      modifyList = modifyList.filter((item) => item.key !== ShareQueryTypeEnum.COLLEAGUE);
    }
    // 不存在虚拟组织
    if (!hasVO) {
      modifyList = modifyList.filter((item) => item.key !== 'virDepartment');
    }
    return modifyList;
  };

  const filterTypeList = getFilterTypeList();

  const handleClick = (key: string) => {
    setCurrentKey(key);
    const params = {
      type: key,
    };
    handleCondition(params);
  };

  const onSelectDept = (condition: any) => {
    handleCondition(condition);
    setCurrentKey('department');
  };

  const onSelectVirtualDept = (condition: any) => {
    handleCondition(condition);
    setCurrentKey('virDepartment');
  };

  const onChangeEmp = (values: any) => {
    setSelectEmpType(values);
  };

  const { run } = useDebounceFn(
    (text?: string) => {
      handleSearchTerm(text);
    },
    {
      wait: 500,
    },
  );

  const onSearch = (e: any) => {
    run(e.target.value);
    // setSearchKeyword(e.target.value)
  };

  return (
    <div className="sc-filter-list" id="okr-share-page">
      <div className="sc-filter-list__left">
        <div className="label">人员范围：</div>
        {filterTypeList.map((item) => {
          const { key, name } = item;
          const selected = currentKey === key;
          if (key === 'department') {
            return <DeptFilter key={key} onSelect={onSelectDept} isSelected={selected} />;
          }
          if (key === 'virDepartment') {
            return (
              <DeptFilter
                key={key}
                onSelect={onSelectVirtualDept}
                isSelected={selected}
                isVirtual
              />
            );
          }
          return (
            <div
              key={key}
              className={cls('filter-item', {
                'filter-item-selected': selected,
              })}
              onClick={() => handleClick(key)}
            >
              {name}
            </div>
          );
        })}
        <div className="label" style={{ marginLeft: 32 }}>
          人员类型：
        </div>
        <div>
          <Dropdown
            overlay={
              <Checkbox.Group onChange={onChangeEmp} value={selectedEmpType}>
                <Menu className="emp-type-dropdown">
                  {data?.map((type: any) => {
                    const { code, name } = type;
                    return (
                      <Menu.Item key={code}>
                        <Checkbox value={code}>{name?.trim()}</Checkbox>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </Checkbox.Group>
            }
            trigger={['click']}
            open={openEmpType}
            onOpenChange={onOpenChange}
            getPopupContainer={(node) => node.parentNode as HTMLElement}
          >
            <a className="dropdown-label" onClick={(e) => e.preventDefault()}>
              {selectedEmpName || '全部'}&nbsp;&nbsp;
              <IconFont type="icon-unie6612" className={openEmpType ? 'icon icon-up' : 'icon'} />
            </a>
          </Dropdown>
        </div>
      </div>
      <div className="search-wrap">
        <Input
          allowClear
          // value={searchKeyword}
          className="chose-name"
          onChange={onSearch}
          placeholder="请输入姓名/工号/邮箱"
        />
      </div>
    </div>
  );
};

export default FilterList;
