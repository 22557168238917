import React from 'react';
import { Table, Typography } from 'antd';
import type { TableProps, TableColumnsType } from 'antd';

export default function Table2(props: TableProps<any>) {
  const columns: TableColumnsType<any> = [
    {
      title: 'ID',
      width: 100,
      dataIndex: 'id',
      fixed: 'left',
    },
    {
      title: '目标(O)名称',
      dataIndex: 'objectName',
      fixed: 'left',
      width: 300,
      render: (text, record) => (
        <Typography.Text style={{ width: 266 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '最新状态',
      width: 100,
      dataIndex: 'objectNewestStatus',
    },
    {
      title: '最新评分',
      width: 100,
      dataIndex: 'objectNewestScore',
      render: (text) => (
        <Typography.Text style={{ width: 100 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '目标(O)周期',
      dataIndex: 'objectCycle',
      width: 150,
      render: (text) => (
        <Typography.Text style={{ width: 150 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '目标(O)负责人',
      width: 150,
      dataIndex: 'objectResponsible',
    },
    {
      title: '目标(O)参与人',
      dataIndex: 'objectParticipant',
      width: 150,
      render: (text) => (
        <Typography.Text style={{ width: 150 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '目标(O)类型',
      width: 100,
      dataIndex: 'objectType',
    },
    {
      title: '所属团队(仅组织OKR)',
      dataIndex: 'objectDept',
      width: 200,
      render: (text) => (
        <Typography.Text style={{ width: 200 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
  ];
  return (
    <Table
      {...props}
      rowKey="objectId"
      size="small"
      columns={columns}
      bordered
      scroll={{ x: 2000 }}
    />
  );
}
