import React, { useState, useRef } from 'react';
import { Input, Button, ConfigProvider, Popconfirm } from 'antd';
import { ProcessSourceTypeEnum } from '@/api/process';
import { getUserIdList } from '@/utils/tools';
import api, { CommentTypeEnum, ICommentItem } from '@/api/common';
import { EmojiPanel, IconFont, SingleUserSelect } from '@/components';
import { IUserInfo } from '@/api/auth';
import './index.less';

const { TextArea } = Input;

interface IProps {
  autoFocus?: boolean;
  id: number;
  onCancel?: () => void;
  targetType: CommentTypeEnum;
  placeholder?: string;
  parentId?: number;
  replyId?: number;
  handleSuccess?: (data: ICommentItem) => void;
  autoSize?: any;
  beforeSubmit?: () => Promise<number>;
}

export default function CommentInput(props: IProps) {
  const {
    autoFocus,
    onCancel,
    id,
    targetType,
    placeholder,
    parentId,
    replyId,
    handleSuccess,
    autoSize,
    beforeSubmit,
  } = props;

  const [actionVisible, setActionVisible] = useState(autoFocus);
  const [visibleSelectPerson, setVisibleSelectPerson] = useState(false);
  const [popoverFromKeyBoard, setPopoverFromKeyBoard] = useState(false);
  const [value, setValue] = useState('');

  const ref = useRef(null);
  const inputRef = useRef(null);

  const onFocus = () => {
    setActionVisible(true);
  };

  const onClickCancel = () => {
    onCancel?.();
    setValue('');
    setActionVisible(false);
  };

  const onClickSubmit = async () => {
    try {
      const userIds = getUserIdList(value);
      let targetId = id;
      if (!id) {
        targetId = (await beforeSubmit?.()) as number;
      }
      const payload = {
        userIds,
        content: value,
        targetType,
        targetId,
        parentId,
        replyId,
      };
      const data = await api.postComment(payload);
      // 刷新
      handleSuccess?.(data);
      setValue('');
      setActionVisible(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeEmoji = (val: string) => {
    setValue(`${value}${val}`);
    // @ts-ignore;
    inputRef.current?.focus();
  };

  const onChangeInput = (e: any) => {
    if (e.nativeEvent.data === '@') {
      setVisibleSelectPerson(true);
      setPopoverFromKeyBoard(true);
    }
    setValue(e.target.value);
  };

  const handleSelect = (empInfo: IUserInfo) => {
    const val = `@{${empInfo.name}-${empInfo.id}}`;
    const newVal = popoverFromKeyBoard ? val.slice(1) : val; // 如果选人弹窗是由键盘事件触发，则需将最开头的“@”符号手动去掉
    setValue(`${value}${newVal}`);
    setPopoverFromKeyBoard(false);
    // @ts-ignore;
    inputRef.current?.focus();
  };

  const onCancelSelectUser = () => {
    setVisibleSelectPerson(false);
    // @ts-ignore;
    inputRef.current?.focus();
  };

  return (
    <div className="comment-textarea-container" ref={ref}>
      <TextArea
        placeholder={placeholder || '说点什么…'}
        className="comment-input"
        autoSize={autoSize || { minRows: 2 }}
        onFocus={onFocus}
        maxLength={5000}
        autoFocus={autoFocus}
        value={value}
        onChange={onChangeInput}
        ref={inputRef}
      />
      {actionVisible && (
        <div className="textarea-action-container">
          <div className="textarea-action-icon">
            <EmojiPanel onChangeInput={onChangeEmoji} />
            <IconFont
              className="m-l-12"
              type="icon-icon-test1"
              onClick={() => setVisibleSelectPerson(true)}
            />
          </div>
          <div className="textarea-action-btn">
            {value ? (
              <Popconfirm
                title="确定取消吗？"
                onConfirm={onClickCancel}
                overlayClassName="delete-popconfirm"
              >
                <span className="cancel-btn">取消</span>
              </Popconfirm>
            ) : (
              <span className="cancel-btn" onClick={onClickCancel}>
                取消
              </span>
            )}
            <ConfigProvider autoInsertSpaceInButton={false}>
              <Button type="primary" size="small" className="submit-btn" onClick={onClickSubmit}>
                确定
              </Button>
            </ConfigProvider>
          </div>
          <SingleUserSelect
            open={visibleSelectPerson}
            onCancel={onCancelSelectUser}
            handleSelect={handleSelect}
            getContainer={() => ref.current}
          />
        </div>
      )}
    </div>
  );
}
