import React, { useEffect, useMemo, useCallback } from 'react';
import { history } from 'umi';
import { Tabs, Tooltip } from 'antd';
import type { Dayjs } from 'dayjs';
import { useSafeState } from 'ahooks';
import * as querystring from 'querystring';
import { ProcessSourceTypeEnum } from '@/api/process';
import { DateTimeRangeEnum } from '@/constants';
import { OkrCycles } from '@/components';
import { getParams } from '@/utils/tools';
import OkrPeriodGuide from './okr-period-guide';
import { ProcessPeriodRangeSelect } from '..';
import ProcessListTab from './process-list-tab';
import ListWrap from './list-wrap';
import useOkrPeriods from './use-okr-periods';
import useDefaultQuery from '@/pages/process/list/hooks';

interface IProps {
  isPersonalCenter?: boolean;
  defaultProcessSource?: ProcessSourceTypeEnum;
  defaultPeriodId?: number;
  selectedUserId?: number; // 当前选择的用户的id
}

function ProcessList(props: IProps) {
  const params: any = getParams();

  const {
    // 是否是个人中心
    isPersonalCenter,
    defaultProcessSource = params.processSourceType || ProcessSourceTypeEnum.PROCESS_WEEK_REPORT,
    defaultPeriodId,
    selectedUserId,
  } = props;

  const { year: queryYear } = useDefaultQuery();

  // OKR 年份，周期年份
  const [year, setYear] = useSafeState(queryYear ? +queryYear : new Date().getFullYear());

  // 进展类型-processSourceType
  const [activeTab, setActiveTab] = useSafeState(defaultProcessSource);
  // OKR 周期：id - 周期id | year - 周期年份
  const [okrPeriodId, setOkrPeriodId] = useSafeState(defaultPeriodId);
  // OKR 周期选择提示弹层
  const [guideVisible, setGuideVisible] = useSafeState(false);

  // 周进展范围快捷筛选
  const [processRange, setProcessRange] = useSafeState({
    timeRange: undefined,
    activeKey: DateTimeRangeEnum.OneMonth,
  });

  useEffect(() => {
    setOkrPeriodId(defaultPeriodId);
  }, [defaultPeriodId]);

  useEffect(() => {
    if (defaultProcessSource) {
      setActiveTab(defaultProcessSource);
    }
  }, [defaultProcessSource]);

  const { periodList, statisticList } = useOkrPeriods({ userId: selectedUserId, year });

  const targetPeriod = useMemo(() => {
    if (!okrPeriodId || !periodList || periodList.length === 0) return undefined;
    return periodList?.find((item) => item.id === okrPeriodId);
  }, [okrPeriodId, periodList]);

  const { id: dateId, dateClass } = targetPeriod || {};

  const hasOkrs = useMemo(() => {
    if (!dateId) return false;
    const idxOkrPeriod = statisticList?.find((item) => item.dateId === dateId);
    return idxOkrPeriod && idxOkrPeriod?.count > 0;
  }, [dateId, statisticList]);

  const weeklyExtraFilters = useMemo(() => {
    const { activeKey, timeRange } = processRange;
    if (activeKey === DateTimeRangeEnum.Custom) {
      const [start, end]: Dayjs[] = timeRange || [];
      return {
        dateTimeRange: activeKey,
        from: start?.startOf('month')?.format?.('YYYY-MM-DD'),
        to: end?.endOf('month')?.format?.('YYYY-MM-DD'),
      };
    }
    return { dateTimeRange: activeKey };
  }, [processRange]);

  const onAddOkr = () => {
    const queryValues = {
      periodId: okrPeriodId,
      year,
    };
    history.push(`/okr?${querystring.stringify(queryValues)}`);
  };

  const commonProps = {
    readonly: isPersonalCenter,
    hasOkrs: !!hasOkrs,
    onClickGuide: () => setGuideVisible(true),
    onAddOkr,
    userId: selectedUserId,
    year,
    periodId: dateId,
  };

  const onCloseGuide = useCallback(() => {
    setGuideVisible(false);
  }, []);

  return (
    <div className="pl-process-list">
      <ProcessListTab
        isPersonalCenter={isPersonalCenter}
        activeKey={activeTab}
        onChange={setActiveTab}
        extra={
          <>
            {activeTab === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT && (
              <ProcessPeriodRangeSelect value={processRange} onChange={setProcessRange} />
            )}
            <Tooltip
              open={guideVisible}
              getPopupContainer={(triggerNode: any) => triggerNode.parentElement}
              title={<OkrPeriodGuide onClose={onCloseGuide} />}
              overlayStyle={{
                backgroundColor: 'rgba(255, 255, 255, 0)',
                maxWidth: 320,
                visibility: 'hidden',
              }}
              placement="bottomRight"
            >
              <div style={{ marginLeft: 32 }}>
                <OkrCycles
                  year={year}
                  periodId={okrPeriodId!}
                  onYearChange={(year) => setYear(year)}
                  onPeriodChange={setOkrPeriodId}
                  statisticsList={statisticList}
                  periodList={periodList}
                />
              </div>
            </Tooltip>
          </>
        }
      />
      <Tabs
        activeKey={activeTab}
        style={{ marginTop: 12 }}
        tabBarStyle={{ display: 'none' }}
        animated
        destroyInactiveTabPane
      >
        <Tabs.TabPane key={ProcessSourceTypeEnum.PROCESS_WEEK_REPORT} tab="周进展">
          <ListWrap
            {...commonProps}
            processSourceType={ProcessSourceTypeEnum.PROCESS_WEEK_REPORT}
            weeklyExtraFilters={weeklyExtraFilters}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key={ProcessSourceTypeEnum.PROCESS_SUMMARY} tab="总结自评">
          <ListWrap {...commonProps} processSourceType={ProcessSourceTypeEnum.PROCESS_SUMMARY} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default ProcessList;
