import { history, useModel } from 'umi';
import React, { useState } from 'react';
import { Popover } from 'antd';
import { useRequest } from 'ahooks';
import Layout from '@ehr-ui/layout';
import api from '@/api/common';
import { SearchUser, IconFont, Avatar, SystemFeedback } from '@/components';
import { dataTrack } from '@/utils/track';
import HeaderMenu from './menu';
import KnowledgeTopic from './knowledge-topic';
import Message from './message';
import styles from './index.less';
import '../assets/globals.css';

function getRedirectParams(url: string): string | null {
  const urlParts = url.split('?');
  if (urlParts.length < 2) {
    return null;
  }
  const queryString = urlParts.slice(1).join('?');
  if (!queryString?.includes('redirect=')) return null;
  const redirectStr = queryString?.replace('redirect=', '');
  return redirectStr;
}

export default function LayoutContainer(props: any) {
  const { children } = props;

  const redirectStr = getRedirectParams(location.href);

  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { name, photoUrl, corpMail } = userInfo || {};

  const [knowledgeVisible, setKnowledgeVisible] = useState(false);

  const { data: needRemindKnowledge, refresh } = useRequest(() => api.getTipInfo(1), {
    onSuccess: (data) => {
      setKnowledgeVisible(data);
    },
  });
  const { run } = useRequest(() => api.muteTip(1), { manual: true });

  const selectUser = (userId: number) => {
    dataTrack('click_header_search');
    history.push(`/team/${userId}?tab=okr`);
  };

  const showKnowledgePopover = (e: React.MouseEvent) => {
    e.stopPropagation();
    dataTrack('click_header_knowledge');
    setKnowledgeVisible(true);
  };

  const onCloseKnowledge = () => {
    setKnowledgeVisible(false);
  };

  const onMuteKnowledge = () => {
    onCloseKnowledge();
    run();
    refresh();
  };

  const onLogout = () => {
    dataTrack('click_logout');
    localStorage.okrUserId = null;
    localStorage.okrUserName = null;
    const { host, origin } = window.location;
    const returnUrl = origin; // 登录成功后跳转的页面
    const errUrl = `${host}/index.html`; // 登录无权限页面
    window.location.href = `/api/auth/login/openId/logout?returnUrl=${returnUrl}&errUrl=${errUrl}`;
  };

  const onClickPersonalCenter = () => {
    dataTrack('click_personal_center');
    history.push(`/team/${userInfo?.id}?tab=okr`);
  };

  if (redirectStr) {
    location.href = redirectStr;
    return;
  }

  return (
    <div className={styles.page} id="app-page">
      <div className={styles.header} id="okr-header">
        <div className="f-align-center">
          <Layout.Portal text="网易OKR分享平台" theme="red" />
          <HeaderMenu />
        </div>
        <div className={styles.headerRight}>
          <SearchUser selectUser={selectUser} className={styles.select} />
          <Popover
            open={knowledgeVisible}
            getPopupContainer={() => document.getElementById('okr-header')!}
            content={
              <KnowledgeTopic
                onClose={onCloseKnowledge}
                defaultVisible={!!needRemindKnowledge}
                onMute={onMuteKnowledge}
              />
            }
          >
            <span onClick={showKnowledgePopover} className={styles.headerItem}>
              <IconFont className={styles.icon} type="icon-ForClassActivity" />
              <span>知识专题</span>
            </span>
          </Popover>
          <Message />
          {/* @ts-ignore */}
          <Layout.Dropdown
            theme="red"
            menu={[
              {
                name: '个人中心',
                onClick: onClickPersonalCenter,
              },
              {
                name: '退出',
                onClick: onLogout,
              },
            ]}
          >
            <Avatar imgPath={photoUrl} userName={name} type="small" />
            <div style={{ marginLeft: 6 }}>Hi，{name || '--'}</div>
          </Layout.Dropdown>
        </div>
      </div>
      <main className={styles.main} id="main">
        {children}
      </main>
      {!!corpMail && <SystemFeedback email={corpMail} />}
    </div>
  );
}
