import React from 'react';
import { useRequest } from 'ahooks';
import classnames from 'classnames';
import processApi, { IProcessVO, ProcessSourceTypeEnum } from '@/api/process';
import { OkrTypeEnum } from '@/api/okr';
import { useStore } from '@/store';
import { O_KR_ENUM, ProcessOtherKey, ProcessPublishStatusEnum } from '@/constants';
import { ReadonlyRichText } from '@/components';
import { getProcessApi, addProcessInfo2OkrList } from '../../dashboard/utils';
import './index.less';

function transformProcessList2Map(processList: IProcessVO[], processOther?: IProcessVO) {
  const currentProcessMap: { [key in string]: IProcessVO } = (processList || [])
    .filter((item) => item.status === ProcessPublishStatusEnum.published)
    .reduce((voMap, current) => {
      const { type, typeSourceId } = current;
      return {
        ...voMap,
        [`${type}-${typeSourceId}`]: current,
      };
    }, {});
  if (processOther && processOther.status === ProcessPublishStatusEnum.published) {
    currentProcessMap[ProcessOtherKey] = processOther;
  }
  return currentProcessMap;
}

function ProcessLine(props: any) {
  const { process, name, className, type = O_KR_ENUM.KR, style } = props;
  const { content, updateTime } = process || {};
  return (
    <div className={classnames('process-line', className)} style={style}>
      {type === O_KR_ENUM.O ? (
        <div className="process-line-name-icon process-line-name-o">O</div>
      ) : (
        <div className="process-line-name-icon process-line-name-kr">KR</div>
      )}
      <div className="process-line-content">
        <div className="process-line-name-text">{name}</div>
        <ReadonlyRichText content={content} className="process-content" updateTime={updateTime} />
      </div>
    </div>
  );
}

interface IProps {
  id: number;
  title: string;
}

// 邮件内容，需要内联样式处理()，和动态列表区分；迭代优化可新增邮件预览
function EmailWeekly(props: IProps) {
  const { title, id } = props;

  const {
    currentProcessDateId,
    currentProcessYear,
    currentProcessSourceType,
    currentProcessOkrDateId,
    currentProcessUserId,
  } = useStore();

  const { data: info } = useRequest(
    async () => {
      const fetchApi = getProcessApi(currentProcessSourceType!, OkrTypeEnum.personal);
      if (!fetchApi) return;
      const [processData, okrList] = await Promise.all([
        fetchApi({
          year: currentProcessYear,
          dateId: currentProcessDateId,
          okrDateId: currentProcessOkrDateId,
        }),
        processApi.getOkrList({
          okrDateId: currentProcessOkrDateId,
          year: currentProcessYear,
          userId: currentProcessUserId,
        }),
      ]);
      const processListMap = transformProcessList2Map(
        processData?.processVOList,
        processData?.processOther,
      );
      const okrListWidthProcess = addProcessInfo2OkrList(okrList, processListMap, true).filter(
        (item) => {
          const { krList, processInfo } = item;
          return krList?.length > 0 || !!processInfo;
        },
      );
      return { processOther: processData?.processOther, okrListWidthProcess };
    },
    {
      refreshDeps: [],
    },
  );

  const { okrListWidthProcess, processOther } = info || {};

  const link = `${window.location.origin}/#/process/dashboard?tab=detail&id=${id}&type=${currentProcessSourceType}`;

  return (
    <div
      style={{ padding: '32px 20px' }}
      className="process-email-content"
      id="process-email-content"
    >
      <div style={{ color: '#333', fontSize: 16, fontWeight: 500, marginBottom: 24 }}>{title}</div>
      <div
        className="discover-process-card"
        style={{
          borderTop: '1px solid #E0E0E0',
          borderBottom: '1px solid #E0E0E0',
          padding: '16px 0',
        }}
      >
        {okrListWidthProcess?.map((item, index) => {
          const { processInfo, objectivesName, uniqKey, krList } = item;
          return (
            <div key={uniqKey} className="process-card">
              <ProcessLine
                className={index !== 0 ? 'process-line-o' : ''}
                name={objectivesName}
                process={processInfo}
                type={O_KR_ENUM.O}
              />
              {krList?.map((krItem) => (
                <ProcessLine
                  key={krItem.uniqKey}
                  name={krItem.keyResultName}
                  process={krItem.processInfo}
                />
              ))}
            </div>
          );
        })}
        {currentProcessSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT &&
          !!processOther?.content && (
            <div className="weekly-other">
              <div className="weekly-other-name">
                <span className="other-text">其他</span>
              </div>
              <ReadonlyRichText
                className="week-other-rich-content"
                content={processOther?.content}
                updateTime={processOther?.updateTime}
              />
            </div>
          )}
      </div>
      <div style={{ color: '#999', fontSize: 14, marginTop: 20 }}>
        访问OKR系统查看周报详情、点赞、评论：
      </div>
      <a title="链接" href={link} style={{ color: '#1872F0', marginTop: 4 }}>
        {link}
      </a>
      <div style={{ color: '#999', fontSize: 14, marginTop: 20 }}>OKR分享平台 okr.netease.com</div>
    </div>
  );
}

export default EmailWeekly;
