export const EMOJI_LIST = [
  '阿弥陀佛',
  '嗷嗷嗷',
  '拜拜',
  '崩溃',
  '鄙视你',
  '啵儿',
  '不说',
  '大哭',
  '带血的刀',
  '得意的笑',
  '电到了',
  '飞吻',
  '尴尬',
  '工作忙',
  '鼓掌',
  '害羞',
  '汗',
  '好爱你',
  '好好笑',
  '很得意',
  '坏',
  '教训',
  '睫毛弯弯',
  '惊讶',
  '啾啾',
  '开怀笑',
  '开心',
  '可爱',
  '抠鼻',
  '哭泣',
  '困了',
  '老大',
  '困了2',
  '来，亲一个',
  '玫瑰',
  '流口水了',
  '扭捏',
  '摸摸头',
  '难过',
  '飘啊飘',
  '怕怕',
  '拍桌子',
  '气呼呼',
  '亲亲',
  '撒娇',
  '欠揍',
  '色眯眯',
  '生气',
  '失望',
  '耍酷',
  '送花给你',
  '叹气',
  '偷偷笑',
  '吐舌微笑',
  '微笑',
  '问号',
  '我吐',
  '握手',
  '无辜',
  '心碎了',
  '秀一下',
  '嘘',
  '仰慕你',
  '有了',
  '晕',
  '砸死你',
  '眨眼',
  '炸弹',
  '抓狂',
  'no',
  'YEAH',
  'yes',
];
