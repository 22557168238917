import dayjs from 'dayjs';
import { ProcessSourceTypeEnum } from '@/api/process';
import { ProcessPublishStatusEnum } from '@/constants';

export function getStatusText(status: ProcessPublishStatusEnum) {
  const statusInfoMap = {
    text: '',
    className: '',
  };
  switch (status) {
    case ProcessPublishStatusEnum.draft: {
      statusInfoMap.text = '草稿';
      statusInfoMap.className = 'draft';
      break;
    }
    case ProcessPublishStatusEnum.published: {
      statusInfoMap.text = '已发布';
      statusInfoMap.className = 'published';
      break;
    }
    case ProcessPublishStatusEnum.overdueForRelease: {
      statusInfoMap.text = '未填写';
      statusInfoMap.className = 'overdue';
      break;
    }
    default:
      break;
  }
  return statusInfoMap;
}

function formatTime(date: any) {
  return dayjs(date).isValid() ? dayjs(date).format('MM/DD') : '-';
}

/**
 * 获取标题展示
 * @param info
 * @returns {string} - 2023年02月第1周 (02/27 ~ 03/05)
 */
export function getProcessTitle(info: any) {
  if (!info) return '--';
  const { name, startDate, endDate } = info || {};
  if (!startDate && !endDate) {
    return name;
  }
  return `${name} (${formatTime(startDate)} ~ ${formatTime(endDate)})`;
}

export function getTitle(processSourceType: ProcessSourceTypeEnum, name: string) {
  let processTypeText = '';
  switch (processSourceType) {
    case ProcessSourceTypeEnum.PROCESS_WEEK_REPORT:
      processTypeText = '周报';
      break;
    case ProcessSourceTypeEnum.PROCESS_CONCLUSION:
      processTypeText = '小结';
      break;
    case ProcessSourceTypeEnum.PROCESS_SUMMARY:
      processTypeText = '总结';
      break;
    default:
      break;
  }
  return `${name || '-'}的${processTypeText}`;
}
