import React from 'react';
import './index.less';

const DEFAULT_ACTIVE_STYLE = {
  color: '#1890ff',
  backgroundColor: 'rgba(32, 150, 252, 0.12)',
};

interface ITabItem {
  key: React.Key;
  tab: string;
  icon: React.ReactElement;
  activeStyle?: React.CSSProperties;
}

interface IProps {
  tabList: ITabItem[];
  activeKey: React.Key;
  onChange: (key: React.Key) => void;
}

const OkrTabs = (props: IProps) => {
  const { tabList, activeKey, onChange } = props;

  const onChangeTab = (key: React.Key) => {
    onChange?.(key);
  };

  return (
    <div className="m-okr-tabs">
      <div className="m-okr-process-tabs">
        {tabList.map((item) => {
          const { key, tab, icon, activeStyle = DEFAULT_ACTIVE_STYLE } = item;
          return (
            <div
              className={`m-okr-process-tab ${activeKey === key ? 'okr-process-tab__active' : ''}`}
              style={activeKey === key ? activeStyle || DEFAULT_ACTIVE_STYLE : {}}
              onClick={() => onChangeTab(key)}
              key={key}
            >
              {icon}
              {tab}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OkrTabs;
