import React, { useEffect, memo } from 'react';
import { history } from 'umi';
import { useRequest } from 'ahooks';
import { Popover, Badge, Tooltip } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { useMessageStore } from '@/store';
import api from '@/api/message';
import { dataTrack } from '@/utils/track';
import MessagePopover from './popover';
import styles from '../index.less';

const HeaderMessage = () => {
  const { runAsync } = useRequest((idList?: number[]) => api.readMessage(idList), { manual: true });

  const { newMessageList, getNewMessageList, newMessageCount } = useMessageStore();

  useEffect(() => {
    getNewMessageList();
  }, []);

  const goToMessage = () => {
    dataTrack('click_message_center');
    history.push('/message');
  };

  const onReadMessage = async (id?: number) => {
    await runAsync(id ? [id] : undefined);
    getNewMessageList();
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      dataTrack('click_message_center');
    }
  };

  if (newMessageCount > 0) {
    return (
      <Popover
        content={
          <MessagePopover
            messageList={newMessageList}
            onReadMessage={onReadMessage}
            messageCount={newMessageCount}
          />
        }
        onOpenChange={onOpenChange}
        overlayClassName="m-header-message-popover"
        getPopupContainer={() => document.getElementById('okr-header')!}
      >
        <Badge
          count={newMessageCount}
          overflowCount={99}
          className={styles.badge}
          offset={[-10, 10]}
        >
          <span className={styles.headerItem}>
            <BellOutlined className={styles.icon} style={{ marginRight: 0 }} />
          </span>
        </Badge>
      </Popover>
    );
  }
  return (
    <Tooltip title="消息中心">
      <span className={styles.headerItem} onClick={goToMessage}>
        <BellOutlined className={styles.icon} style={{ marginRight: 0 }} />
      </span>
    </Tooltip>
  );
};

export default memo(HeaderMessage);
