import React, { useRef, useState, useEffect } from 'react';
import { history, useModel } from 'umi';
import { useRequest } from 'ahooks';
import { Spin, Row, Col, Tabs, Button, Form } from 'antd';
import okrApi, { OkrPeriodEnum, ProcessAddTypeEnum } from '@/api/okr';
import { useOkrStore } from '@/store';
import { O_KR_ENUM } from '@/constants/okr';
import { IUserInfo } from '@/api/auth';
import { getParams } from '@/utils/tools';
import { getEditAuthMap } from '@/utils/okr';
import { OkrName, OkrScore, OkrStatus, OkrPrincipal } from '@/components/okr/operate';
import Process from './process';

const { TabPane } = Tabs;

interface IProps {
  onRefreshList?: () => void;
  onRefresh?: (refresh?: boolean) => void;
}

const Kr = (props: IProps) => {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { detailInfo, setHasEdit, readonly } = useOkrStore();
  const { detailId: krId, index } = detailInfo || {};

  const { onRefresh, onRefreshList } = props;

  const [form] = Form.useForm();
  const urlParams: any = getParams();
  const refreshTabList = useRef(); // 刷新tab下内容
  const [activeKey, setActivityKey] = useState('process');

  const { data, loading, run: onRefreshDetail } = useRequest(() => okrApi.getKrDetail(krId));
  const { krDetail, authMap, dateId, dateType, year } = data || {};
  const { keyResultName = '', userList = [], krProcessStatusVO } = krDetail || {};
  const { processStatus, processScore } = krProcessStatusVO || {};

  // 获取okr权限
  const editAuthMap = getEditAuthMap(authMap || {}, userInfo, readonly);

  // @ts-ignore kr编辑权限
  const krUpdateAuth = editAuthMap.updateAuthKrFunc(userList || []);

  const showAddBtn = history.location.pathname === '/okr';

  const onKrNameChange = (value: string) => {
    okrApi
      .updateKr({
        id: krId,
        keyResultName: value,
        principalIdList: userList.map((i) => i.id),
      })
      .then(() => {
        onRefreshDetail();
      });
  };

  const onPrincipalChange = (value: IUserInfo[]) => {
    okrApi
      .updateKr({ id: krId, keyResultName, principalIdList: value.map((i) => i.id) })
      .then(() => {
        onRefreshDetail();
        onRefreshList?.();
      });
    return value;
  };

  const onStatusOk = ({ status, content }: any) =>
    okrApi.addKrProcess({
      content,
      status,
      processAddType: ProcessAddTypeEnum.STATUS,
      keyResultId: krId,
    });
  const onStatusChange = () => {
    //@ts-ignore
    refreshTabList.current?.refresh();
  };

  const onScoreOk = ({ score, content }: any) =>
    okrApi.addKrProcess({
      content,
      score,
      processAddType: ProcessAddTypeEnum.SCORE,
      keyResultId: krId,
    });

  const onScoreChange = () => {
    //@ts-ignore
    refreshTabList.current?.refresh();
  };

  const onDelete = () => {
    okrApi.delKr(krId).then(() => {
      setHasEdit(true);
      onRefresh?.();
    });
  };

  const onClickProcess = () => {
    history.push(
      `/process/list?year=${year}&periodId=${dateId}&tab=${
        (urlParams.tab === 'okr' ? 'user' : urlParams.tab) || 'user' // 修复个人中心tab=okr时，跳转到进展页面，tab显示为okr的bug
      }`,
    );
  };

  const values = {
    keyResultName,
    userList,
    processStatus,
    processScore,
  };

  const onValuesChange = () => {
    setHasEdit(true);
  };

  useEffect(() => {
    form.setFieldsValue(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [krDetail]);

  // 自定义周期
  const isCustomPeriod = dateType === OkrPeriodEnum.custom;

  return (
    <div className="m-detail-drawer">
      <Spin spinning={loading}>
        {!!krDetail && (
          <Form form={form} onValuesChange={onValuesChange}>
            <div className="m-okr-detail-header">
              <div className="m-kr-detail-index">KR{index + 1}</div>
              <div className="m-detail-header-right">
                <Form.Item className="m-detail-obj-name" name="keyResultName">
                  <OkrName
                    type="KR"
                    authEdit={krUpdateAuth}
                    onChange={onKrNameChange}
                    onDelete={krUpdateAuth && onDelete}
                    isDetail
                  />
                </Form.Item>
                <Row gutter={[10, 0]}>
                  <Col span={6}>
                    <div className="m-edit-title">负责人</div>
                    <Form.Item noStyle name="userList">
                      <OkrPrincipal
                        authEdit={krUpdateAuth}
                        isMultipleSelect
                        isDetail
                        onChange={onPrincipalChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <div className="m-edit-title">状态</div>
                    <Form.Item noStyle name="processStatus">
                      <OkrStatus
                        authEdit={krUpdateAuth}
                        onOk={onStatusOk}
                        onChange={onStatusChange}
                        type={O_KR_ENUM.KR}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <div className="m-edit-title">评分</div>
                    <Form.Item noStyle name="processScore">
                      <OkrScore authEdit={krUpdateAuth} onOk={onScoreOk} onChange={onScoreChange} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        )}
      </Spin>
      <div className="m-okr-detail-tab">
        <Tabs
          activeKey={activeKey}
          onChange={(key) => setActivityKey(key)}
          tabBarExtraContent={
            activeKey === 'process' &&
            krUpdateAuth &&
            !isCustomPeriod &&
            showAddBtn && (
              <Button type="link" style={{ fontSize: 12 }} onClick={onClickProcess}>
                + 添加进展
              </Button>
            )
          }
        >
          <TabPane tab="进展记录" key="process">
            <Process ref={refreshTabList} id={krId} type="KR" />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Kr;
