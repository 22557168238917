import { useLayoutEffect, useState, useMemo } from 'react';
import { useModel, Link } from 'umi';
import { Empty, Divider, Spin } from 'antd';
import { useRequest, useDebounceFn } from 'ahooks';
import api, { IDiscoverItem, IRequestDiscover } from '@/api/discover';
import authApi from '@/api/auth';
import { DeptTreeSelect } from '@/components';
import {
  LEVEL_FILTER_TYPE_LIST,
  LEVEL_FILTER_TYPE,
  DISCOVER_FILTER_TYPE_LIST,
  DynamicRequireTypeEnumEventParamMap,
  DynamicRequireTypeEnum,
} from '@/constants/discover';
import { ICommentItem } from '@/api/common';
import { generateUUID } from '@/utils/tools';
import { dataTrack } from '@/utils/track';
import { DiscoverCard, FilterSelect } from '..';
import styles from './index.less';

export enum DiscoverListTabEnum {
  Team,
  Follow,
  All,
}

const EVENT_PARAM_MAP = {
  [DiscoverListTabEnum.Team]: {
    range: 'click_discover_team_range_select',
    type: 'click_discover_team_type_select',
  },
  [DiscoverListTabEnum.Follow]: {
    type: 'click_discover_follow_type_select',
  },
  [DiscoverListTabEnum.All]: {
    type: 'click_discover_all_type_select',
  },
};

const MESSAGE_KEY = 'updatable';

interface IProps {
  tab: DiscoverListTabEnum;
  userId?: number; // 个人中心需要传
  hideDeptFilter?: boolean;
  scrollId?: string;
}

const DiscoverList = (props: IProps) => {
  const { tab, userId, hideDeptFilter, scrollId = 'main' } = props;

  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const [currentPage, setCurrentPage] = useState(1);
  const [dataList, setDataList] = useState<IDiscoverItem[]>([]);
  const [isBottom, setIsBottom] = useState(false);

  const { data: followCount } = useRequest(() => authApi.getFollowCount(), {
    ready: tab === DiscoverListTabEnum.Follow,
  });

  const apiFunc = {
    [DiscoverListTabEnum.Team]: api.getTeamDiscover,
    [DiscoverListTabEnum.Follow]: api.getMyFollowDiscover,
    [DiscoverListTabEnum.All]: api.getAllDiscover,
  }[tab];

  const removeRepeatData = (dataList: IDiscoverItem[]) => {
    const map = new Map();
    dataList.forEach((item) => {
      map.set(item.id, item);
    });
    return [...map.values()];
  };

  const { data, loading, run, params } = useRequest(
    // @ts-ignore
    (params?: IRequestDiscover = { type: 'W' }) => {
      let pageSize = 5;
      console.log('params', params);
      if (
        [DynamicRequireTypeEnum.SUMMARY, DynamicRequireTypeEnum.WEEKLY].includes(params?.type || '')
      ) {
        pageSize = 2;
      }
      return apiFunc({
        currentPage,
        pageSize,
        userId,
        ...(params || {}),
      });
    },
    {
      onSuccess: (res) => {
        if (currentPage === 1) {
          setDataList(res?.dataList);
        } else {
          const uniqueDataList = removeRepeatData([...(dataList || []), ...(res?.dataList || [])]);
          setDataList(uniqueDataList);
        }
      },
    },
  );

  const { page } = data || {};
  const { totalPage } = page || {};

  const onChangePage = () => {
    if (loading) return;
    const scrollElement = document.getElementById(scrollId) as any;
    // 判断是否加载数据
    const htmlHeight = scrollElement.scrollHeight || document.body.scrollHeight,
      // clientHeight是网页在浏览器中的可视高度，
      clientHeight = scrollElement.clientHeight || document.body.clientHeight,
      // scrollTop是浏览器滚动条的top位置，
      scrollTop = scrollElement.scrollTop || document.body.scrollTop;
    // console.log(scrollTop, clientHeight, htmlHeight);
    if (scrollTop + clientHeight + 200 >= htmlHeight) {
      loadList();
    }
  };

  useLayoutEffect(() => {
    const scrollElement = document.getElementById(scrollId) as any;
    scrollElement.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    const scrollElement = document.getElementById(scrollId) as any;
    scrollElement.addEventListener('scroll', onChangePage);
    return () => {
      scrollElement.removeEventListener('scroll', onChangePage);
    };
  }, [onChangePage]);

  const { run: loadList } = useDebounceFn(
    () => {
      if (currentPage >= (totalPage || 0)) {
        // 返回已经到最底部
        setIsBottom(true);
        return;
      }
      const param = params[0] || {};
      console.log('param', param, params);
      run({ type: 'W', ...param, currentPage: currentPage + 1 });
      setCurrentPage(currentPage + 1);
    },
    {
      wait: 500,
      leading: true,
    },
  );

  const onChangeRange = (level: 1 | 2 | 3 | undefined) => {
    const param = params[0] || {};
    setCurrentPage(1);
    setDataList([]);
    run({ type: 'W', ...param, level, currentPage: 1 });

    // @ts-ignore
    const eventId = EVENT_PARAM_MAP[tab].range || '';
    if (!eventId) return;
    dataTrack(eventId, { data: +(level || 0) + 1 });
  };

  const onChangeType = (type: 'O' | 'K' | 'W' | 'S' | undefined) => {
    const param = params[0] || {};
    setCurrentPage(1);
    setDataList([]);
    console.log('param', param);
    run({ ...param, type, currentPage: 1 });

    const eventData = type ? DynamicRequireTypeEnumEventParamMap[type] : 1;
    if (!eventData) return;
    dataTrack(EVENT_PARAM_MAP[tab].type, { data: eventData });
  };

  const onChangeDept = (deptId: string) => {
    const param = params[0] || {};
    setCurrentPage(1);
    setDataList([]);
    run({ type: 'W', ...param, deptId, currentPage: 1 });
  };

  // 根据当前用户身份，控制其“我的上级”、“我的下属”、“我的同级”的筛选标签显隐
  const rangeList = useMemo(() => {
    if (!userInfo) return [];
    const { hasUndered, hasLeader, hasPartner } = userInfo;
    let modifyList = LEVEL_FILTER_TYPE_LIST;
    // 不存在下属
    if (!hasUndered) {
      modifyList = modifyList.filter((item) => item.value !== LEVEL_FILTER_TYPE.SUB);
    }
    // 不存在上级
    if (!hasLeader) {
      modifyList = modifyList.filter((item) => item.value !== LEVEL_FILTER_TYPE.SUP);
    }
    // 不存在同级
    if (!hasPartner) {
      modifyList = modifyList.filter((item) => item.value !== LEVEL_FILTER_TYPE.SIBLING);
    }
    return modifyList;
  }, [userInfo]);

  const refreshLike = (discoverId: number) => {
    const newDataList = dataList?.map((item) => {
      if (item.id === discoverId) {
        return {
          ...item,
          thumbUpVO: {
            isThumbUp: true,
            thumbUpCount: (item.thumbUpVO?.thumbUpCount || 0) + 1,
            userList: [
              ...(item.thumbUpVO?.userList || []),
              {
                id: userInfo?.id,
                name: userInfo?.name,
                photoUrl: userInfo?.photoUrl,
              },
            ],
          },
        };
      }
      return item;
    });
    // @ts-ignore
    setDataList(newDataList);
  };

  const refreshComment = (discoverId: number, commentData?: ICommentItem) => {
    if (!commentData) return;
    const newDataList = dataList?.map((item) => {
      if (item.id === discoverId) {
        return {
          ...item,
          commentVOList: [commentData || {}, ...(item.commentVOList || [])],
        };
      }
      return item;
    });
    setDataList(newDataList);
  };

  const handleDeleteComment = (discoverId: number, commentId: number) => {
    const newDataList = dataList?.map((item) => {
      if (item.id === discoverId) {
        const newCommentList = (item.commentVOList || [])
          .filter((comment) => comment.id !== commentId)
          .filter((item) => item.parentId !== commentId);
        return {
          ...item,
          commentVOList: newCommentList,
        };
      }
      return item;
    });
    setDataList(newDataList);
  };

  const showUnFollowNotice = tab === DiscoverListTabEnum.Follow && !followCount && !loading;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className="f-align-center">
          {tab === DiscoverListTabEnum.Team && (
            <FilterSelect
              label="范围"
              dataList={rangeList}
              onChange={onChangeRange}
              style={{ marginRight: 20 }}
            />
          )}
          {tab === DiscoverListTabEnum.All && !hideDeptFilter && (
            <div>
              <span>部门范围：</span>
              <DeptTreeSelect
                style={{ marginRight: 20 }}
                hasAllOption
                onChange={onChangeDept}
                bordered={false}
              />
            </div>
          )}
          <FilterSelect
            label="类型"
            dataList={DISCOVER_FILTER_TYPE_LIST}
            onChange={onChangeType}
            defaultValue={DynamicRequireTypeEnum.WEEKLY}
          />
        </div>
        {tab === DiscoverListTabEnum.Follow && (
          <Link
            className="u-follow-btn"
            target="_blank"
            to={{ pathname: '/okr', search: '?tab=share' }}
          >
            管理我关注的同事
          </Link>
        )}
      </div>
      <div>
        {showUnFollowNotice && (
          <Empty
            description={
              <div>
                你还没有关注任何同事哦，
                <Link
                  className="u-follow-btn"
                  target="_blank"
                  to={{ pathname: '/okr', search: '?tab=share' }}
                >
                  点此去关注同事！
                </Link>
              </div>
            }
          />
        )}
        {dataList?.length
          ? dataList?.map((item) => (
              <DiscoverCard
                key={generateUUID()}
                data={item}
                refresh={refreshComment}
                refreshLike={refreshLike}
                handleDeleteComment={handleDeleteComment}
              />
            ))
          : !loading &&
            !showUnFollowNotice && (
              <Empty
                className="mt-20"
                description={<div className="g-font-gray-weak">暂无动态</div>}
              />
            )}
        {loading && (
          <div className="p-8 items-center justify-center flex">
            <Spin spinning={loading} />
          </div>
        )}
        {isBottom && !!dataList?.length && !loading && !showUnFollowNotice && (
          <Divider dashed>
            <div className="g-font-gray-weak">
              无更多动态了
              <br />
              更新OKR或周报都能产生动态哦
            </div>
          </Divider>
        )}
      </div>
    </div>
  );
};

export default DiscoverList;
