import { GATEWAY } from '@/api/config';
import api from '..';
import { ResponsePagePromise } from '../interface';
import { IDiscoverItem, IRequestDiscover } from './interface';

export default {
  // 团队动态
  getTeamDiscover: (params: IRequestDiscover): ResponsePagePromise<IDiscoverItem> =>
    api.get(`${GATEWAY}/dynamic/page/team`, params),
  // 我关注的动态
  getMyFollowDiscover: (params: IRequestDiscover): ResponsePagePromise<IDiscoverItem> =>
    api.get(`${GATEWAY}/dynamic/page/attention`, params),
  // 全部动态
  getAllDiscover: (params: IRequestDiscover): ResponsePagePromise<IDiscoverItem> =>
    api.get(`${GATEWAY}/dynamic/page/all`, params),
};

export * from './interface';
