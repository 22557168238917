import React, { useState, useCallback } from 'react';
import { history } from 'umi';
import { useRequest } from 'ahooks';
import { Table, Tooltip, Typography, App } from 'antd';
import type { TableColumnsType } from 'antd';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { useAntdTable } from '@/hooks';
import api, { IQueryShareUserListParams } from '@/api/okr';
import authApi, { IUserInfo } from '@/api/auth';
import { FilterList, SettingModal } from './components';
import './index.less';

const DEFAULT_CONDITION = {
  currentPage: 1,
  pageSize: 10,
};

const OKRShareCenter = () => {
  // @ts-ignore
  const [condition, setCondition] = useState<IQueryShareUserListParams>({});
  const [currentEmpType, setCurrentEmpType] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const { message } = App.useApp();

  const { runAsync: follow } = useRequest((id: number) => authApi.addAttention(id), {
    manual: true,
  });

  const { runAsync: unfollow } = useRequest((id: number) => authApi.delAttention(id), {
    manual: true,
  });

  const changeContact = async (record: any) => {
    if (!record) return;
    if (record.attention) {
      await unfollow(record?.id);
      message.success(`取消关注（${record?.userName}）成功`);
    } else {
      await follow(record?.id);
      message.success(`关注（${record?.userName}）成功`);
    }
    await refreshAsync();
  };

  const jump = (id: number) => {
    history.push({
      pathname: `/team/${id}`,
      search: '?tab=okr',
    });
  };

  const columns: TableColumnsType<IUserInfo> = [
    {
      title: '姓名',
      dataIndex: 'userName',
      render: (name, user) => {
        const { id } = user;
        return <Typography.Link onClick={() => jump(id)}>{name}</Typography.Link>;
      },
    },
    { title: '工号', dataIndex: 'uNo' },
    {
      title: '部门',
      dataIndex: 'deptPath',
    },
    { title: '今年已建目标', dataIndex: 'okrYearSum' },
    {
      title: '最新周报',
      dataIndex: 'latestWeeklyReport',
      render: (value: string) => value || '无',
    },
    {
      title: '关注',
      dataIndex: 'attention',
      render: (value: boolean, record: any) => (
        <Tooltip placement="right" title={value ? '取消关注' : '关注'}>
          {value ? (
            <StarFilled className="contact" onClick={(e) => changeContact(record)} />
          ) : (
            <StarOutlined className="uncontact" onClick={(e) => changeContact(record)} />
          )}
        </Tooltip>
      ),
    },
  ];

  const { tableProps, refreshAsync } = useAntdTable(
    (pagination) =>
      api.getShareCenterList({
        ...(condition || {}),
        currentPage: pagination.current,
        pageSize: pagination.pageSize,
      }),
    {
      ready: !!condition?.type,
      useNewPagination: true,
      refreshDeps: [condition],
      debounceWait: 10,
    },
  );

  const handleCondition = useCallback(
    (params: any) => {
      setCondition({
        ...(params || {}),
        ...DEFAULT_CONDITION,
        empTypeList: currentEmpType,
        desc: searchKeyword,
      });
    },
    [currentEmpType, searchKeyword],
  );

  const handleEmpType = useCallback(
    (empType: string) => {
      setCurrentEmpType(empType);
      setCondition({
        ...(condition || {}),
        ...DEFAULT_CONDITION,
        empTypeList: empType,
        desc: searchKeyword,
      });
    },
    [condition, searchKeyword],
  );

  const handleSearchTerm = useCallback(
    (searchTerm?: string) => {
      setSearchKeyword(searchTerm || '');
      setCondition({
        ...(condition || {}),
        ...DEFAULT_CONDITION,
        empTypeList: currentEmpType,
        desc: searchTerm,
      });
    },
    [currentEmpType, condition],
  );

  return (
    <div className="m-share-center-wrap">
      <div className="sc-title">
        共享中心
        <SettingModal />
      </div>
      <FilterList
        handleCondition={handleCondition}
        handleEmpType={handleEmpType}
        handleSearchTerm={handleSearchTerm}
      />
      <Table style={{ marginTop: 20 }} columns={columns} rowKey="id" {...tableProps} />
    </div>
  );
};

export default OKRShareCenter;
