import React, { useState, useEffect } from 'react';
import { Upload } from '@netease-ehr/ui';
import { PaperClipOutlined } from '@ant-design/icons';

const fileSize = 20 * 1024 * 1024; // (200M)
const fileLength = 50;
const validFileType = [
  'jpg',
  'jpeg',
  'png',
  'ppt',
  'pptx',
  'doc',
  'docx',
  'pdf',
  'xlsx',
  'xls',
  'txt',
  'rar',
  'zip',
  'md',
  'html',
  'gif',
];

const formatData = (data: any) => {
  if (!data) return [];
  return Array.isArray(data)
    ? data.map((item) => ({
        uid: item.id || item.nosKey || item.uid,
        ...item,
      }))
    : [
        {
          uid: data.id || data.nosKey || data.uid,
          ...data,
        },
      ];
};

const UploadFile = (props: any) => {
  const { fileList: defaultFileList } = props;
  const [fileList, setFileList] = useState(formatData(defaultFileList));

  useEffect(() => {
    setFileList(formatData(defaultFileList));
  }, [defaultFileList]);

  return (
    <Upload
      {...props}
      maxSize={fileSize}
      maxCount={fileLength}
      fileList={fileList}
      validFileType={validFileType}
    >
      <PaperClipOutlined className="upload-icon" />
    </Upload>
  );
};

export default UploadFile;
