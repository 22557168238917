import api from '@/api';
import { ResponsePromise, ResponsePagePromise } from '@/api/interface';
import { GATEWAY } from '@/api/config';
import {
  ILikePayload,
  ICommentPayload,
  ICommentItem,
  IDeptTreeItem,
  ISearchUserParams,
} from './interface';
import { IUserInfo } from '../auth';

export default {
  // 公共-关键词搜索员工
  searchUser: (params: ISearchUserParams): ResponsePagePromise<IUserInfo> =>
    api.get(`${GATEWAY}/user/searchUser`, params),
  // 查询用户关注点赞信息
  getUserLikeInfo: (userId?: number): ResponsePromise<IUserInfo> =>
    api.get(`${GATEWAY}/user/popularity`, { userId }),
  getTipInfo: (tipType: number): ResponsePromise<boolean> =>
    api.get(`${GATEWAY}/common/tip`, { tipType }),
  muteTip: (tipType: number) => api.post(`${GATEWAY}/common/tip`, { tipType }),
  // 点赞 recordType: 记录类型 1浏览。2点赞
  thumbUp: (payload: ILikePayload) =>
    api.post(`${GATEWAY}/record/create`, { ...(payload || {}), recordType: 2 }),
  /**
   * 评论-新增评论
   */
  postComment: (payload: ICommentPayload) => api.post(`${GATEWAY}/comment/create`, payload),

  // 获取员工类型列表
  getEmpTypes: () => api.get(`${GATEWAY}/common/emp/types`),
  // 删除评论/回复
  deleteComment: (id: number) => api.delete(`${GATEWAY}/comment/delete`, { id }),
  /**
   * 获取进展评论列表 - 后端接口确定
   * @param params
   */
  getCommentList: (params: {
    targetId: number;
    targetType: string;
    userId?: number;
  }): ResponsePromise<ICommentItem[]> => api.get(`${GATEWAY}/comment/list`, params),
  /**
   * 公共-用户的部门树
   */
  getDeptTree: (parentId?: string): ResponsePromise<IDeptTreeItem[]> =>
    api.get(`${GATEWAY}/common/deptTree`, { parentId }),
  /**
   * 公共-用户的虚拟部门树
   */
  getVirDeptTree: (parentId?: string | number): ResponsePromise<IDeptTreeItem[]> =>
    api.get(`${GATEWAY}/common/virDeptTree`, { parentId }),
  // nos 获取 token
  getNosToken: (data: { nosKey: string; bucketName: string }): ResponsePromise<string> =>
    api.get('/api/file/nos/token', data),
  // nos 获取 url
  getNosUrl: (data: {
    nosKey: string;
    bucketName: string;
    fileName: string;
    fileNameEncode: string;
  }): ResponsePromise<string> => api.get('/api/file/nos/url', data),
};

export * from './interface';
