//@ts-nocheck
import React, { Component } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/zh_CN';

const { RangePicker } = DatePicker;

const year = dayjs().get('year');
const DEFAULT_FORMAT = 'YYYY-M-D';

function getRangeTime(start, end, current = year) {
  return [dayjs(`${current}-${start}`, DEFAULT_FORMAT), dayjs(`${current}-${end}`, DEFAULT_FORMAT)];
}
const dateRanges = {
  今年: () => getRangeTime('1-1', '12-31'),
  上半年: () => getRangeTime('1-1', '6-30'),
  下半年: () => getRangeTime('7-1', '12-31'),
  Q1: () => getRangeTime('1-1', '3-31'),
  Q2: () => getRangeTime('4-1', '6-30'),
  Q3: () => getRangeTime('7-1', '9-30'),
  Q4: () => getRangeTime('10-1', '12-31'),
};

class RangePickerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      ranges: props.ranges || dateRanges,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.value) {
      this.setState({
        value: nextProps.value,
      });
    }
  }

  // 更新/清除value并传递
  onChange = (date, dateString) => {
    this.setState({
      value: date,
    });
    this.props.onChange && this.props.onChange(dateString);
  };

  // 清除value
  clear = () => {
    this.setState({
      value: [],
    });
  };

  render() {
    const { ranges, value } = this.state;
    const {
      width,
      size,
      suffixIcon,
      className,
      allowClear,
      style,
      wrapStyle,
      disabled,
      open,
      getPopupContainer,
      renderExtraFooter,
    } = this.props;
    return (
      <span style={{ ...wrapStyle, width, display: 'inline-block' }}>
        <RangePicker
          disabled={disabled}
          size={size}
          // true， false 和不传   是三种不同的形式
          {...(open ? { open } : {})}
          suffixIcon={suffixIcon}
          className={className}
          allowClear={!!allowClear}
          style={style}
          placeholder={['开始日期', '结束日期']}
          ranges={ranges}
          format="YYYY-MM-DD"
          value={value}
          getPopupContainer={getPopupContainer}
          renderExtraFooter={renderExtraFooter}
          onChange={this.onChange}
          locale={locale}
        />
      </span>
    );
  }
}

export default RangePickerComponent;
