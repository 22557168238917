import React from 'react';
import { Table, Typography } from 'antd';
import type { TableProps, TableColumnsType } from 'antd';

export default function Table3(props: TableProps<any>) {
  const columns: TableColumnsType<any> = [
    {
      title: '目标ID',
      width: 100,
      fixed: 'left',
      dataIndex: 'objectId',
    },
    {
      title: '目标(O)名称',
      dataIndex: 'krObjectName',
      width: 300,
      render: (text, record) => (
        <Typography.Text style={{ width: 266 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '关键成果ID',
      width: 100,
      dataIndex: 'krId',
    },
    {
      title: '关键成果（KR）名称',
      dataIndex: 'krName',
      width: 300,
      render: (text) => (
        <Typography.Text style={{ width: 280 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '目标(O)周期',
      dataIndex: 'krObjectCycle',
      width: 150,
      render: (text) => (
        <Typography.Text style={{ width: 150 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '目标(O)负责人工号',
      width: 150,
      dataIndex: 'objectResponsibleEmpIds',
      render: (text) => (
        <Typography.Text style={{ width: 150 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '目标(O)负责人姓名',
      width: 150,
      dataIndex: 'krObjectResponsible',
      render: (text) => (
        <Typography.Text style={{ width: 150 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '目标(O)类型',
      width: 150,
      dataIndex: 'krObjectType',
      render: (text) => (
        <Typography.Text style={{ width: 150 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '所属团队(仅组织OKR)',
      dataIndex: 'krObjectDept',
      width: 200,
      render: (text) => (
        <Typography.Text style={{ width: 200 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '关键成果(KR)负责人工号',
      width: 200,
      dataIndex: 'responsibleEmpIds',
      render: (text) => (
        <Typography.Text style={{ width: 200 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '关键成果(KR)负责人姓名',
      width: 200,
      dataIndex: 'krResponsible',
      render: (text) => (
        <Typography.Text style={{ width: 200 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '关键成果(KR)最新状态',
      width: 150,
      dataIndex: 'krNewestStatus',
      render: (text) => (
        <Typography.Text style={{ width: 150 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: '关键成果(KR)最新评分',
      width: 150,
      dataIndex: 'krNewestScore',
      render: (text) => (
        <Typography.Text style={{ width: 150 }} ellipsis={{ tooltip: true }}>
          {text || '--'}
        </Typography.Text>
      ),
    },
    {
      title: (
        <span>
          目标（O）负责人
          <br />
          员工类型
        </span>
      ),
      dataIndex: 'objectResponsibleEmpType',
      width: 150,
    },
    {
      title: (
        <span>
          目标（O）负责人
          <br />
          在职状态
        </span>
      ),
      dataIndex: 'objectResponsibleState',
      width: 150,
    },
    {
      title: (
        <span>
          目标（O）负责人
          <br />
          入职时间
        </span>
      ),
      dataIndex: 'objectResponsibleEntryDate',
      width: 150,
    },
  ];
  return <Table {...props} rowKey="krId" size="small" columns={columns} scroll={{ x: 2600 }} />;
}
