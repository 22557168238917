import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useModel } from 'umi';
import { App } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { useInterval } from 'ahooks';
import classnames from 'classnames';
import type { EventEmitter } from 'ahooks/lib/useEventEmitter';
import processApi, {
  IAppendix,
  IProcessVO,
  ProcessSourceOperateTypeEnum,
  ProcessSourceTypeEnum,
} from '@/api/process';
import { CommentTypeEnum } from '@/api/common';
import { useStore } from '@/store';
import { OkrTypeEnum } from '@/api/okr';
import { RequestCodeMap } from '@/api';
import { ProcessOtherKey, ProcessPublishStatusEnum } from '@/constants';
import { Comment } from '@/components';
import { CacheEditingInfo, Like, ModifyHistory, RichTextEditor } from '..';
import './index.less';

const LOCK_INTERVAL = 10 * 1000;

interface IProps {
  readonly?: boolean;
  releaseAll$?: EventEmitter<any>;
  processOther: IProcessVO;
  deptId?: string;
  auth: boolean;
  getProcessVO: () => void;
  cacheEditingProcess: any;
  modifyProcessListMap: (partialProcessListMap: { [key in string]: IProcessVO }) => void;
}

function WeeklyOtherContent(props: IProps) {
  const {
    // 一键发布事件
    releaseAll$,
    processOther, // ”其他“模块的信息集
    deptId,
    readonly: defaultReadonly,
    auth,
    // 缓存正在编辑的进展
    cacheEditingProcess,
    getProcessVO,
    modifyProcessListMap,
  } = props;

  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const {
    currentProcessType,
    currentProcessUserId = 0,
    currentProcessDateId,
    setStoreValues,
    processDetail,
  } = useStore();

  // 详情
  const {
    id,
    status,
    commentCount,
    thumbUpCount,
    thumbUpEd,
    appendixList,
    content: defaultContent,
    updateTime,
  } = processOther || {};

  const { message, modal } = App.useApp();

  const readonly = useMemo(() => {
    if (defaultReadonly) return defaultReadonly;
    return !auth;
  }, [defaultReadonly, auth]);

  const [content, setContent] = useState(defaultContent);

  useEffect(() => {
    setContent(defaultContent);
  }, [defaultContent]);

  const [isActive, setIsActive] = useState(false);

  // 锁定
  const [lockInterval, setLockInterval] = useState<number | undefined>(undefined);

  const editorRef = useRef();

  const onChangeActive = (active: boolean) => {
    if (!active) {
      setIsActive(active);
      setLockInterval(undefined);
      return;
    }
    if (readonly) return;
    processApi
      .lockProcess({
        okrType: 'PROCESS_OTHER',
        processSourceTypeEnum: ProcessSourceTypeEnum.PROCESS_WEEK_REPORT,
        dateId: currentProcessDateId,
        userId: currentProcessUserId,
      })
      .then(() => {
        setIsActive(active);
      })
      .catch((e: any) => {
        if (+e?.code === RequestCodeMap.locked) {
          setLockInterval(undefined);
          modal.error({
            title: null,
            content: `【${e?.data}】正在编辑此内容，暂不支持多人同时编辑，可在【${e?.data}】退出编辑后，您可刷新该页面再进行编辑。`,
            okText: '我知道了',
          });
        } else {
          message.error(e?.msg);
        }
      });
  };

  useEffect(() => {
    // 进入编辑态，需要定时锁定
    if (isActive) {
      CacheEditingInfo.getInstance().updateEditingInfo({ [ProcessOtherKey]: true });
      setLockInterval(LOCK_INTERVAL);
    } else {
      CacheEditingInfo.getInstance().updateEditingInfo({ [ProcessOtherKey]: false });
      if (cacheEditingProcess.current) {
        cacheEditingProcess.current[ProcessOtherKey] = undefined;
      }
      setLockInterval(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const onClickEdit = () => {
    onChangeActive(true);
  };

  const getPayload = async (params: any) => {
    let id = processOther?.id;
    if (!id) {
      id = await beforeSubmit();
    }
    return {
      id,
      processSourceTypeEnum: ProcessSourceTypeEnum.PROCESS_WEEK_REPORT,
      processSourceOperateTypeEnum: ProcessSourceOperateTypeEnum.PROCESS_OTHER,
      ...params,
    };
  };

  const onHandleSaveDraft = async (params: any, shouldCallback?: boolean) => {
    const payload = await getPayload(params);
    console.log('payload', payload, params);
    processApi
      .temporaryStorageProcess(payload)
      .then((data) => {
        if (shouldCallback) {
          modifyProcessListMap?.({ [ProcessOtherKey]: { ...processOther, ...(data || {}) } });
        }
        setStoreValues({
          processDetail: {
            ...processDetail,
            entity: {
              ...processDetail.entity,
              status:
                processDetail.entity.status === ProcessPublishStatusEnum.published
                  ? ProcessPublishStatusEnum.published
                  : ProcessPublishStatusEnum.draft,
            },
          },
        });
      })
      .catch((e: any) => {
        message.warning(e?.msg);
      });
  };

  const onHandleSave = async (params: any) => {
    const payload = await getPayload(params);
    processApi
      .releaseProcess(payload)
      .then((data) => {
        modifyProcessListMap?.({
          // 草稿状态需要修改成发布状态
          [ProcessOtherKey]: {
            ...processOther,
            ...data,
            status: ProcessPublishStatusEnum.published,
          },
        });
        setStoreValues({
          processDetail: {
            ...processDetail,
            entity: {
              ...processDetail.entity,
              status: ProcessPublishStatusEnum.published,
            },
          },
        });
      })
      .catch((e: any) => {
        message.warning(e?.msg);
      });
  };

  useInterval(async () => {
    if (status !== ProcessPublishStatusEnum.published) {
      //@ts-ignore
      await onHandleSaveDraft(editorRef.current?.getPayload());
    }
    processApi
      .lockProcess({
        okrType: 'PROCESS_OTHER',
        processSourceTypeEnum: ProcessSourceTypeEnum.PROCESS_WEEK_REPORT,
        dateId: currentProcessDateId,
        userId: currentProcessUserId,
      })
      .catch((e: any) => {
        setLockInterval(undefined);
        setIsActive(false);
        message.error(e?.msg || '不能编辑');
      });
  }, lockInterval);

  releaseAll$?.useSubscription(async (action) => {
    // if (!isActive) return;
    switch (action) {
      case 'onGet': {
        //@ts-ignore
        const contentPayload = editorRef.current?.getPayload();
        const payload = await getPayload(contentPayload);
        console.log('onGet payload', payload);
        cacheEditingProcess.current = {
          ...cacheEditingProcess.current,
          [ProcessOtherKey]: payload,
        };
        break;
      }
      case 'onSuccess': {
        setIsActive(false);
        break;
      }
      default:
        break;
    }
  });

  const beforeSubmit = async () => {
    const processId = await processApi.createProcessWhenEmpty({
      processSourceType: ProcessSourceTypeEnum.PROCESS_WEEK_REPORT,
      operateTarget: ProcessSourceOperateTypeEnum.PROCESS_OTHER,
      dateId: currentProcessDateId,
      targetId: currentProcessType === OkrTypeEnum.organization ? deptId : currentProcessUserId,
    });
    return processId;
  };

  return (
    <div className="weekly-other-content">
      <div className="weekly-other-content-title">其他</div>
      <div
        className={classnames('weekly-editor-content', {
          active: thumbUpCount > 0 || commentCount > 0,
        })}
      >
        <RichTextEditor
          updateTime={updateTime}
          ref={editorRef}
          isActive={isActive}
          status={status}
          readonly={readonly}
          onChangeActive={onChangeActive}
          onHandleSaveDraft={onHandleSaveDraft}
          onHandleSave={onHandleSave}
          content={content}
          files={appendixList}
        />
        {!readonly && status === ProcessPublishStatusEnum.draft && (
          <div className="okr-tip-draft">草稿</div>
        )}
        <div className={`weekly-editor-operate  ${isActive ? 'hide' : ''}`}>
          <div className="operate-left">
            <Like
              isLiked={thumbUpEd}
              count={thumbUpCount}
              belongUserId={currentProcessUserId}
              operateTarget={ProcessSourceOperateTypeEnum.PROCESS_OTHER}
              id={id}
              beforeSubmit={beforeSubmit}
            />
            <Comment
              id={id}
              dateId={currentProcessDateId}
              count={commentCount}
              userInfo={userInfo!}
              targetType={CommentTypeEnum.PROCESS_OTHER}
              processType={ProcessSourceTypeEnum.PROCESS_WEEK_REPORT}
              getProcessVO={getProcessVO}
              beforeSubmit={beforeSubmit}
            />
          </div>
          <div className="operate-right">
            {!readonly && !!content && (
              <span className="edit-btn" onClick={onClickEdit}>
                编辑
              </span>
            )}
            {status === ProcessPublishStatusEnum.published && (
              <ModifyHistory id={id} type="PROCESS_OTHER" />
            )}
          </div>
        </div>
        {!isActive && !!appendixList?.length && (
          <div className="weekly-appendixList-list">
            {appendixList.map((item: IAppendix) => (
              <div key={item.nosKey} className="appendix-item">
                <PaperClipOutlined /> <a href={item.url}>{item.name}</a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default WeeklyOtherContent;
