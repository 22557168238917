import React, { memo } from 'react';
import { ProcessSourceTypeEnum } from '@/api/process';
import './pd-layout-header.less';

function PdLayoutHeader(props: { processSource: ProcessSourceTypeEnum }) {
  const { processSource } = props;
  return (
    <div className="pd-layout_header">
      <div className="pd-layout_header-okr">OKRS</div>
      <div className="pd-layout_header-process">
        {processSource !== ProcessSourceTypeEnum.PROCESS_WEEK_REPORT && (
          <div className="pd-layout_header-process-score">目标打分</div>
        )}
        <div className="pd-layout_header-process-content">进展</div>
      </div>
    </div>
  );
}

export default memo(PdLayoutHeader);
