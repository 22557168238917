import React, { memo } from 'react';
import { useModel } from 'umi';
import { useSafeState } from 'ahooks';
import { DeptTreePanel } from '@/components';
import { OrganizationProcessList } from '@/pages/process/list/components';
import useDefaultQuery from '@/pages/process/list/hooks';
import { DeptTypeEnum, IOkrDeptItem } from '@/api/okr';

const OrganizationList = () => {
  const {
    deptId,
    year: queryYear,
    periodId: defaultPeriodId,
    type: defaultProcessSource,
  } = useDefaultQuery();

  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  // OKR 年份，周期年份
  const [year, setYear] = useSafeState(queryYear ? +queryYear : new Date().getFullYear());
  // 选中部门
  const [selectedDeptId, setSelectedDeptId] = useSafeState(deptId || userInfo?.defaultDeptId);

  const onDeptChange = (type: DeptTypeEnum, data: IOkrDeptItem) => {
    if (!data) {
      setSelectedDeptId(userInfo?.defaultDeptId);
      return;
    }
    setSelectedDeptId(data?.deptId);
  };

  return (
    <div className="process-list_content">
      <div className="process-list_sider">
        <DeptTreePanel
          hasVirDept={false}
          defaultDept={deptId ? { id: deptId } : undefined}
          year={year}
          onChange={onDeptChange}
        />
      </div>
      <div className="process-list_process">
        <OrganizationProcessList
          defaultProcessSource={defaultProcessSource}
          defaultPeriodId={defaultPeriodId}
          deptId={selectedDeptId}
          year={year}
          onChangeYear={setYear}
        />
      </div>
    </div>
  );
};

export default memo(OrganizationList);
