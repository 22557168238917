import { IUserInfo, IAuthDeptVO } from '@/api/auth';
import { O_KR_ENUM } from '@/constants';
import { PageRequestParams } from '@/api/interface';

export enum PeriodTypeEnum {
  okr = 'OKR',
  summary = 'SUMMARY',
}

// 对齐类型
export enum RelTypeEnum {
  U = 'U', // 我的上级
  M = 'M', // 我的同级
  W = 'W', // 我的下属
}

// 进展类型：1-进展，2-状态，3-评分
export enum KrProcessTypeEnum {
  process = 1,
  status = 2,
  score = 3,
}

export enum ProcessAddTypeEnum {
  PROCESS = 'PROCESS', // 添加了关键成果进展
  STATUS = 'STATUS', // 添加了关键成果状态
  SCORE = 'SCORE', // 添加了关键成果评分
  OBJECTIVES_PROCESS = 'OBJECTIVES_PROCESS', // 添加了个人目标进展
  OBJECTIVES_STATUS = 'OBJECTIVES_STATUS', // 添加了个人目标状态
  OBJECTIVES_SCORE = 'OBJECTIVES_SCORE', // 添加了个人目标总结评分
  OBJECTIVES_GROUP_PROCESS = 'OBJECTIVES_GROUP_PROCESS', // 添加了组织目标进展
  OBJECTIVES_GROUP_STATUS = 'OBJECTIVES_GROUP_STATUS', // 添加了组织目标状态
  OBJECTIVES_GROUP_SCORE = 'OBJECTIVES_GROUP_SCORE', // 添加了组织目标总结评分
}

export enum KrProcessStatusEnum {
  smooth = 101,
  delay = 102,
  hindered = 103,
}

// O添加进展状态
export enum OkrProcessTypeEnum {
  PROCESS = 1, // KR进展更新
  STATUS = 2, // KR状态更新
  SCORE = 3, // KR评分更新
  OBJECTIVES_PROCESS = 4, // 个人O的进展更新
  OBJECTIVES_STATUS = 5, // 个人O的状态更新
  OBJECTIVES_SCORE = 6, // 个人O的评分更新
  OBJECTIVES_GROUP_PROCESS = 7, // 组织O的进展更新
  OBJECTIVES_GROUP_STATUS = 8, // 组织O的状态更新
  OBJECTIVES_GROUP_SCORE = 9, // 组织O的评分更新
}

// okr周期1=月度  2=季度  3=半年度 4=年度 5-自定义周期 7 双月
export enum OkrPeriodEnum {
  month = 1,
  quarter = 2,
  halfYear = 3,
  year = 4,
  custom = 5,
  dMonth = 7, // 双月
}

export enum OkrTypeEnum {
  personal = 1, // 1.个人okr，
  organization = 2, // 2.组织okr
}

export enum OkrTypeCodeEnum {
  OKR = 'OKR',
  GROUP_OKR = 'GROUP_OKR',
}

// 部门类型，包含实体部门与虚拟组织
export enum DeptTypeEnum {
  REAL_DEPT = 'REAL_DEPT', // 实体部门
  VIR_DEPT = 'VIR_DEPT', // 虚拟部门
}

// 共享类型
export enum ShareQueryTypeEnum {
  COLLEAGUE = 'COLLEAGUE', // 我的同级
  LEADER = 'LEADER', // 我的上级
  SUBORDINATE = 'SUBORDINATE', // 我的下属
  REAL_DEPT = 'REAL_DEPT', // 实体部门
  VIR_DEPT = 'VIR_DEPT', // 虚拟部门
  OTHER = 'OTHER', // 其他共享
}

// OKR 基本类型
export interface IOkrItem {
  id: number;
  deptId: string;
  objectivesName: string; // O的内容
  sorts: number;
  principalVO: IUserInfo; // O的负责人
  status: boolean; // 是否发布
  processStatusVO: IOkrProcessStatusVO; // O的进展状态
  objectivesType: OkrTypeEnum; //  1- 个人OKR 2-组织OKR
  objectivesBelongsVOList: IBelongTypeVO[]; // O所属部门、虚拟组织、人员
  authMap: IOkrAuthMap; // O的权限
  krList: IKrItem[]; // KR列表
  relResultList: IRelResultVO[]; // 对齐数据
  thumbUpCount: IThumbUp; // 点赞信息
  messageCount: number; // 评论数量
  authShareVO: IAuthShare;
}

// OKR抽屉详情
export interface IOkrDetail
  extends Pick<
    IOkrItem,
    | 'id'
    | 'objectivesName'
    | 'authMap'
    | 'principalVO'
    | 'objectivesBelongsVOList'
    | 'processStatusVO'
    | 'status'
    | 'objectivesType'
  > {
  dateId: number;
  year: number; // O的年份
  authShareVO: IAuthShare; // 共享范围对象
  titleName: string; // OKR周期标题
  dateType: OkrPeriodEnum; // 周期类型
}

export interface IOkrDeptTreeItem {
  name: string;
  id: string;
  hasChildren: boolean;
  deptLeaderUNo: string;
  childList?: IOkrDeptTreeItem[];
}

// 获取个人OKR周期 入参
export interface IQueryPeriod {
  userId?: number;
  deptId?: string;
  periodType?: PeriodTypeEnum;
  year: number;
  needCustomize?: boolean; // 是否需要自定义周期
}

// 获取个人OKR周期 返回值
export interface IPeriodItem {
  id: number;
  year: string;
  dateClass: string;
  display: number;
  type: OkrPeriodEnum;
  typeName: string;
  startDate: string;
  endDate: string;
  startStr: string;
  endStr: string;
  period: string; // 使用此字段做前端展示
  deptFlag: boolean; // boolean：属于部门，false：不属于部门
}

// okr 统计数量
export interface IOkrStatisticsItem {
  dateId: number;
  count: number;
  year: number;
}

// 添加OKR (草稿态) 入参
export interface IOkrAddDraftPayload {
  dateId: number; // 周期id
  objectivesType: OkrTypeEnum; // 1.个人okr，2.组织okr
}

// 个人OKR列表入参
export interface IQueryPersonalOkrList {
  userId?: number;
  dateId?: number; // 周期id
  customize?: boolean; // 是否查自定义周期，okr列表传true，其他地方（进展）传false
  year?: number;
}

// OKR列表返回值
export interface IOkrListResponse {
  name: string;
  list: IOkrItem[];
}

// 共享范围对象
export interface IAuthShare {
  authDeptList: IAuthDeptVO[];
  authVirDeptList: IAuthDeptVO[];
  authUserList: IUserInfo[];
}

export interface IOkrAuthMap {
  addSummary: boolean;
  delete: boolean;
  updateAuth: boolean;
  updateDate: boolean;
  updateDsc: boolean;
  updateGroupOkr: boolean;
  updateKr: boolean;
  updateMile: boolean;
  updateName: boolean;
  updatePlanSpeed: boolean;
  updatePrincipal: boolean;
  updateProcess: boolean;
  updateRel: boolean;
  updateUser: boolean;
}

export interface IKrItem {
  id: number;
  objectivesId: number;
  keyResultName: string;
  sorts: number;
  userList: IUserInfo[];
  krProcessStatusVO: IOkrProcessStatusVO;
  summaryStatus?: string;
  summaryScore?: string;
}

export interface IKrDetail {
  authMap: IOkrAuthMap;
  krDetail: IKrItem;
  year: number;
  dateId: number;
  dateType: OkrPeriodEnum;
}

export interface IThumbUp {
  isThumbUp: boolean;
  thumbUpCount: number;
}

export interface IRelResultItem {
  id: number;
  relType: RelTypeEnum;
  titleName: string; // 对齐周期标题
  objectivesId: number; // O的id
  objectivesName: string; // O的内容
  objectivesType: OkrTypeEnum;
  dateType: string;
  dateId: number;
  year: number;
  userId: number; // O的负责人
  userName: string;
  jobNumber: string;
  photoUrl: string; // O的负责人头像
  krRelVO: {
    id: number;
    krId: number;
    krType: string;
    krName: string;
  }[];
  createTime: string;
}

// O对齐关系
export interface IRelResultVO {
  relType: RelTypeEnum; // 对齐类型
  userList: {
    belongName: string;
    photoUrl: string;
    relResult: IRelResultItem[];
  }[];
}

// 添加O的对齐关系
export interface IAddOkrRelPayload {
  id: number; // O的id
  relList: {
    id: number;
    type: O_KR_ENUM;
    relType: RelTypeEnum;
  }[];
}

export interface IOkrProcessStatusVO {
  processStatus: number;
  processScore: string;
  processCount: number;
}

export interface IBelongTypeVO {
  objectivesId: number;
  belongId: number; // 部门编码/虚拟组织Id/人员id
  belongName: string; // 部门名称/虚拟组织名称/人员名称
  belongType: 1 | 2 | 3; // 1-真实部门 2-人员 3-虚拟部门
}

// 创建 / 更新 KR 信息
export interface IUpdateKrPayload {
  id?: number;
  objectivesId?: number;
  principalIdList?: number[];
  keyResultName: string;
}

export interface IAddProcessPayload {
  content: string;
  status?: number; // 进展状态：101-顺利进行 102-有点延迟  103-很大障碍
  processAddType: ProcessAddTypeEnum;
}

// O进展-新增 入参
export interface IAddOProcessPayload extends IAddProcessPayload {
  objectivesId: number;
}

// KR进展-新增 入参
export interface IAddKrProcessPayload extends IAddProcessPayload {
  keyResultId: number;
  score?: number;
}

// 查询O进展
export interface IOProcessItem {
  belongUser: number; // OKR进展添加人id
  content: string; // 进展内容
  objectivesId: number; // O的id
  title: string;
  processType?: OkrProcessTypeEnum; // 周报不返回
  status: KrProcessStatusEnum; // 进展状态：101-顺利进行 102-有点延迟  103-很大障碍
  user: IUserInfo;
  modifyTime: string;
  score: number;
}

// KR进展-查询
export interface IKrProcessItem extends Omit<IOProcessItem, 'objectivesId'> {
  keyResultId: number;
}

// okr对齐地图
export interface IOkrAlignMapItem {
  childCount: number;
  id: number;
  objectivesName: string;
  photoUrl: string;
  userId: number;
  userName: string;
  summaryStatus: number;
  relType: RelTypeEnum;
}

// 对弃地图入参
export interface IOkrAlignMapParams {
  dateId: number;
  userId?: number;
  deptId?: string;
  virDeptId?: string;
}

// 部门
export interface IOkrDeptItem {
  buId: string;
  children?: IOkrDeptItem[];
  deptId: string;
  default: boolean;
  deptHrUNo: string;
  deptLeaderName: string;
  deptLeaderNickName: string;
  deptLeaderUNo: string;
  deptPath: string;
  direct: string;
  displayOrder: string;
  ehrDeptId: string;
  hasChildren: boolean;
  id: string;
  level: number;
  name: string;
  nameEn: string;
  okrCount: number;
  parentId?: string;
  status: number;
}

// 个人OKR 发布 - 入参
export interface IPublishOkrPayload {
  id: number;
  objectivesName: string;
  objectivesType: OkrTypeEnum;
  authShareList: {
    deptIdList: string[];
    virDeptIdList: string[];
    userIdList: number[];
  };
}

// OKR周期更新 - 入参
export interface IUpdateOkrDatePayload {
  id: number; // OKR的主键
  dateId: number; // 周期id
}

// 修改 OKR 所属团队 - 入参
export interface IUpdateOkrBelongPayload {
  id: number; // OKR的主键
  objectivesType: OkrTypeCodeEnum; // OKR/GROUP_OKR
  deptIdList?: string[]; // 部门id
  virDeptIdList?: string[]; // 虚拟部门id
  userIdList?: number[]; // 人员id
}

// 获取对齐员工的OKR列表
export interface IQueryAlignOkrItem {
  id: number;
  name: string;
  type: O_KR_ENUM;
  krList: IQueryAlignOkrItem[];
}

// okr操作记录
export interface IOkrRecordItem {
  operator: IUserInfo;
  operateTime: string;
  detail: string;
}

// 共享中心-查询人员列表 查询入参
export interface IQueryShareUserListParams extends PageRequestParams {
  empTypeList?: string;
  desc?: string;
  deptId?: string;
  type: ShareQueryTypeEnum;
}

// 对齐地图-搜索返回集合
export interface IOkrAlignSearchCompose {
  userList: {
    id: number;
    code: string;
    name: string;
    deptPath: string;
  }[];
  deptList: {
    id: string;
    name: string;
    deptPath: string;
  }[];
  virDeptList: {
    id: string;
    name: string;
    deptPath: string;
  }[];
}
