import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModel } from 'umi';
import { useInterval } from 'ahooks';
import type { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { App } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import { useStore } from '@/store';
import { RequestCodeMap } from '@/api';
import { CommentTypeEnum } from '@/api/common';
import processApi, {
  IProcessVO,
  ProcessSourceOperateTypeEnum,
  ProcessSourceTypeEnum,
} from '@/api/process';
import { OkrTypeEnum } from '@/api/okr';
import { O_KR_ENUM, ProcessPublishStatusEnum } from '@/constants';
import { generateUUID } from '@/utils/tools';
import { Comment } from '@/components';
import { dataTrack } from '@/utils/track';
import { CacheEditingInfo, Like, OkrStatus, RichTextEditor } from '..';
import ModifyHistory from '../modify-history';
import { getUniqKey } from '../dashboard/utils';
import './index.less';

const LOCK_INTERVAL = 10 * 1000;

function checkNotEmpty(value: any) {
  return Array.isArray(value) ? !!value?.length : !['', undefined, null].includes(value);
}

const EVENT_ID_MAP = {
  [ProcessSourceTypeEnum.PROCESS_WEEK_REPORT]: 'click_process_edit_week_content',
};

interface IProps {
  cacheEditingProcess: any;
  releaseAll$: EventEmitter<any>;
  okrAuth: boolean;
  readonly?: boolean;
  typeSourceId: number;
  okrType: string;
  detail?: IProcessVO;
  modifyProcessListMap: (partialProcessListMap: any) => void;
  getProcessVO: () => void;
  updateOAverageScore?: (nextKrProcessInfo: { [key in string]: IProcessVO }) => void;
  orgTargetId?: number;
}

const ContentEditCard = (props: IProps) => {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { message, modal } = App.useApp();

  const {
    currentProcessDateId,
    currentProcessUserId,
    currentProcessSourceType,
    currentProcessType,
    setStoreValues,
    processDetail,
  } = useStore();

  const {
    // 缓存正在编辑的进展
    cacheEditingProcess,
    // 一键发布事件
    releaseAll$,
    okrAuth,
    readonly: defaultReadonly,
    typeSourceId,
    okrType,
    detail,
    modifyProcessListMap,
    getProcessVO,
    updateOAverageScore,
    orgTargetId,
  } = props;

  const {
    id,
    status,
    commentCount,
    evaluationStatus,
    evaluationScore,
    content,
    updateTime,
    appendixList,
    thumbUpVO,
  } = detail || {};

  const [isActive, setIsActive] = useState(false);
  const [lightStatus, setLightStatus] = useState(evaluationStatus);
  const [summaryScore, setSummaryScore] = useState(evaluationScore);
  const [displayUpdateTime, setDisplayUpdateTime] = useState(updateTime);

  const editorRef = useRef();
  const editorContainerRef = useRef<HTMLDivElement>(null);

  const readonly = useMemo(() => {
    if (defaultReadonly) return true;
    if (detail?.id) return !detail?.auth;
    return !okrAuth;
  }, [defaultReadonly, detail, okrAuth]);

  useEffect(() => {
    setDisplayUpdateTime(updateTime);
  }, [updateTime]);

  useEffect(() => {
    setLightStatus(evaluationStatus);
  }, [evaluationStatus]);

  useEffect(() => {
    setSummaryScore(evaluationScore);
  }, [evaluationScore]);

  // 锁定
  const [lockInterval, setLockInterval] = useState<number | undefined>(undefined);

  const handleSelect = useCallback((statusOrScore: any) => {
    const fieldName = Object.keys(statusOrScore)[0];
    if (fieldName === 'evaluationScore') {
      const score = statusOrScore.evaluationScore;
      setSummaryScore(score);
    } else {
      const light = statusOrScore.evaluationStatus;
      setLightStatus(light);
    }
    setIsActive(true);
  }, []);

  const onChangeActive = (active: boolean) => {
    if (!active) {
      setIsActive(active);
      setLockInterval(undefined);
      return;
    }
    if (readonly) return;
    processApi
      .lockProcess({
        okrType,
        typeSourceId,
        processSourceTypeEnum: currentProcessSourceType,
        dateId: currentProcessDateId,
      })
      .then(() => {
        setIsActive(active);
      })
      .catch((e) => {
        if (+e?.code === RequestCodeMap.locked) {
          setLockInterval(undefined);
          modal.error({
            title: `【${e?.data}】正在编辑此内容，暂不支持多人同时编辑，可在【${e?.data}】退出编辑后，您可刷新该页面再进行编辑。`,
            okText: '我知道了',
          });
        } else {
          message.error(e?.msg);
        }
      });
  };

  const onClickEdit = () => {
    onChangeActive(true);

    setTimeout(() => {
      const scrollContainer = document.getElementById('main');
      const containerRect = scrollContainer?.getBoundingClientRect();
      const divRect = editorContainerRef.current?.getBoundingClientRect();
      const scrollOffset = scrollContainer?.scrollTop;

      // @ts-ignore
      const topOffSet = divRect?.top - containerRect?.top + scrollOffset;

      scrollContainer?.scrollTo({ top: topOffSet - 68, behavior: 'smooth' });
    }, 100);

    // @ts-ignore
    const eventId = EVENT_ID_MAP[currentProcessSourceType];
    if (!eventId) return;
    dataTrack(eventId);
  };

  /**
   * 获取保存参数
   * @param params
   * @param {string[]} [params.userIds] @用户
   * @param {string} [params.content]
   */
  const getPayload = async (params: any) => {
    let processId = id;
    if (!processId) {
      processId = await beforeSubmit();
    }
    return {
      id: processId,
      processSourceTypeEnum: currentProcessSourceType,
      processSourceOperateTypeEnum: ProcessSourceOperateTypeEnum.PROCESS,
      evaluationScore: summaryScore,
      evaluationStatus: lightStatus,
      ...params,
    };
  };

  const onHandleSaveDraft = async (params: any, shouldCallback: boolean) => {
    const payload = await getPayload(params);
    const data = await processApi.temporaryStorageProcess({
      appendixList,
      ...payload,
    });
    setStoreValues({
      processDetail: {
        ...processDetail,
        entity: {
          ...processDetail.entity,
          status:
            processDetail.entity.status === ProcessPublishStatusEnum.published
              ? ProcessPublishStatusEnum.published
              : ProcessPublishStatusEnum.draft,
        },
      },
    });
    setDisplayUpdateTime(data?.updateTime);
    const uniqKey = getUniqKey(okrType, typeSourceId);
    shouldCallback &&
      modifyProcessListMap?.({
        [uniqKey]: { ...data, auth: true },
      });
  };

  const onHandleSave = async (params: any) => {
    setLockInterval(undefined);
    const payload = await getPayload(params);
    processApi.releaseProcess(payload).then((data) => {
      const uniqKey = getUniqKey(okrType, typeSourceId);
      const nextProcessInfo = {
        [uniqKey]: { ...data, status: ProcessPublishStatusEnum.published, auth: true },
      };
      if (
        updateOAverageScore &&
        currentProcessSourceType === ProcessSourceTypeEnum.PROCESS_SUMMARY
      ) {
        // 如果是KR且进展类型是总结，需要计算平均分
        updateOAverageScore?.(nextProcessInfo);
        return;
      }
      modifyProcessListMap?.(nextProcessInfo);
      setStoreValues({
        processDetail: {
          ...processDetail,
          entity: {
            ...processDetail.entity,
            status: ProcessPublishStatusEnum.published,
          },
        },
      });
    });
  };

  useEffect(() => {
    // 进入编辑态，需要定时锁定
    const uniqKey = getUniqKey(okrType, typeSourceId);
    if (isActive) {
      setLockInterval(LOCK_INTERVAL);
      CacheEditingInfo.getInstance().updateEditingInfo({ [uniqKey]: true });
    } else {
      cacheEditingProcess.current[uniqKey] = undefined;
      CacheEditingInfo.getInstance().updateEditingInfo({ [uniqKey]: false });
      setLockInterval(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    setIsActive(false);
  }, [currentProcessSourceType, currentProcessDateId]);

  // 锁定逻辑，定时触发后端，
  useInterval(() => {
    status !== ProcessPublishStatusEnum.published &&
      //@ts-ignore
      onHandleSaveDraft(editorRef.current?.getPayload());
    processApi
      .lockProcess({
        okrType,
        typeSourceId,
        processSourceTypeEnum: currentProcessSourceType,
        dateId: currentProcessDateId,
      })
      .catch((e: any) => {
        setLockInterval(undefined);
        setIsActive(false);
        message.error(e?.msg || '不能编辑');
      });
  }, lockInterval);

  releaseAll$.useSubscription(async (action) => {
    // console.log('action', action);
    if (!isActive) return;
    switch (action) {
      case 'onGet': {
        //@ts-ignore
        const payload = await getPayload(editorRef.current?.getPayload());
        // console.log('payload', payload);
        cacheEditingProcess.current = {
          ...cacheEditingProcess.current,
          //@ts-ignore
          [getUniqKey(payload.processSourceOperateTypeEnum, payload.id)]: payload,
        };
        break;
      }
      case 'onSuccess': {
        setIsActive(false);
        break;
      }
      default:
        break;
    }
  });

  const domId = generateUUID();

  const beforeSubmit = async () => {
    const processId = await processApi.createProcessWhenEmpty({
      processSourceType: currentProcessSourceType!,
      operateTarget: ProcessSourceOperateTypeEnum.PROCESS,
      sourceId: typeSourceId,
      okrType,
      dateId: currentProcessDateId,
      targetId:
        currentProcessType === OkrTypeEnum.organization ? orgTargetId : currentProcessUserId,
    });
    return processId;
  };

  const { thumbUpCount, isThumbUp } = thumbUpVO || {};

  return (
    <div className={`content-edit-card ${isActive ? 'active' : ''}`} key={typeSourceId}>
      <div
        className={`okr-status ${
          currentProcessSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT
            ? 'week-status'
            : ''
        }`}
      >
        {currentProcessSourceType === ProcessSourceTypeEnum.PROCESS_WEEK_REPORT && (
          <div className="status-label">{okrType === O_KR_ENUM.O ? '目标' : 'KR'}进展</div>
        )}
        {[ProcessSourceTypeEnum.PROCESS_SUMMARY, ProcessSourceTypeEnum.PROCESS_CONCLUSION].includes(
          currentProcessSourceType!,
        ) && (
          <OkrStatus
            okrType={okrType}
            isActive={isActive}
            readonly={readonly}
            handleSelect={handleSelect}
            processSource={currentProcessSourceType!}
            evaluationStatus={evaluationStatus!}
            evaluationScore={evaluationScore!}
          />
        )}
      </div>
      <div className={`other-content ${thumbUpCount || commentCount ? 'active' : ''}`} id={domId}>
        <div className="okr-editor" ref={editorContainerRef}>
          <RichTextEditor
            ref={editorRef}
            isActive={isActive}
            readonly={readonly}
            onChangeActive={onChangeActive}
            onHandleSaveDraft={onHandleSaveDraft}
            onHandleSave={onHandleSave}
            content={content!}
            status={status!}
            updateTime={displayUpdateTime!}
            processType={currentProcessSourceType}
            statusEmpty={!checkNotEmpty(lightStatus) && !checkNotEmpty(summaryScore)}
            okrType={okrType}
            files={appendixList}
          />
        </div>
        <div className={`other-content_actions ${isActive ? 'hide' : ''}`}>
          <div className="flex items-center">
            <Like
              isLiked={isThumbUp}
              count={thumbUpCount!}
              belongUserId={currentProcessUserId!}
              operateTarget={ProcessSourceOperateTypeEnum.PROCESS}
              id={id}
              beforeSubmit={beforeSubmit}
            />
            <Comment
              targetType={CommentTypeEnum.PROCESS}
              count={commentCount || 0}
              id={id!}
              userInfo={userInfo!}
              typeSourceId={typeSourceId}
              dateId={currentProcessDateId}
              okrType={okrType}
              processType={currentProcessSourceType}
              getProcessVO={getProcessVO}
              domId={domId}
              beforeSubmit={beforeSubmit}
            />
          </div>
          <div>
            {!readonly && !!content && (
              <span className="edit-content-btn" onClick={onClickEdit}>
                编辑
              </span>
            )}
            {!readonly && status === ProcessPublishStatusEnum.published && (
              <ModifyHistory id={id} />
            )}
          </div>
        </div>
        {!isActive && !!appendixList?.length && (
          <div className="okr-file-list">
            {appendixList.map((item) => (
              <div key={item.nosKey} className="appendix-item">
                <PaperClipOutlined /> <a href={item.url}>{item.name}</a>
              </div>
            ))}
          </div>
        )}
      </div>
      {!readonly && status === ProcessPublishStatusEnum.draft && (
        <div className="okr-tip-draft">草稿</div>
      )}
    </div>
  );
};

export default ContentEditCard;
