import create from 'zustand';
import api, { IMessageItem } from '@/api/message';

interface IMessageStore {
  newMessageList: IMessageItem[];
  getNewMessageList: () => Promise<void>;
  newMessageCount: number;
}

const useMessageStore = create<IMessageStore>((set) => ({
  newMessageList: [],
  getNewMessageList: async () => {
    const data = await api.getNewMessage();
    set({ newMessageList: data?.dataList, newMessageCount: data?.page?.totalCount || 0 });
  },
  newMessageCount: 0,
}));

export default useMessageStore;
