import React, { memo } from 'react';
import { Empty, Spin, Popover } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import processApi from '@/api/process';
import { ReadonlyRichText } from '@/components';
import './index.less';

const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const Content = ({ id, type }: any) => {
  const { data, loading } = useRequest(
    () => processApi.getSingleProcessHistory({ processId: id, type }),
    {
      refreshDeps: [id, type],
    },
  );

  return (
    <Spin spinning={loading}>
      <div className="okr-process-modify-history">
        {!data || (data.length === 0 && <Empty />)}
        {data?.map((item) => {
          const { userName, id: dynamicId, dsc, updateTime } = item;
          return (
            <div key={dynamicId} className="okr-process-modify-history_item">
              {dayjs(updateTime).format(TIME_FORMAT)} {userName}
              <ReadonlyRichText content={dsc} updateTime={updateTime} />
            </div>
          );
        })}
      </div>
    </Spin>
  );
};

function ModifyHistory(props: any) {
  return (
    <Popover
      title="编辑记录"
      placement="left"
      content={<Content {...props} />}
      destroyTooltipOnHide
      trigger="click"
      // @ts-ignore
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <ClockCircleOutlined className="okr-editor-history" style={{ color: '#ccc' }} />
    </Popover>
  );
}

export default memo(ModifyHistory);
