import React, { memo } from 'react';
import { Typography } from 'antd';
import { Avatar } from '@/components';
import { IProcessOkrItem } from '@/api/process';
import './index.less';

interface IProps {
  data: IProcessOkrItem;
}

function OCard(props: IProps) {
  const { data } = props;

  const { dateName, objectivesName, photoUrl, principal, objectivesBelongsVOList } = data || {};

  const belongNameArr = objectivesBelongsVOList?.map((item) => item.belongName);

  let text = `${principal || '--'} | ${dateName || '--'}`;
  const belongText = belongNameArr?.length ? belongNameArr.join(' | ') : '--';
  if (belongNameArr?.length) {
    text = `${text} | ${belongText}`;
  }

  return (
    <div className="process-o-card">
      <div className="o-title-container">
        <div className="o-avatar">O</div>
        <div className="o-other">
          <h2 className="o-title">{objectivesName || '--'}</h2>
          <div className="o-info">
            <Avatar className="o-info-avatar" imgPath={photoUrl} userName={principal} />
            <Typography.Text ellipsis={{ tooltip: true }} className="o-info-text">
              {text}
            </Typography.Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(OCard);
