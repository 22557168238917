import React, { memo } from 'react';
import api from '@/api/auth';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import './index.less';

interface IProps {
  attentionUserId: number;
  value: boolean;
  onChange: (value: boolean) => void;
}

const Attention = ({ value, attentionUserId, onChange }: IProps) => {
  const onClickAttention = (status: boolean) => {
    const fetch = status ? api.delAttention : api.addAttention;
    fetch(attentionUserId).then(() => {
      onChange?.(!value);
    });
  };

  return (
    <div className="m-attention">
      {value ? (
        <StarFilled
          type="star"
          className="m-icon-attention"
          onClick={() => {
            onClickAttention(true);
          }}
        />
      ) : (
        <StarOutlined
          type="star"
          className="m-icon-not-attention"
          onClick={() => {
            onClickAttention(false);
          }}
        />
      )}
    </div>
  );
};

export default memo(Attention);
