import React, { memo } from 'react';
import { useModel } from 'umi';
import { useSafeState } from 'ahooks';
import { SideMemberSelect } from '@/components';
import { ProcessList } from '@/pages/process/list/components';
import useDefaultQuery from '@/pages/process/list/hooks';

const PersonalList = () => {
  const { userId, type, periodId, year: queryYear } = useDefaultQuery();

  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  // 选中用户
  const [selectedUserId, setSelectedUserId] = useSafeState(userId ? +userId : undefined);

  return (
    <div className="process-list_content">
      <div className="process-list_sider">
        <SideMemberSelect
          defaultUserId={userId}
          userInfo={userInfo!}
          // 区分埋点使用
          isProcess
          onSelect={(user) => setSelectedUserId(user?.id)}
        />
      </div>
      <div className="process-list_process">
        <ProcessList
          defaultProcessSource={type}
          selectedUserId={selectedUserId}
          defaultPeriodId={periodId}
        />
      </div>
    </div>
  );
};

export default memo(PersonalList);
