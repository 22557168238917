import React, { memo } from 'react';
import { Space, Select } from 'antd';
import styles from './index.less';

interface IProps {
  dataList: { label: string; value: any }[];
  label?: string;
  onChange?: (value: any) => void;
  style?: React.CSSProperties;
  defaultValue?: string;
}

const FilterSelect = (props: IProps) => {
  const { label, dataList, onChange, style, defaultValue } = props;

  return (
    <div style={style} className={styles.container}>
      {!!label && <span className={styles.label}>{label}：</span>}
      <Space size={[0, 8]} wrap>
        <Select
          bordered={false}
          options={dataList}
          popupMatchSelectWidth={false}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          onChange={onChange}
          defaultValue={defaultValue || ''}
        />
      </Space>
    </div>
  );
};

export default memo(FilterSelect);
