import React from 'react';
import { Popover } from 'antd';
import { useRequest } from 'ahooks';
import api from '@/api/okr';
import Info from '../okr-info';

function OKRPopover({ children, info = {}, mouseEnterDelay = 0.5, placement = 'topLeft' }: any) {
  const { id } = info;

  const { data, loading, run } = useRequest((id: number) => api.getKrList(id), {
    manual: true,
  });

  const onOpenChange = (visible: boolean) => {
    if (visible) {
      run(id);
    }
  };
  return (
    <Popover
      placement={placement}
      mouseEnterDelay={mouseEnterDelay}
      getPopupContainer={() => document.getElementById('share-center-okr-relative-wrap')!}
      content={<Info id={id} info={{ ...info, krList: data, loading }} />}
      onOpenChange={onOpenChange}
    >
      {children}
    </Popover>
  );
}

export default OKRPopover;
