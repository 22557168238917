import React, { useEffect, useState } from 'react';
import { Popconfirm } from 'antd';
import { DeleteFilled, MessageFilled } from '@ant-design/icons';
import { Link } from 'umi';
import api, { CommentTypeEnum, ICommentItem } from '@/api/common';
import { initUserLInk } from '@/utils/tools';
import { Avatar } from '@/components';
import CommentContent from '@/components/comment/comment-content';
import CommentInput from '../comment-input';
import './index.less';

interface IContentProps {
  data: ICommentItem;
  parentId?: number;
  isReply?: boolean;
  targetType: CommentTypeEnum;
  id: number;
  handleSuccess?: (comment?: ICommentItem) => void;
  handleDelete?: (count: number, id: number) => void;
  likeComponent?: React.ReactNode;
}

const CommentUserWithContent = (props: IContentProps) => {
  const { data, parentId, isReply, targetType, id, handleSuccess, handleDelete, likeComponent } =
    props;

  const {
    content,
    createUserName,
    createTime,
    deleteAble,
    children,
    createUserId,
    replyId,
    receiver,
    receiverId,
  } = data || {};

  const [showInput, setShowInput] = useState(false);

  const displayContent = `：${initUserLInk(content)}`;

  const toggleCommentInput = () => {
    setShowInput(!showInput);
  };

  const onDelete = async () => {
    try {
      await api.deleteComment(data?.id);
      // 刷新
      handleSuccess?.();
      handleDelete?.((children?.length || 0) + 1, data?.id);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCardCommentSuccess = (comment?: ICommentItem) => {
    setShowInput(false);
    handleSuccess?.(comment);
  };

  return (
    <div className="comment-unit">
      <div className="comment-card-header">
        <div className="comment-user">
          <Link
            to={{ pathname: `/team/${createUserId}`, search: '?tab=okr' }}
            onFocus={() => undefined}
          >
            <span className="comment-user-name">{createUserName}</span>
          </Link>
          {!!replyId && (
            <span>
              <span> 回复 </span>
              <Link
                to={{ pathname: `/team/${receiverId}`, search: '?tab=okr' }}
                onFocus={() => undefined}
              >
                <span className="comment-user-name">{receiver}</span>
              </Link>
            </span>
          )}
          <CommentContent className="comment-content-text">{displayContent}</CommentContent>
        </div>
      </div>
      <div className="comment-other">
        <span>{createTime}</span>
        <div className="comment-actions">
          {deleteAble && (
            <Popconfirm
              title="是否确认删除？"
              onConfirm={onDelete}
              overlayClassName="delete-popconfirm"
            >
              <DeleteFilled className="comment-header-icon delete-icon" />
            </Popconfirm>
          )}
          {likeComponent}
          <MessageFilled className="comment-header-icon" onClick={toggleCommentInput} />
          {!!children?.length && <span style={{ marginLeft: 2 }}>{children?.length}</span>}
        </div>
      </div>
      {showInput && (
        <CommentInput
          placeholder={`回复 ${createUserName}: `}
          autoFocus
          onCancel={() => setShowInput(false)}
          parentId={parentId}
          replyId={isReply ? data?.id : undefined}
          targetType={targetType}
          id={id}
          handleSuccess={handleCardCommentSuccess}
        />
      )}
    </div>
  );
};

interface IProps {
  data: ICommentItem;
  targetType: CommentTypeEnum;
  id: number;
  handleSuccess?: (data?: ICommentItem) => void;
  handleDelete?: (count: number, id: number) => void;
}

export default function CommentCard(props: IProps) {
  const { data, targetType, id, handleSuccess, handleDelete } = props;

  const { photoUrl, createUserId, children, createUserName } = data || {};

  const [showAllBtn, setShowAllBtn] = useState(children?.length > 2);
  const [displayReplyList, setDisplayReplyList] = useState(children?.slice(0, 2));
  const showReplyList = !!children?.length;

  const showALlList = () => {
    setDisplayReplyList(children);
    setShowAllBtn(false);
  };

  useEffect(() => {
    setDisplayReplyList(children?.slice(0, 2));
    setShowAllBtn(children?.length > 2);
  }, [children]);

  return (
    <div className="comment-card-container">
      <Link
        to={{ pathname: `/team/${createUserId}`, search: '?tab=okr' }}
        onFocus={() => undefined}
      >
        <Avatar imgPath={photoUrl} className="avatar-32" userName={createUserName} />
      </Link>
      <div className="comment-card-right">
        <CommentUserWithContent
          data={data}
          parentId={data?.id}
          id={id}
          targetType={targetType}
          handleSuccess={handleSuccess}
          handleDelete={handleDelete}
        />
        {showReplyList && (
          <div className="comment-reply-list">
            {displayReplyList?.map((item) => (
              <CommentUserWithContent
                key={item.id}
                data={item}
                parentId={data?.id}
                id={id}
                isReply
                targetType={targetType}
                handleSuccess={handleSuccess}
                handleDelete={handleDelete}
              />
            ))}
            {showAllBtn && (
              <div style={{ marginTop: 8 }}>
                <span className="comment-user-name" onClick={showALlList}>
                  查看全部沟通记录
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
