import { memo, useState, useRef } from 'react';
import { Button, App } from 'antd';
import api from '@/api/setting';

const downloadNosFile = (url: string) => {
  const a = document.createElement('a');
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

interface IProps {
  getTaskId: () => Promise<string>;
}

const ExportButton = (props: IProps) => {
  const { getTaskId } = props;

  const [status, setStatus] = useState<0 | 1>(0);
  const [percent, setPercent] = useState(0);

  const ref = useRef<any>(0);

  const { message } = App.useApp();

  const startTask = async () => {
    try {
      if (status > 0) return;
      /**
       * 获取任务接口，需要后端提供
       * params: 接口参数
       */
      setStatus(1);
      setPercent(percent + 1);
      const processKey = await getTaskId();
      getProgress(processKey);
      ref.current = setInterval(() => getProgress(processKey), 1000);
    } catch (e) {
      clearTimeout(ref.current);
      setPercent(0);
      setStatus(0);
    }
  };

  const getProgress = (processKey: string) => {
    api
      .getTaskProgress({ processKey })
      .then((data) => {
        if (percent < 99) {
          setPercent(percent + 1);
          setStatus(1);
        }

        // 当状态为已“完成”，才停止轮询，开始下载
        if (+data.status === 1) {
          clearTimeout(ref.current);
          downloadNosFile(data.nosUrl);
          message.success('导出成功');
          setPercent(0);
          setStatus(0);
        }
      })
      .catch(() => {
        clearTimeout(ref.current);
        setPercent(0);
        setStatus(0);
      });
  };

  return (
    <Button type="primary" disabled={!!status} onClick={startTask}>
      导出 {status ? `${percent}%` : ''}
    </Button>
  );
};

export default memo(ExportButton);
