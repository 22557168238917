import { useLayoutEffect, useRef, useState } from 'react';
import { useParams, history, useModel } from 'umi';
import { Button, Tabs } from 'antd';
import { useRequest, useScroll } from 'ahooks';
import api from '@/api/auth';
import commonApi from '@/api/common';
import { Avatar, AnimateUp } from '@/components';
import { ProcessSourceTypeEnum } from '@/api/process';
import { DiscoverList } from '@/pages/discover/components';
import { getParams } from '@/utils/tools';
import { DiscoverListTabEnum } from '@/pages/discover/components/discover-list';
import { ProcessList } from '@/pages/process/list/components';
import { PersonalOkr } from './components';
import styles from './index.less';

const usePersonalCenterStyle = () => {
  const threshold = 100;

  const addClass = () => {
    const headerNav = document.getElementById('okr-header');
    const main = document.getElementById('app-page');
    if (headerNav) {
      headerNav.classList.add('personal-center-header');
    }
    if (main) {
      main.classList.add('personal-center-main');
    }
  };

  const removeClass = () => {
    const headerNav = document.getElementById('okr-header');
    const main = document.getElementById('app-page');
    if (headerNav) {
      headerNav.classList.remove('personal-center-header');
    }
    if (main) {
      main.classList.remove('personal-center-main');
    }
  };

  useLayoutEffect(() => {
    addClass();
    return () => {
      removeClass();
    };
  }, []);

  const ref = useRef(null);
  const scroll = useScroll(ref);
  if ((scroll?.top || 0) >= threshold) {
    const headerNav = document.getElementById('okr-header');
    if (headerNav) {
      headerNav.classList.remove('personal-center-header');
    }
  } else {
    addClass();
  }
  return ref;
};

const getUserFormat = (userName?: string, userId?: string) =>
  [userName, userId].filter((item) => item).join(' | ');

const TAB_LIST = [
  {
    label: '动态',
    key: 'discover',
  },
  {
    label: 'OKR',
    key: 'okr',
  },
  {
    label: 'OKR 进展',
    key: 'process',
  },
];

const PersonCenter = () => {
  const { initialState } = useModel('@@initialState');
  const { userInfo } = initialState || {};

  const { id }: { id: string } = useParams();
  const { tab }: { tab?: string } = getParams();

  const [showAnimateUp, setShowAnimateUp] = useState(false);

  const ref = usePersonalCenterStyle();

  const { data } = useRequest(() => api.getUserInfo(+id), {
    ready: !!id,
    refreshDeps: [id],
  });

  const { data: likeInfo, refreshAsync: refreshLikeInfo } = useRequest(
    () => commonApi.getUserLikeInfo(+id),
    {
      ready: !!id,
      refreshDeps: [id],
    },
  );

  console.log('likeInfo', likeInfo);

  const followSuccess = async () => {
    await refreshLikeInfo();
    setShowAnimateUp(true);
    setTimeout(() => {
      setShowAnimateUp(false);
    }, 500);
  };

  const { run: cancelFollow } = useRequest(() => api.delAttention(+id), {
    manual: true,
    onSuccess: refreshLikeInfo,
  });
  const { run: follow } = useRequest(() => api.addAttention(+id), {
    manual: true,
    onSuccess: followSuccess,
  });

  const { fansCount, ifAttentioned, thumbUpCount } = likeInfo || {};
  const { photoUrl, name, uNo, deptPath } = data || {};

  const onChange = (key: string) => {
    history.replace(`/team/${id}?tab=${key}`);
  };

  const onClickWeek = () => {
    history.push(
      `/process/list?tab=user&processSourceType=${ProcessSourceTypeEnum.PROCESS_WEEK_REPORT}`,
    );
  };

  const onClickSummary = () => {
    history.push(
      `/process/list?tab=user&processSourceType=${ProcessSourceTypeEnum.PROCESS_SUMMARY}`,
    );
  };

  const renderFollowBtn = () => {
    if (ifAttentioned) {
      return (
        <Button className={styles.cancelFollowBtn} onClick={cancelFollow}>
          已关注
        </Button>
      );
    }
    return (
      <Button className={styles.followBtn} onClick={follow}>
        + 关注
      </Button>
    );
  };

  return (
    <div className={styles.page} ref={ref} id="personalPage">
      <header className={styles.header}>
        <Avatar imgPath={photoUrl} type="big" userName={name} />
        <div className={styles.headerInfo}>
          <div className={styles.infoLeft}>
            <div className={styles.userName}>{getUserFormat(name, uNo)}</div>
            <div className={styles.dept}>{deptPath}</div>
            <div className={styles.followInfo}>
              <span>
                粉丝 <AnimateUp show={showAnimateUp}>{fansCount || 0}</AnimateUp>
              </span>
              <span className={styles.separator}>|</span>
              <span>获得赞数 {thumbUpCount || 0}</span>
            </div>
          </div>
          <div className={styles.infoRight}>
            {userInfo?.id === +id ? (
              <>
                <Button className={styles.rightBtn} onClick={onClickSummary}>
                  我要写总结
                </Button>
                <Button type="primary" className={styles.rightBtn} onClick={onClickWeek}>
                  我要写周报
                </Button>
              </>
            ) : (
              renderFollowBtn()
            )}
          </div>
        </div>
        <Tabs activeKey={tab} onChange={onChange} items={TAB_LIST} animated />
      </header>
      <div className={styles.list}>
        {tab === 'discover' && (
          <div className={styles.tabContainer}>
            <DiscoverList
              tab={DiscoverListTabEnum.All}
              userId={+id}
              hideDeptFilter
              scrollId="personalPage"
            />
          </div>
        )}
        {tab === 'okr' && <PersonalOkr id={+id} />}
        {tab === 'process' && <ProcessList isPersonalCenter selectedUserId={+id} />}
      </div>
    </div>
  );
};

export default PersonCenter;
