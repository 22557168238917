import { Key, DependencyList, useEffect } from 'react';
import { DataNode, TreeProps } from 'antd/es/tree';
import { useSafeState } from 'ahooks';
import { ResponsePromise } from '@/api/interface';
import type { IDeptTreeItem } from '@/api/common';

const updateTreeData = (list: DataNode[], key: Key, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });

function deptListToNodes(deptList: IDeptTreeItem[]): DataNode[] {
  // @ts-ignore
  return deptList.map((item) => {
    const { deptId, deptName, hasChildren } = item;
    return {
      ...item,
      key: deptId,
      value: deptId,
      title: deptName,
      isLeaf: !hasChildren,
    };
  });
}

function getDefaultPayload(parentNode?: DataNode) {
  return { parentId: parentNode?.key };
}

function useTreeData<T>(
  fetchApi: (payload: any) => ResponsePromise<T>,
  options: {
    refreshDeps?: DependencyList;
    getPayload?: (parentNode?: DataNode) => any;
    listToNodes?: (list: T, parentNode?: DataNode) => DataNode[];
    onSuccess?: (list: T) => void;
  },
) {
  const {
    getPayload = getDefaultPayload,
    refreshDeps = [],
    listToNodes = deptListToNodes as any,
    onSuccess,
  } = options;
  const [treeData, setTreeData] = useSafeState<DataNode[]>();
  const [loading, setLoading] = useSafeState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    fetchApi(getPayload())
      .then((data) => {
        const newData = listToNodes(data);
        setTreeData(listToNodes(newData));
        onSuccess?.(newData);
      })
      .catch(() => {
        setTreeData([]);
      })
      .finally(() => setLoading(false));
  }, [...refreshDeps]);

  const onLoadData: TreeProps['loadData'] = (node: any) =>
    new Promise<void>((resolve, reject) => {
      const { key, children } = node;
      if (children && children.length > 0) {
        resolve();
        return;
      }
      fetchApi(getPayload(node))
        .then((data) => {
          const currentNodes = listToNodes(data, node);
          setTreeData((origin) => updateTreeData(origin || [], key, currentNodes));
          resolve();
        })
        .catch((e) => {
          console.warn('fetch tree data error: ', e);
          reject(e);
        });
    });

  return { loading, treeData, onLoadData };
}

export default useTreeData;
