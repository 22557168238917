import { memo } from 'react';
import { TABS } from '@/constants/discover';
import { IconFont } from '@/components';
import './index.less';

const MENU_ITEMS = [TABS.team, TABS.follow, TABS.current];

interface IProps {
  activeKey: string;
  isCollapse: boolean;
  handleToggle: () => void;
  onChangePane: (path: string) => void;
}

const LeftMenu = (props: IProps) => {
  const { activeKey, isCollapse, handleToggle, onChangePane } = props;

  return (
    <ul className="discover-left-menu" style={{ marginLeft: isCollapse ? '-208px' : '0' }}>
      {MENU_ITEMS.map((item) => (
        <li
          key={item.key}
          className={activeKey === item.key ? 'active' : ''}
          onClick={() => onChangePane(item.key)}
          style={{ backgroundColor: activeKey === item.key ? item.hoverColor : '' }}
        >
          <IconFont className="discover-menu-icon" type={item.icon} style={{ color: item.color }} />
          <span style={activeKey === item.key ? { color: item.color } : {}}>{item.label}</span>
        </li>
      ))}

      {isCollapse ? (
        <IconFont className="u-trigger" type="icon-shouqi" onClick={handleToggle} />
      ) : (
        <IconFont className="u-trigger" type="icon-moren" onClick={handleToggle} />
      )}
    </ul>
  );
};

export default memo(LeftMenu);
