import React, { useState } from 'react';
import { Tabs } from 'antd';
import { Manager, OutsourcingManage, DataExport } from './components';
import './index.less';

const ACTIVE_KEY_ENUM = {
  Manager: 'manager',
  OutsourcingManage: 'outsourcing',
  DataExport: 'dataExport',
};

const Operation = () => {
  const [activeKey, setActiveKey] = useState(ACTIVE_KEY_ENUM.Manager);

  const items = [
    {
      key: ACTIVE_KEY_ENUM.Manager,
      label: '管理员配置',
    },
    {
      key: ACTIVE_KEY_ENUM.OutsourcingManage,
      label: '外包人员配置',
    },
    {
      key: ACTIVE_KEY_ENUM.DataExport,
      label: '数据导出',
    },
  ];

  return (
    <div className="p-user-manage">
      <Tabs onChange={setActiveKey} items={items} />
      <div className="m-tab-content">
        {activeKey === ACTIVE_KEY_ENUM.Manager && <Manager />}
        {activeKey === ACTIVE_KEY_ENUM.OutsourcingManage && <OutsourcingManage />}
        {activeKey === ACTIVE_KEY_ENUM.DataExport && <DataExport />}
      </div>
    </div>
  );
};

export default Operation;
