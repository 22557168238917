import React, { useState, useEffect, memo } from 'react';
import { Popover, Tooltip } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { STATUS_OPTION, SCORE_OPTION, O_KR_ENUM } from '@/constants';
import { ProcessSourceTypeEnum } from '@/api/process';
import { dataTrack } from '@/utils/track';
import './index.less';

const O_KR_TEXT_MAP = {
  [O_KR_ENUM.KR]: 'KR',
  [O_KR_ENUM.O]: '目标',
};

const transformScore = (value: any) => {
  if (['', null, undefined].includes(value)) {
    return '';
  }
  return +value;
};

const SCORE_LIST = SCORE_OPTION.map((item) => ({ ...item, label: `${item.label}分` }));

interface IProps {
  okrType?: string;
  isActive?: boolean;
  readonly?: boolean;
  processSource: ProcessSourceTypeEnum;
  handleSelect: (values: any) => void;
  evaluationStatus: number;
  evaluationScore: number;
}

function OkrStatus(props: IProps) {
  const {
    okrType = O_KR_ENUM.O,
    isActive,
    readonly,
    handleSelect,
    processSource,
    evaluationStatus,
    evaluationScore,
  } = props;

  const oDisabled =
    okrType === O_KR_ENUM.O && processSource === ProcessSourceTypeEnum.PROCESS_SUMMARY;

  const [visible, setVisible] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    processSource === ProcessSourceTypeEnum.PROCESS_SUMMARY
      ? transformScore(evaluationScore)
      : +evaluationStatus,
  );

  const selectedItem = (
    processSource === ProcessSourceTypeEnum.PROCESS_CONCLUSION ? STATUS_OPTION : SCORE_LIST
  ).find((item) => item.key === selectedValue);

  useEffect(() => {
    const newVal =
      processSource === ProcessSourceTypeEnum.PROCESS_SUMMARY
        ? transformScore(evaluationScore)
        : +evaluationStatus;
    setSelectedValue(newVal);
  }, [evaluationStatus, evaluationScore, processSource]);

  const onSelectItem = (fieldName: string, value: any) => {
    setSelectedValue(value);
    setVisible(false);
    handleSelect?.({ [fieldName]: value });

    if (fieldName === 'evaluationScore') {
      dataTrack('click_process_kr_score');
    }
  };

  const onVisibleChange = (v: boolean) => {
    setVisible(v);
  };

  const renderStatusSelect = () => (
    <div>
      {STATUS_OPTION.map((item) => {
        const { color, label, key, popover } = item;
        return (
          <Tooltip
            overlay={popover}
            placement="right"
            overlayClassName="status-select-option-tooltip"
            key={key}
          >
            <div
              className="status-select-option"
              onClick={() => onSelectItem('evaluationStatus', key)}
            >
              <div className="status-light" style={{ borderColor: color, marginRight: 6 }} />
              <span style={{ color }}>{label}</span>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );

  const renderScoreSelect = () => (
    <div>
      {SCORE_LIST.map((item) => {
        const { color, key, popover } = item;
        return (
          <Tooltip
            overlay={popover}
            placement="right"
            overlayClassName="status-select-option-tooltip"
            key={key}
          >
            <div
              className="status-select-option"
              onClick={() => onSelectItem('evaluationScore', key)}
            >
              <div className="status-light" style={{ borderColor: color, marginRight: 6 }} />
              <span style={{ color }}>{key}</span>
            </div>
          </Tooltip>
        );
      })}
    </div>
  );

  const summaryScore = Math.floor(evaluationScore);

  const placeholder = readonly ? '未选择' : '请选择';

  const renderSelect = () =>
    selectedItem ? (
      <span style={{ color: selectedItem.color }}>{selectedItem.label}</span>
    ) : (
      placeholder
    );

  return (
    <div className="okr-status-container">
      <div className="status-label">
        {/* @ts-ignore */}
        {O_KR_TEXT_MAP[okrType]}
        {processSource === ProcessSourceTypeEnum.PROCESS_SUMMARY ? '评分' : '状态'}
      </div>
      <Popover
        open={visible}
        onOpenChange={onVisibleChange}
        trigger="click"
        content={
          processSource === ProcessSourceTypeEnum.PROCESS_CONCLUSION
            ? renderStatusSelect()
            : renderScoreSelect()
        }
        placement="bottom"
        overlayClassName="status-select-panel"
        //@ts-ignore
        getPopupContainer={(node) => node.parentElement}
      >
        <div
          className={`status-select ${isActive ? 'active' : ''} ${
            readonly || oDisabled ? 'readonly' : ''
          }`}
        >
          {selectedItem && processSource === ProcessSourceTypeEnum.PROCESS_CONCLUSION && (
            <div
              className="status-light"
              style={{ borderColor: selectedItem.color, marginBottom: 2 }}
            />
          )}
          {oDisabled ? (
            <span
              style={{
                color: evaluationScore
                  ? SCORE_LIST.find((item) => item.key === summaryScore)?.color
                  : '#999',
                textAlign: 'center',
              }}
            >
              {evaluationScore || 'KR平均分'}
            </span>
          ) : (
            renderSelect()
          )}
          <CaretDownOutlined className="arrow-icon" />
        </div>
      </Popover>
    </div>
  );
}

export default memo(OkrStatus);
