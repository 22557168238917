// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/srv/nbs/0/heran/okr2023-static/okr2023-static-docker-ehr_online/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/layouts/index.tsx').default,
    "routes": [
      {
        "path": "/404",
        "exact": true,
        "component": require('@/pages/404/index.tsx').default
      },
      {
        "path": "/discover/all",
        "exact": true,
        "component": require('@/pages/discover/all/index.tsx').default
      },
      {
        "path": "/discover/detail/:id/:type/:userId",
        "exact": true,
        "component": require('@/pages/discover/detail/[id]/[type]/[userId]/index.tsx').default
      },
      {
        "path": "/discover/follow",
        "exact": true,
        "component": require('@/pages/discover/follow/index.tsx').default
      },
      {
        "path": "/discover",
        "exact": true,
        "component": require('@/pages/discover/index.tsx').default
      },
      {
        "path": "/discover/team",
        "exact": true,
        "component": require('@/pages/discover/team/index.tsx').default
      },
      {
        "path": "/discover/:id",
        "exact": true,
        "component": require('@/pages/discover/[id]/index.tsx').default
      },
      {
        "path": "/",
        "exact": true,
        "component": require('@/pages/index.tsx').default
      },
      {
        "path": "/message",
        "exact": true,
        "component": require('@/pages/message/index.tsx').default
      },
      {
        "path": "/okr/align-map",
        "exact": true,
        "component": require('@/pages/okr/align-map/index.tsx').default
      },
      {
        "path": "/okr",
        "exact": true,
        "component": require('@/pages/okr/index.tsx').default
      },
      {
        "path": "/okr/organization",
        "exact": true,
        "component": require('@/pages/okr/organization/index.tsx').default
      },
      {
        "path": "/okr/personal",
        "exact": true,
        "component": require('@/pages/okr/personal/index.tsx').default
      },
      {
        "path": "/okr/share",
        "exact": true,
        "component": require('@/pages/okr/share/index.tsx').default
      },
      {
        "path": "/process/dashboard/detail",
        "exact": true,
        "component": require('@/pages/process/dashboard/detail.tsx').default
      },
      {
        "path": "/process/dashboard",
        "exact": true,
        "component": require('@/pages/process/dashboard/index.tsx').default
      },
      {
        "path": "/process/list",
        "exact": true,
        "component": require('@/pages/process/list/index.tsx').default
      },
      {
        "path": "/team/:id",
        "exact": true,
        "component": require('@/pages/team/[id]/index.tsx').default
      },
      {
        "path": "/setting",
        "routes": [
          {
            "path": "/setting/operation",
            "exact": true,
            "component": require('@/pages/setting/operation/index.tsx').default
          },
          {
            "path": "/setting/period",
            "exact": true,
            "component": require('@/pages/setting/period/index.tsx').default
          },
          {
            "path": "/setting/report/detail",
            "exact": true,
            "component": require('@/pages/setting/report/detail/index.tsx').default
          },
          {
            "path": "/setting/report/detail/table",
            "exact": true,
            "component": require('@/pages/setting/report/detail/table/index.tsx').default
          },
          {
            "path": "/setting/report/detail/table/table1",
            "exact": true,
            "component": require('@/pages/setting/report/detail/table/table1.tsx').default
          },
          {
            "path": "/setting/report/detail/table/table2",
            "exact": true,
            "component": require('@/pages/setting/report/detail/table/table2.tsx').default
          },
          {
            "path": "/setting/report/detail/table/table3",
            "exact": true,
            "component": require('@/pages/setting/report/detail/table/table3.tsx').default
          },
          {
            "path": "/setting/report",
            "exact": true,
            "component": require('@/pages/setting/report/index.tsx').default
          },
          {
            "path": "/setting/share",
            "exact": true,
            "component": require('@/pages/setting/share/index.tsx').default
          },
          {
            "path": "/setting/view-panel",
            "exact": true,
            "component": require('@/pages/setting/view-panel/index.tsx').default
          }
        ],
        "component": require('@/pages/setting/_layout.tsx').default
      },
      {
        "component": require('@/pages/404/index.tsx').default
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
